<template>
    <div
      class="
        flex
        space-x-8
        justify-between
        group group-hover:font-semibold
        flex-grow-0
      "
    >
      <count-card v-for="(report, i) in reports" :key="i" :report="report" />
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex';
  import CountCard from './CountCard.vue';
  export default {
    components: { CountCard },
    data() {
      return {
        reports: [
          {
            icon: 'user',
            title: 'Verifiied students',
            count: '',
            color: '#4600FF',
          },
          {
            icon: 'user-lock',
            title: 'Suspended students',
            count: '',
            color: '#FF0074',
          },
          {
            icon: 'user',
            title: 'Pending students',
            count: '',
            color: 'orange',
          },
          {
            icon: 'dollar-sign',
            title: 'Unpaid Bills in ETB',
            count: '',
            color: '#38B2AC',
          },
          {
            icon: 'dollar-sign',
            title: 'Paid Bills in ETB',
            count: '',
            color: '#38B2AC',
          },
          {
            icon: 'chart-line',
            title: 'paid bills',
            count: '',
            color: '#4299E1',
          },
          {
              icon: 'chart-line',
              title: 'unpaid bills',
              count: '',
              color: '#4299E1',
            },
            {
              icon: 'home',
              title: 'Schools',
              count: '',
              color: '#4299E1',
            },
        ],
        urls: {
          vendor: {
            customers: 'all-total-customers',
            bills: 'all-total-bills',
          },
          org: {
            customers: 'org-total-customers',
            bills: 'org-total-bills',
          },
          branch: {
            customers: 'branch-total-customers',
            bills: 'branch-total-bills',
          },
          officer: {
            customers: 'officer-total-customers',
            bills: 'officer-total-bills',
          },
        },
        permissions: {
          org: 'org',
          branch: 'branch',
          officer: 'officer',
          vendor: 'vendor',
          '': 'officer',
        },
      };
    },
    computed: {
      ...mapGetters(['user']),
      permission() {
        return this.permissions[this.user.dashboard];
      },
      customersUrl() {
        return '/billing/reports/total-customers';// + this.urls[this.permission]['customers'];
      },
      billsUrl() {
        return '/billing/reports/total-bills';// + this.urls[this.permission]['bills'];
      },
      SchoolsUrl() {
        return '/billing/utiltypes/total-report-orgs';// + this.urls[this.permission]['bills'];
      },
    },
    watch: {
      customersUrl: {
        deep: true,
        immediate: true,
        handler: function () {
          this.getTotalCustomers();
        },
      },
      billsUrl: {
        deep: true,
        immediate: true,
        handler: function () {
          this.getTotalBillsUploaded();
        },
      },
      SchoolsUrl: {
        deep: true,
        immediate: true,
        handler: function () {
          this.getTotalSchools();
        },
      },
    },
    methods: {
      ...mapActions('main', ['request']),
      async getTotalCustomers() {
        const response = await this.request({
          url: this.customersUrl,
          method: 'get',
        });
        if (response) {
          this.reports[0].count =
            response.items.find(({ _id }) => _id == 'Verified')?.count || 0;
          this.reports[1].count =
            response.items.find(({ _id }) => _id == 'Suspended')?.count || 0;
          this.reports[2].count =
            response.items.find(({ _id }) => _id == 'Pending')?.count || 0;
          console.log(response);
        }
      },
      async getTotalBillsUploaded() {
        const response = await this.request({
          url: this.billsUrl,
          method: 'get',
        });
        if (response) {
          this.reports[3].count = response.items[0]?.amount || 0;
          this.reports[4].count = response.items[1]?.amount || 0;
          this.reports[5].count = response.items[1]?.count || 0;
          this.reports[6].count = response.items[0]?.count || 0;
        }
      },
      async getTotalSchools() {
        const response = await this.request({
          url: this.SchoolsUrl,
          method: 'post',
        });
        if (response) {
          this.reports[7].count = response[0]?.count || 0;
        }
      },
    },
    created() {},
  };
  </script>
  
  <style>
  </style>