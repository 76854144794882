<template>
  <div class="space-y-2  text-sm">
    <div
      class="
        uppercase
        text-lg
        tracking-widest
        flex
        space-x-6
        w-full
        justify-between
        shadow-md
        p-4
      "
    >
      <div class="text-textMedium lg:grid lg:grid-cols-4 lg:gap-x-4 flex-grow">
        <div class="flex space-x-4">
          <base-input
            type="date"
            label="STARTING DATE"
            placeholder=""
            v-model="Form.payment_start_date"
            :errors="formErrors['payment_start_date']"
            bordered
            required
          />
          <base-input
            type="date"
            label="DUE DATE"
            placeholder=""
            v-model="Form.payment_due_date"
            :errors="formErrors['payment_due_date']"
            bordered
            required
          />
         
          <base-select
            label="PAYMENT TERM"
            placeholder="Select term"
            v-model="Form.paymentTermId"
            :items="paymentTermOptions"
            :errors="formErrors['paymentTermId']"
            bordered
            required
            class="flex-shrink-0"
          />
          <base-select
            label="PAYMENT TYPE"
            placeholder="Select"
            v-model="Form.paymentType"
            :items="paymentTypeOptions"
            :errors="formErrors['paymentType']"
            bordered
            required
            class="flex-shrink-0"
          />
        </div>
        <div class="flex flex-grow space-x-4">
          <!-- <base-text-area
            :rows="1"
            label="DESCRIPTION"
            placeholder="DESCRIPTION"
            v-model="Form.description"
            :errors="errors['description']"
            bordered
            required
            class="w-full"
          /> -->
        </div>
      </div>

      <div
        class="
          grid
          lg:grid-cols-2
          gap-1
          border border-borderGray
          rounded
          ml-5
          w-56
          text-center
          flex-shrink-0
        "
      >
        <div class="  space-py-0 p-2 uppercase bg-brighterGray">
          <div class="text-textMedium text-lg font-medium">
            {{ totalCustomers | formateNumber }}
          </div>
          <div class="text-textLight text-sm">total customers</div>
        </div>
        <div class="space-y-0 py-2 uppercase">
          <div class="text-textMedium text-gl font-medium">
            {{ totalAmount | formateNumber }}
          </div>
          <div class="text-textLight text-sm">total payment</div>
        </div>
      </div>
    </div>
    <div class="space-y-1">
      <data-table
        :headers="headers"
        :items="payments"
        :isWorking="isLoading"
        :hideCount="true"
        :stripped="false"
        :canSelect="false"
        :searchable="true"
        :paginate="true"
      >
        <template #toolset>
          <div class="flex flex-wrap">
            <base-button
              :isWorking="isWorking"
              label="Upload Payment"
              icon="plus"
              class="px-2 text-sm uppercase rounded"
              @click="preUploadPayment"
            />
          </div>
        </template>
        <template #default="{ index }">
          <td
            v-for="header in headers"
            :key="header.key"
            class="align-top"
            :style="{
              width:
                header.key === 'utile_acc_no'
                  ? '150px'
                  : header.key === 'amount'
                  ? '200px'
                  : undefined
            }"
          >
            <div
              v-if="header.key === 'customer_name'"
              class="flex space-x-2 items-center"
            >
              <div class="flex-auto">
                <base-input
                  type="text"
                  v-model="payments[index].customer_name"
                  :errors="errors[index].customer_name"
                  bordered
                  padding="small"
                />
              </div>
            </div>
            <div v-else-if="header.key === 'utile_acc_no'" class="px-1">
              <base-input
                v-model="payments[index].utile_acc_no"
                :errors="errors[index].utile_acc_no"
                bordered
                padding="small"
                @enter="displayCustomer(payments[index].utile_acc_no, index)"
                @blur="
                  fetchCustomerByAccNo(payments[index].utile_acc_no, index)
                "
              />
            </div>
            <div
              v-else-if="header.key === 'amount'"
              class="flex space-x-4 items-start"
            >
              <base-input
                v-model="payments[index].amount"
                :errors="errors[index].amount"
                bordered
                padding="small"
                class="flex-auto"
                @enter="addRow(index)"
              />
            </div>
            <div
              v-else-if="header.key === 'trans_date'"
              class="flex space-x-4 items-start px-1"
            >
              <base-input
                v-model="payments[index].trans_date"
                :errors="errors[index].trans_date"
                bordered
                padding="small"
                class="flex-auto"
                @enter="addRow(index)"
              />
            </div>
            <div
              v-else-if="header.key === 'description'"
              class="flex space-x-4 items-start"
            >
              <base-input
                v-model="payments[index].description"
                :errors="errors[index].description"
                bordered
                padding="small"
                class="flex-auto"
                @enter="addRow(index)"
              />
            </div>
            <div
              v-else-if="header.key === 'action'"
              class="flex space-x-4 items-start"
            >
              <div
                class="
                  p-3
                  bg-brighterGray
                  text-textMedium
                  hover:bg-brightGray
                  hover:text-error
                  cursor-pointer
                  active:bg-danger
                  rounded-full
                "
                @click="removeRow(index)"
              >
                <BaseIcon name="times" :size="10" />
              </div>
            </div>
            <div v-else class="flex space-x-4 items-start px-1">
              <base-input
                v-model="payments[index][header.key]"
                :errors="errors[index][header.key]"
                bordered
                padding="small"
                class="flex-auto"
                @enter="addRow(index)"
              />
            </div>
          </td>
        </template>
        <template #no-data>
          <div :style="!allValid ? { color: '#ec3118' } : {}">No Record</div>
        </template>
      </data-table>
      <div class="flex space-x-2 justify-end">
        <input
          v-if="fileSelector"
          type="file"
          class="hidden"
          ref="csv-file-input"
          @input="handleFileSelect"
        />
        <base-button
          :label="importing ? 'importing' : 'import csv'"
          :primary="false"
          class="text-sm px-2 rounded"
          :class="{ 'bg-brightGray hover:text-primary': !importing }"
          :isWorking="importing"
          @click="openFileSelector"
        />
        <base-button
          label="remove all rows"
          :primary="false"
          class="text-sm px-2 rounded bg-brightGray hover:text-primary"
          @click="removeAllRows"
        />
        <base-button
          label="add row"
          :primary="false"
          class="text-sm px-2 rounded bg-brightGray hover:text-primary"
          @click="addRow(payments.length - 1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';
import DataTable from '@/components/table/DataTable.vue';
import eventBus from '@/eventBus';
import billCustomFields from '@/mixins/billCustomFields.js';
// import eventBus from '@/eventBus';
export default {
  props: ['title'],
  components: { DataTable },
  mixins: [validation, billCustomFields],
  data() {
    return {
      allValid: false,
      isWorking: false,
      Form: {
        payment_due_date: undefined,
        payment_start_date: undefined,
        paymentType: undefined,
        paymentTermId: undefined
        // description: undefined,
      },
      payments: [],
      paymentTermOptions: [],
      paymentTypeOptions: [],
      errors: [],
      formErrors: {},
      importing: false,
      unwathcCallbacks: [],
      fileSelector: false
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
    headers() {
      return [
        { key: 'cust_util_id', label: 'student id' },
        ...this.customFields.map(({ key, name }) => ({
          key: key,
          label: name
        })),
        { key: 'total_amount', label: 'Total Amount' },
        { key: 'action', label: undefined }
      ];
    },
    formTemplate() {
      let template = {
        cust_util_id: undefined
      };
      if (this.customFields)
        this.customFields.forEach(({ key }) => {
          template[key] = undefined;
        });
      template['total_amount'] = 0;
      return template;
    },

    totalAmount() {
      return this.payments.reduce(
        (acc, { total_amount }) => acc + Number(total_amount),
        0
      );
    },
    totalCustomers() {
      return this.payments.length;
    },
    customers() {
      return this.payments.map(payee =>
        Object.keys(this.formTemplate).reduce(
          (acc, key) => ({
            ...acc,
            [key]: key === 'amount' ? +payee[key] : payee[key]
          }),
          {}
        )
      );
    }
  },
  watch: {
    // customers() {
    //   this.smartSet({ stateKey: 'customers', data: this.customers });
    // },

    initial: {
      immediate: true,
      handler() {
        this.validationHandler();
        // this.setVal();
      }
    },
    Form: {
      immediate: false,
      deep: true,
      handler() {
        this.validationHandler();
        // this.setVal();
      }
    },
    payments: {
      immediate: true,
      deep: true,
      handler() {
        this.validationHandler();
        // this.setVal();
      }
    },
    headers() {
      this.validationHandler();
    }
  },
  methods: {
    ...mapActions('main', ['request']),
    validationHandler() {
      this.resetErrors(0);
      if (!this.initial) {
        this.payments.forEach((payee, i) =>
          Object.keys(this.formTemplate).forEach(key => {
            if (!payee[key])
              this.errors[i][key] = [
                this.headers.find(header => header.key === key).label +
                  ' is required'
              ];
            else this.errors[i][key] = [];
          })
        );
        Object.keys(this.Form).forEach(key => {
          if (!this.Form[key]) {
            this.formErrors[key] = `${key} is required`;
          } else {
            this.formErrors[key] = ``;
          }
        });
      }
    },

    addRow(index) {
      this.payments = [
        ...this.payments.slice(0, index + 1),
        { ...this.formTemplate },
        ...this.payments.slice(index + 1).map(({ ...rest }) => ({ ...rest }))
      ];
    },
    removeRow(index) {
      this.payments = [
        ...this.payments.slice(0, index),
        ...this.payments.slice(index + 1).map(({ ...rest }) => ({ ...rest }))
      ];
    },
    removeAllRows() {
      this.payments = [];
      this.errors = [];
    },
    importCsv(file) {
      this.importing = true;
      this.$papa.parse(file, {
        skipEmptyLines: true,
        complete: ({ data }) => {
          this.importing = false;
          // let lastIndex = this.payments.length;
          data.slice(1).forEach(bill => {
            const cust_bill = Object.keys(this.formTemplate).reduce(
              (customer, key, i) => ({ ...customer, [key]: bill[i] }),
              {}
            );
            data.total_amount = Number(data.total_amount);
            this.payments.push(cust_bill);
          });
          this.resetFile();
        }
      });
    },
    handleFileSelect() {
      let file = this.$refs['csv-file-input'].files[0];
      this.importCsv(file);
    },
    openFileSelector() {
      this.fileSelector = true;
      this.$nextTick(() => {
        this.$refs['csv-file-input'].click();
      });
    },
    resetFile() {
      this.fileSelector = false;
    },
    resetErrors(startIndex) {
      if (startIndex < 0) startIndex = 0;
      this.payments
        .slice(startIndex)
        .forEach(
          (payee, i) =>
            (this.errors[i + startIndex] = Object.keys(
              this.formTemplate
            ).reduce((acc, key) => ({ ...acc, [key]: [] }), {}))
        );
    },
    resetForm() {
      this.payments = [];
    },
    async preUploadPayment() {
      this.initial = false;
      if (!this.isValid()) return;
      let componentProps = {
        title: 'UPLOAD ' + this.Form.payment_type + ' PAYMENT',
        message:
          'Are you sure you really want to upload ' +
          this.totalCustomers.toLocaleString() +
          ' total customers and ' +
          this.totalAmount.toLocaleString() +
          ' of total payment'
      };
      eventBus.$emit('open-modal', {
        modalId: 3,
        componentProps,
        cb: this.uploadPayment
      });
    },
    async uploadPayment() {
      this.isWorking = true;
      const response = await this.request({
        method: 'post',
        url: 'billing/utilcustomers/add',
        data: {
          ...this.Form,
          customers: this.payments
          // total_amount: this.totalAmount,
        }
      });
      if (response) {
        eventBus.$emit('open-toast', {
          error: false,
          message: 'payment uploaded successfully'
        });
        if (this.isGranted('0999up')) this.$router.push('/payments');
        else this.$router.push('/');
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror,
          timeout: 120
        });
      }
      this.isWorking = false;
    },
    async getPaymentTypes() {
      const response = await this.request({
        url: 'billing/paymentType?limit=' + 100000
      });
      if (response) {
        this.paymentTypeOptions = response.items.map(
          ({ _id: value, title: label }) => ({ label, value })
        );
      } else {
        this.paymentTypeOptions = [];
      }
    },
    async getPaymentTerms() {
      const response = await this.request({
        url: 'billing/paymentTerm?limit=' + 100000
      });
      if (response) {
        this.paymentTermOptions = response.items.map(
          ({ _id: value, title: label }) => ({ label, value })
        );
      } else {
        this.paymentTermOptions = [];
      }
    }
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce(acc => {
          acc['paymentType'] = { required };
          acc['paymentTermId'] = { required };
          acc['payment_start_date'] = { required };
          acc['payment_due_date'] = { required };

          return acc;
        }, {})
      },
      payments: {
        ...this.payments.reduce(
          (acc, payee, index) => ({
            ...acc,
            [index]: {
              ...Object.keys(this.formTemplate).reduce(
                (acc, key) => ({ ...acc, [key]: { required } }),
                {}
              )
            }
          }),
          {}
        )
      }
    };
  },
  created() {
    this.getPaymentTerms();
    this.getPaymentTypes();
  }
};
</script>

<style></style>
