<template>
  <div v-if="!isLoading" class="w-full px-6 flex flex-col space-y-6">
    <div class="flex flex-col space-y-0">
      <!-- <div class="py-4 capitalize">quantity</div> -->
      <div
        class="lg:flex w-full space-y-4 lg:space-y-0 lg:space-x-6 justify-between"
      >
        <div
          class="border border-green-300 w-full px-4 py-2 rounded-md flex flex-col space-y-2"
        >
          <p class="text-[#171725]">Paid</p>
          <div>
            <div class="flex space-x-3 items-center">
              <p class="text-4xl text-green-600">
                {{ formateNumber(getReport(true).amount) }}
              </p>

            </div>
            <div>
            </div>
          </div>
        </div>
        <div
          class="border border-orange-400 w-full lg:w-[32%] px-4 py-2 rounded-md flex flex-col space-y-2"
        >
          <p class="text-[#171725]">Unpaid</p>
          <div>
            <div class="flex space-x-3 items-center">
              <p class="text-4xl text-orange-500">
                {{ formateNumber(getReport(false).amount ) }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="border border-red-400 w-full lg:w-[32%] px-4 py-2 rounded-md flex flex-col space-y-2"
        >
          <p class="text-[#171725]">Penalty</p>
          <div>
            <div class="flex space-x-3 items-center">
              <p class="text-4xl text-error">
                {{ formateNumber(getReport(true).penalty + getReport(false).penalty) }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="border border-red-400 w-full lg:w-[32%] px-4 py-2 rounded-md flex flex-col space-y-2"
        >
          <p class="text-[#171725]">TotalBills</p>
          <div>
            <div class="flex space-x-3 items-center">
              <p class="text-4xl text-error">
            <span class="text-success text-xs">  Paid:</span>  {{ formateNumber(getReport(true).count ) }}
              </p>
              <p class="text-4xl text-error">
                <span class=" text-primary text-xs">  UnPaid:</span>
             {{ formateNumber(getReport(false).count ) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  props: {
    url: { type: String, default: 'billing/reports/summary-report' },
    query: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      isLoading: true,
      report: []
    };
  },
  watch: {
    url() {
      this.fetchReport();
    },
    query() {
      this.fetchReport();
    }
  },
  methods: {
    ...mapActions('main', ['request']),
    formateNumber(number=0) {
      return number.toLocaleString()
    },
    async fetchReport() {
      this.isLoading = true;
      console.log(this.$route.params.id)
      const queryToUrl = Object.entries(this.query).reduce((acc, [key, value]) => (`${acc}&${key}=${value}`), '')
      const response = await this.request({
        method: 'get',
        url: `${this.url}?${queryToUrl}id=${this.$route.params.id}`
      });
      if (response) {
        this.report = response.item;
      }
      this.isLoading = false;
    },
    getReport(status) {
      if (this.report && this.report.length)
        return (
          this.report.find(({ _id }) => _id == status) ||
          {
            penalty: 0,
            amount: 0,
            count: 0,
            remaining: 0

          }
        );
      return {};
    }
  },
  created() {
    this.fetchReport();
  }
};
</script>
<style></style>
