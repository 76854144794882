<template>
  <div class="space-y-2">
    <div v-for="(student, i) in items" :key="i">
      <StudentCard :student="student" />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import StudentCard from "./StudentCard.vue";
export default {
  props: ['keyword'],
  components: { StudentCard },
  data() {
    return {
      items: [],
    }
  },
  watch: {
    keyword() {
      this.searchStudents()
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    async searchStudents() {
      try {
        const res = await this.request({
          url: "billing/customer/search?keyword=" + this.keyword,
          method: 'get',
        });
        if (res) {
          this.items = res.items;
        } else {
          this.items = [];
        }
      } catch (error) {
        console.error(error);
        this.items = [];
      }
    }
  },
  mounted() {
    this.searchStudents()
  }
}
</script>
