<template>
  <div class="p-2 pb-48">
    <div class="px-7 w-2/3 mx-auto">
      <div class="text-2xl text-textMedium mb-2">
        Student Registration Form
      </div>
      <stepper
        :activeStep="activeStep"
        :errorSteps="errorSteps"
        :steps="smartSteps"
        @changeStep="handleChangeStep"
      />
      <!-- {{ errorSteps }} -->
    </div>

    <div class="py-2 space-y-2">
      <div
        v-show="steps[activeStep] === 'Basic details'"
        class="lg:w-2/3 mx-auto grid grid-cols-2 gap-3"
      >
        <base-input
          :label="labels.fullName"
          :placeholder="labels.fullName"
          :bordered="true"
          required
          class="col-span-2"
          v-model="Form.fullName"
          :errors="errors.fullName"
        />
        <base-input
          v-if="!myOrganization.autoGenerateStudentId"
          :label="labels.studentSchoolId"
          :placeholder="labels.studentSchoolId"
          :bordered="true"
          required
          v-model="Form.studentSchoolId"
          :errors="errors.studentSchoolId"
        >
          <!-- <template #suffix>
            <base-button
              label="Generate"
              class="px-1 rounded-r uppercase max-w-fit"
            />
          </template> -->
        </base-input>
        <base-input
          :label="labels.tel"
          placeholder="2519---------"
          :bordered="true"
          required
          v-model="Form.tel"
          :errors="errors.tel"
        />
        <!-- <base-input
          label="Account Number"
          placeholder="-------------"
          :bordered="true"
          required
          v-model="Form.accountNumber"
          :errors="errors.accountNumber"
          @blur="validateAccountNumber"
          class="col-span-2"
        /> -->
        <base-input
          label="Grade"
          placeholder="Grade 1"
          :bordered="true"
          v-model="Form.grade"
          :errors="errors.grade"
        />
        <base-input
          label="Section"
          placeholder="A"
          :bordered="true"
          v-model="Form.section"
          :errors="errors.section"
        />
      </div>
      <!-- v-show="steps[activeStep] === 'Photo'" -->
      <div class="lg:w-2/3 mx-auto">
        <div
          class="
            bg-brightGray
            text-textDarkest
            hover:text-primary
            cursor-pointer
            h-24
            flex
            space-x-2
            items-center
            justify-center
            rounded
          "
          @click="openFileDialog('photo-file-input')"
        >
          <input
            type="file"
            ref="photo-file-input"
            accept="image/x-png,image/jpeg"
            class="hidden"
            @change="handleFileSelect('photo-file-input', 'photo')"
          />
          <base-icon name="image" :size="32" />
          <div class="text-lg">Select photo</div>
        </div>
        <div class="h-24 flex flex-col items-center justify-center">
          <img
            v-if="photoObjUrl"
            class="max-h-full max-w-full"
            :src="photoObjUrl"
            alt=""
          />
          <div v-else class="text-textMedium">
            student's photo that you are gonna select will be displayed over
            here.
          </div>
        </div>
      </div>
      <div
        class="flex pt-4 w-2/3 mx-auto"
        :class="activeStep === 0 ? 'justify-end' : 'justify-between'"
      >
        <base-button
          v-if="activeStep > 0"
          label="Back"
          :primary="false"
          class="px-4 rounded bg-brightGray text-textMedium hover:text-primary"
          @click="handleBack"
        />
        <base-button
          v-if="activeStep < steps.length - 1"
          label="Continue"
          class="px-4 rounded"
          @click="handleContinue"
        />
        <base-button
          v-else
          label="Register"
          :isWorking="working"
          @click="registerCustomer"
          class="px-4 rounded"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import eventBus from '@/eventBus';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';
import { custLabels } from '@/config/staticData';
import Stepper from '../card/Stepper.vue';
export default {
  components: { Stepper },
  props: {oldCustomer: {type: Object, default:  () => ({})}},
  mixins: [validation],
  data() {
    return {
      myOrganization: {},
      studentSchoolId: undefined,
      activeStep: 0,
      steps: ['Basic details'],
      personRegErrors: [],
      customer: undefined,
      working: false,
      customerName: undefined,
      errors: {},
      customerCreated: false,
      Form: {
        fullName: undefined,
        tel: undefined,
        // accountNumber: undefined,
        grade: undefined,
        section: undefined,
        studentSchoolId: undefined,
        photo: undefined
      },
      defaultForm: {
        tel: undefined
      },
      labels: custLabels,
      photoObjUrl: undefined,

      meta: [],
      isValidatingAccount: false,
      isValidAccount: false,
      initial: true
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
    ...mapGetters(['user']),
    requiredFields() {
      let fields = ['fullName', 'tel'];
      if (!this.myOrganization.autoGenerateStudentId) fields.push('studentSchoolId');
      return fields;
    },

    smartSteps() {
      return this.steps;
    },

    errorSteps() {
      let arr = [];
      // if (this.type === 'KYC') {
      // } else if (this.type === 'Company') {
      // } else if (this.type === 'Person') {
      //   Object.keys(this.stepFields.Person).forEach(key => {
      //     if (
      //       this.stepFields.Person[key].some(k => {
      //         return this.personRegErrors.includes(k);
      //       })
      //     ) {
      //       arr.push(key);
      //     }
      //   });
      // }

      return arr;
    }
  },
  methods: {
    ...mapActions('main', ['upload', 'request']),
    handleBack() {
      this.activeStep--;
    },
    handleContinue() {
      this.activeStep++;
    },
    reset() {
      this.activeStep = 0;
    },
    handleChangeStep(step) {
      this.activeStep = step;
    },
    handleStepError(errors) {
      this.personRegErrors = [];

      Object.keys(errors).forEach(key =>
        errors[key].length > 0 ? this.personRegErrors.push(key) : undefined
      );

      // Object.keys(errors).forEach((key) => {
      //   if (errors[key].length) {
      //     this.personRegErrors.push(key);
      //   }
      // });
    },
    resetForm() {
      Object.keys(this.Form).forEach(
        key =>
          (this.Form[key] =
            key in this.defaultForm ? this.defaultForm[key] : undefined)
      );
      this.photoObjUrl = undefined;
    },
    validatePhone(phoneNumber) {
      return "/^[+]251[39]{1}[0-9]{8}$/".test(phoneNumber);
    },
    handlePhoneValidation() {
      if (!this.validatePhone(this.Form.tel)) {
        this.errors.tel = ['Invalid phone number'];
      }
    },
    openFileDialog(ref) {
      this.$refs[ref].click();
    },
    handleFileSelect(ref, key) {
      this.Form[key] = this.$refs[ref].files[0];

      this.setObjURL(key);
    },
    setObjURL(key) {
      const urlKey = [key + 'ObjUrl'];
      if (this[urlKey]) URL.revokeObjectURL(this[urlKey]);
      if (this.Form[key]) this[urlKey] = URL.createObjectURL(this.Form[key]);
    },
    async checkCustomerExist() {
      if (this.Form.studentSchoolId) {
        let customerExist = await this.request({
          method: 'post',
          url: 'billing/customer/customerExists',
          data: { studentSchoolId: this.Form.studentSchoolId }
        });
        if (customerExist && !customerExist.error) {
          eventBus.$emit('open-modal', {
            modalId: 128,
            componentProps: { customer: customerExist.items[0] },
            cb: this.registerCustomer()
          });
        }
      } else {
        this.registerCustomer();
      }
    },
    async registerCustomer() {
      this.initial = false;
      // if(this.Form.accountNumber && !this.isValidAccount) {
      //   eventBus.$emit('open-toast', {
      //     error: true,
      //     message: "Account number is not valid",
      //   });
      //   return;
      // }
      /// NOTE: REMOVED PHONE VALIDATION

      // if (!this.isValid()) {
      //   return;
      //   // return eventBus.$emit('open-toast', {
      //   //   error: true,
      //   //   message: 'Please insert all mandatory fields',
      //   // });
      // }

      // if (this.Form.tin && this.Form.tin.length !== 10) {
      //   return (this.errors.tin = ['tin number should be 10 digits']);
      // }

      this.working = true;
      let { photo, ...restData } = this.Form;

      let response = await this.request({
        method: this.customer ? 'put' : 'post',
        url: this.customer
          ? `billing/customer/${this.customer._id}`
          : 'billing/customer',
        data: restData
      });
      if (response && !response.error) {
        if (photo) {
          let data = new FormData();
          data.append('photo', photo);
          await this.upload({
            method: 'post',
            url: 'billing/customer/photo/' + response.item._id,
            data
          });
        }

        eventBus.$emit('open-toast', {
          message: 'Student registered successfully'
        });

        this.initial = true;
        this.customerCreated = true;

        eventBus.$emit('open-modal', {
          modalId: 20,
          componentProps: {
            customer: response.item
          }
        });

        // this is the id which is generated by us
        this.resetForm();
        this.customerRegistered();
      } else {
        eventBus.$emit('open-toast', {
          message: this.berror ? this.berror : 'Something went wrong',
          error: true
        });
      }
      this.working = false;
    },
    customerRegistered() {
      this.$router.push('/register-customer');
      this.$emit('registered');
    },

    openCustomerSelector(option = undefined) {
      eventBus.$emit('open-modal', {
        modalId: 1,
        cb:
          option === 'blind-guardian'
            ? this.selectGuardianBlind
            : this.selectParent
      });
    },
    async getMyOrganization() {
      const res = await this.request({
        method: 'get',
        url: 'billing/utiltypes/myorg'
      });
      if (res) {
        this.myOrganization = res.item;
      }
    },
    // async validateAccountNumber() {
    //   this.isValidatingAccount = true;
    //   const response = await this.request({
    //     method: 'get',
    //     url: `billing/customer/account-validation/${this.Form.accountNumber}`,
    //   });
    //   if (response) {
    //     //
    //     console.info({response});
    //     this.isValidAccount = true;
    //   } else {
    //     this.isValidAccount = false;
    //     eventBus.$emit('open-toast', {
    //       error: true,
    //       message: this.berror,
    //     });
    //   }
    //   this.isValidatingAccount = false
    // },

  },
  watch: {
    errors: function() {
      this.$emit('errors', this.errors);
    },
    'Form.tel': {
      deep: true,
      immediate: false,
      handler: function() {
        // if (!this.initial) 
        // this.handlePhoneValidation();
      }
    }
  },
  created() {
    this.getMyOrganization();
  },
  mounted() {
    Object.keys(this.Form).forEach(key => {
      this.$watch('Form.' + key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (this.requiredFields.includes(key)) {
            acc[key] = { required };
          }
          console.info('acc ', acc);
          return acc;
        }, {})
      }
    };
  }
};
</script>
<style></style>
