<template>
  <div class="space-y-3 pb-10">
    <div v-if="loadingCustomer" class="flex justify-center items-center">
      <bar-loader />
    </div>
    <div
      v-else-if="customer"
      class="lg:flex w-full space-x-5 space-y-5 lg:space-y-0"
    >
      <div class="space-y-5 lg:w-1" :class="{ 'lg:w-1/3': customer }">
        <div class="space=y-4">
          <base-button
            label="GO BACK"
            class="bg-brightGray text-textDark px-4 w-32"
            @click="goback"
          />
          <div class="w-full space-y-4">
            <pannel title="STUDENT DETAILS" class="w-full">
              <div class="flex w-full items-center text-textMedium">
                <div
                  @mouseenter="hovered = true"
                  @mouseleave="hovered = false"
                  class="w-full space-y-4"
                >
                  <div
                    class="
                      col-span-2
                      py-2
                      pt-4
                      font-bold
                      text-2xl
                      border-b-2 border-textLight border-dashed
                      text-primary text-center
                      capitalize
                      inline-flex items-center space-x-3 w-full
                    "
                  >
                    <span v-if="!editing" class="w-full">{{
                      customer.fullName
                    }}</span>
                    <base-icon
                      v-if="hovered && canEdit && !editing"
                      name="pencil-alt"
                      class="cursor-pointer"
                      @click="
                        editing = true;
                        Form.fullName = customer.fullName;
                      "
                    />
                    <input
                      v-if="editing"
                      v-model="Form.fullName"
                      class="font-bold text-2xl border-b-2 border-textLight  text-primary text-center"
                    />
                    <base-button
                      v-if="editing"
                      icon="check-circle"
                      label="save"
                      :primary="false"
                      class="bg-gray-400 rounded"
                      padding="small"
                      @click="handleUpdate"
                    />
                  </div>
                  <div class="flex space-x-3">
                    <div>
                      <div class="text-center uppercase text-lg">photo</div>
                      <div
                        class="
                          w-40
                          h-32
                          rounded
                          bg-brightGray
                          relative
                          z-10
                          grow-active
                          group
                        "
                      >
                        <div
                          class="
                            absolute
                            top-0
                            right-0
                            bottom-0
                            left-0
                            z-20
                            bg-black
                            opacity-50
                            text-white
                            hidden
                            group-hover:flex
                            justify-center
                            items-center
                            cursor-pointer
                          "
                          @click="expandImg('photo-img')"
                        >
                          <base-icon name="expand" :size="20" />
                        </div>
                        <img
                          ref="photo-img"
                          class="w-full h-full rounded"
                          v-if="customer.photo"
                          :src="PROFILE_PATH + customer.photo"
                          v-viewer="{ inline: false, movable: true }"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="flex w-full space-x-4 justify-around">
                      <div class="shadow-md p-2 uppercase space-y-2">
                        <div class="sm:grid grid-cols-2 gap-2 row-gap-0">
                          <div>Status</div>
                          <div class="font-bold">{{ customer.status }}</div>
                        </div>

                        <div class="sm:grid grid-cols-2 gap-2 row-gap-0">
                          <div>Telephone</div>
                          <div class="font-bold">
                            {{ customer.tel | beautify }}
                          </div>
                        </div>

                        <div class="sm:grid grid-cols-2 gap-2 row-gap-0">
                          <div>ID:</div>
                          <div class="font-bold">
                            {{ customer.studentSchoolId }}
                          </div>
                        </div>

                        <div
                          class="sm:grid grid-cols-2 gap-2 row-gap-0"
                          v-if="customer.office"
                        >
                          <div>Branch :</div>
                          <div class="font-bold">
                            {{ customer.office.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </pannel>
          </div>
        </div>
      </div>
      <customer-bills
        class="lg:w-2/3"
        v-if="customer"
        :studentSchoolId="customer.studentSchoolId"
        @close="goback"
      />
    </div>
    <div class="flex justify-center w-full" v-else>
      <img src="@/assets/page_not_found.svg" class="w-1/2" />
    </div>
  </div>
</template>

<script>
import { PROFILE_PATH, SIGNATURE_PATH } from '@/config/config';
import { formatePhoneNumber } from '@/utils';
import { mapActions, mapGetters } from 'vuex';
import pannel from '@/components/card/pannel.vue';
import eventBus from '@/eventBus.js';
import CustomerBills from '@/components/card/customer/CustomerBills.vue';
// import KYCDetail from './KYCDetail.vue';
export default {
  components: { pannel, CustomerBills },
  data() {
    return {
      working: false,
      initial: true,
      loadingCustomer: true,
      isMal: false,
      PROFILE_PATH,
      SIGNATURE_PATH,
      customer: '',
      Form: {
        fullName: ''
      },
      hovered: false,
      canEdit: true,
      editing: false,

      bills: []
    };
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('main', ['berror'])
  },

  filters: {
    beautify: formatePhoneNumber,
    formateNumber(number) {
      return Number(number) ? Number(number).toLocaleString() : 0;
    }
  },

  methods: {
    ...mapActions('main', ['request']),
    async fetchCustomer() {
      this.loadingCustomer = true;
      let response = await this.request({
        method: 'get',
        url: `billing/customer/search-cust-id?studentSchoolId=${this.$route.query.id}`
      });

      if (response) {
        this.customer = response.item;
      }
      this.loadingCustomer = false;
    },
    async handleUpdate() {
      let response = await this.request({
        method: 'put',
        url: `billing/customer/${this.customer._id}`,
        data: this.Form
      });
      if (response) {
        this.customer.fullName = this.Form.fullName;
        eventBus.$emit('open-toast', {
          message: 'student name updated successfully',
          error: false
        });
        this.hovered = false;
        this.editing = false;
        this.Form.fullName = '';
      } else {
        eventBus.$emit('open-toast', {
          message: this.berror,
          error: true
        });
      }
    },
    goback() {
      this.$router.go(-1);
    },

    handleEditCustomer() {
      this.$router.push(`/customers/edit/${this.customer._id}/`);
    },

    async handleSuspendCustomer(status) {
      let response;
      this.working = true;
      response = await this.request({
        method: 'put',
        url: `customer/verify/${this.customer._id}`,
        data: {
          status
        }
      });

      if (!response.error) {
        eventBus.$emit('open-toast', {
          message: `Student ${status} successfully`
        });

        this.fetchCustomer();
      } else {
        eventBus.$emit('open-toast', {
          message: `Error changing status of student`
        });
      }
      this.working = false;
    },

    expandImg(ref) {
      this.$refs[ref].click();
    }
  },

  async created() {
    await this.fetchCustomer();
    // this.request({
    //   url: 'billing/utilcustomers/sign',
    //   method: 'get'
    // })
  }
};
</script>

<style></style>
