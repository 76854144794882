<template>
  <div class="text-textMedium space-y-4">
    <div
      class="
        text-2xl
        uppercase
        py-2
        shadow-md
        text-textDark
        font-semibold
        bg-brighterGray
        text-center
      "
    >
     {{action == 'reg' ? 'ADD NEW ROLE' : 'ROLE'}}
    </div>
    <form class="space-y-4 p-4 pb-4">
        <base-select
          label="School"
          :items="organizations"
          v-model="util_id"
          bordered
          :searchable="true"
        >
          <template #default="{ item }">
            <div class="flex space-x-2 items-center">
              <base-icon name="user" />
              <div class="capitalize">{{ item.label }}</div>
            </div>
          </template>
          <template #option="{ item }">
            <div class="flex space-x-2 items-center">
              <base-icon name="user" />
              <div>{{ item.label }}</div>
            </div>
          </template>
        </base-select>
      <base-input
        type="text"
        placeholder="Name"
        label="NAME"
        bordered
        :readOnly="action == 'view'"
        required
        :min="0"
        v-model="Form.role_name"
        @enter="handleSave"
      />
      <base-input
        type="text"
        placeholder="Code"
        label="Code"
        bordered
        :readOnly="action == 'view'"
        required
        :min="0"
        v-model="Form.role_code"
        @enter="handleSave"
      />
      <base-text-area
        placeholder="Description"
        type="text"
        :readOnly="action == 'view'"
        label="Description"
        bordered
        required
        v-model="Form.role_description"
      />
      <div class="flex justify-between mt-3">
        <base-button
          label="cancel"
          class="
            py-1
            px-4
            text-textMedium
            bg-brightGray
            hover:bg-danger
            hover:text-error
            rounded
          "
          @click="close"
        />
        <base-button
          :label="btnLabel"
          icon="check-circle"
          :isWorking="isWorking"
          class="py-1 px-4 rounded"
          @click="handleSave"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    action: {
      type: String,
      default: 'reg',
    },
    btnLabel: {
      type: String,
      default: 'Save',
    },
    role: {}
  },
  data() {
    return {
      isWorking: false,
      util_id: undefined,
      organizations: [],
      Form: {
        role_name: undefined,
        role_code: undefined,
        role_description: undefined,
      },
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    async handleSave() {
      let url = 'billing/roles/addorgrole';
      let method = 'post'
      if(this.action == 'edit' && this.role) {
        url = 'billing/roles/role/'+this.role._id;
        method= 'put'
      }
      this.isWorking = true;
      const response = await this.request({
        method,
        url,
        data: {...this.Form, util_id: this.util_id},
      });
      if (response) {
        this.isWorking = false;
        this.$emit('close', {
          invokeCallBack: true,
          response: response.item,
        });
      }
      this.isWorking = false;
    },
    async fetchOrganizations() {
      let response = await this.request({
        method: 'get',
        url: 'billing/utiltypes',
      });
      if (response) {
        this.organizations = response.items.map(({ util_name, _id }) => ({
          label: util_name,
          value: _id,
        }));
      }
    },
    close() {
      this.$emit('close');
    },
  },
  created() {
    this.fetchOrganizations()
    if (this.role) {
      this.util_id = this.role.util_id
      this.Form.role_name = this.role.role_name
      this.Form.role_code = this.role.role_code
      this.Form.role_description = this.role.role_description
    }
  },
};
</script>

<style></style>
