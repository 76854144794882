<template>
  <div class="w-full space-y-1">
    <label
      :style="'font-size: ' + size + 'px;'"
      class="text-textMedium"
      v-if="label"
      >{{ label }}<span v-if="required" class="text-error">*</span></label
    >
    <div class="text-textMedium relative">
      <div
        :style="'font-size: ' + size + 'px;'"
        class="
          flex
          space-x-3
          items-center
          rounded
          p-2
          border border-transparent
          cursor-pointer
        "
        :class="{
          'bg-blueGray': success && !bordered,
          'bg-danger': !success && !bordered,
          'border-borderGray': success && bordered,
          'border-error': !success && bordered,
          'py-2 px-3': padding === 'big',
          'py-1 px-2': padding === 'small'
        }"
        @click="handleOpen"
        v-outsideClick="{ handler: handleBlur }"
      >
        <div v-if="!itemSelected" class="flex-auto">Select</div>
        <div v-else class="flex-auto">
          <slot :item="itemSelected">{{ itemSelected[searchKey] }}</slot>
        </div>
        <BaseIcon
          v-if="selectorOpened"
          name="caret-up"
          class="text-textLight"
        />
        <BaseIcon v-else name="caret-down" class="text-textLight" />
        <slot name="suffix" class="flex-grow-0"></slot>
      </div>

      <div
        v-if="selectorOpened"
        class="selector bg-white absolute z-50 w-full shadow-2xl"
        :style="'font-size: ' + size + 'px;'"
      >
        <input
          v-if="searchable"
          type="text"
          class="outline-none w-full p-2"
          placeholder="Search"
          autofocus
          v-model="search"
          @click.stop
        />
        <div v-if="itemsFiltered.length === 0" class="p-2">No data</div>
        <div v-else class="overflow-y-auto py-2" style="max-height: 40vh">
          <div
            class="hover:bg-brightGray cursor-pointer text-textLight"
            :class="{
              'p-2': padding === 'big',
              'p-1': padding === 'small'
            }"
            v-for="item in itemsFiltered"
            :key="item.value"
            @click="handleChange(item.value)"
          >
            <slot name="option" :item="item">{{ item[searchKey] }}</slot>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!success" class="text-xs" style="color: #ec3118">
      <div>{{ errors }}</div>

      <!-- <div v-for="(error, i) in errors" :key="i">{{ error }}</div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    label: { type: String },
    size: { type: Number, default: 16 },
    errors: { type: Array, default: () => [] },
    padding: { type: String, default: ['big', 'small'][0] },
    items: { type: Array, default: () => [] },
    bordered: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    searchKey: { type: String, default: 'label' }
  },
  computed: {
    success() {
      return this.errors.length === 0;
    },
    itemsFiltered() {
      return this.items.filter(item =>
        RegExp('.*' + this.search + '.*', 'i').test(item[this.searchKey])
      );
    },
    itemSelected() {
      return this.items.find(item => item.value === this.value);
    }
  },
  data() {
    return {
      selectorOpened: false,
      search: ''
    };
  },
  methods: {
    handleFocus() {
      this.selectorOpened = true;
    },
    handleBlur() {
      this.selectorOpened = false;
      this.search = '';
    },
    handleOpen() {
      if (!this.selectorOpened) this.handleFocus();
      else this.handleBlur();
    },
    handleChange(value) {
      this.$emit('input', value);
    }
  }
};
</script>

<style>
.selector {
  animation: slideUp 0.2s;
}
@keyframes slideUp {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
