<template>
  <section class="bg-gray-50 dark:bg-gray-900">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto">
      <div
        class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
      >
        <!-- <img
          class="w-8 h-8 mr-2"
          src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg"
          alt="logo"
        /> -->
        Mama wallet
      </div>
      <div
        class="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8"
      >
        <h2
          class="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"
        >
          Link Account
        </h2>
        <form class="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="#">
          <div>
            <label
              for="tel"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Your tel</label
            >
            <input
              type="tel"
              name="tel"
              v-model="Form.tel"
              id="tel"
              class="bg-gray-100 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="+251900000000"
              required
            />
          </div>
          <div class="">
            <label
              for="passcode"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >New PIN CODE</label
            >
            <input
              type="passcode"
              name="passcode"
              id="passcode"
              v-model="Form.passcode"
              placeholder="••••"
              class="bg-gray-100 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required=""
            />
          </div>
          <div class="">
            <label
              for="confirm-passcode"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Confirm PIN CODE</label
            >
            <input
              type="confirm-passcode"
              name="confirm-passcode"
              id="confirm-passcode"
              placeholder="••••"
              v-model="Form.confirmPasscode"
              class="bg-gray-100 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-600 focus:border-blue-600 block w-full p-2 dark:bg-gray-00 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required=""
            />
          </div>
          <base-button
            :isWorking="isWorking"
            label="Link account"
            :primary="false"
            class="w-full text-white bg-blue-600 hover:bg-600-700 focus:ring-4 focus:outline-none focus:ring-300 font-medium rounded-lg text-sm px-5 py-3 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            @click="linkToMamaWallet"
          />
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../../eventBus';

export default {
  props: ['customer'],
  data() {
    return {
      Form: {
        tel: '',
        passcode: '',
        confirmPasscode: ''
      },
      isWorking: false
    };
  },
  computed: {
    ...mapGetters('main', ['berror'])
  },
  methods: {
    ...mapActions('main', ['request']),
    async linkToMamaWallet() {
      this.isWorking = true;
      let response = await this.request({
        method: 'post',
        url: 'mama/link-account/' + this.customer._id,
        data: {
          ...this.Form
        }
      });
      if (response) {
        eventBus.$emit('open-toast', {
          message:
            'Customer linked to ' + this.Form.tel + ' mama account successfully'
        });
        this.$emit('close', { invokeCallBack: true });
      } else {
        eventBus.$emit('open-toast', {
          message: this.berror,
          error: true
        });
      }
      this.isWorking = false;
    }
  }
};
</script>

<style></style>
