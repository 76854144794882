<template>
  <div class="">
    <pre>{{ isDataLoaded }}</pre>
    <pre>{{ sheetNames }}</pre>
    <tabs>
      <tab
        v-for="({ sheetName, students, headers=[], length }, index) in sheets"
        :key="index"
        :title="`${sheetName} (${length})`"
      >
        <data-table
          :headers="headers.map((label) => ({ label, key: label }))"
          :items="students"
          :hasActions="false"
          :handlers="[]"
        ></data-table>
      </tab>
    </tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Tab from '@/components/tab/Tab.vue';
import Tabs from '@/components/tab/Tabs.vue';
import DataTable from '@/components/table/DataTable.vue';
export default {
  components: { Tabs, Tab, DataTable },
  computed: {
    ...mapGetters('migration', ['isDataLoaded', 'sheetNames', 'sheets']),
  },
};
</script>

<style></style>
