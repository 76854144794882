<template>
  <div class="space-y-2">
    <div class="flex justify-between px-4 py-2 shadow-md">
      <!-- <div class="w-1/2">
        <base-select
          label="UTILITY ORG"
          v-model="selectedUtilId"
          :items="utilityOrgOptions"
        />
      </div> -->
      <div class="w-1/2 text-lg shadow-md border-borderGray p-2 font-semibold">
        {{ myOrganization.util_name }}
      </div>

      <div class="flex items-end">
        <base-button
          v-if="isGranted('0077ra')"
          :isWorking="isWorking"
          label="SAVE"
          icon="check-circle"
          class="rounded px-2"
          @click="handleSave"
        />
      </div>
    </div>
    <div v-if="isLoading" class="w-full flex justify-center">
      <bar-loader />
    </div>
    <div v-else class="md:grid grid-cols-2 gap-4">
      <pannel title="Organization API" class="mt-4">
        <div class="space-y-2 text-textMedium">
          <base-input
            v-model="Form.url"
            bordered
            label="URL"
            placeholder="http://www.example.com/update-bill"
          />
          <base-select
            v-model="Form.method"
            bordered
            label="METHOD"
            :items="methodTypeOptions"
          />
        </div>
        <div class="text-center bg-brightGray text-textMedium p-2 m-2">
          DATA
        </div>
        <template v-for="(field, index) in Form.data">
          <div
            class="grid grid-cols-2 gap-2 py-2"
            :class="{ ' py-2': index % 2 == 0 }"
            :key="index"
          >
            <div>
              <base-input
                v-model="Form.data[index]['label']"
                bordered
                placeholder="FIELD NAME"
                label="NAME"
              />
            </div>
            <div class="flex space-x-1">
              <base-select
                v-model="Form.data[index]['value']"
                bordered
                label="VALUE"
                :items="dataOptions"
              >
                <template #suffix>
                  <div></div>
                </template>
              </base-select>
              <base-button
                v-if="isGranted('0077ra')"
                icon="trash-alt"
                class="text-lightDanger text-center self-end pb-2"
                :iconSize="22"
                :primary="false"
                @click="removeRow(index)"
              />
            </div>
          </div>
        </template>

        <div class="flex justify-around py-4">
          <base-button
            v-if="isGranted('0007ra')"
            label="Add Field"
            icon="plus"
            :primary="false"
            class="rounded px-2 border border-primary text-primary"
            @click="addRow(Form.data.length - 1)"
          />
        </div>
      </pannel>
      <pannel title="PREVIEW" class="mt-4">
        <div>
          <div class="flex justify-center flex-wrap">
            <base-button
              class="px-4 rounded border border-primary"
              :primary="false"
              label="Generate Key Pair"
              @click="generateKeyPair"
            />
          </div>
          <base-text-area
            v-model="Form.publicKey"
            :rows="5"
            label="Public Key"
          />
          <base-text-area
            v-model="privateKey"
            :readOnly="true"
            :rows="8"
            label="Private Key"
            class="cursor-pointer"
          />
          <!-- <div class="" v-for="(field, i) in result" :key="i">
            <base-input
              :type="field.type"
              v-model="Form[field.key]"
              :label="field.name"
              :placeholder="field.name"
            />
          </div> -->
        </div>
      </pannel>
    </div>
  </div>
</template>

<script>
import pannel from '@/components/card/pannel.vue';
// import utilityOrg from '@/mixins/utilityOrg';
import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../../eventBus';
import BarLoader from '../../collection/BarLoader.vue';
export default {
  components: { pannel, BarLoader },
  // mixins: [utilityOrg],
  data() {
    return {
      isWorking: false,
      isLoading: false,
      customFields: [],
      myOrganization: {},
      methodTypeOptions: [
        { label: 'POST', value: 'POST' },
        { label: 'PUT', value: 'PUT' },
        { label: 'PATCH', value: 'PATCH' },
      ],
      formTemplate: {
        label: '',
        value: '',
      },
      privateKey: '',
      Form: {
        method: 'POST',
        url: '',
        publicKey: '',
        data: [],
      },
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
    ...mapGetters(['user']),
    result() {
      return this.customFields.map((field) => {
        const key = field.name.toLowerCase().split(' ').join('_');
        field.key = key;
        return field;
      });
    },
    dataOptions() {
      let options = this.customFields.map((field) => {
        const value = field.name.toLowerCase().split(' ').join('_');
        return { label: field.name, value: value };
      });

      options.unshift(
        ...[
          { label: 'ID', value: 'cust_util_id' },
          { label: 'STUDENT NAME', value: 'fullName' },
          { label: 'TOTAL BILL AMOUNT', value: 'total_amount' },
          { label: 'PAID AMOUNT', value: 'paid_amount' },
          { label: 'PENALTY', value: 'penalty' },
        ]
      );
      return options;
    },
  },
  watch: {
    selectedUtilId() {
      if (this.selectedUtilId) this.getUtilOrgCustomFields();
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    addRow(index) {
      this.Form.data = [
        ...this.Form.data.slice(0, index + 1),
        { ...this.formTemplate },
        ...this.Form.data.slice(index + 1).map(({ ...rest }) => ({ ...rest })),
      ];
    },
    removeRow(index) {
      this.Form.data = [
        ...this.Form.data.slice(0, index),
        ...this.Form.data.slice(index + 1).map(({ ...rest }) => ({ ...rest })),
      ];
    },
    async generateKeyPair() {
      const response = await this.request({
        url: 'billing/third/generate-key-pair',
        method: 'get',
      });
      if (response) {
        this.Form.publicKey = response.publicKey;
        this.privateKey = response.privateKey;
      }
    },
    async getMyOrganization() {
      const res = await this.request({
        method: 'get',
        url: 'billing/utiltypes/myorg',
      });
      if (res) {
        this.selectedUtilId = res.item._id;
        this.myOrganization = res.item;
      }
    },

    async getUtilOrgCustomFields() {
      this.isLoading = true;
      this.customFields = [];
      let response = await this.request({
        method: 'get',
        url: 'billing/customfields/bill',
        //  + this.selectedUtilId,
      });
      if (response) {
        this.customFields = response.items[0].bill_fields;
      }
      this.isLoading = false;
    },
    async getMyOrganizationRequestApiData() {
      this.isLoading = true;
      this.Form.url = '';
      this.Form.mehtod = '';
      this.Form.data = [];
      let response = await this.request({
        method: 'get',
        url: 'billing/org-api/myorg',
        //  + this.selectedUtilId,
      });
      if (response) {
        this.Form.url = response.item.url;
        this.Form.method = response.item.method;
        this.Form.data = response.item.data;
        this.Form.publicKey = response.item.publicKey;
      }
      this.isLoading = false;
    },
    async handleSave() {
      this.isWorking = true;
      let response = await this.request({
        method: 'post',
        url: 'billing/org-api/' + this.selectedUtilId,
        data: {
          ...this.Form,
        },
      });
      if (response) {
        eventBus.$emit('open-toast', {
          message: 'ORGANIZATION API saved successfully',
        });
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror,
        });
      }

      this.isWorking = false;
    },
  },
  async created() {
    await this.getMyOrganization();
    this.getMyOrganizationRequestApiData();
  },
};
</script>

<style>
</style>