const DASHBOARDS = {
  DIRECTOR: '/offices',
  BM: '/daily-op6n',
  CSM: '/daily-op6n',
  CHECKER: '/transactions',
  SCSO: '/daily-op6n',
  MAKER: '/daily-op6n',
  OPADMIN: '/configs',
  OPDIRECTOR: '/configs',
  ADMIN: '/operators',
  AUDITOR: '/audit_report',
  ITDIRECTOR: '/daily-op6n',

  BACKMAKER: '/b-transfer'
};
export default DASHBOARDS;
