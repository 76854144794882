<template>
  <div class="space-y-2">
    <div class="flex justify-between px-4 py-2 shadow-md">
      <!-- <div class="w-1/2">
        <base-select
          label="UTILITY ORG"
          v-model="selectedUtilId"
          :items="utilityOrgOptions"
        />
      </div> -->
      <div class="w-1/2 text-lg shadow-md border-borderGray p-2 font-semibold">
        {{ myOrganization.util_name }}
      </div>

      <div class="flex items-end">
        <base-button
          v-if="isGranted('0077cf')"
          :isWorking="isWorking"
          label="SAVE"
          icon="check-circle"
          class="rounded px-2"
          @click="handleSave"
        />
      </div>
    </div>
    <div v-if="isLoading" class="w-full flex justify-center">
      <bar-loader />
    </div>
    <div v-else class="md:grid grid-cols-2 gap-4">
      <pannel title="BILL CUSTOM FIELDS" class="mt-4">
        <template v-for="(field, index) in customFields">
          <div
            class="grid grid-cols-2 gap-2 py-2"
            :class="{ ' py-2': index % 2 == 0 }"
            :key="index"
          >
            <div>
              <base-input
                v-model="customFields[index]['name']"
                bordered
                placeholder="FIELD NAME"
                label="NAME"
              />
            </div>
            <div class="flex space-x-1">
              <base-select
                v-model="customFields[index]['type']"
                bordered
                label="TYPE"
                :items="fieldTypeOptions"
              >
                <template #suffix>
                  <div></div>
                </template>
              </base-select>
              <base-button
                v-if="isGranted('0077cf')"
                icon="trash-alt"
                class="text-lightDanger text-center self-end pb-2"
                :iconSize="22"
                :primary="false"
                @click="removeRow(index)"
              />
            </div>
          </div>
        </template>

        <div class="flex justify-around py-4">
          <base-button
            v-if="isGranted('0007cf')"
            label="Add Field"
            icon="plus"
            :primary="false"
            class="rounded px-2 border border-primary text-primary"
            @click="addRow(customFields.length - 1)"
          />
        </div>
      </pannel>
      <pannel title="PREVIEW" class="mt-4">
        <div>
          <base-input
            type="number"
            label="STUDENT ID"
            placeholder="Total Amount"
          />
          <base-input
            type="number"
            label="Total amount"
            placeholder="Total Amount"
          />
          <div class="" v-for="(field, i) in result" :key="i">
            <base-input
              :type="field.type"
              v-model="Form[field.key]"
              :label="field.name"
              :placeholder="field.name"
            />
          </div>
        </div>
      </pannel>
    </div>
  </div>
</template>

<script>
import pannel from '@/components/card/pannel.vue';
// import utilityOrg from '@/mixins/utilityOrg';
import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../../eventBus';
import BarLoader from '../../collection/BarLoader.vue';
export default {
  components: { pannel, BarLoader },
  // mixins: [utilityOrg],
  data() {
    return {
      isWorking: false,
      isLoading: false,
      customFields: [],
      myOrganization: {},
      fieldTypeOptions: [
        { label: 'Number', value: 'number' },
        { label: 'Text', value: 'text' },
        { label: 'Date', value: 'date' },
      ],
      formTemplate: {
        key: '',
        name: '',
        type: 'text',
      },
      Form: {},
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
    ...mapGetters(['user']),
    result() {
      return this.customFields.map((field) => {
        const key = field.name.toLowerCase().split(' ').join('_');
        field.key = key;
        return field;
      });
    },
  },
  watch: {
    selectedUtilId() {
      if (this.selectedUtilId) this.getUtilOrgCustomFields();
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    addRow(index) {
      this.customFields = [
        ...this.customFields.slice(0, index + 1),
        { ...this.formTemplate },
        ...this.customFields
          .slice(index + 1)
          .map(({ ...rest }) => ({ ...rest })),
      ];
    },
    removeRow(index) {
      this.customFields = [
        ...this.customFields.slice(0, index),
        ...this.customFields
          .slice(index + 1)
          .map(({ ...rest }) => ({ ...rest })),
      ];
    },
    async getMyOrganization() {
      const res = await this.request({
        method: 'get',
        url: 'billing/utiltypes/myorg',
      });
      if (res) {
        this.selectedUtilId = res.item._id;
        this.myOrganization = res.item;
      }
    },

    async getUtilOrgCustomFields() {
      this.isLoading = true;
      this.customFields = [];
      let response = await this.request({
        method: 'get',
        url: 'billing/customfields/bill',
        //  + this.selectedUtilId,
      });
      if (response) {
        this.customFields = response.items[0].bill_fields;
      }
      this.isLoading = false;
    },
    async handleSave() {
      this.isWorking = true;
      let response = await this.request({
        method: 'post',
        url: 'billing/customfields/' + this.selectedUtilId,
        data: {
          bill_fields: this.customFields,
        },
      });
      if (response) {
        eventBus.$emit('open-toast', {
          message: 'Custom fields updated successfully',
        });
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror,
        });
      }

      this.isWorking = false;
    },
  },
  async created() {
    await this.getMyOrganization();
    this.getUtilOrgCustomFields();
  },
};
</script>

<style>
</style>