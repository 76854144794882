<template>
  
  <section class="p-2 bg-blue-500 dark:bg-blue-900">
    <div
      class="flex px-6 mx-auto t-0 md:h-screen lg:py-0"
    >
    <div class="flex flex-col items-center w-2/3 px-12 mx-12">
      <img class="w-64 h-64 rounded-full " src="@/assets/Lion Bank.jpg"> 
      <a
        href="#"
        style="color: #e6ff09"
        class="flex m-3 text-6xl font-bold yellow-text dark:text-white"
      >
       AnbesaSchoolBilling
      </a>
      <div class="px-12 text-4xl font-bold text-center text-white ti_font">Online School Fee Management System Solution</div>
      <div class="px-12 font-sans text-xl text-center text-gray-200 justify heeb_font ">End-to-end, Simple, Secure and Interactive Online Education Fee Management System from Lion International Bank S.C. </div>
    </div>
    <div class="flex flex-col justify-center w-1/4 space-y-0">

      <div
        class=" px-8 mx-8 rounded-lg shadow-lg bg-gradient-to-b from-orange-500 to-pink-500 dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700"
      >
     
        <div class="justify-center px-1 space-y-1 md:space-y-2 sm:p-8">
        
          <div class="rounded-lg mx-8 px-2 shadow-lg bg-gradient-to-b from-orange-600 to-orange-800 text-center text-white">
            <h1  class="px-3 text-white"      >
            School Billing Login
          </h1>
          </div>
          <div class="flex items-center justify-center pt-5">
            <div class="flex items-center justify-center w-20 h-20 text-gray-300 bg-gray-200 rounded-full">
              <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="text-gray-400 w-14 h-14"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
            />
          </svg>           </div>
          </div>
          <div class="space-y-4 md:space-y-6" action="/">
            
            <div v-if="berror" class="text-sm p-2 bg-red-300 rounded text-white" style="">
              {{ berror }}
            </div>
            <div class="space-y-2">
              
              <div class="flex">
                <span class="input-group-prepend">
                  <i class="prefix-icon">        
                    <svg xmlns="http://www.w3.org/2000/svg" 
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
            class="w-8 h-8 text-gray-400 bg-gray-200" > 
                    <path d="M12 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2zm0 10c3.313 0 6 1.966 6 3v2h-12v-2c0-1.034 2.687-3 6-3z"/> </svg>                  </i>
                </span>
                <input
                type="username"
                v-model="username"
                name="username"
                id="username"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm  focus:ring-cyan-800 focus:border-cyan-800 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-cyan-800 dark:focus:border-cyan-800"
                placeholder="user name"
                required=""
              />              </div>
              <div class="flex">
                <span class="input-group-prepend">
                  <i class="prefix-icon">        
                    <svg xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
            class="w-8 h-8 text-gray-400 bg-gray-200">
                      <path d="M12 2c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8zm0-11c-1.654 0-3 1.346-3 3 0 .553.447 1 1 1s1-.447 1-1c0-.551-.449-1-1-1-.551 0-1-.449-1-1s.449-1 1-1c1.654 0 3 1.346 3 3v1h2v-1c0-2.757-2.243-5-5-5-2.757 0-5 2.243-5 5s2.243 5 5 5c2.757 0 5-2.243 5-5 0-1.654-.646-3.154-1.688-4.281-.188-.234-.479-.359-.781-.359-.301 0-.594.125-.781.359-.541.675-.831 1.54-.831 2.422 0 2.757 2.243 5 5 5s5-2.243 5-5-2.243-5-5-5z"/> </svg>                  </i>
                </span>
              <input
                v-model="password"
                type="password"
                name="password"
                id="password"
                @enter="handleLogin"
                placeholder="••••••••"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm  focus:ring-cyan-800 focus:border-cyan-800 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-cyan-800 dark:focus:border-cyan-800"
                required=""
              />
              </div>

            </div>
            
            <base-button
            label="LOGIN"
            class="uppercase rounded-lg py-2 bg-pink-700"
            :isWorking="working"
            @click="handleLogin"
          />
            <!-- <p class="text-sm font-light text-gray-500 dark:text-gray-400">
              Don’t have an account yet?
              <a
                href="#"
                class="font-medium border-cyan-800 hover:underline dark:border-cyan-800"
                >Sign up</a
              >
            </p> -->
          </div>
        </div>
        
      </div>
      <div class="relative h-16 pt-1 mx-8 rounded-lg">
        <div class=" bottom-0 mx-0 h-16  rounded-lg  bg-gradient-to-b from-gray-400 to-transparent"></div>
      </div>
    </div>
    </div>
    
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { validation } from './../../mixins/validationMixin.js';
export default {
  mixins: [validation],
  data() {
    return {
      username: '',
      password: '',
      working: false,
      errors: {},
      meta: [
        { key: 'username', label: 'USERNAME' },
        { key: 'password', label: 'PASSWORD' },
      ],
      initial: true,
    };
  },
  computed: {
    ...mapGetters(['berror']),
  },
  methods: {
    ...mapActions(['login']),
    ...mapActions('main', ['request']),
    async handleLogin() {
      this.initial = false;
      if (!this.isValid()) return;
      this.working = true;
      let response = await this.login({
        username: this.username,
        password: this.password,
      });
      if (response) window.location.reload();
      else {
        this.password = '';
        this.initial = true;
      }
      this.working = false;
    },
  },
  watch: {
    username() {
      if (!this.initial) this.isValid();
    },
    password() {
      if (!this.initial) this.isValid();
    },
  },
  validations: {
    username: {
      required,
    },
    password: {
      required,
    },
  },
};
</script>

<style scoped>
.ti_font{
  font-family: 'Segoe UI', Roboto, Oxygen, 
}
.heeb_font{
  font-weight:480;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group-prepend {
  display: flex;
}

.prefix-icon {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0;
}
