<template>
  <div></div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import eventBus from '../../eventBus';
export default {
  computed: {
    ...mapGetters('timer', ['inactivityCounter', 'inactivityPeriod'])
  },
  watch: {
    inactivityCounter() {
      if (this.inactivityCounter >= this.inactivityPeriod) {
        this.stopCounting({ stateKey: 'inactivity' });
        eventBus.$emit('sign-out');
      }
    }
  },
  methods: {
    ...mapActions('timer', ['startCounting', 'stopCounting'])
  },
  beforeCreate() {
  },
  created() {
    const sessionTimestamp = parseInt(localStorage.getItem('sessionTimestamp'));    
    const currentTime = Date.now();
    const inactiveTime = currentTime - sessionTimestamp;
    const counter = parseInt(inactiveTime/1000)
    if(counter > this.inactivityPeriod) {
// TODO logout
      eventBus.$emit('sign-out');
    } else {
      this.startCounting({ stateKey: 'inactivity', counter}, );
    }
  },
  beforeDestroy() {
    this.stopCounting({ stateKey: 'inactivity' });
  }
};
</script>

<style></style>
