<template>
  <div class="py-4 text-textMedium space-y-4">
    <div class="pb-2 text-2xl text-center border-b border-brightGray">
      Change Role
    </div>
    <div class="space-y-4 px-4">
      <credential-card :credential="credential" />
      <div>
        <div v-if="loadingRoles"></div>
        <!-- <div
          v-else-if="!permitted"
          class="text-center text-lg bg-danger uppercase"
        >
          Sorry! You can't change {{ role }} role
        </div> -->
        <base-select
          v-else
          label="Role"
          :items="roles"
          v-model="role"
          bordered
          :searchable="true"
        >
          <template #default="{ item }">
            <div class="flex space-x-2 items-center">
              <base-icon name="user" />
              <div class="capitalize">{{ item.label }}</div>
            </div>
          </template>
          <template #option="{ item }">
            <div class="flex space-x-2 items-center">
              <base-icon name="user" />
              <div>{{ item.label }}</div>
            </div>
          </template>
        </base-select>
      </div>
      <div class="flex justify-between py-2">
        <base-button
          label="cancel"
          class="
            py-2
            px-6
            text-textMedium
            bg-brightGray
            hover:bg-danger
            hover:text-error
            rounded
          "
          @click="close"
        />
        <base-button
          v-if="!loadingRoles"
          :isWorking="isWorking"
          icon="check-circle"
          label="Save"
          class="py-2 px-6 rounded"
          @click="handleRegister"
        />
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus';
import { mapActions, mapGetters } from 'vuex';
import CredentialCard from '@/components/card/CredentialCard.vue';
export default {
  components: { CredentialCard },
  props: ['credential'],
  data() {
    return {
      photo: '',
      role: '',
      roles: [],
      permitted: undefined,
      isWorking: false,
      loadingRoles: true,
    };
  },

  computed: {
    ...mapGetters(['user']),
    ...mapGetters('main', ['berror']),
  },
  methods: {
    ...mapActions('main', ['request']),
    async fetchRoles() {
      this.loadingRoles = true;
      let response = await this.request({
        method: 'get',
        url: 'billing/roles/roles',
      });
      if (response) {
        this.roles = response.items.map((role) => ({
          label: role.role_name,
          value: role._id,
        }));
      }
      this.loadingRoles = false;
    },
    async handleRegister() {
      this.initial = false;
      this.isWorking = true;
      let response = await this.request({
        method: 'put',
        url: `billing/credential/role/${this.credential._id}`,
        data: {
          role: this.role,
        },
      });
      if (response) {
        eventBus.$emit('open-toast', {
          message: 'Credential Role changed successfully',
        });
        this.$emit('close', { invokeCallBack: true });
        this.initial = true;
      } else {
        eventBus.$emit('open-toast', {
          message: this.berror,
          error: true,
        });
      }
      this.isWorking = false;
    },
    close() {
      this.$emit('close');
    },
  },
  async created() {
    this.role = this.credential.role?._id;
    await this.fetchRoles();
    // this.permitted = this.roles.find(({ value }) => value === this.role);
  },
};
</script>

<style></style>
