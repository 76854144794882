<template>
  <div
    class="
      rounded
      grow-active
      shadow-sm
      text-textMedium
      bg-brighterGray
      hover:shadow-lg
      hover:p-4
      hover:text-textDarkest
      group
      cursor-pointer
      p-2
      space-y-2
    "
    :class="{
      ' border-2 border-primary shadow-md': selected,
      ' border border-brightGray': !selected,
    }"
  >
    <div class="">
      <div class="text-xl text-center font-semibold">
        {{ utilityOrg.util_name }}
      </div>
    </div>
    <div class="p-2 space-y-3">
      <div class="flex text-left items-end px-2">
        <div class="w-1/2 uppercase text-sm text-textMedium px-0">CODE</div>
        <div class="w-1/2 text-lg text-textLight p0">
          {{ utilityOrg.util_id }}
        </div>
      </div>
      <div class="flex text-left items-end p-2 bg-brightGray">
        <div class="w-1/2 uppercase text-sm text-textMedium px-0">
          TELEPHONE
        </div>
        <div class="w-1/2 text-lg text-textLight p0">
          {{ utilityOrg.tel }}
        </div>
      </div>
      <div class="flex text-left items-end px-2">
        <div class="w-1/2 uppercase text-sm text-textMedium px-0">
          Partial Payment
        </div>
        <div class="w-1/2 text-lg text-textLight p-0">
          {{ utilityOrg.allow_partial_payment ? 'Allowed' : 'Denied' }}
        </div>
      </div>
      <div class="flex text-left items-end px-2">
        <div class="w-1/2 uppercase text-sm text-textMedium px-0">
          Student ID
        </div>
        <div class="w-1/2 text-lg text-textLight p-0">
          {{ utilityOrg.autoGenerateStudentId ? 'Auto Generated' : 'Manual' }}
        </div>
      </div>
      <div class="flex text-left items-end px-2" v-if="utilityOrg.autoGenerateStudentId">
        <div class="w-1/2 uppercase text-sm text-textMedium px-0">
          Last Student ID
        </div>
        <div class="w-1/2 text-lg text-textLight p-0">
          {{ utilityOrg.schoolLastStudentId }}
        </div>
      </div>
      <!-- <div class="flex text-left items-end p-2 bg-brightGray">
        <div class="w-1/2 uppercase text-sm text-textMedium px-0">UNIT</div>
        <div class="w-1/2 text-lg text-textLight p0">
          {{ utilityOrg.unit }}
        </div>
      </div> -->

      <!-- {{ utilityOrg }} -->
    </div>
  </div>
</template>

<script>
export default {
  props: ['utilityOrg', 'selected'],
};
</script>

<style></style>
