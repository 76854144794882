<template>
  <div>
    <apexchart
      type="area"
      class="-my-10 -mx-5"
      height="100"
      width="100"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  props: ['color', 'categories', 'series'],
  data: function () {
    return {
      chartOptions: {
        chart: {
          id: 'iconchart',
          animations: {enabled: true},
          dropShadow: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: false,
        },
        legend: {
          show: false,
        },
        noData: {
          text: 'No Transactions',
          align: 'center',
          verticalAlign: 'middle',
        },
        stroke: {
          curve: 'smooth',
          width: 3,
        },
        title: {
          text: '',
        },
        yaxis: {
          labels: {
            show: false,
          },
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        colors: [this.color],
        xaxis: {
          labels: {
            show: false,
          },
          // categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
    };
  },
  created() {
    this.chartOptions.xaxis.categories = this.categories;
  },
};
</script>

<style>
</style>