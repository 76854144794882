<template>
  <div class="text-textMedium space-y-4">
    <div class="text-2xl uppercase py-2 text-center">DELETE STUDENT BILL</div>
    <div class="space-y-4 px-4 pb-4">
      <customer-payment :payment="payment"/>
      <div class="flex justify-between">
        <base-button
          label="cancel"
          class="
            py-1
            px-4
            text-textMedium
            bg-brightGray
            hover:bg-danger
            hover:text-error
            rounded
          "
          @click="close"
        />
        <base-button
          :isWorking="working"
          icon="trash-alt"
          label="Delete Bill"
          class="py-1 px-4 rounded"
          @click="deleteCustomerBill"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import eventBus from '@/eventBus';
import CustomerPayment from './CustomerPayment.vue';
export default {
  components: { CustomerPayment },
  props: ['payment', 'paymentId'],
  data() {
    return {
      working: false,
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    async deleteCustomerBill() {
      this.working = true;
      let response = await this.request({
          method: 'delete',
          url: 'billing/utilcustomers/'+this.payment._id+'/'+this.paymentId,
      });
      if (response) {
        this.$emit('close', {
          invokeCallBack: true,
          response: { payment: this.payment },
        });
        eventBus.$emit('open-toast', {
          message:  'Payment bill deleted successfully.',
        });
      }
      this.working = false;
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style></style>
