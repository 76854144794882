<template>
  <div class="space-y-2">
    <div
      class="
        uppercase
        text-lg
        tracking-widest
        flex
        space-x-6
        w-full
        justify-between
        shadow-md
        p-4
      "
    >
      <div class="flex space-x-4">
        <div
          class="text-textMedium lg:grid lg:grid-cols-4 lg:gap-4 space-x-4 flex-grow"
        >
          <base-input
            type="date"
            :readOnly="!edit"
            label="STARTING DATE"
            placeholder=""
            v-model="Form.payment_start_date"
            :errors="errors['payment_start_date']"
            bordered
            required
          />
          <base-input
            type="date"
            :readOnly="!edit"
            label="DUE DATE"
            placeholder=""
            v-model="Form.payment_due_date"
            :errors="errors['payment_due_date']"
            bordered
            required
          />
          
          <base-select
            :readOnly="!edit"
            label="PAYMENT TERM"
            placeholder="Select term"
            v-model="Form.paymentTermId"
            :items="paymentTermOptions"
            :errors="errors['paymentTermId']"
            bordered
            required
            class="flex-shrink-0"
          />
          <base-select
            :readOnly="!edit"
            label="PAYMENT TYPE"
            placeholder="Select"
            v-model="Form.paymentType"
            :items="paymentTypeOptions"
            :errors="errors['paymentType']"
            bordered
            required
            class="flex-shrink-0"
          />
        </div>
        <div class="flex flex-grow space-x-4 space-y-4 lg:space-y-0">
          <div class="flex items-center justify-center">
            <base-button
              v-if="edit && isGranted('0099up')"
              :primary="false"
              :isWorking="working"
              icon="check-circle"
              label="Update"
              title="Edit"
              class="
                text-primary
                shadow-md
                border
                font-semibold
                border-borderGray
                p-2
                rounded
              "
              :iconSize="18"
              @click="preUpdatePayment"
            />
            <base-button
              v-else-if="isGranted('0099up')"
              :primary="false"
              icon="pencil-alt"
              title="Edit"
              class="
                text-primary
                shadow-md
                border border-borderGray
                p-2
                rounded
              "
              :iconSize="22"
              @click="editPayment"
            />
          </div>
        </div>
      </div>

      <div></div>
      <div
        class="
          grid
          lg:grid-cols-2
          gap-2
          border border-borderGray
          rounded
          p-3
          text-center
        "
      >
        <div class="space-y-0 p-2 uppercase bg-brighterGray">
          <div class="text-textMedium text-2xl font-medium">
            {{ payment.total_customers | formateNumber }}
          </div>
          <div class="text-textLight text-sm">students</div>
        </div>
        <div class="space-y-0 p-2 uppercase">
          <div class="text-textMedium text-2xl font-medium">
            {{ payment.total_amount | formateNumber }}
          </div>
          <div class="text-textLight text-sm">total amount</div>
        </div>
      </div>
    </div>
    <div class="space-y-1">
      <data-table
        :headers="headers"
        :items="payments"
        :small="true"
        :hideCount="true"
        :isWorking="loadingCustomers"
        :stripped="true"
        :canSelect="true"
        @select="select"
        @unselect="unselect"
        :hasDynamicActions="true"
        :dynamicHandlers="actionHandlers"
        :searchable="true"
        :paginate="true"
        :rowsPerPage="rowsPerPage"
        :totalPages="total_pages"
        @pageChange="handlePageChange"
        @pageSizeChange="handlePageSizeChange"
      >
        <template #toolset>
          <div class="flex space-x-3">
            <base-button
              v-if="isGranted('0003uc')"
              :primary="false"
              label="Add Bill"
              icon="plus"
              @click="addCustomerBill"
              class="
                px-2
                uppercase
                rounded
                bg-brightGray
                hover:text-primary
                border border-primary
              "
            />
          </div>
        </template>
        <template #no-data>
          <div :style="!allValid ? { color: '#ec3118' } : {}">No Record</div>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';
import billCustomFields from '@/mixins/billCustomFields.js';
import DataTable from '@/components/table/PaginateTable.vue';
// import DataTable from '@/components/table/DataTable.vue';

import eventBus from '@/eventBus';
import { toYYYYMMDD } from '@/utils';
// import eventBus from '@/eventBus';
export default {
  props: ['title'],
  components: { DataTable },
  mixins: [validation, billCustomFields],
  data() {
    return {
      allValid: false,
      edit: false,
      selectedId: undefined,
      working: false,
      isWorking: false,
      isLoading: false,
      loadingCustomers: false,
      rowsPerPage: 15,
      currentPage: 1,
      total_pages: 1,
      Form: {
        payment_due_date: undefined,
        payment_start_date: undefined,
        paymentType: undefined,
        paymentTermId: undefined
        // description: undefined,
      },
      payment: undefined,
      payments: [],
      paymentTermOptions: [],
      paymentTypeOptions: [],
      formTemplate: {
        fullName: undefined,
        previous_reading: undefined,
        present_reading: undefined,
        consumption: undefined,
        util_amount: undefined,
        total_amount: undefined,
        status: undefined
      },
      errors: [],
      importing: false,
      unwathcCallbacks: [],
      fileSelector: false
    };
  },
  computed: {
    headers() {
      return [
        { key: 'studentSchoolId', label: 'ID' },
        { key: 'studentName', label: 'Full Name' },
        { key: 'grade', label: 'grade/Dep\'t' },
        { key: 'section', label: 'section' },
        { key: 'billCode', label: 'Bill Code' },
        ...this.customFields.map(({ key, name }) => ({
          key: 'other.' + key,
          label: name
        })),
        { key: 'penalty', label: 'Penalty' },
        { key: 'amount', label: 'Amount' },
        { key: 'isPaid', label: 'Status' }
      ];
    },
    selectedItem() {
      return this.payments.find(item => item._id === this.selectedId);
    },
    actionHandlers() {
      if (!this.selectedItem) return [];
      return [
        {
          label: 'Edit Bill',
          icon: 'pencil-alt',
          handler: this.editCustomerBill,
          fullParam: true,
          code: '0033uc',
          condition: !this.selectedItem.isPaid
        },
        {
          label: 'Delete Bill',
          icon: 'trash-alt',
          handler: this.deleteCustomerBill,
          fullParam: true,
          code: '0033uc',
          condition: !this.selectedItem.isPaid
        },
        {
          label: 'Print',
          icon: 'eye',
          handler: this.PrintReceipt,
          fullParam: true,
          code: '0033uc',
           condition: this.selectedItem.isPaid&&this.selectedItem.paid_by=='LIB-BANK'

        },
        // {
        //   label: 'pay',
        //   icon: 'pencil-alt',
        //   handler: this.Paybill,
        //   fullParam: true,
        //   code: '0033uc',
        //   condition: !this.selectedItem.isPaid

        // }
      ];
    },
    totalAmount() {
      return this.payments.reduce(
        (acc, { total_amount }) => acc + Number(total_amount),
        0
      );
    },
    totalCustomers() {
      return this.payments.length;
    },
    customers() {
      return this.payments.map(payee =>
        Object.keys(this.formTemplate).reduce(
          (acc, key) => ({
            ...acc,
            [key]: key === 'amount' ? +payee[key] : payee[key]
          }),
          {}
        )
      );
    }
  },
  watch: {
    // customers() {
    //   this.smartSet({ stateKey: 'customers', data: this.customers });
    // },
    // payments: {
    //   immediate: true,
    //   deep: true,
    //   handler() {
    //     this.validationHandler();
    //     // this.setVal();
    //   },
    // },
  },
  methods: {
    ...mapActions('main', ['request']),
    updateRecord() {},
    insertRecord() {},
    select(id) {
      this.selectedId = id;
    },
    unselect() {
      this.selectedId = undefined;
    },
    editPayment() {
      this.edit = true;
    },
    async preUpdatePayment() {
      let componentProps = {
        title: 'UPDATE PAYMENT Information',
        message:
          'Are you sure you really want to update '
      };
      eventBus.$emit('open-modal', {
        modalId: 3,
        componentProps,
        cb: this.updatePayment
      });
    },
    async updatePayment() {
      this.working = true;
      let response = await this.request({
        method: 'put',
        url: 'billing/utilpayments/' + this.payment._id,
        data: {
          ...this.Form
        }
      });
      if (response) {
        this.getDocument();
        eventBus.$emit('open-toast', {
          message: 'Payment updated successfully.'
        });
      }
      this.working = false;
      this.edit = false;
    },
    async addCustomerBill() {
      let componentProps = {
        payment: this.payment
      };
      eventBus.$emit('open-modal', {
        modalId: 7,
        componentProps,
        cb: this.getPaymentCustomers
      });
    },
    async editCustomerBill(bill) {
      let componentProps = {
        bill: bill,
        paymentId: this.payment._id,
      };
      eventBus.$emit('open-modal', {
        modalId: 5,
        componentProps,
        cb: this.getPaymentCustomers
      });
    },
    async payCustomerBill(payment) {
      let componentProps = {
        payment
      };
      eventBus.$emit('open-modal', {
        modalId: 4,
        componentProps,
        cb: this.getPaymentCustomers
      });
    },
    async deleteCustomerBill(payment) {
      let componentProps = {
        payment,
        paymentId: this.payment._id
      };
      eventBus.$emit('open-modal', {
        modalId: 6,
        componentProps,
        cb: this.getPaymentCustomers
      });
    },
    async PrintReceipt(payment) {
      eventBus.$emit('open-modal', {
        modalId: 204,
        componentProps: {
         bill: payment
        }
      });
    },
    // async Paybill(payment) {
    //   eventBus.$emit('open-modal', {
    //     modalId: 205,
    //     componentProps: {
    //      payment,
    //     paymentId: this.payment._id
    //     }
    //   });
    // },
    async getDocument() {
      this.isLoading = true;
      const response = await this.request({
        method: 'get',
        url: 'billing/utilpayments/' + this.$route.params.id
      });
      if (response) {
        this.payment = response.item;
      }
      this.isLoading = false;
    },
    async getPaymentCustomers() {
      this.loadingCustomers = true;
      this.payments = [];
      const response = await this.request({
        method: 'get',
        url:
          'billing/utilcustomers/customers/' +
          this.$route.params.id +
          this.generateQuery(this.rowsPerPage, this.currentPage)
      });
      if (response) {
        this.payments = response.items;
        this.total_pages = response.total_pages;
      }
      this.loadingCustomers = false;
    },
    generateQuery(rowsPerPage, currentPage) {
      return `?limit=${rowsPerPage}&page=${currentPage}`;
    },
    handlePageChange(page) {
      if (page >= 1 && page <= this.total_pages) {
        this.currentPage = page;
        this.getPaymentCustomers();
      }
    },
    handlePageSizeChange(pageSize) {
      this.rowsPerPage = pageSize;
      this.currentPage = 1;
      this.getPaymentCustomers();
    },
    async getPaymentTypes() {
      const response = await this.request({
        url: 'billing/paymentType?limit=' + 100000
      });
      if (response) {
        this.paymentTypeOptions = response.items.map(
          ({ _id: value, title: label }) => ({ label, value })
        );
      } else {
        this.paymentTypeOptions = [];
      }
    },
    async getPaymentTerms() {
      const response = await this.request({
        url: 'billing/paymentTerm?limit=' + 100000
      });
      if (response) {
        this.paymentTermOptions = response.items.map(
          ({ _id: value, title: label }) => ({ label, value })
        );
      } else {
        this.paymentTermOptions = [];
      }
    }
  },
  validations() {
    return {
      payments: {
        ...this.payments.reduce(
          (acc, payee, index) => ({
            ...acc,
            [index]: {
              ...Object.keys(this.formTemplate).reduce(
                (acc, key) => ({ ...acc, [key]: { required } }),
                {}
              )
            }
          }),
          {}
        )
      }
    };
  },

  async created() {
    this.getPaymentTerms();
    this.getPaymentTypes();
    await this.getDocument();
    if (this.payment) {
      this.Form.payment_due_date = toYYYYMMDD(this.payment.payment_due_date);
      this.Form.payment_start_date = toYYYYMMDD(
        this.payment.payment_start_date
      );

      this.Form.paymentType = this.payment.paymentType;
      this.Form.paymentTermId = this.payment.paymentTermId;
    }
    await this.getPaymentCustomers();
  }
};
</script>

<style></style>
