import apiRequest from '../../services/apiRequest';

const state = {
    error: '',
    cashonvaults: [],
    glclasses: [],
    glheads: [],
    glnodes: [],
    glleafs: [],
    myAccounts: [], /// the account numbers of a maker.
    currencies: [],
    stoppageDetails: null,
    nsfDetails: null,
    depositDetails: null,
    manualTrDetails: null,
    cashInTransitDetails: null,
    cpoDetails: null,
    transferDetails: null,
    aftDetails: null,
    cashonvaultsReport: [],
    chartOfAccounts: [],
    halfTrialBalances: [],
    glReport: []
};

const mutations = {
    SMART_SET: (state, { stateKey, data }) => {
        state[stateKey] = data;
    },
    SET_ERROR: (state, { message }) => {
        /**
         * FYI: if you want to use errorCode in your error display.
         * Set it here
         */
        state.error = message;
    }
};

const actions = {
    setErrorEmpty({ commit }) {
        commit('SET_ERROR', {
            message: ''
        });
    },
    setData({ commit }, { stateKey, data }) {
        commit('SMART_SET', {
            stateKey,
            data
        });
    },
    async requestMutate({ commit }, { stateKey, ...params }) {
        try {
            const { data } = await apiRequest.request(params);
            commit('SMART_SET', {
                stateKey,
                data: data.items
            });
            return true;
        } catch (e) {
            commit('SET_ERROR', {
                error: e.errorCode,
                message: e.message
            });
            return false;
        }
    },
    async request({ commit }, params) {
        try {
            const { data } = await apiRequest.request(params);
            return data;
        } catch (e) {
            commit('SET_ERROR', {
                error: e.errorCode,
                message: e.message
            });
            return false;
        }
    },
    async upload({ commit }, data) {
        try {
            const response = await apiRequest.upload(data);
            return response;
        } catch (e) {
            commit('SET_ERROR', {
                error: e.errorCode,
                message: e.message
            });
            return false;
        }
    },
    async haf_download({ commit }, data) {
        try {
            const response = await apiRequest.download({
                ...data,
                responseType: 'blob'
            });
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', data.fileName);
            document.body.appendChild(fileLink);

            fileLink.click();
            return true;
        } catch (e) {
            commit('SET_ERROR', {
                error: e.errorCode,
                message: e.message
            });
            return false;
        }
    },
    async download({ commit }, params) {
        try {
            const { data } = await apiRequest.download(params);
            return data;
        } catch (e) {
            commit('SET_ERROR', {
                error: e.errorCode,
                message: e.message
            });
            return false;
        }
    },
    async getMyAccounts({ dispatch, commit }, payload) {
        let response = await dispatch('request', {
            method: 'get',
            url: 'account/accounts/my-accounts/' + payload.cust_id
        });
        if (response)
            commit('SMART_SET', {
                stateKey: 'myAccounts',
                data: response.items.map(item => item.acc_no)
            });
    }
};

const getters = {
    berror: state => state.error, //error from backend
    cashonvaults: state => state.cashonvaults,
    glclasses: state => state.glclasses,
    glheads: state => state.glheads,
    glnodes: state => state.glnodes,
    glleafs: state => state.glleafs,
    currencies: state => state.currencies,
    depositDetails: state => state.depositDetails,
    stoppageDetails: state => state.stoppageDetails,
    manualTrDetails: state => state.manualTrDetails,
    cashInTransitDetails: state => state.cashInTransitDetails,
    nsfDetails: state => state.nsfDetails,
    cpoDetails: state => state.cpoDetails,
    transferDetails: state => state.transferDetails,
    aftDetails: state => state.aftDetails,
    cashonvaultsReport: state => state.cashonvaultsReport,
    chartOfAccounts: state => state.chartOfAccounts,
    halfTrialBalances: state => state.halfTrialBalances,
    glReport: state => state.glReport,
    myAccounts: state => state.myAccounts
};

const main = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default main;