<template>
  <div class="bg-brightGray h-full flex">
    <div class="sidebar w-56 bg-darkGray text-white py-2 h-full">
      <div
        class="
          text-2xl
          font-hairline
          text-white
          px-2
          flex
          items-center
          space-x-3
        "
      >
        <BaseIcon name="key" class="text-success" size="23" />
        <div>Iconfig</div>
      </div>
      <div class="px-1 mt-5">
        <div
          v-for="(item, i) in items"
          :key="i"
          class="
            cursor-pointer
            hover:bg-textDark
            py-2
            px-2
            flex
            space-x-5
            items-center
            rounded
          "
        >
          <BaseIcon :name="item.icon" size="19" class="text-textLight" />
          <div class="text-blueGray">
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
    <div class="main grid grid-cols-12 w-full">
      <div class="col-span-9 p-4">
        <div class="bg-white rounded-lg p-3 px-5">Hello there</div>
      </div>
      <div class="col-span-3 py-4">
        <p class="text-success text-xl font-semi-bold">Account Title</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          label: 'Credentials',
          value: 'accounts',
          icon: 'user',
        },
        {
          label: 'Customers',
          value: 'accounts',
          icon: 'credential',
        },
        {
          label: 'Accounts',
          value: 'accounts',
          icon: 'user',
        },
        {
          label: 'Account Types',
          value: 'accounts',
          icon: 'user',
        },
        {
          label: 'Offices',
          value: 'accounts',
          icon: 'home',
        },
      ],
    };
  },
};
</script>

<style>
</style>