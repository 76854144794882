<template>
  <modal-template
    saveLabel="Reset"
    @cancel="handleCancel"
    @save="resetPassword"
  >
    <template #title>Reset password</template>
    <template>
      <div class="space-y-4">
      <credential-card :credential="credential" />
        <div v-if="canNotReset" class="text-error">
          You can not reset password for this credential, the credential needs to
          make request for password reset.
        </div>
        <div v-else class="grid grid-cols-2 gap-2">
          <base-input
            type="password"
            label="PASSWORD"
            bordered
            v-model="password"
            :errors="errors.password"
          />
          <base-input
            type="password"
            label="CONFIRM PASSWORD"
            bordered
            v-model="confirmPassword"
            :errors="errors.confirmPassword"
          />
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';
import eventBus from '@/eventBus';
import { mapActions, mapGetters } from 'vuex';
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import CredentialCard from '@/components/card/CredentialCard.vue';
export default {
  props: ['credential'],
  components: { ModalTemplate, CredentialCard },
  mixins: [validation],
  data() {
    return {
      photo: '',
      password: '',
      confirmPassword: '',
      meta: [
        { key: 'password', label: 'PASSWORD' },
        { key: 'confirmPassword', label: 'CONFIRM PASSWORD' }
      ],
      errors: {},
      initial: true,
      working: false
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
    canNotReset() {
      return this.credential.status !== 'Request Reset';
    }
  },
  methods: {
    ...mapActions('main', ['request']),
    async getCredential() {
      let response = await this.request({
        method: 'get',
        url: 'billing/credential/' + this.credential._id
      });
      if (response) this.photo = response.item.photo;
    },
    async resetPassword() {
      if (this.canNotReset) return;
      this.initial = false;
      if (!this.isValid()) return;
      this.working = true;
      let response = await this.request({
        url: 'billing/credential/reset/' + this.credential._id,
        method: 'post',
        data: {
          password: this.password
        }
      });
      if (response) {
        eventBus.$emit('open-toast', {
          message: 'Password is resetted successfully.'
        });
        this.$emit('close', { invokeCallBack: true });
      } else
        eventBus.$emit('open-toast', { message: this.berror, error: true });

      this.working = false;
    },
    handleCancel() {
      this.$emit('close');
    }
  },
  created() {
    this.getCredential();
  },
  watch: {
    password() {
      if (!this.initial) this.isValid();
    },
    confirmPassword() {
      if (!this.initial) this.isValid();
    }
  },
  validations: {
    password: { required },
    confirmPassword: { sameAsPassword: sameAs('password') }
  }
};
</script>

<style></style>
