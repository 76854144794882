<template>
  <div class="p-2">
    <bar-loader class="w-12 mx-auto block" v-if="working" />
    <data-table
      v-if="!working"
      name="BRANCHES"
      :headers="headers"
      :items="documents"
      :canSelect="true"
      @select="select"
      @unselect="unselect"
      :hasDynamicActions="true"
      :dynamicHandlers="actionHandlers"
      :paginate="true"
      :rowsPerPage="8"
      :searchable="true"
    >
      <template #toolset>
        <div class="flex flex-wrap">
          <base-button
            code="0006oo"
            label="NEW BRANCH"
            icon="plus"
            class="px-2 text-sm uppercase rounded"
            @click="openBranchRegistrationForm"
          />
        </div>
      </template>

      <template #default="{ dtItem }">
        <td v-for="(header, i) in headers" :key="i" class="py-3 px-2">
          <div v-if="header.key === 'status'" class="flex">
            <div
              class="inline-block px-3 text-sm text-white lowercase rounded"
              :style="{
                backgroundColor: getColor(dtItem.status),
              }"
            >
              {{ dtItem.status }}
            </div>
          </div>
          <div v-else-if="header.key === 'autoGenerateStudentId'" class="flex">
            <switch-button
              class="flex-shrink-0"
              :value="dtItem.autoGenrateStudentId"
            />
          </div>
          <div v-else>
            {{ getProp(dtItem, header.key) }}
          </div>
        </td>
      </template>
    </data-table>
  </div>
</template>
<script>
import DataTable from '@/components/table/DataTable.vue';
import { getPropByString } from '@/utils';
import { mapActions, mapGetters } from 'vuex';
import BarLoader from '@/components/collection/BarLoader.vue';
import SwitchButton from '../../../components/base/SwitchButton.vue';
import eventBus from '@/eventBus';
export default {
  props: [],
  components: { DataTable, BarLoader, SwitchButton },
  data() {
    return {
      selectedId: '',
      working: false,
      documents: [],
      headers: [
        {
          label: 'SCHOOL NAME',
          key: 'util_id.util_name',
        },

        {
          label: 'BRANCH NAME',
          key: 'name',
        },
        {
          label: 'BRANCH CODE',
          key: 'code',
        },
        {
          label: 'ADDRESS',
          key: 'address',
        },
        { label: 'Auto ID', key: 'autoGenerateStudentId' },
        { label: 'Prefix', key: 'studentIdPrefix' },
        { label: 'Last student id', key: 'officeLastStudentId' },
        {
          label: 'STATUS',
          key: 'status',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
    ...mapGetters(['user']),
    selectedItem() {
      return this.documents.find((item) => item._id === this.selectedId);
    },
    actionHandlers() {
      if (!this.selectedItem) return [];
      return [
        {
          label: 'Edit Branch',
          icon: 'pencil-alt',
          handler: this.editBranch,
          fullParam: true,
          code: '0006oo',
        },
      ];
    },
  },
  watch: {},
  methods: {
    ...mapActions('main', ['setData', 'request']),
    getProp: getPropByString,
    async fetchdocuments() {
      this.working = true;
      this.documents = [];
      let response = await this.request({
        method: 'get',
        url: `billing/office/all-branches`,
      });
      this.working = false;
      if (response && !response.error) {
        this.documents = response.items;
      }
    },
    select(id) {
      this.selectedId = id;
    },
    unselect() {
      this.selectedId = '';
    },
    getColor(status = '') {
      let colors = {
        verified: 'teal',
        pending: 'orange',
        suspended: 'red',
      };
      return colors[status.toLowerCase()];
    },
    openBranchRegistrationForm() {
      eventBus.$emit('open-modal', { modalId: 23, cb: this.fetchdocuments });
    },
    editBranch(branch) {
      eventBus.$emit('open-modal', {
        modalId: 2,
        componentProps: {
          action: 'edit',
          btnLabel: 'Update',
          branch,
        },
        cb: this.fetchdocuments,
      });
    },
    insertNewItem({ response }) {
      this.documents.unshift(response);
    },
    updateItem({ response }) {
      this.documents.unshift(response);
    },
  },
  mounted() {
    this.fetchdocuments();
  },
};
</script>
<style></style>
