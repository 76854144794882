<template>
  <confirm-template :isWorking="working" @no="handleNo" @yes="handleYes">
    <template #title>Delete Pending User</template>
    <template>
      <div class="space-y-4">
        <credential-card :credential="credential" />
        <div>Are you sure you really want to delete this user?</div>
      </div>
    </template>
  </confirm-template>
</template>

<script>
import eventBus from '@/eventBus';
import { mapActions, mapGetters } from 'vuex';
import ConfirmTemplate from '@/components/card/confirmation/ConfirmTemplate.vue';
import CredentialCard from '@/components/card/CredentialCard.vue';
export default {
  props: ['credential'],
  components: { ConfirmTemplate, CredentialCard },
  data() {
    return {
      photo: '',
      working: false,
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
  },

  methods: {
    ...mapActions('main', ['request']),
    async getCredential() {
      let response = await this.request({
        method: 'get',
        url: 'billing/credential/' + this.credential._id,
      });
      if (response) this.photo = response.item.photo;
    },
    async handleYes() {
      this.working = true;
      let response = await this.request({
        url: 'billing/credential/delete/' + this.credential._id,
        method: 'put',
      });
      if (response) {
        eventBus.$emit('open-toast', {
          message: 'User deleted successfully',
        });
        this.$emit('close', { invokeCallBack: true });
      } else
        eventBus.$emit('open-toast', { message: this.berror, error: true });
      this.working = false;
    },
    handleNo() {
      this.$emit('close');
    },
  },
  created() {
    this.getCredential();
  },
};
</script>

<style></style>
