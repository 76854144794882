<template>
  <div class="px-3">
    <div class="flex justify-center text-center py-2 w-full">
      <div class="space-y-5">
        <p class="text-xl uppercase font-medium text-textMedium text-center">
          {{ title || 'Confirmation' }}
        </p>
        <p class="text-textMedium text-center">
          {{ message }}
        </p>
      </div>
    </div>

    <div class="flex justify-between">
        <base-button
          label="cancel"
          class="
            py-1
            px-4
            text-textMedium
            bg-brightGray
            hover:bg-danger
            hover:text-error
            rounded
          "
          @click="close"
        />
      <base-button label="YES" class="px-3 rounded" @click="proceed" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'message'],
  methods: {
    cancel() {
      this.$emit('close');
    },
    close() {
      this.$emit('close');
    },
    proceed() {
      this.$emit('close', { invokeCallBack: true });
    },
  },
};
</script>

<style>
</style>