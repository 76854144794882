<template>
  <div class="text-textMedium space-y-4 font-mono">
    <div
      class="text-2xl uppercase py-2 shadow-md text-textDark font-semibold bg-brighterGray text-center"
    >
      Ticket Check out
    </div>

    <div class="flex justify-between mt-3 px-3 space-x-3">

      

  <table class="w-1/2 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
              <th scope="col" class="px-1 py-1">
                  Range
              </th>
              <th scope="col" class="px-1 py-1">
                  Agent Name
              </th>
              
          </tr>
      </thead>
      <tbody>
          <tr  v-for="(agent, i) in ranges"
          :key="i" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th scope="row" class="px-0 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {{agent.range }}
              </th>
              <td class="px-1 py-4">
                  {{agent.name}}
              </td>
              
          </tr>
          
          
      </tbody>
  </table>
<div>
  <div class="flex">
    <base-input
        type="number"
        placeholder="range"
        bordered
        required
        :min="0"
        v-model="range"
        @keyup.enter="getMapped()"
        class="col-span-1 text-textDark text-lg"
      />
      <base-button
        label="GO"
        class="py-1 px-3 text-white bg-blue-600 hover:bg-danger hover:text-error rounded"
        @click="getMapped()"
      />
  </div>
  <table class="w-full text-sm text-left rtl:text-right  border-l text-gray-500 dark:text-gray-400">
    
    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr class="mx-8 justify-between">
           
            <th scope="col" class="px-5 py-1" >Range</th>
            <th scope="col" class="px-5 py-1">Printed</th>
        </tr>
    </thead>
    <tbody>
        <tr  v-for="lists in mapped.sort(a,b=>a.range-b.range)"
        :key="lists" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            
            <td>{{lists.range||'Latest'}}</td>
            <td v-for="counts in lists.counts" :key="counts" class="px-2 py-1 flex">
             {{  counts.confirmed ? 'Scanned' : 'Not Scanned'}}: {{ counts.count }}
            </td>
            
            
            
        </tr>
        
        
    </tbody>
</table>
    </div>  
  </div>
  <base-button
  label="cancel"
  class="py-1 px-4 text-textMedium bg-brightGray hover:bg-danger hover:text-error rounded"
  @click="close"
/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../eventBus';
export default {
  props: { ranges: { type: Object, default: () => [] } },

  data() {
    return {
      isWorking: false,
      util_id: undefined,
      range:1000,
      mapped:[]
    };
  },
  watch: {
    util_id() {
      this.fetchOrganizations();
    },
    ranges(){
      return this.ranges((a, b) => {
  return a - b;
});
    }
  },

  computed: {
    ...mapGetters('main', ['berror']),
  },
  methods: {
    ...mapActions('main', ['request']),
    async getMapped() {
      let id=this.$route.params.id;
      this.isWorking = true;
      const response = await this.request({
        method: 'get',
        url: `billing/events/agnet/mapped/${id}?num=${this.range}`,
      });
      if (response) {
        this.mapped=response.items;
      this.mapped.sort((a, b) => {
  const [aStart, aEnd] = this.sortthem(a.range);
  const [bStart, bEnd] = this.sortthem(b.range);

  // Sort by start value first, then by end value
  if (aStart !== bStart) {
    return aStart - bStart;
  }
  return aEnd - bEnd;
});
        this.isWorking = false;
        
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror,
        });
      }
      this.isWorking = false;
    },
 sortthem(rangeString) {
  // Extract start and end values from the range string
  const [start, end] = rangeString.split('-').map(Number);

  // Handle cases where the range string is not in the expected format
  if (isNaN(start) || isNaN(end) || start > end) {
    return [Infinity, -Infinity];  // Set to infinity for sorting
  }

  return [start, end];
},
    close() {
      this.$emit('close');
    },
  },
  created() {

    this.getMapped();
  },
};
</script>

<style></style>
