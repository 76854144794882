<template>
  <div class="grid grid-cols-4 col-gap-4 row-gap-4 text-textLight text-lg">
    <div
      v-for="report in grantedReports"
      :key="report.value"
      class="
        border border-borderGray
        p-3
        rounded
        shadow-sm
        cursor-pointer
        hover:shadow-md
        font-semibold
        text-xl text-start
        flex-shrink-0
      "
      @click="generateReport(report.value)"
    >
      {{ report.label }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedReport: '',
      reports: [
        {
          label: 'School Students',
          value: '/report/myorg-customers',
          code: 'r-myorgcust',
        },
        {
          label: 'Branch Students',
          value: '/report/mybr-customers',
          code: 'r-mybrcust',
        } ,//{ 
        //   label: 'Students of another organization',
        //   value: '/report/org-customers',
        //   code: 'r-orgcust',
        // },
        {
          label: 'Students by branch',
          value: '/report/br-customers',
          code: 'r-brcust',
        },
        {
          label: 'School Payments',
          value: '/report/myorg-payments',
          code: 'r-myorgpymnts',
        },
        {
          label: 'Branch Payments',
          value: '/report/mybr-payments',
          code: 'r-mybrpymnts',
        },
        {
          label: 'Payments by branch',
          value: '/report/br-payments',
          code: 'r-brpymnts',
        },
        // bills
        {
          label: 'School Bills',
          value: '/report/myorg-bills',
          code: 'r-myorgbills',
        },
        {
          label: 'Branch Bills',
          value: '/report/mybr-bills',
          code: 'r-mybrbills',
        },
        // {
        //   label: 'Bills of another organization',
        //   value: '/report/org-bills',
        //   code: 'r-orgbills',
        // },
        {
          label: 'Bills by branch',
          value: '/report/br-bills',
          code: 'r-brbills',
        },
      ],
    };
  },
  computed: {
    grantedReports() {
      return this.reports.filter(({ code }) => this.isGranted(code));
    },
  },
  methods: {
    generateReport(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style>
</style>