<template>
  <div class="w-1/2 mx-auto flex justify-center">
    <div class="space-y-2 text-center">
      <div class="flex justify-center py-10">
        <img src="@/assets/page_not_found.svg" class="w-1/2" />
      </div>
      <div class="text-textMedium">
        Sorry for the inconvenience. We have not found this page, but we can
        definitely let you go to your dashboard. If you want to, You just have
        to click the button shown below.
      </div>
      <div class="flex justify-center pt-4">
        <base-button
          label="Go to dashboard"
          :primary="false"
          class=" py-2 px-6 rounded bg-brightGray hover:text-primary"
          @click="gotoDashboard"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DASHBOARDS from '../config/dashboards';
import ROLES from '../config/roles';
export default {
  computed: {
    ...mapGetters(['user']),
    role() {
      if (this.user?.role) return this.user.role;
      return '';
    }
  },
  methods: {
    gotoDashboard() {
      const currentDashboard =
        DASHBOARDS[Object.keys(ROLES).find(key => ROLES[key] === this.role)];
      this.$router.push(currentDashboard);
    }
  }
};
</script>

<style></style>
