import REPORTS from '@/views/protected/report/Reports'
// customers
import MY_ORG_STUDENTS_REPORT from '@/views/protected/report/customers/MyOrgCustomersReport'
import MY_BRANCH_STUDENTS_REPORT from '@/views/protected/report/customers/MyBranchCustomersReport'
import STUDENTS_BY_ORG_REPORT from '@/views/protected/report/customers/CustomersByOrgReport'
import STUDENTS_BY_BRANCH_REPORT from '@/views/protected/report/customers/CustomersByBranchReport'
// payments
import MY_ORG_PAYMENTS_REPORT from '@/views/protected/report/payments/MyOrgPaymentsReport'
import MY_BRANCH_PAYMENTS_REPORT from '@/views/protected/report/payments/MyBranchPaymentsReport'
import PAYMENTS_BY_ORG_REPORT from '@/views/protected/report/payments/PaymentsByOrgReport'
import PAYMENTS_BY_BRANCH_REPORT from '@/views/protected/report/payments/PaymentsByBranchReport'

// uploaded bills
import MY_ORG_BILLS_REPORT from '@/views/protected/report/uploadedBills/MyOrganization'
import BILLS_BY_ORG_REPORT from '@/views/protected/report/uploadedBills/OtherOrg'
import MY_BRANCH_BILLS_REPORT from '@/views/protected/report/uploadedBills/MyBranch'
import BILLS_BY_BRANCH_REPORT from '@/views/protected/report/uploadedBills/OtherBranch'

// bill tranactions
// import MY_ORG_BILL_TRXNS_REPORT from '@/views/protected/report/billTransactions/MyOrganization'
// import BILL_TRXNS_BY_ORG_REPORT from '@/views/protected/report/billTransactions/OtherOrg'
// import MY_BRANCH_BILL_TRXNS_REPORT from '@/views/protected/report/billTransactions/MyBranch'
// import BILL_TRXNS_BY_BRANCH_REPORT from '@/views/protected/report/billTransactions/OtherBranch'


// bank tranactions
import MY_ORG_BANK_TRXNS_REPORT from '@/views/protected/report/bankTransactions/MyOrganization'
import BANK_TRXNS_BY_ORG_REPORT from '@/views/protected/report/bankTransactions/OtherOrg'
import MY_BRANCH_BANK_TRXNS_REPORT from '@/views/protected/report/bankTransactions/MyBranch'
import BANK_TRXNS_BY_BRANCH_REPORT from '@/views/protected/report/bankTransactions/OtherBranch'

// branch tranactions
import MY_ORG_TRXNS_REPORT from '@/views/protected/report/branchTransactions/MyOrganization'
import TRXNS_BY_ORG_REPORT from '@/views/protected/report/branchTransactions/OtherOrg'
import MY_BRANCH_TRXNS_REPORT from '@/views/protected/report/branchTransactions/MyBranch'
import TRXNS_BY_BRANCH_REPORT from '@/views/protected/report/branchTransactions/OtherBranch'

// officer transactions
import MY_TRXNS from '@/views/protected/report/officer/MyTransactions'
import OFFICER_TRXNS from '@/views/protected/report/officer/OfficerTransactions'


import STUDENT_BILLS_REPORT from '@/views/protected/report/CustomerBillsReport'

const publicMeta = {
  public: false
};

const REPORT_ROUTES = [
  {
    path: 'reports',
    component: REPORTS,
    meta: {code: 3}
  },
// customers
  {
    path: 'report/students',
    component: MY_ORG_STUDENTS_REPORT,
    meta: {code: 'r-myorgcust'}
  },
  {
    path: 'report/myorg-customers',
    component: MY_ORG_STUDENTS_REPORT,
    meta: {code: 'r-myorgcust'}
  },
  {
    path: 'report/mybr-customers',
    component: MY_BRANCH_STUDENTS_REPORT,
    meta: {code: 'r-mybrcust'}
  },
  {
    path: 'report/org-customers',
    component: STUDENTS_BY_ORG_REPORT,
    meta: {code: 'r-orgcust'}
  },
  {
    path: 'report/br-customers',
    component: STUDENTS_BY_BRANCH_REPORT,
    meta: {code: 'r-brcust'}
  },
// payments
  {
    path: 'report/myorg-payments',
    component: MY_ORG_PAYMENTS_REPORT,
    meta: {code: 'r-myorgpymnts'}
  },
  {
    path: 'report/org-payments',
    component: PAYMENTS_BY_ORG_REPORT,
    meta: {code: 'r-orgpymnts'}
  },
  {
    path: 'report/mybr-payments',
    component: MY_BRANCH_PAYMENTS_REPORT,
    meta: {code: 'r-mybrpymnts'}
  },
  {
    path: 'report/br-payments',
    component: PAYMENTS_BY_BRANCH_REPORT,
    meta: {code: 'r-brpymnts'}
  },
// bills
  {
    path: 'report/org-bills',
    component: BILLS_BY_ORG_REPORT,
    meta: {code: 'r-orgbills'}
  },
  {
    path: 'report/myorg-bills',
    component: MY_ORG_BILLS_REPORT,
    meta: {code: 'r-myorgbills'}
  },
  {
    path: 'report/myorg-billss/:id',
    component: MY_ORG_BILLS_REPORT,
    meta: {code: 3}
  },
  {
    path: 'report/mybr-bills',
    component: MY_BRANCH_BILLS_REPORT,
    meta: {code: 'r-mybrbills'}
  },
  {
    path: 'report/br-bills',
    component: BILLS_BY_BRANCH_REPORT,
    meta: {code: 'r-brbills'}
  },

  // bill transactions
  // {
  //   path: 'report/org-bill-trxns',
  //   component: BILL_TRXNS_BY_ORG_REPORT,
  //   meta: {code: 'r-oo-bill-tr'}
  // },
  // {
  //   path: 'report/myorg-bill-trxns',
  //   component: MY_ORG_BILL_TRXNS_REPORT,
  //   meta: {code: 'r-mo-bill-tr'}
  // },
  // {
  //   path: 'report/mybr-bill-trxns',
  //   component: MY_BRANCH_BILL_TRXNS_REPORT,
  //   meta: {code: 'r-mb-bill-tr'}
  // },
  // {
  //   path: 'report/br-bill-trxns',
  //   component: BILL_TRXNS_BY_BRANCH_REPORT,
  //   meta: {code: 'r-ob-bill-tr'}
  // },

  // bank transactions
  {
    path: 'report/org-bank-trxns',
    component: BANK_TRXNS_BY_ORG_REPORT,
    meta: {code: 'r-oo-bank-tr'}
  },
  {
    path: 'report/myorg-bank-trxns',
    component: MY_ORG_BANK_TRXNS_REPORT,
    meta: {code: 'r-mo-bank-tr'}
  },
  {
    path: 'report/mybr-bank-trxns',
    component: MY_BRANCH_BANK_TRXNS_REPORT,
    meta: {code: 'r-mb-bank-tr'}
  },
  {
    path: 'report/br-bank-trxns',
    component: BANK_TRXNS_BY_BRANCH_REPORT,
    meta: {code: 'r-ob-bank-tr'}
  },

  // branch/office transactions
  {
    path: 'report/org-bill-trxns',
    component: TRXNS_BY_ORG_REPORT,
    meta: {code: 'r-oo-bill-tr'}
  },
  {
    path: 'report/myorg-bill-trxns',
    component: MY_ORG_TRXNS_REPORT,
    meta: {code: 'r-mo-bill-tr'}
  },
  {
    path: 'report/mybr-bill-trxns',
    component: MY_BRANCH_TRXNS_REPORT,
    meta: {code: 'r-mb-bill-tr'}
  },
  {
    path: 'report/br-bill-trxns',
    component: TRXNS_BY_BRANCH_REPORT,
    meta: {code: 'r-ob-bill-tr'}
  },

// officer transactions
  {
    path: 'report/officer-trxns',
    component: OFFICER_TRXNS,
    meta: {code: 'r-mb-bank-tr'}
  },
  {
    path: 'report/my-trxns',
    component: MY_TRXNS,
    meta: {code: 'r-ob-bank-tr'}
  },


  {
    path: 'report/cust-bills',
    component: STUDENT_BILLS_REPORT,
    meta: {code: 3}
  },
].map(({ meta, ...route }) => ({
  ...route,
  meta: {
    ...meta,
    ...publicMeta
  }
}));
export default REPORT_ROUTES;
