<template>
  <div class="space-y-2">
    <tabs>
      <tab title="Bill Custom Fields" v-if="hasGrantedActivity([ '0777cf'])">
          <payment-custom-fields />
      </tab>
      <tab title="Penalty Formula" v-if="hasGrantedActivity([ '0999up'])">
        <due-date-formula />
    </tab>
    <tab title="Roles" v-if="hasGrantedActivity(['0222r'])">
          <roles/>
      </tab>
      <tab title="API CONFIG" v-if="hasGrantedActivity([ '0777ra'])">
          <organization-api-configuration />
      </tab>
    </tabs>
  </div>
</template>

<script>
import Tab from '@/components/tab/Tab.vue';
import Tabs from '@/components/tab/Tabs.vue';
import Roles from '@/views/protected/DynamicRole/Roles.vue';
import PaymentCustomFields from '@/components/forms/config/PaymentCustomFields.vue';
import OrganizationApiConfiguration from '@/components/forms/config/OrganizationApiConfiguration.vue';
import DueDateFormula from '../Utilities/DueDateFormula.vue';
export default {
  components: {
    Tab,
    Tabs,
    Roles,
    PaymentCustomFields,
    OrganizationApiConfiguration,
    DueDateFormula,
  },
};
</script>
<style>
</style>