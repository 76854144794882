<template>
  <div class="bg-brightGray h-full p-4">
    <base-title title="Payment Types">
      <template #actions>
        <div class="flex space-x-3">
          <base-button
            label="Back"
            icon="arrow-left"
            class="px-2 bg-gray text-textDarkest"
            :primary="false"
            @click="$router.back()"
          />
          <base-button
            label="New payment type"
            icon="plus"
            class="px-2"
            @click="handleCreatePaymentType"
          />
          <!-- :isVisible="permission.add" -->
        </div>
      </template>
    </base-title>
    <div class="mt-2 grid grid-cols-3 gap-2">
      <div class="bg-white p-2 item-grid" v-for="(item, i) in items" :key="i">
        <div class="flex justify-between w-full">
          <div class="flex space-x-2 items-center">
            <p class="text-lg text-textDarkest uppercase">
              {{ item.title }}
            </p>
            <!-- <div class="w-3 h-2 bg-primary"></div> -->
          </div>
          <div class="flex justify-between">
            <status-chip :label="item.status" />
          </div>
        </div>
        <div></div>
        <div class="flex space-x-4 items-end">
          <div v-if="!item.isMandatory" class="flex space-x-5 mt-3">
            <!--v-if="permission.delete || !isSchoolOfficial"-->
            <base-icon
              name="trash-alt"
              class="cursor-pointer text-textMedium"
              @click="handleDeleteItem(item._id)"
            />
            <!--v-if="permission.update || !isSchoolOfficial"-->
            <base-icon
              name="pencil-alt"
              class="cursor-pointer text-textMedium"
              @click="handleEditItem(i)"
            />
            <!--v-if="permission.view || !isSchoolOfficial"-->
            <!-- <base-icon
              name="eye"
              class="cursor-pointer text-textMedium"
              @click="handleItemDetail(item._id)"
            /> -->
          </div>
          <div class="flex-grow flex space-x-3 justify-end">
            <p class="text-textMedium">Is penalized</p>
            <switch-button
              class="flex-shrink-0"
              :value="item.isPenalized"
              @input="preUpdateIsPenalized(item)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import eventBus from '@/eventBus';
import SwitchButton from '../../../components/base/SwitchButton.vue';
export default {
  components: { SwitchButton },
  props: [],
  data() {
    return {
      permission: {},
      isSchoolOfficial: false,
      items: [],
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    ...mapActions('main', ['request']),
    handleCreatePaymentType() {
      let componentProps = {};
      eventBus.$emit('open-modal', {
        modalId: 29,
        componentProps,
        cb: this.handleFetchPaymentTypes,
      });
    },
    async preUpdateIsPenalized(item) {
      let componentProps = {
        title: item.isPenalized ? `Disable Penalty` : `Enable Penalty`,
        message:
          'Are you sure you really want to ' +
          (item.isPenalized
            ? `Disable penalty calculation`
            : `Enable penalty calculation`),
      };
      eventBus.$emit('open-modal', {
        modalId: 3,
        componentProps,
        cb: this.handleTogglePenalized,
        cbParams: item,
      });
    },
    async handleTogglePenalized(item) {
      let response = await this.request({
        method: 'put',
        url: 'billing/paymentType/' + item._id,
        data: {
          isPenalized: !item.isPenalized,
        },
      });
      if (response) {
        eventBus.$emit('open-toast', {
          message: 'payment type updated successfully',
        });
        this.handleFetchPaymentTypes();
      } else {
        eventBus.$emit('open-toast', {
          message: 'error updating payment type',
          error: true,
        });
      }
    },

    async handleFetchPaymentTypes() {
      let response = await this.request({
        method: 'get',
        url: 'billing/paymentType',
      });
      if (response && !response.error) {
        this.items = response.items;
      } else {
        this.items = [];
      }
    },

    async handleEditItem(index) {
      let componentProps = {
        oldForm: this.items[index],
      };
      eventBus.$emit('open-modal', {
        modalId: 29,
        componentProps,
        cb: this.handleFetchPaymentTypes,
      });
    },

    async handleDeleteItem(id) {
      let componentProps = {
        message: 'Are you sure you want to delete the selected payment type',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 3,
        componentProps,
        cb: this.proceedDelete,
        cbParams: { id },
      });
    },

    async proceedDelete({ id }) {
      let response = await this.request({
        method: 'delete',
        url: 'billing/paymentType/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'payment type deleted successfully',
        });
        this.handleFetchPaymentTypes();
      } else {
        eventBus.$emit('open-toast', {
          message: 'error deleting payment type',
          error: true,
        });
      }
    },

    async handleItemDetail(id = 0) {
      this.$router.push({
        name: 'bill-generator-formula',
        params: { id },
      });
    },
  },
  created() {
    this.handleFetchPaymentTypes();
  },
};
</script>

<style scoped>
.item-grid {
  animation: slider 0.5s;
}

@keyframes slider {
  0% {
    transform: translateY(20px);
    opacity: 0%;
  }
  100% {
    transform: translateY(0);
    opacity: 100%;
  }
}
</style>
