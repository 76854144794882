<template>
  <tippy
    placement="bottom"
    trigger="click"
    theme="light"
    :arrow="arrow"
    class="
      border
      rounded
      text-primary
      border-primary
      hover:bg-primary
      hover:text-white
    "
    animation="scale"
    size="large"
    interactive
    ref="actions-tt"
  >
    <template v-slot:trigger>
      <slot
        ><base-button
          :primary="false"
          class="hover:bg-primary hover:text-white"
          :icon="btnO9n === 'horizontal' ? 'ellipsis-h' : 'ellipsis-v'"
      /></slot>
      <!-- class="text-textDark hover:bg-primary hover:text-white rounded-full p-2 text-sm" -->
    </template>

    <div class="py-2 text-left">
      <!-- <div
        class="pt-1 pb-2 mb-2 border-b border-brightGray uppercase text-center text-textMedium"
      >
        Actions
      </div> -->
      <div
        class="
          py-2
          px-4
          bg-white
          hover:bg-brightGray
          cursor-pointer
          grow-active
          text-textDark
          hover:text-primary
          grid grid-cols-4
          gap-1
        "
        v-for="(action, i) in grantedActions"
        :key="i"
        @click="smartHandler(action)"
      >
        <div class="flex items-center">
          <BaseIcon v-if="action.icon" :name="action.icon" :size="14" />
        </div>
        <div class="col-span-3 text-sm flex items-center capitalize">
          {{ action.label }}
        </div>
      </div>
    </div>
  </tippy>
</template>

<script>
import { activity } from '@/mixins/activity';
export default {
  mixins: [activity],
  props: {
    actions: {},
    item: {},
    arrow: { type: Boolean, default: true },
    btnO9n: { type: String, default: 'horizontal' }, //?btnO9n=btnOrientation
  },
  computed: {
    grantedActions() {
      return this.actions.filter(
        ({ code, condition = true }) => this.isGranted(code) && condition
      );
    },
  },
  methods: {
    hideActionDialog() {
      this.$refs['actions-tt'].tip.hide();
    },
    smartHandler(action) {
      this.hideActionDialog();
      if (action?.fullParam) action.handler(this.item);
      else if (action?.idParam) action.handler(this.item._id);
      else action.handler();
    },
  },
};
</script>

<style></style>
