import { mapActions } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
      customFields: [],
    };
  },
  computed: {
  },
  methods: {
    ...mapActions('main', ['request']),
    async getUtilOrgBillCustomFields() {
      this.isLoading = true;
      this.customFields = [];
      let response = await this.request({
        method: 'get',
        url: 'billing/customfields/bill',
      });
      if (response) {
        this.customFields = response.items[0].bill_fields;
      }
      this.isLoading = false;
    },
  },
  watch: {
  },
  async created() {
    this.getUtilOrgBillCustomFields();
  }
};
