<template>
  <div class="space-y-2">
    <bar-loader v-if="isLoading" />
    <div v-else class="flex justify-between">
      <div class="text-lg uppercase text-primary font-medium">
        SCHOOLS
      </div>
      <div class="flex justify-end space-x-3">
        <base-button
          v-if="isGranted('0008ut')"
          label="Add School"
          icon="plus"
          class="px-2 rounded"
          @click="openRegForm"
        />
        <div class="flex justify-end">
          <!-- <actions
            v-if="actionHandlers.length > 0"
            :actions="actionHandlers"
            :item="selectedItem"
            :arrow="true"
          >
            <div
              class="
                flex
                items-center
                px-2
                py-1
                space-x-2
                text-sm
                rounded
                cursor-pointer
                bg-brighterGray
                hover:text-primary
                active:bg-brighterGray
                hover:bg-brightGray
              "
            >
              <div>actions</div>
              <BaseIcon name="chevron-down" :size="12" />
            </div>
          </actions> -->
        </div>
      </div>
    </div>

    <counts class="" />

    
    <div>
      <!-- <div
        class="
          p-2
          grid
          xl:grid-cols-3
          lg:grid-cols-3
          md:grid-cols-2
          sm:grid-cols-2
          gap-2
        "
      > -->
      <data-table
      :headers="headers"
      :items="documents"
      :searchable="true"
      :canSelect="true"
      @select="select"
      @unselect="unselect"
      :isWorking="isLoading"
      :dynamicHandlers="actionHandlers"
      :hasDynamicActions="true"
    ></data-table>

        <!-- <div
          class=""
          v-for="utilityOrg in documents"
          :key="utilityOrg._id"
          @click="selectUtilityOrg(utilityOrg.util_id)"
        >
          <utility-card
            :utilityOrg="utilityOrg"
            :selected="selectedId == utilityOrg.util_id"
          />
        </div> -->
      <!-- </div> -->
      <pagination
        v-if="paginate && documents && documents.length > 0"
        :currentPage="currentPage"
        :pageSize="pageSize"
        :totalPages="totalPages"
        @changepage="handlePageChange"
        @changepagesize="handlePageSizeChange"
      />
    </div>
    <div>
      <data-table
      :headers="sh_headers"
      :items="sch_documents"
      :searchable="true"
      :canSelect="true"
      @select="select"
      @unselect="unselect"
      :isWorking="isLoading"
      :dynamicHandlers="actionHandlers"
      :hasDynamicActions="true"
    ></data-table>
      <pagination
        v-if="paginate && sch_documents && sch_documents.length > 0"
        :currentPage="currentPage"
        :pageSize="pageSize"
        :totalPages="totalPages"
        @changepage="handlePageChange"
        @changepagesize="handlePageSizeChange"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import eventBus from '@/eventBus';
import Pagination from '@/components/pagination/Pagination.vue';
// import Actions from '@/components/card/Actions.vue';
import BarLoader from '../../../components/collection/BarLoader.vue';
import DataTable from '../../../components/table/DataTable.vue';
import Counts from '../../../components/card/dashboard/SchoolCounts.vue';

export default {
  components: {Counts, Pagination,
    //  Actions,
      BarLoader, DataTable },
  data() {
    return {
      documents: [],
      sch_documents: [],
      isLoading: false,
      selectedId: '',
      paginate: true,
      totalPages: 0,
      rowsPerPage: 8,
      currentPage: 1,
      pageSize: 10,
      sh_headers: [
        { label: "name", key: "util_name" },
        { label: "code", key: "util_id" },
        { label: "Type", key: "org_type" },
        { label: "account number", key: "accountNumber" },
        { label: "phone number", key: "tel" },
      ],
      headers: [
        { label: "name", key: "school.name" },
        { label: "Account NUmber", key: "school.acc_no" },
         { label: "Bill Amount", key: "totalAmount" },
        { label: "total BIlls", key: "totalCount" },
        { label: "Bills Report", key: "statuses" },
      ]
    };
  },
  computed: {
    ...mapGetters(['user']),
    displayItems() {
      if (!this.paginate) return this.sch_documents;
      let startIndex = (this.currentPage - 1) * this.pageSize;
      let endIndex = startIndex + this.pageSize;
      if (endIndex > this.sch_documents.length) endIndex = this.sch_documents.length;
      return this.sch_documents.slice(startIndex, endIndex);
    },
    selectedItem() {
      if (this.selectedId && this.sch_documents)
        return this.sch_documents.find((item) => item._id=== this.selectedId);
      else return '';
    },
    actionHandlers() {

      if (!this.selectedItem) return [];
      return [
        {
          label: 'Edit School',
          icon: 'pencil-alt',
          handler: this.editUtilityOrg,
          fullParam: true,
          code: "0088ut",
        },
        {
          label: 'Detail',
          icon: 'more',
          handler: this.SchoolDetail,
          fullParam: true,
          code: "0088ut",
        },
        {
          label: 'Authorized Tasks',
          icon: 'eye',
          handler: this.openOrganizationAuthorizedTasks,
          idParam: true,
          code: "0088ut",
        },
      ];
    },
  },
  watch: {
    currentPage() {
      this.getDocuments();
    },
  },
  methods: {
    ...mapActions('main', ['requestMutate', 'request']),
    openRegForm() {
      eventBus.$emit('open-modal', { modalId: 1, cb: this.insertNewItem });
    },
    editUtilityOrg(utilityOrg) {
      eventBus.$emit('open-modal', {
        modalId: 9,
        componentProps: { utilityOrg, action: 'edit' },
        cb: this.getDocuments,
      });
    },
    openOrganizationAuthorizedTasks(id) {
      this.$router.push('/org-activities/'+id)
    },
    SchoolDetail(id) {
      this.$router.push('/report/myorg-billss/'+id._id)
    },
    selectUtilityOrg(id) {
      this.selectedId = id;
    },
    select(id) {
      this.selectedId = id;
    },
    unselect() {
      this.selectedId = '';
    },
    insertNewItem({ response }) {
      this.sch_documents.unshift(response);
    },
    handlePageChange(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.currentPage = 1;
    },
    async openDetails(utilityOrg) {
      let response = await this.request({
        method: 'get',
        url: 'utilityOrg/heads/' + utilityOrg._id,
      });
      if (response) {
        utilityOrg.head = response.items[0];
      }
      let componentProps = { utilityOrg };
      eventBus.$emit('open-modal', { modalId: 19, componentProps });
    },
    async getDocuments() {
      this.documents = [];
      this.isLoading = true;
      const response = await this.request({
        url: 'billing/reports/total-bill-orgs',
        method: 'get',
      });
      if (response) {
        this.documents = response.items;
        // this.documents = response.items
      } else {
        this.documents = [];
      }
      this.isLoading = false;
    },
    async getSchoDocuments() {
      this.sch_documents = [];
      this.isLoading = true;
      const response = await this.request({
        url: 'billing/utiltypes',
        method: 'get',
      });
      if (response) {
        this.sch_documents = response.items;
        // this.sch_documents = response.items
      } else {
        this.sch_documents = [];
      }
      this.isLoading = false;
    },
  },
  created() {
    this.getDocuments();
    this.getSchoDocuments();
  },
};
</script>

<style></style>
