<template>
  <div class="space-y-3 p-4">
    <div
      class="flex justify-between text-textMedium text-xl border-b-2 border-gray-200 pb-3"
    >
      <p>Select worksheets</p>
      <div class="hover:text-red-400 cursor-pointer" @click="handleClose">
        <base-icon name="times" :size="24" />
      </div>
    </div>
    <div class="">
      <base-radio
        v-model="Form.students"
        label="Students"
        :items="sheetOptions"
      />
      <base-radio
        v-model="Form.payments"
        label="Payments"
        :items="sheetOptions"
      />
    </div>
    <div class="flex justify-end">
      <base-button
        :isWorking="isWorking"
        label="Next"
        :iconRight="true"
        icon="chevron-right"
        @click="handleSave"
        class="rounded px-3"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../../../eventBus';
export default {
  props: ['school', 'branch'],
  data() {
    return {
      isWorking: false,
      Form: {
        students: undefined,
        payments: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('migration', ['sheets', 'fileId']),
    ...mapGetters('main', ['berror']),
    sheetOptions() {
      return this.sheets.map(({ name }) => ({ label: name, value: name }));
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    async handleSave() {
      this.isWorking = true;
      console.info({ fileId: this.fileId });
      const response = await this.request({
        url: 'migration/parse-sheets',
        method: 'post',
        data: {
          fileId: this.fileId,
          school: this.school,
          branch: this.branch,
          ...this.Form,
        },
      });
      if (response) {
        // TODO inc stepper
        eventBus.$emit('open-modal', {
          modalId: 34,
          componentProps: {
            school: this.school,
            branch: this.branch,
          }
        });
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror,
        });
      }
      this.isWorking = false;
    },
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style></style>
