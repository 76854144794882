<template>
  <div class="space-y-2">
    <!-- <pre>{{ { bills: items } }}</pre> -->
    <div v-for="(bill, i) in items" :key="i">
      <BillCard :bill="bill" @refresh="searchBills" />
    </div>
  </div>
</template>

<script>
import BillCard from "./BillCard.vue";
import { mapActions } from 'vuex';
export default {
  components: { BillCard },
  props: ['keyword'],
  data() {
    return {
      items: [],
    }
  },
  watch: {
    keyword() {
      this.searchBills()
    }
  },
  methods: {
    ...mapActions('main', ['request']),
    async searchBills() {
      try {
        const res = await this.request({
          url: "billing/utilcustomers/search?keyword=" + this.keyword,
          method: 'get',
        });
        if (res) {
          this.items = res.items;
        } else {
          this.items = [];
        }
      } catch (error) {
        console.error(error);
        this.items = [];
      }
    }
  },
  mounted() {
    this.searchBills()
  }
}
</script>

<style></style>
