<template>
  <div class="space-y-3 p-4 w-full h-full">
    <div class="font-semibold text-center">
      <div class="text-xl text-textMedium">Penality Formula</div>
      <div
        class="
          flex flex-wrap
          space-x-3
          justify-center
          border border-borderGray
          rounded
          p-3
          shadow-md
          bg-brightGray
          text-textMedium text-2xl
        "
      >
        <div v-for="(data, i) in formula" :key="i" class="">{{ data }}</div>
        <div>{{ temp }}</div>
      </div>
    </div>
    <div class="flex space-x-3 p-2">
      <!-- {{ result }} -->
    </div>
    <div class="xl:grid grid-cols-2 gap-4 items-center justify-center">
      <div class="flex flex-wrap xl:justify-end">
        <div class="">
          <BaseButton
            label="Late Days"
            :primary="false"
            class="m-3 border rounded border-borderGray shadow-sm px-3 text-xl"
            @click="push('late_days')"
          />
          <BaseButton
            label="Amount"
            :primary="false"
            class="m-3 border rounded border-borderGray shadow-sm px-3 text-xl"
            @click="push('amount')"
          />
          <!-- <BaseButton
          v-for="(customField, i) in numberCustomFields" :key="i"
            :label="customField.name"
            :primary="false"
            class="m-3 border rounded border-borderGray shadow-sm px-3 text-xl"
            @click="push(customField.key)"
          /> -->
        </div>
      </div>
      <div class="flex flex-wrap xl:justify-end">
        <div class="w-1/4 h-full" v-for="(action, i) in actions" :key="i">
          <BaseButton
            :label="action"
            :primary="false"
            class="m-3 border rounded border-borderGray shadow-sm px-3 text-xl"
            @click="push(action)"
          />
        </div>
      </div>
    </div>

    <div class="flex justify-between mt-3">
      <base-button
        label="cancel"
        class="
          py-1
          px-4
          text-textMedium
          bg-brightGray
          hover:bg-danger
          hover:text-error
          rounded
        "
        @click="close"
      />
      <base-button
        :isWorking="working"
        label="Save Formula"
        icon="check"
        class="py-1 px-4 rounded"
        @click="saveFormula"
      />
    </div>
  </div>
</template>

<script>
// import billCustomFields from '@/mixins/billCustomFields.js';
import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../../eventBus';

export default {
  // props: ['payment'],
  // mixins: [billCustomFields],
  data() {
    return {
      working: false,
      formula: [],
      temp: '',
      id: '',
      result: 0,
      actions: [
        '(',
        ')',
        '%',
        '/',
        7,
        8,
        9,
        '*',
        4,
        5,
        6,
        '-',
        1,
        2,
        3,
        '+',
        0,
        '.',
        'c',
        '=',
      ],
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
    // numberCustomFields() {
    //   return this.customFields.filter(({ type }) => type == 'number');
    // },
  },
  methods: {
    ...mapActions('main', ['request']),
    push(data) {
      if (typeof data == 'number' || data == '.')
        this.temp = this.temp.concat(data);
      else if (data == 'c') {
        this.formula = [];
        this.temp = '';
      } else {
        if (this.temp) {
          this.formula.push(Number(this.temp));
          this.temp = '';
        }
        this.formula.push(data);
      }
    },
    async saveFormula() {
      this.working = true;
      if(this.temp) {
        this.formula.push(Number(this.temp));
        this.temp = '';
      }
      let response = await this.request({
        method: 'put',
        url: 'billing/penaltyFormula/'+this.id,
        data: {
          formula: this.formula,
        },
      });
      if (response) {
        this.$emit('close', {
          invokeCallBack: true,
          response: { penalty: response.item.formula },
        });
        eventBus.$emit('open-toast', {
          message: 'Formula Saved Successfully.',
        });
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror,
        });
      }
      this.working = false;
    },
    async getFormula() {
      const response = await this.request({
        method: 'get',
        url: 'billing/penaltyFormula',
      });
      if(response && response.item) {
        this.id = response.item._id;
        this.formula = response.item.formula
      }
    },
    close() {
      this.$emit('close');
    },
  },
  created() {
    this.getFormula()
    // if (this.payment && this.payment.due_date_formula) {
    //   this.formula = this.payment.due_date_formula;
    // }
  },
};
</script>

<style>
</style>