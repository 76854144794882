<template>
  <div class="text-textMedium space-y-4">
    <form class="space-y-4 p-4 pb-4">
      <base-input
        type="text"
        placeholder="Full Name"
        label="School Name"
        bordered
        required
        v-model="Form.util_name"
        @enter="handleSave"
      />
      <div class="grid md:grid-cols-2 gap-2">
        <base-input
          placeholder="School Code"
          type="text"
          label="School Code"
          bordered
          required
          v-model="Form.util_id"
          @enter="handleSave"
        />
        <!-- <base-radio
          v-if="false"
          label="Allow Partial Payment"
          bordered
          required
          :items="partialPaymentOptions"
          v-model="Form.allow_partial_payment"
          @enter="handleSave"
        /> -->
        <base-input
          label="Phone Number"
          type="text"
          placeholder="+2519---------"
          bordered
          required
          :min="0"
          v-model="Form.tel"
          @enter="handleSave"
        />
        <base-input
          placeholder=""
          type="text"
          label="Account Number"
          bordered
          required
          :min="0"
          v-model="Form.accountNumber"
          @enter="handleSave"
        />
      </div>
      <div class="flex justify-between mt-3">
        <base-button
          label="cancel"
          class="
            py-1
            px-4
            text-textMedium
            bg-brightGray
            hover:bg-danger
            hover:text-error
            rounded
          "
          @click="close"
        />
        <base-button
          label="UPDATE"
          icon="check-circle"
          :isWorking="working"
          class="py-1 px-4 rounded"
          @click="updateUtilityOrg"
        />
      </div>
    </form>
  </div>
</template>

<script>
import eventBus from '@/eventBus';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: ['utilityOrg'],
  data() {
    return {
      working: false,
      partialPaymentOptions: [
        {
          label: 'Yes',
          value: true
        },
        {
          label: 'No',
          value: false
        }
      ],
      custIdOptions: [
        {
          label: 'Yes',
          value: true
        },
        {
          label: 'No',
          value: false
        }
      ],
      Form: {
        util_name: undefined,
        util_id: undefined,
        allow_partial_payment: undefined,
        accountNumber: undefined,
        tel: undefined
      }
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
    updatedFields() {
      return Object.keys(this.Form).filter(
        field => this.Form[field] != this.utilityOrg[field]
      );
    },
    updatedData() {
      let data = Object.create({});
      this.updatedFields.forEach(field => {
        data[field] = this.Form[field];
      });
      return data;
    }
  },
  methods: {
    ...mapActions('main', ['request']),
    async updateUtilityOrg() {
      this.working = true;
      let response = await this.request({
        method: 'put',
        url: 'billing/utiltypes/' + this.utilityOrg._id,
        data: this.Form
        // data: {
        //   ...this.updatedData,
        // },
      });
      if (response) {
        this.$emit('close', {
          invokeCallBack: true,
          response: { utilityOrg: response.item }
        });
        eventBus.$emit('open-toast', {
          message: 'School updated successfully.'
        });
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror
        });
      }
      this.working = false;
    },
    close() {
      this.$emit('close');
    }
  },
  created() {
    if (this.utilityOrg) {
      Object.keys(this.Form).forEach(
        field => (this.Form[field] = this.utilityOrg[field])
      );
    }
  }
};
</script>

<style></style>
