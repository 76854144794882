<template>
  <div class="space-y-2">
    <bar-loader v-if="isLoading" />
    <div v-else class="flex justify-between">
      <div class="text-lg uppercase text-primary font-medium">BILLS</div>
      <div class="flex justify-end space-x-3">
        <div class="flex justify-end space-x-2">
          <!-- <template v-for="(action, i) in grantedActions">
            <base-button
              :isWorking="working"
              :label="action.label"
              :icon="working ? 'spin' : action.icon"
              class="px-2 rounded"
              @click="action.handler"
              :key="i"
            />
          </template> -->
        </div>
      </div>
    </div>
    <div>
      <data-table
        :headers="headers"
        :items="documents"
        :searchable="true"
        :canSelect="true"
        :isWorking="isLoading"
        :hasDynamicActions="true"
        :dynamicHandlers="grantedActions"
      ></data-table>
      <div
        class="
          p-2
          grid
          xl:grid-cols-3
          lg:grid-cols-3
          md:grid-cols-2
          sm:grid-cols-2
          gap-2
        "
      >
        <!-- <pre>{{ documents }}</pre>
        <div
          class=""
          v-for="customerBill in documents"
          :key="customerBill._id"
          @click="selectCustomerBill(customerBill._id)"
        >
          <customer-bill-card
            :customerBill="customerBill"
            :selected="selectedIds.includes(customerBill._id)"
            @setRemainingAmount="setRemainingAmount"
            @setPenalty="setPenalty"
            @setDiscount="setDiscount"
          />
        </div> -->
      </div>
      <pagination
        v-if="paginate && documents && documents.length > 0"
        :currentPage="currentPage"
        :pageSize="pageSize"
        :totalPages="totalPages"
        @changepage="handlePageChange"
        @changepagesize="handlePageSizeChange"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import eventBus from '@/eventBus';
import Pagination from '@/components/pagination/Pagination.vue';
import BarLoader from '@/components/collection/BarLoader.vue';
// import CustomerBillCard from './CustomerBillCard.vue';
import DataTable from '../../table/DataTable.vue';
export default {
  components: { Pagination, BarLoader, DataTable },
  props: ['studentSchoolId'],
  data() {
    return {
      working: false,
      documents: [],
      isLoading: false,
      selectedId: '',
      selectedIds: [],
      paginate: true,
      totalPages: 0,
      rowsPerPage: 8,
      currentPage: 1,
      pageSize: 10,
      headers: [
        
         
          {label: 'Payment Term',
          key: 'paymentTerm'
        },
        {
          label: 'BILL CODE',
          key: 'billCode'
        },
        {
          label: 'Total Amount',
          key: 'amount'
        },
        {
          label: 'Payment Amount',
          key: 'paymentAmount'
        },
        {
          label: 'Registration',
          key: 'registrationFee'
        },
        {
          label: 'Tutor',
          key: 'tutorFee'
        },
        {
          label: 'Transportation',
          key: 'transportationFee'
        },
        {
          label: 'Materials',
          key: 'materialsFee'
        },
        {
          label: 'Others',
          key: 'othersFee'
        },
        {
          label: 'Penalty',
          key: 'penalty'
        },
        {
          label: 'Payment Date',
          key: 'paymentDate'
        },
        {
          label: 'Transaction Number',
          key: 'transactionNumber'
        },
        {
          label: 'Status',
          key: 'isPaid'
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['user']),
    grantedActions() {
      // return this.actionHandlers.filter(
      //   ({ code, condition = true }) => this.isGranted(code) && condition
      // );
      return this.actionHandlers
    },
    displayItems() {
      if (!this.paginate) return this.documents;
      let startIndex = (this.currentPage - 1) * this.pageSize;
      let endIndex = startIndex + this.pageSize;
      if (endIndex > this.documents.length) endIndex = this.documents.length;
      return this.documents.slice(startIndex, endIndex);
    },
    selectedItem() {
      if (this.selectedIds.length == 1 && this.documents)
        return this.documents.find(item => item._id === this.selectedIds[0]);
      else return '';
    },
    selectedItems() {
      if (this.selectedIds && this.documents)
        return this.documents.filter(({ _id }) =>
          this.selectedIds.includes(_id)
        );
      else return '';
    },

    actionHandlers() {
      // if (!this.selectedItems) return [];
      // const unpaidBills = this.selectedItems.filter(({ status }) =>
      //   ['unpaid', 'partial paid', 'partial_paid'].includes(status)
      // );
      // const unpaidAmount = unpaidBills.reduce(
      //   (acc, { remaining_amount }) => (acc += remaining_amount),
      //   0
      // );
      return [
        // {
        //   label: 'Pay All Bills',
        //   icon: 'check-circle',
        //   handler: this.payCustomerBill,
        //   fullParam: true,
        //   condition:
        //     this.selectedItem.status != 'Paid',
        //     // && this.selectedItem._id == this.documents[0]._id,
        //   code: '212',
        // },

        // {
        //   label: `Pay ${unpaidAmount} Birr`,
        //   icon: 'check',
        //   handler: this.paySelectedBills,
        //   condition: unpaidBills.length > 0,
        //   // && this.selectedItem._id == this.documents[0]._id,
        //   code: '212'
        // },
        {
          label: `Pay Bill`,
          icon: 'check',
          handler: this.payCustomerBill,
          fullParam: true,
          // condition: !this.selectedItem.isPaid,
          // code: 'pay-bill'
           code: '0044crc',
        },
        // {
        //   label: 'View Detail',
        //   icon: 'eye',
        //   handler: this.viewCustomerBillDetail,
        //   fullParam: true,
        //   condition: this.selectedIds.length == 1, // for multiple selector
        //   code: '213'
        // }
      ];
    }
  },
  watch: {
    currentPage() {
      this.getDocuments();
    }
  },
  methods: {
    ...mapActions('main', ['requestMutate', 'request']),
    payCustomerBill(payment) {
      let componentProps = {
        payment
      };
      eventBus.$emit('open-modal', {
        modalId: 205,
        componentProps,
        cb: this.getDocuments
      });
    },
    async paySelectedBills() {
      this.working = true;
      let response;
      for (let index = 0; index < this.selectedItems.length; index++) {
        const bill = this.selectedItems[index];
        response = await this.request({
          method: 'post',
          url: 'billing/utilcustomers/officer-pay/' + bill._id,
          data: {
            amount: bill.remaining_amount,
            penalty: bill.penalty,
            discount: bill.discount,
            has_penalty: bill.penalty > 0,
            has_discount: bill.discount > 0
          }
        });
      }
      if (response) {
        eventBus.$emit('open-toast', {
          message: 'Bills paid successfully.'
        });
        this.$emit('close');
        this.selectedIds = [];
        this.getDocuments();
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message:
            response.message || 'Error on paying bills. please try again!'
        });
      }
      this.working = false;
    },
    async viewCustomerBillDetail() {
      eventBus.$emit('open-modal', {
        modalId: 27,
        componentProps: {
          payment: this.selectedItem
        }
      });
    },
    selectCustomerBill(id) {
      const index = this.selectedIds.indexOf(id);
      index > -1
        ? this.selectedIds.splice(index, 1)
        : this.selectedIds.push(id);
      // this.selectedId = id;
    },
    handlePageChange(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.currentPage = 1;
    },
    async getDocuments() {
      this.isLoading = true;
      this.documents = [];
      const response = await this.request({
        method: 'get',
        url: 'billing/utilcustomers/customer-bills?studentSchoolId=' + this.studentSchoolId
      });
      if (response) {
        this.documents = response.items;
        // this.documents = response.items
      } else {
        this.documents = [];
      }
      this.isLoading = false;
    },
    setRemainingAmount(id, amount) {
      const index = this.documents.findIndex(({ _id }) => _id == id);
      this.documents[index].remaining_amount = amount;
    },
    setPenalty(id, amount) {
      const index = this.documents.findIndex(({ _id }) => _id == id);
      this.documents[index].penalty = amount;
    },
    setDiscount(id, amount) {
      const index = this.documents.findIndex(({ _id }) => _id == id);
      this.documents[index].discount = amount;
    }
  },
  created() {
    this.getDocuments();
  }
};
</script>

<style></style>
