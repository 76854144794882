<template>
  <div class="text-textMedium space-y-4">
    <div class="text-2xl uppercase py-2 text-center">EDIT STUDENT BILL</div>
    <edit-customer-bill v-if="edit" :bill="bill" :paymentId="paymentId" @close="close"/>
    <div v-else class="space-y-4 px-4 pb-4">
      <customer-payment :payment="bill" />
      <div class="flex justify-between">
        <base-button
          label="cancel"
          class="
            py-1
            px-4
            text-textMedium
            bg-brightGray
            hover:bg-danger
            hover:text-error
            rounded
          "
          @click="close"
        />
        <base-button
          label="Edit"
          icon="pencil-alt"
          class="py-1 px-4 rounded"
          @click="editCustomerBill"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CustomerPayment from './CustomerPayment.vue';
import EditCustomerBill from '@/components/forms/EditCustomerBill.vue';
export default {
  components: { CustomerPayment, EditCustomerBill },
  props: ['bill', 'paymentId'],
  data() {
    return {
      working: false,
      edit: false,
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    editCustomerBill() {
      this.edit = true;
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style></style>
