<template>
  <div class="space-y-3 p-2">
    <smart-report :headers="headers" :title="title" :url="url">
      <template #header>
        
      <div class="flex justify-center w-full p-2 border border-primary">
        <div class="text-xl text-primary font-semibold">
          {{ title }}
        </div>
      </div>
      </template>
    </smart-report>
  </div>
</template>

<script>
import { currentDate } from '@/mixins/currentDate';
import SmartReport from '../SmartReport.vue';
export default {
  components: { SmartReport },
  mixins: [currentDate],
  data() {
    return {
      url: 'billing/reports/my-org-payments',
      headers: [
        {
          label: 'PAYMENT TYPE',
          key: 'payment_type',
        },
        {
          label: 'DUE DATE',
          key: 'payment_due_date',
          format: 'date',
        },
        {
          label: 'DESCRIPTION',
          key: 'description',
        },
        {
          label: 'TOTAL AMOUNT',
          key: 'total_amount',
        },
        {
          label: 'TOTAL STUDENTS',
          key: 'total_customers',
        },
      ],
    };
  },
  computed: {
    title() {
      return `PAYMENTS REPORT AS OF ${this.currentDate}`;
    },
  },
};
</script>

<style>
</style>