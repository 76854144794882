<template>
  <div
    :class="{
      'bg-primary text-white cursor-pointer': primary && !disabled,
      'bg-gray-200 text-textLight opacity-50 cursor-not-allowed': disabled,
      'bg-gray-200 text-textLight opacity-50 cursor-wait': isWorking,
      idle: !isWorking,
    }"
    class="
      base-btn
      p-1
      tracking-widest
      flex
      space-x-1
      items-center
      justify-center
    "
    @click="handleClick"
  >
    <BaseIcon v-if="isWorking" :size="iconSize" name="spin" class="spinner" />
    <BaseIcon
      v-if="!isWorking && icon && !iconRight"
      :size="iconSize"
      :name="icon"
    />
    <div v-if="label || label == 0">{{ label }}</div>
    <BaseIcon
      v-if="!isWorking && icon && iconRight"
      :size="iconSize"
      :name="icon"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: {  },
    primary: { type: Boolean, default: true },
    icon: { type: String },
    iconSize: { type: Number, default: 14 },
    iconRight: { type: Boolean },
    disabled: { type: Boolean, default: false },
    isWorking: { type: Boolean, default: false },
  },
  methods: {
    handleClick() {
      if (this.isWorking || this.disabled) return;
      this.$emit('click');
    },
  },
};
</script>

<style>
.base-btn.idle:hover {
  opacity: 0.8;
  transition: transform 0.5s;
}
.base-btn.idle:active {
  opacity: 0.7;
  transform: scale(1.1);
}
</style>
