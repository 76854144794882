var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full space-y-1"},[(_vm.label)?_c('label',{staticClass:"text-textMedium inline-flex",style:('font-size: ' + _vm.size + 'px;')},[_vm._v(_vm._s(_vm.label)),(_vm.required)?_c('span',{staticClass:"text-error"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"input-box"},[_c('div',{staticClass:"flex flex-shrink-0 -space-x-1"},[_c('input',_vm._b({staticClass:"w-full custom-input py-2 rounded tracking-widest text-textMedium",class:{
          'bg-brighterGray shadow-md': _vm.success && _vm.bordered,
          'bg-brightGray': _vm.success && !_vm.bordered,
          'bg-danger': !_vm.success && !_vm.bordered,
          'border-borderGray': _vm.success && _vm.bordered,
          'border-error': !_vm.success && _vm.bordered,
          'py-2 px-3': _vm.padding === 'big',
          'py-1 px-2': _vm.padding === 'small',
          border: _vm.bordered,
        },style:('font-size: ' + _vm.size + 'px;'),attrs:{"readonly":_vm.readOnly},on:{"input":_vm.handleInput,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleOnEnter.apply(null, arguments)},"focus":_vm.handleFocus,"blur":_vm.handleBlur,"mousewheel":function($event){$event.preventDefault();},"scroll":function($event){$event.preventDefault();}}},'input',_vm.$attrs,false)),_vm._t("suffix")],2)]),(!_vm.success)?_c('div',{staticClass:"text-xs",staticStyle:{"color":"#ec3118"}},[_c('div',[_vm._v(_vm._s(_vm.errors))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }