import Pagination from '@/components/pagination/Pagination.vue';

export default {
  components: {Pagination,},
  data() {
    return {
      paginate: true,
      totalPages: 0,
      rowsPerPage: 8,
      currentPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    officesOptions() {
      return this.offices.map(item => ({
        label: item.office[0].name,
        value: item._id
      }));
    }
  },
  methods: {
    handlePageChange(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.currentPage = 1;
    },
  },

};
