const state = {
  sioClient: null,
  clientConnected: false
};

const mutations = {
  SMART_SET: (state, { stateKey, data }) => {
    state[stateKey] = data;
  }
};

const actions = {
  smartSet({ commit }, { stateKey, data }) {
    commit('SMART_SET', {
      stateKey,
      data
    });
  }
};

const getters = {
  sioClient: state => state.sioClient,
  clientConnected: state => state.clientConnected
};

const socket = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

export default socket;
