<template>
  <tippy
    placement="bottom"
    trigger="click"
    theme="light"
    arrow
    size="large"
    interactive
    ref="setting-menu-tt"
  >
    <template v-slot:trigger>
      <div
        class="
          w-8
          h-8
          bg-brightGray
          rounded-full
          flex justify-center
          items-center
          hover:opacity-75
          cursor-pointer
        "
      >
        <img
          v-if="photo"
          class="w-full h-full rounded-full hover:opacity-75"
          :src="PROFILE_PATH + photo"
          alt=""
        />
        <BaseIcon v-else name="user" class="text-textMedium" />
      </div>
    </template>
    <div class="space-y-1 pt-3 space-y-8 text-textMedium">
      <div class="px-2 flex justify-center">
        <div class="space-y-2">
          <div class="w-24 h-24 rounded-full bg-brightGray">
            <img
              v-if="photo"
              class="w-full h-full rounded-full hover:opacity-75"
              :src="PROFILE_PATH + photo"
              alt=""
            />
          </div>
          <div class="space-y-1">
            <div class="text-xl">{{ name }}</div>
            <div class="font-sans">@{{ username }}</div>
            <div class="font-sans uppercase text-sm">{{ role }}</div>
            <!-- <div class="font-sans uppercase text-sm pt-3">{{ office }}</div> -->
            <div class="font-sans uppercase text-sm pt-3 italic font-semibold">
              {{ user.util_id ? user.util_id.util_name : '' }}
            </div>
          </div>
        </div>
      </div>
      <div class="px-2 py-2 text-left">
        <div
          class="
            py-2
            pl-3
            pr-20
            hover:bg-brightGray
            hover:text-primary
            cursor-pointer
            grow-active
          "
          @click="handleSettings"
        >
          Account Settings
        </div>

        <div
          class="
            py-2
            pl-3
            pr-20
            hover:bg-brightGray
            hover:text-primary
            cursor-pointer
            grow-active
          "
          @click="signout"
        >
          Sign out
        </div>
      </div>
    </div>
  </tippy>
</template>

<script>
import { PROFILE_PATH } from '@/config/config';
import { mapGetters, mapActions } from 'vuex';
export default {
  data() {
    return {
      PROFILE_PATH,
    };
  },
  computed: {
    ...mapGetters(['user']),
    role() {
      return this.user.role?.role_name;
    },
    username() {
      return this.user.username;
    },
    name() {
      return this.user.name;
    },
    photo() {
      return this.user.photo;
    },
    my_office() {
      return this.user.office;
    },
  },
  methods: {
    ...mapActions(['logout']),
    hideDialog() {
      this.$refs['setting-menu-tt'].tip.hide();
    },
    async signout() {
      let signedOut = await this.logout();
      if (signedOut) window.location.reload();
      this.hideDialog();
    },
    handleSettings() {
      this.$router.push('settings');
      this.hideDialog();
    },
  },
};
</script>

<style></style>
