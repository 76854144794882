import { mapActions } from 'vuex';
export const currentDate = {
  data() {
    return {
      currentDate: ''
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    async getCurrentDate() {
      let response = await this.request({
        url: 'billing/credential/currentdate',
        method: 'get'
      });
      if (response) this.currentDate = response.trans_date;
    }
  },
  created() {
    this.getCurrentDate();
  }
};
