<template>
  <div class="w-full space-y-1">
    <label
      :style="'font-size: ' + size + 'px;'"
      class="text-textMedium inline-flex"
      v-if="label"
      >{{ label }}<span v-if="required" class="text-error">*</span></label
    >
    <div class="input-box">
      <div class="flex flex-shrink-0 -space-x-1">
        <input
          :style="'font-size: ' + size + 'px;'"
          v-bind="$attrs"
          :readonly="readOnly"
          @input="handleInput"
          @keyup.enter="handleOnEnter"
          @focus="handleFocus"
          @blur="handleBlur"
          @mousewheel.prevent=""
          @scroll.prevent=""
          class="
            w-full
            custom-input
            py-2
            rounded
            tracking-widest
            text-textMedium
          "
          :class="{
            'bg-brighterGray shadow-md': success && bordered,
            'bg-brightGray': success && !bordered,
            'bg-danger': !success && !bordered,
            'border-borderGray': success && bordered,
            'border-error': !success && bordered,
            'py-2 px-3': padding === 'big',
            'py-1 px-2': padding === 'small',
            border: bordered,
          }"
        />
        <slot name="suffix" class="flex-grow-0"></slot>
      </div>
    </div>
    <div v-if="!success" class="text-xs" style="color: #ec3118">
      <div>{{errors}}</div>
      <!-- <div v-for="(error, i) in errors" :key="i">{{ error }}</div> -->
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: String,
    readOnly: { type: Boolean, default: false },
    size: { type: Number, default: 16 },
    errors: { type: Array, default: () => [] },
    padding: { type: String, default: ['big', 'small'][0] },
    bordered: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
  },
  computed: {
    success() {
      return this.errors.length === 0;
    },
  },
  methods: {
    handleInput() {
      if (this.$attrs.type == 'number') {
        const no_decimal_digit = this.$attrs.integer ? 0 : 3;
        let value = event.target.value;
        let index = value.indexOf('.');
        if (index > 0) {
          event.target.value = value.slice(0, index + no_decimal_digit);
        }
        if (event.target.value < 0) {
          event.target.value = event.target.value * -1;
        }
        this.$emit('input', Number(event.target.value));
      } else this.$emit('input', event.target.value);
    },
    handleOnEnter() {
      this.$emit('enter');
    },
    handleFocus() {
      this.$emit('focus');
    },

    handleBlur() {
      this.$emit('blur');
    },
  },
};
</script>

<style>
.custom-input:focus {
  outline-color: #6666ff;
}
.custom-input::placeholder {
  font-size: 14px;
}

.input-box {
  position: relative;
}

.icon-right {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translate(0, -50%);
}
</style>
