<template>
  <div class="space-y-3 p-2">
    <smart-report :headers="headers" :title="title" :url="url">
      <template #header>
        <div class="flex w-full border border-primary m-0 p-0">
          <div
            class="
              flex
              w-full
              justify-center
              text-xl text-primary
              font-semibold
              p-2
            "
          >
            {{ title }}
          </div>
          <div class="flex justify-end items-center m-0 p-0 self-center text-textLight">
            <base-icon name="filter" class="mr-3"/>
            <div
              class="
                flex flex-col
                justify-center
                border-0 border-primary border-r
                m-0
                py-0
                px-2
                h-full
                cursor-pointer
                hover:font-semibold
              "
              :class="{'font-semibold bg-info text-white': option==active}"
              v-for="(option, i) in options" :key="i"
              @click="toggle(option)"
            >
              {{option}}
            </div>
          </div>
        </div>
      </template>
    </smart-report>
  </div>
</template>

<script>
import { currentDate } from '@/mixins/currentDate';
import SmartReport from '../SmartReport.vue';
export default {
  components: { SmartReport },
  mixins: [currentDate],
  data() {
    return {
      options: ['ALL', 'CASH', 'TRANSFER'],
      active: 'ALL',
      headers: [
        {
          label: 'ID',
          key: 'cust_util_id',
        },
        {
          label: 'Payment Type',
          key: 'util_payment_id.payment_type',
        },
        {
          label: 'STUDENT NAME',
          key: 'fullName',
        },
        {
          label: 'AMOUNT',
          key: 'amount',
        },
        {
          label: 'Penalty',
          key: 'penalty',
        },
        {
          label: 'Remaining Amount',
          key: 'remaining_amount',
        },
        {
          label: 'DATE',
          key: 'date',
          format: 'date',
        },
        {
          label: 'TIME',
          key: 'time',
        },
        {
          label: 'Payment TYPE',
          key: 'bank.payment_type',
        },
        {
          label: 'Bank',
          key: 'bank.bank_name',
        },
        {
          label: 'B Officer',
          key: 'bank.officer_name',
        },
        {
          label: 'TR ID',
          key: 'trId',
        },
      ],
    };
  },
  computed: {
    title() {
      return `MY BRANCH ${this.active} TRANSACTIONS IN BANK REPORT AS OF ${this.currentDate}`;
    },
    url() {
       if (this.active == 'CASH') return 'billing/stmt/cash/my-branch'
      else if (this.active == 'TRANSFER') return 'billing/stmt/transfer/my-branch'
      else  return 'billing/reports/my-branch-bank-trxn'
    }
  },
  methods: {
    toggle(active){
      this.active = active
    }
  },
};
</script>

<style>
</style>