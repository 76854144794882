<template>
  <div class="space-y-2 text-textMedium">
    <div class="flex justify-between items-center shadow-md p-2">
      <template>
        <div v-if="organization" class="flex space-x-3">
          <div
            class="
              rounded
              text-xl text-center
              items-center
              border border-borderGray
              p-2
              bg-brightGray
            "
          >
            {{ organization.util_id }}
          </div>
          <div>
            <div class="text-textLight text-lg font-semibold">
              {{ organization.util_name }}
            </div>
            <div class="text-textMedium text-sm">
              {{ organization.util_name }}
            </div>
          </div>
        </div>
      </template>
      <base-button
        v-if="updatedActivities.length > 0"
        :isWorking="working"
        icon="check-circle"
        label="UPDATE"
        @click="preUpdate"
        class="rounded px-2"
      />
    </div>
    <div class="md:grid grid-cols-2 gap-4 pl-8 pb-8">
      <div v-for="(clas, index) in CLASSES" :key="index" class="py-2">
        <div class="flex space-x-4 text-lg text-textLight uppercase">
          <base-check-box
            :selected="isClassSelected(clas.activities)"
            :color="
              updatedActivities.some((code) => clas.activities.includes(code))
                ? 'text-info'
                : ''
            "
            :value="clas.name"
            @toggle="toggleClass(clas.activities)"
          />
          {{ clas.name }}
        </div>
        <template v-for="(activity, i) in classActivities(clas.activities)">
          <div :key="i" class="flex pl-16 space-y-2">
            <base-check-box
              :selected="grantedActivities.includes(activity.code)"
              :color="
                updatedActivities.includes(activity.code) ? 'text-info' : ''
              "
              :value="activity.code"
              @toggle="toggle(activity.code, activity.required)"
            />
            <div class="space-y-0">
              <div class="font-medium">
                {{ activity.name }}
              </div>
              <div class="text-textLight text-sm lowercase tracking-wider">
                {{ activity.description }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { ALL_ACTIVITIES, CLASSES } from '@/config/staticData';
import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../../eventBus';
export default {
  components: {},
  data() {
    return {
      loading: false,
      working: false,
      organization: '',
      ALL_ACTIVITIES,
      CLASSES,
      activities: [],
      grantedActivities: [],
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
    updatedActivities() {
      return [
        ...this.activities.filter(
          (code) => !this.grantedActivities.includes(code)
        ),
        ...this.grantedActivities.filter(
          (code) => !this.activities.includes(code)
        ),
      ];
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    classActivities(actions = []) {
      return this.ALL_ACTIVITIES.filter(({ code }) => actions.includes(code));
    },
    isClassSelected(actions = []) {
      const length = this.grantedActivities.filter((activity) =>
        actions.includes(activity)
      ).length;
      if (length == actions.length) {
        return true;
      } else return false;
    },
    toggleClass(actions) {
      const notGrantedActivities = actions.filter(
        (activity) => !this.grantedActivities.includes(activity)
      );
      if (notGrantedActivities.length > 0) {
        this.grantedActivities.unshift(...notGrantedActivities);
      } else {
        actions.forEach((code) => {
          const index = this.grantedActivities.indexOf(code, 0);
          if (index >= 0) this.grantedActivities.splice(index, 1);
        });
      }
    },
    toggle(a_code, required) {
      const index = this.grantedActivities.indexOf(a_code, 0);
      if (index < 0) {
        this.grantedActivities.push(a_code);
        required.forEach((code) => {
          if (!this.grantedActivities.includes(code)) {
            this.grantedActivities.push(code);
          }
        });
      } else {
        this.grantedActivities.splice(index, 1);
      }
    },
    async getGrantedActions() {
      this.loading = true;
      this.organization = '';
      let url = 'billing/utiltypes/' + this.$route.params.id;
      const response = await this.request({
        method: 'get',
        url,
      });
      if (response && !response.error) {
          this.organization = response.item;
        this.activities = response.item.authorized_actions;
        this.grantedActivities.unshift(...response.item.authorized_actions);
      }
      this.loading = false;
    },
    preUpdate() {
      const componentProps = {
        title: 'AUTHORIZE ACTIONS',
        message: 'Are you sure you to update authorized actions',
      };
      eventBus.$emit('open-modal', {
        modalId: 3,
        componentProps,
        cb: this.updateGrantedActivities,
      });
    },
    async updateGrantedActivities() {
      let url = 'billing/utiltypes/authorization/' + this.organization._id;
      this.working = true;
      const response = await this.request({
        method: 'put',
        url,
        data: {
          authorized_actions: this.grantedActivities,
        },
      });
      if (response && !response.error) {
        this.activities = response.item.authorized_actions;
        eventBus.$emit('open-toast', {
          message: 'Granted Activities updated successfully',
        });
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror,
        });
      }
      this.working = false;
    },
  },
  mounted() {
    this.getGrantedActions();
  },
};
</script>

<style>
</style>