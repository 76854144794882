<template>
  <div class="flex flex-col space-y-1">
    <div class="flex space-x-4">
      <base-select
        label="Select school"
        :items="schoolOptions"
        v-model="Form.school"
        searchable
      >
        <template #default="{ item }">
          <div class="flex space-x-2 items-center">
            <base-icon name="user" />
            <div class="capitalize">{{ item.label }}</div>
          </div>
        </template>
        <template #option="{ item }">
          <div class="flex space-x-2 items-center">
            <base-icon name="user" />
            <div>{{ item.label }}</div>
          </div>
        </template>
      </base-select>
      <base-select
        label="Select branch"
        searchable
        placeholder="Search Branch..."
        :items="branchOptions"
        v-model="Form.branch"
      />
    </div>
    <file-input v-bind="Form" />
    <data-loaded-overview v-if="false" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DataLoadedOverview from './components/DataLoadedOverview.vue';
import FileInput from './components/FileInput.vue';
export default {
  components: { FileInput, DataLoadedOverview },
  data() {
    return {
      schoolOptions: [],
      branchOptions: [],
      Form: {
        school: undefined,
        branch: undefined,
      },
    };
  },
  watch: {
    'Form.school': {
      deep: true,
      immediate: true,
      handler: function () {
        this.Form.branch = undefined;
        this.fetchBranches();
      },
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    async fetchBranches() {
      this.branchOptions = [];
      let response = await this.request({
        method: 'get',
        url: 'billing/office/org/' + this.Form.school,
      });
      if (response) {
        this.branchOptions = response.items.map((office) => ({
          label: office.name,
          value: office._id,
        }));
      }
    },
    async fetchSchools() {
      let response = await this.request({
        method: 'get',
        url: 'billing/utiltypes',
      });
      if (response) {
        this.schoolOptions = response.items.map(({ util_name, _id }) => ({
          label: util_name,
          value: _id,
        }));
      }
    },
  },
  created() {
    this.fetchSchools();
  },
};
</script>

<style></style>
