import { TOKEN_KEY, USER_KEY } from '../config/config';
const CURRENT_LANGUAGE_KEY = '__current_language__';
const TILL_SELECT_KEY = '__till_selected__';
const VAULTS_SELECT_KEY = '__vaults_selected__';

export class AppStorage {
  constructor(storage) {
    this.storage = storage || window.localStorage;

    /** Is storage is supported or not */
    if (!this.isSupported()) {
      throw new Error('Storage is not supported by browser!');
    }
  }

  setItem(key, value) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  getItem(key) {
    return JSON.parse(this.storage.getItem(key));
  }

  removeItem(key) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }

  isSupported() {
    let supported = true;

    if (!this.storage) {
      supported = false;
    }

    return supported;
  }
}

const appLocalStorage = new AppStorage();
// const appSesstionLocalStorage = new AppStorage(window.sessionStorage);
const appStorage = appLocalStorage; //MARK: - Default localstorage

const tokenService = {
  getToken() {
    return appStorage.getItem(TOKEN_KEY);
  },

  saveToken(accessToken) {
    appStorage.setItem(TOKEN_KEY, accessToken);
  },

  removeToken() {
    appStorage.removeItem('channel');
    appStorage.removeItem(TOKEN_KEY);
  }
};

const userInfoService = {
  getUser() {
    return appStorage.getItem(USER_KEY);
  },
  saveUser(user) {
    appStorage.setItem(USER_KEY, user);
  },
  removeUser() {
    appStorage.removeItem(USER_KEY);
  },
  setVaultsSelected(value) {
    appStorage.setItem(VAULTS_SELECT_KEY, value);
  },
  getVaultsSelected() {
    return appStorage.getItem(VAULTS_SELECT_KEY);
  },
  setTillSelected(value) {
    appStorage.setItem(TILL_SELECT_KEY, value);
  },
  getTillSelected() {
    return appStorage.getItem(TILL_SELECT_KEY);
  }
};

const LanguageService = {
  getCurrentLanguage() {
    return localStorage.getItem(CURRENT_LANGUAGE_KEY);
  },

  setCurrentLanguage(currentLanguage) {
    localStorage.setItem(CURRENT_LANGUAGE_KEY, currentLanguage);
  }
};

export { appStorage, tokenService, userInfoService, LanguageService };
