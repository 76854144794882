import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vuelidate from 'vuelidate';
import loadSprites from './plugins/loadSvg';
import tippy from './plugins/tippy';
import VuePapaParse from 'vue-papa-parse';

import apiService from './services/apiService';
import { tokenService } from './services/storageService';

import { i18n } from './plugins/i18n.js';

import { directives } from './directives/index';
directives.forEach(d => Vue.directive(d.name, d.hooksContainer));

import '@/styles/tailwind.css';
import '@/styles/scrollbar.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/themes/light-border.css';
import 'tippy.js/themes/google.css';
import 'tippy.js/themes/translucent.css';

import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';
Vue.use(Viewer);

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

// import { installApex } from './plugins/apex';
// installApex();
import { format } from '@/mixins/formateNumber';
import { activity } from '@/mixins/activity';

import registerGlobally from './components/globals';
registerGlobally();
Vue.mixin(format)
Vue.mixin(activity)
Vue.use(VuePapaParse);
Vue.config.productionTip = false;
apiService.init(apiService.getBaseUrl());
apiService.addInterceptor();

if (tokenService.getToken()) {
  apiService.setHeader();
  apiService.mount401Interceptor();
  // apiService.requestAgainOnServerBusy();
}

loadSprites();
tippy();
Vue.use(Vuelidate);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
