<template>
  <div class="space-y-3">
    <!-- <pre>{{payment}}</pre> -->
    <div class="flex w-full justify-between p-4">
      <div class="p-2 space-y-2 px-2">
        <div
          v-if="payment.customer"
          class="flex w-full shadow-md space-x-2 items-center"
        >
          <div class="flex-grow space-y-0">
            <div class="text-lg text-primary border-dashed  font-semibold">
              {{ payment.fullName }}
            </div>
            <div class="text-sm text-light">
              {{ payment.cust_util_id }}
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-2 py-2">
          <div class="uppercase text-sm text-textLight">PAYMENT TYPE</div>
          <div
            v-show="payment.util_payment_id"
            class="text-lg text-textMedium p0"
          >
            {{ payment.util_payment_id.payment_type }}
          </div>
        </div>
        <div class="grid grid-cols-2 gap-2 py-2">
          <div class="uppercase text-sm text-textLight">AMOUNT</div>
          <div class="text-lg text-textMedium p0">
            {{ payment.total_amount | formateNumber }}
          </div>
        </div>
        <div class="grid grid-cols-2 gap-2 py-2" v-if="payment.penalty">
          <div class="uppercase text-sm text-textLight">PENALTY</div>
          <div class="text-lg text-textMedium p0">
            {{ payment.penalty | formateNumber }}
          </div>
        </div>
        <div class="grid grid-cols-2 gap-2 py-2" v-if="payment.discount">
          <div class="uppercase text-sm text-textLight">DISCOUNT</div>
          <div class="text-lg text-textMedium p0">
            {{ payment.discount | formateNumber }}
          </div>
        </div>
        <div class="grid grid-cols-2 gap-2 py-2">
          <div class="uppercase text-sm text-textLight">REMAINING AMOUNT</div>
          <div class="text-lg text-textMedium p0">
            {{ payment.remaining_amount | formateNumber }}
          </div>
        </div>

        <div v-if="customFields.length > 0 && payment.other">
          <template>
            <div
              v-for="({ key, name: label }, i) in customFields"
              :key="key"
              class="grid grid-cols-2 gap-2 py-2"
              :class="{ 'bg-brightGray': i % 2 == 0 }"
            >
              <div class="uppercase text-sm text-textLight">
                {{ label.split('_').join(' ') }}
              </div>
              <div class="text-lg text-textMedium p0">
                {{ payment.other[key] }}
              </div>
            </div>
          </template>
        </div>
        <div class="grid grid-cols-2 gap-2 bg-brightGray py-2">
          <div class="uppercase text-sm text-textLight">STATUS</div>
          <div
            class="text-lg p-0 font-semibold uppercase"
            :class="{
              'text-lightDanger': [
                'unpaid',
                'partial-paid',
                'partial paid'
              ].includes(payment.status),
              'text-info': ['paid'].includes(payment.status)
            }"
          >
            {{ payment.status }}
          </div>
        </div>
      </div>
      <div class="">
        <data-table
          name="Transactions"
          :items="documents"
          :headers="headers"
          :paginate="true"
          :rowsPerPage="5"
          :small="false"
        >
        </data-table>
      </div>
    </div>
    <div class="flex justify-end mr-3">
      <base-button
        label="close"
        class="
          py-1
          px-4
          text-textMedium
          bg-brightGray
          hover:bg-danger
          hover:text-error
          rounded
        "
        @click="close"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import DataTable from '../../../table/DataTable.vue';
import billCustomFields from '@/mixins/billCustomFields.js';
export default {
  components: { DataTable },
  props: ['payment'],
  mixins: [billCustomFields],
  data() {
    return {
      loading: false,
      documents: [],
      headers: [
        {
          key: 'amount',
          label: 'Amount'
        },
        {
          key: 'penalty',
          label: 'Penalty'
        },
        {
          key: 'discount',
          label: 'discount'
        },
        {
          key: 'remaining_amount',
          label: 'Remaining Amount'
        },
        {
          key: 'description',
          label: 'Description'
        },
        {
          key: 'bank.bank_name',
          label: 'Bank'
        },
        {
          key: 'office.officer_name',
          label: 'Officer'
        },
        {
          format: 'date',
          key: 'date',
          label: 'Date'
        },
        {
          key: 'time',
          label: 'Time'
        },
        {
          key: 'transaction_id',
          label: 'TRID'
        }
      ]
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    close() {
      this.$emit('close');
    },
    async getBankStatements() {
      this.loading = true;
      this.documents = [];
      const response = await this.request({
        url: 'billing/stmt/bill-id/' + this.payment._id + '?limit='+1000,
        method: 'get'
      });
      if (response) {
        this.documents = response.items;
      }
      this.loading = false;
    }
  },
  created() {
    this.getBankStatements();
  }
};
</script>
<style></style>
