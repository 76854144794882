<template>
  <div class="space-y-3 p-2">
    <smart-report
      :headers="headers"
      :title="title"
      :url="url"
      :has_query="hasQuery"
    >
      <template #header>
        <div class="lg:flex w-full border border-primary m-0 p-0">
          <div
            class="
              flex
              w-full
              justify-center
              text-xl text-primary
              font-semibold
              p-2
              text-center
            "
          >
            {{ title }}
          </div>
          <div class="flex justify-between space-x-4 flex-shrink-0 ">
            <div
              class="
                flex
                justify-center
                items-center
                self-center
                space-x-4
                py-2
                w-64
                flex-shrink-0
                mx-4
              "
            >
              <base-select
                searchable
                v-model="selectedId"
                :items="documents"
              />
            </div>
            <!-- <div
              class="
                flex
                justify-end
                items-center
                flex-grow-0
                m-0
                p-0
                self-center
                text-textLight
              "
            >
              <base-icon name="filter" class="mr-3" />
              <div
                class="
                  flex flex-col
                  justify-center
                  border-0 border-primary border-r
                  m-0
                  py-0
                  px-2
                  h-full
                  cursor-pointer
                  hover:font-semibold
                "
                :class="{
                  'font-semibold bg-info text-white': option == active,
                }"
                v-for="(option, i) in options"
                :key="i"
                @click="toggle(option)"
              >
                {{ option }}
              </div>
            </div> -->
          </div>
        </div>
      </template>
    </smart-report>
  </div>
</template>

<script>
import { currentDate } from '@/mixins/currentDate';
import SmartReport from '../SmartReport.vue';
import { mapActions } from 'vuex';
export default {
  components: { SmartReport },
  mixins: [currentDate],
  data() {
    return {
      selectedId: '',
      documents: [],
      options: ['ALL', 'CASH', 'TRANSFER'],
      active: 'ALL',
      headers: [
        {
          label: 'ID',
          key: 'cust_util_id',
        },
        {
          label: 'Payment Type',
          key: 'util_payment_id.payment_type',
        },
        {
          label: 'STUDENT NAME',
          key: 'fullName',
        },
        {
          label: 'AMOUNT',
          key: 'amount',
        },
        {
          label: 'Penalty',
          key: 'penalty',
        },
        {
          label: 'Remaining Amount',
          key: 'remaining_amount',
        },
        {
          label: 'DATE',
          key: 'date',
          format: 'date',
        },
        {
          label: 'TIME',
          key: 'time',
        },
        {
          label: 'Officer',
          key: 'office.officer_name',
        },
        {
          label: 'TR ID',
          key: 'trId',
        },
      ],
    };
  },
  computed: {
    hasQuery() {
      return this.status ? true : false;
    },
    title() {
      return (
        (this.selectedItem ? this.selectedItem.label : '') +
        ` BILL TRANSACTIONS REPORT AS OF ${this.currentDate}`
      );
    },
    url() {
      let url = 'billing/reports/org-bill-trxns/';
      // if (this.active == 'CASH') url = 'billing/stmt/cash/org/';
      // else if (this.active == 'TRANSFER') url = 'billing/stmt/transfer/org/';
      // if (!this.selectedId) return '';
      return url + this.selectedId;
    },
    selectedItem() {
      if (!this.selectedId) return '';
      return this.documents.find(({ value }) => value == this.selectedId);
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    toggle(active) {
      this.active = active;
    },
    async fetchDocuments() {
      const response = await this.request({
        url: 'billing/utiltypes',
        method: 'get',
      });
      if (response) {
        this.documents = response.items.map(({ _id, util_name }) => ({
          label: util_name,
          value: _id,
        }));
      }
    },
  },
  created() {
    this.fetchDocuments();
  },
};
</script>

<style>
</style>