<template>
  <div class="space-y-2 text-xs">
    <tabs v-if="uploadedCustomers.length > 0">
      <tab title="Not Uploaded">
        <not-uploaded-customers />
      </tab>
      <tab title="Uploaded">
        <uploaded-customers />
      </tab>
    </tabs>
    <not-uploaded-customers v-else />
  </div>
</template>

<script>
import {  mapGetters } from 'vuex';
import { validation } from '@/mixins/validationMixin.js';
import Tab from '../../../components/tab/Tab.vue';
import NotUploadedCustomers from './customer/notUploadedCustomers.vue';
import Tabs from '../../../components/tab/Tabs.vue';
import UploadedCustomers from './customer/uploadedCustomers.vue';
// import billCustomFields from '@/mixins/billCustomFields.js';
// import eventBus from '@/eventBus';
export default {
  props: ['title'],
  components: {  NotUploadedCustomers, Tab, Tabs, UploadedCustomers },

  mixins: [validation], //billCustomFields],
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters('customer', ['isWorking', 'uploadedCustomers']),
  },
};
</script>

<style></style>
