const state = {
  showExportPdf: false,
  headers: [],
  items: [],
  title: '',
  printer_on: false
};

const mutations = {
  SMART_SET: (state, { stateKey, data }) => {
    state[stateKey] = data;
  }
};

const actions = {
  smartSet({ commit }, { stateKey, data }) {
    commit('SMART_SET', {
      stateKey,
      data
    });
  },
  async printReport({ commit }, {title, headers, items}) {
    commit('SMART_SET', {
      stateKey: 'showExportPdf',
      data: true
    });
    commit('SMART_SET', {
      stateKey: 'title',
      data: title
    });
    commit('SMART_SET', {
      stateKey: 'headers',
      data: headers
    });
    commit('SMART_SET', {
      stateKey: 'items',
      data: items
    });
    return true;
  },
  async clearExportPdfReport({ commit }) {
    commit('SMART_SET', {
      stateKey: 'showExportPdf',
      data: false
    });
    commit('SMART_SET', {
      stateKey: 'title',
      data: ''
    });
    commit('SMART_SET', {
      stateKey: 'headers',
      data: []
    });
    commit('SMART_SET', {
      stateKey: 'items',
      data: []
    });
    return true;
  }
};

const getters = {
  showExportPdf: state => state.showExportPdf,
  headers: state => state.headers,
  items: state => state.items,
  title: state => state.title,
  printer_on: state => state.printer_on
};

const printer = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

export default printer;
