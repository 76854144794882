import UtilityOrgRegistration from '@/components/forms/UtilityOrgRegistration';
import BranchRegistration from '@/components/forms/BranchRegistration';
import OrgBranchRegistration from '@/components/forms/OrgBranchRegistration';
import agentRegistration from '@/components/forms/AgentRegistration';
import checkTicketsReport from '@/components/forms/checkTickets';
import Confirm from '@/components/card/confirmation/Confirm.vue';
import PayCustomerBill from '@/components/card/utilities/payment/PayCustomerBill.vue';
import EditCustomerBill from '@/components/card/utilities/payment/EditCustomerBill.vue';
import EditUtilityOrg from '@/components/card/utilities/payment/EditUtilityOrg.vue';
import DeleteCustomerBill from '@/components/card/utilities/payment/DeleteCustomerBill.vue';
import AddCustomerBill from '@/components/forms/AddCustomerBill.vue';
import DetailCustomerBill from '@/components/card/utilities/payment/DetailCustomerBill';
import payBill from '@/components/card/utilities/payment/SchoolPayBill.vue';
import RoleRegistration from '@/components/forms/RoleRegistration.vue';
import OrgRoleRegistration from '@/components/forms/OrgRoleRegistration.vue';
import SmartExport from '@/components/forms/common/SmartExport';
import SmartExportReport from '@/components/forms/common/SmartExportReport';

// credential
import CreateCredential from '@/components/forms/credential/CreateCredential.vue';
import CreateOrgCredential from '@/components/forms/credential/CreateOrgCredential.vue';
import ChangeRole from '@/components/forms/credential/ChangeRole.vue';
import ChangeOffice from '@/components/forms/credential/ChangeOffice.vue';
import RequestReset from '@/components/forms/credential/RequestReset.vue';
import unLock from '@/components/forms/credential/unLock.vue';
import LogoutUser from '@/components/forms/credential/LogoutUser.vue';
import ResetPassword from '@/components/forms/credential/ResetPassword.vue';
import DeleteUser from '@/components/forms/credential/DeleteUser.vue';
import ChangeStatus from '@/components/forms/credential/ChangeStatus.vue';
import ChangeDashboard from '@/components/forms/credential/ChangeDashboard.vue';

import CustomerCreated from '@/components/card/info/CustomerCreated.vue';
import ChangeCustomerStatus from '@/components/forms/customer/ChangeCustomerStatus.vue';
import SearchCustomer from '@/components/forms/SearchCustomer.vue';

import DueDateFormula from '@/views/protected/Utilities/DueDateFormula.vue';
import DiscountFormula from '@/views/protected/Utilities/FPDiscountFormula.vue';
import MamaLinkForm from '@/components/forms/customer/MamaLinkForm.vue';
import CbeBirrLinkForm from '@/components/forms/customer/CbeBirrLinkForm.vue';
import PrintReceipt from '@/components/forms/customer/PrintReceipt.vue';

import AddPaymentType from '@/components/forms/payment/AddPaymentType.vue';
import AddPaymentTerm from '@/components/forms/payment/AddPaymentTerm.vue';
import FilterHeaders from '@/components/filter/Filter.vue';

import EditCustomer from '@/components/forms/EditCustomer.vue';
import MatchWorksheets from '@/views/protected/migration/components/MatchWorksheets.vue';
import ParsingCounter from '@/views/protected/migration/components/ParsingCounter.vue';
import ImportingCounter from '@/views/protected/migration/components/ImportingCounter.vue';

let modals = [
  {
    id: 1,
    component: UtilityOrgRegistration,
    width: 680
  },
  {
    id: 2,
    component: BranchRegistration,
    width: 680
  },
  {
    id: 3,
    component: Confirm,
    width: 580
  },
  {
    id: 4,
    component: PayCustomerBill,
    width: 580
  },
  {
    id: 5,
    component: EditCustomerBill,
    width: 580
  },
  {
    id: 6,
    component: DeleteCustomerBill,
    width: 580
  },
  {
    id: 7,
    component: AddCustomerBill,
    width: 580
  },
  {
    id: 8,
    component: SmartExport,
    width: 680
  },
  {
    id: 9,
    component: EditUtilityOrg,
    width: 680
  },
  {
    id: 10,
    component: RoleRegistration,
    width: 480
  },

  // credential
  {
    id: 11,
    component: CreateCredential,
    width: 580
  },
  {
    id: 12,
    component: ChangeOffice,
    width: 580
  },
  {
    id: 13,
    component: ChangeRole,
    width: 580
  },
  {
    id: 14,
    component: DeleteUser,
    width: 580
  },
  {
    id: 15,
    component: RequestReset,
    width: 580
  },
  {
    id: 16,
    component: ResetPassword,
    width: 580
  },
  {
    id: 17,
    component: LogoutUser,
    width: 580
  },
  {
    id: 18,
    component: unLock,
    width: 580
  },
  {
    id: 19,
    component: ChangeStatus,
    width: 580
  },
  {
    id: 20,
    component: CustomerCreated,
    width: 580
  },
  {
    id: 21,
    component: SearchCustomer,
    width: 580
  },
  {
    id: 22,
    component: CreateOrgCredential,
    width: 580
  },
  {
    id: 23,
    component: OrgBranchRegistration,
    width: 680
  },
  {
    id: 222,
    component: agentRegistration,
    width: 680
  },
  {
    id: 333,
    component: checkTicketsReport,
    width: 680
  },
  {
    id: 24,
    component: OrgRoleRegistration,
    width: 680
  },
  {
    id: 25,
    component: DueDateFormula,
    width: 780
  },
  {
    id: 26,
    component: ChangeCustomerStatus,
    width: 580
  },
  {
    id: 27,
    component: DetailCustomerBill,
    width: 1200
  },
  {
    id: 28,
    component: ChangeDashboard,
    width: 680
  },
  {
    id: 29,
    component: AddPaymentType,
    width: 680
  },
  {
    id: 30,
    component: AddPaymentTerm,
    width: 680
  },
  {
    id: 31,
    component: FilterHeaders,
    width: 480
  },
  {
    id: 32,
    component: EditCustomer,
    width: 780
  },
  {
    id: 33,
    component: MatchWorksheets,
    width: 780
  },
  {
    id: 34,
    component: ParsingCounter,
    width: 480
  },
  {
    id: 35,
    component: ImportingCounter,
    width: 480
  },

  {
    id: 200,
    component: SmartExportReport,
    width: 680
  },
  {
    id: 201,
    component: DiscountFormula,
    width: 680
  },
  {
    id: 202,
    component: MamaLinkForm,
    width: 680
  },
  {
    id: 203,
    component: CbeBirrLinkForm,
    width: 680
  },
  {
    id: 204,
    component: PrintReceipt,
    width: 780
  },
  {
    id: 205,
    component: payBill,
    width: 540
  },
];

export default modals;
