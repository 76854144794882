<template>
  <div class="text-textMedium space-y-4">
    <form class="space-y-4 p-4 pb-4">
      <div class="grid md:grid-cols-2 gap-2 row-gap-4">
        <base-input
          label="ID"
          placeholder="Student ID"
          bordered
          required
          v-model="Form.cust_util_id"
        />
        <base-input
          label="AMOUNT"
          type="number"
          placeholder="AMOUNT"
          bordered
          required
          v-model="Form.total_amount"
        />
        <template v-for="(field, i) in customFields">
          <base-input
            :key="i"
            :type="field.type"
            v-model="Form.other[field.key]"
            :label="field.name"
            :placeholder="field.name"
            bordered
          />
        </template>
      </div>
      <div class="flex justify-between mt-3">
        <base-button
          label="cancel"
          class="
            py-1
            px-4
            text-textMedium
            bg-brightGray
            hover:bg-danger
            hover:text-error
            rounded
          "
          @click="close"
        />
        <base-button
          :isWorking="working"
          label="Update Bill"
          icon="check"
          class="py-1 px-4 rounded"
          @click="updateCustomerBill"
        />
      </div>
    </form>
  </div>
</template>

<script>
import eventBus from '@/eventBus';
import { mapActions } from 'vuex';
import billCustomFields from '@/mixins/billCustomFields';
export default {
  mixins: [billCustomFields],
  data() {
    return {
      payment: {},
      paymentId: undefined,
      working: false,
      Form: {
        cust_util_id: undefined,
        total_amount: 0,
        other: {},
      },
    };
  },
  computed: {
    updatedFields() {
      return Object.keys(this.Form).filter((field) => {
        const value = this.Form[field];
        if (typeof(this.Form[field]) == 'object' && value)
          return Object.keys(value).find(
            (field2) => value[field2] != this.payment[field2]
          );
        return value != this.payment[field];
      });
    },
    updatedData() {
      let data = Object.create({});
      this.updatedFields.forEach((field) => {
        data[field] = this.Form[field];
      });
      return data;
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    async updateCustomerBill() {
      this.working = true;
      let response = await this.request({
        method: 'put',
        url: 'billing/utilcustomers/' + this.payment._id+'/'+this.paymentId                                                                                       ,
        data: {
          ...this.updatedData,
        },
      });
      if (response) {
        this.$emit('close', {
          invokeCallBack: true,
          response: { payment: response.item },
        });
        eventBus.$emit('open-toast', {
          message: 'Student Bill updated successfully.',
        });
      }
      this.working = false;
    },
    close() {
      this.$emit('close');
    },
  },
  created() {
    if (this.$attrs.bill) {
      this.payment = Object.freeze(this.$attrs.bill);
      this.paymentId = Object.freeze(this.$attrs.paymentId);
      this.Form.cust_util_id = this.payment.studentSchoolId;
      this.Form.total_amount = this.payment.amount;
      this.Form.other = this.payment.other || {};
      this.payment = { ...this.payment, ...this.payment.other };
      delete this.payment.other;
    }
  },
};
</script>

<style></style>
