<template>
  <modal-template @close="handleClose" @save="handleSave">
    <template #title>
      {{ isUpdate ? 'Update' : 'Create New' }} Payment Type
    </template>
    <template>
      <div class=" ">
        <div class="grid grid-cols-1 gap-5">
          <base-input
            label="Title"
            placeholder="Registration Fee"
            v-model="Form.title"
            :errors="errors.title"
            @enter="handleSave"
          />
          <div class="flex space-x-3">
            <switch-button
              class="flex-shrink-0"
              :value="Form.isPenalized"
              @input="handleToggle"
            />
            <p class="text-textMedium">Is penalized</p>
          </div>
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '@/eventBus';
import { mapActions } from 'vuex';
import SwitchButton from '../../base/SwitchButton.vue';
export default {
  components: { ModalTemplate, SwitchButton },
  props: ['oldForm'],
  data() {
    return {
      Form: {
        title: '',
        isPenalized: false,
      },
      errors: {},
    };
  },
  computed: {
    isUpdate() {
      if (this.oldForm) return true;
      else return false;
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    handleToggle() {
      this.Form.isPenalized = !this.Form.isPenalized;
    },
    async handleSave() {
      if (this.isUpdate) this.handleUpdate();
      else this.handleCreate();
    },
    populateData() {
      Object.keys(this.Form).forEach((key) => {
        this.Form[key] = this.oldForm[key];
      });
    },
    async handleCreate() {
      let response = await this.request({
        method: 'post',
        url: 'billing/paymentType',
        data: this.Form,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'payment type added successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: response.message || 'Error creating payment type',
          error: true,
        });
      }
    },

    async handleUpdate() {
      let response = await this.request({
        method: 'put',
        url: 'billing/paymentType/' + this.oldForm._id,
        data: this.Form,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'payment type updated successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: 'error updating payment type',
          error: true,
        });
      }
    },
  },
  created() {
    if (this.isUpdate) this.populateData();
  },
};
</script>

<style></style>
