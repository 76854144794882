<template>
  <div class="lg:grid lg:grid-cols-2 lg:p-10">
    <div class="flex justify-center items-center">
      <div
        class=" rounded-full flex justify-center items-center"
        style="width: 30vw; height: 30vw"
      >
        <div class="space-y-0 shadow-sm leading-none p-4">
          <div class="flex">
            <div class="text-4xl text-primary tracking-widest font-extrabold">
              LIB-Infinity
            </div>
          </div>
          <div class="text-lg tracking-widest">Billing</div>
        </div>
      </div>
    </div>
    <div class="space-y-4 p-10 border-l border-brightGray">
      <div class="text-3xl text-textDark mb-10 text-center">
        Change Password
      </div>
      <div class="space-y-2">
        <div class="md:flex md:space-x-4">
          <base-input
            placeholder="Full Name"
            label="Full Name"
            bordered
            disabled
            required
            v-model="name"
            :errors="errors.name"
          />
        </div>
        <!-- <div class="md:flex md:space-x-4">
          <base-input
            placeholder="Female"
            label="Gender"
            disabled
            bordered
            v-model="gender"
            :errors="errors.gender"
          />
          <base-input
            placeholder="2519--------"
            label="Phone Number"
            disabled
            bordered
            v-model="tel"
            :errors="errors.tel"
          />
        </div> -->

        <div class="md:flex md:space-x-4">
          <base-input
            type="password"
            placeholder="Current password"
            label="Current Password"
            bordered
            required
            v-model="currentPassword"
            :errors="errors.currentPassword"
          />
        </div>
        <div class="md:flex md:space-x-4">
          <base-input
            type="password"
            placeholder="New password"
            label="New Password"
            bordered
            required
            v-model="password"
            @enter="handleRegister"
            :errors="errors.password"
          />
          <base-input
            type="password"
            placeholder="Confirm Password"
            label="Confirm Password*"
            bordered
            v-model="confirmPassword"
            :errors="errors.confirmPassword"
            @enter="handleRegister"
          />
        </div>
      </div>
      <div class="text-sm text-textLight">
        <div
          v-for="(message, index) in pswdSuggestions"
          :key="index"
          class="space-y-2 flex items-center text-error"
          :class="message.satisfied ? 'text-success' : 'text-error'"
        >
          <base-icon
            v-if="message.satisfied"
            name="check-circle-s"
            :size="12"
            fill="green"
            class="mr-4"
          />
          {{ message.suggestion }}
        </div>
      </div>
      <div class="flex justify-end">
        <base-button
          label="Update"
          class="py-2 px-6 rounded"
          :isWorking="working"
          @click="handleRegister"
        />
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus';
import { mapActions, mapGetters } from 'vuex';
import { required, maxLength, sameAs } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';
export default {
  components: { },
  mixins: [validation],
  computed: {
    ...mapGetters('main', ['berror']),
  },
  data() {
    return {
      working: false,
      me: {},
      name: '',
      tel: '',
      gender: '',
      password: '',
      currentPassword: '',
      confirmPassword: '',
      errors: {},
      sexItems: [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
      ],
      meta: [
        { key: 'password', label: 'New Password' },
        { key: 'currentPassword', label: 'Currrent Password' },
        { key: 'confirmPassword', label: 'Confirm Password' },
      ],
      pswdSuggestions: {
        lcl: {
          suggestion: ' Letters (a-z)(A-Z)',
          satisfied: false,
        },
        // cl: {
        //   suggestion: 'Capital Letters (A- Z)',
        //   satisfied: false
        // },
        numbers: {
          suggestion: 'Numbers (0 - 9)',
          satisfied: false,
        },
        length: {
          suggestion: 'Length greater than 5',
          satisfied: false,
        },
        // sc: {
        //   suggestion:
        //     'Special characters ( ( ) [ ] { } ? ! $ % & / = * + ~ , . ; : < > -_ )',
        //   satisfied: false
        // }
      },
      initial: true,
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    ...mapActions(['logout']),
    isPasswordValid() {
      this.pswdSuggestions.lcl.satisfied = /[a-z]|[A-Z]/.test(this.password);
      this.pswdSuggestions.length.satisfied = this.password.length > 5;
      this.pswdSuggestions.numbers.satisfied = /[0-9]/.test(this.password);
      // this.pswdSuggestions.sc.satisfied = /\W|_/.test(this.password);
      return ['lcl', 'numbers', 'length'].every(
        (key) => this.pswdSuggestions[key].satisfied
      );
    },
    async handleRegister() {
      this.initial = false;
      if (!this.isValid()) return;
      if (!this.isPasswordValid()) return;
      this.working = true;
      if (this.password == this.currentPassword) {
        eventBus.$emit('open-toast', {
          message: 'Current and New Password should be different',
          error: true,
        });
        this.working = false;
        return;
      }
      let response = await this.request({
        method: 'put',
        url: 'billing/credential/changepassword',
        data: {
          password: this.password,
          currentPassword: this.currentPassword,
        },
      });
      this.working = false;
      if (response) {
        eventBus.$emit('open-toast', {
          message: ' Password is modified',
        });
        let newResponse = await this.logout();
        if (newResponse) {
          window.location.reload();
        }
      } else {
        eventBus.$emit('open-toast', {
          message: this.berror || 'failed to update password.',
          error: true,
        });
      }
    },
    async getProfile() {
      let response = await this.request({
        url: 'billing/credential/me',
        method: 'get',
      });
      if (response) {
        this.name = response.items[0].name;
        this.gender = response.items[0].gender;
        this.tel = response.items[0].tel;
      }
    },
  },
  created() {
    this.getProfile();
  },
  watch: {
    password() {
      if (!this.initial) this.isValid();
      this.isPasswordValid();
    },
    confirmPassword() {
      if (!this.initial) this.isValid();
    },
  },
  validations: {
    currentPassword: {
      required,
    },
    password: {
      required,
      maxLength: maxLength(15),
    },
    confirmPassword: { required, sameAsPassword: sameAs('password') },
  },
};
</script>

<style></style>
