import moment from 'moment';
import Color from 'color';

export function truncateStr(str, maxLength = 100, ending = '...') {
  return str.length > maxLength
    ? str.substring(0, maxLength - ending.length) + ending
    : str;
}

export function partition(array, n) {
  const copy = array.slice();
  return copy.length ? [copy.splice(0, n)].concat(partition(copy, n)) : [];
}

export function isToday() {
  const today = moment.utc();
  const start = today.clone().startOf('day');
  const end = today.clone().endOf('day');

  return moment.isBetween(start, end, null, '[]');
}

export function ago(date, noSuffix) {
  return moment.utc(date).fromNow(noSuffix)
}

export function isBetween(target, from, to) {
  return moment.utc(target).isBetween(from, to, 'day', '[]') //[] means inclusive
}
export function isSameOrBefore(date, target) {
  return moment.utc(date).isSameOrBefore(target, 'day')
}
export function isSameOrAfter(date, target) {
  return moment.utc(date).isSameOrAfter(target, 'day')
}

export function datesOrdered(date1, date2) {
  return moment.utc(date1).isBefore(moment(date2));
}

export function isDateSameOrAfter(date, from) {
  return moment.utc(date).isSameOrAfter(moment(from));
}

export function isDateSameOrBefore(date, to) {
  return moment.utc(date).isSameOrBefore(moment(to));
}

export function dateInRange(date, { from, to }) {
  return (
    moment.utc(from).isSameOrBefore(moment(date)) &&
    moment.utc(date).isSameOrBefore(moment(to))
  );
}
export function isSameDate(currentDate, dateTobeCompared) {
  return (
    moment.utc(currentDate).format('YYYY-MM-DD') ==
    moment.utc(dateTobeCompared).format('YYYY-MM-DD')
  );
}
export function formatTime(date) {
  return moment.utc(date).format('hh:mm A');
}

export function formatDate(date) {
  return moment.utc(date).format('MMM DD, YYYY');
}
export function toMonthDate(date) {
  return moment.utc(date).format('MMM DD');
}

export function formatInputDate(date) {
  return moment.utc(date).format('YYYY-MM-DD');
}
export function toDDMMYYYY(date) {
  return moment.utc(date).format('DD-MM-YYYY');
}
export function toYYYYMMDD(date) {
  return moment.utc(date).format('YYYY-MM-DD');
}
export function toMMDDYYYY(date) {
  return moment.utc(date).format('MM-DD-YYYY');
}

export function capitilize(word) {
  return word.charAt(0).toUpperCase() + word.substr(1);
}

export function getUserRole() {
  let user = localStorage.getItem('_u_k');
  user = user && JSON.parse(user);
  return user ? user.role : '';
}

export function getUserRoles() {
  let user = localStorage.getItem('_u_k');
  user = user && JSON.parse(user);
  return user ? user.roles : [];
}

export const tuneColor = {
  /* eslint-disable implicit-arrow-linebreak */
  darken: (colorValue, amount) =>
    Color(colorValue)
      .darken(amount)
      .string(),
  lighten: (colorValue, amount) =>
    Color(colorValue)
      .lighten(amount)
      .string()
};

export const buttonVariants = {
  primary: '#ec3118',
  success: '#0B875B',
  danger: '#E13C3C',
  warning: '#F89C1C',
  info: '#0fb9b1',
  secondary: '#F4F5F7'
};

export function responseFields(attributes) {
  let attrs = '';
  if (attributes.length) {
    attributes.forEach(attr => {
      attrs += `${attr.id},`;
    });
  }
  return attrs;
}

export function isValidEmail(value) {
  return /.+@.+\..+/.test(value);
}

export function isRequired(value) {
  return ['', null, undefined].indexOf(value) === -1;
}

export function isAlpha(value) {
  return /^[a-zA-Z][a-zßüÜöÖäÄ_][\wßüÜöÖäÄ]*$/g.test(value); // MARK: Alphabets
}

export function strongPassword(value) {
  return (
    /[a-z]/.test(value) && //MARK: checks for a-z
    /[0-9]/.test(value) && //MARK: checks for 0-9
    /\W|_/.test(value) && //MARK: checks for special char
    /[A-Z]/.test(value) && //MARK: checks for capital letterd
    value.length >= 8
  );
}

export function getErrorMessages(label, validationResult) {
  const MESSAGES = {
    required: `${label} is required`,
    email: `${label} is invalid email.`,
    sameAsPassword: `${label} did not match with Password.`,
    sameAsNewPassword: `${label} did not match with New Password.`,
    noFileSelected: `Select a ${label} please.`
  };
  let valKeys = Object.keys(validationResult);
  let rules = Object.keys(MESSAGES).filter(key => valKeys.includes(key));
  if (!validationResult.$invalid) return [];
  return rules.reduce((messageAcc, rule) => {
    if (!validationResult[rule]) messageAcc.push(MESSAGES[rule]);
    return messageAcc;
  }, []);
}

export function validate(meta, rootValidationResult) {
  if (!rootValidationResult.$invalid) return null;
  let errors = {};
  meta.forEach(({ key, label }) => {
    let keyArr = key.split('.');
    let keyStr = keyArr[keyArr.length - 1];
    if (!label) label = keyStr[0].toUpperCase() + keyStr.slice(1);
    errors[keyStr] = getErrorMessages(
      label,
      getPropByString(rootValidationResult, key)
    );
  });
  return errors;
}

export function getPropByString(obj, propString, date) {
  if (!propString) return obj;
  var prop,
    props = propString.split('.');

  for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
    prop = props[i];

    var candidate = obj[prop];
    if (candidate) {
      obj = candidate;
    } else {
      break;
    }
  }
  if(date) return toDDMMYYYY(obj[props[i]])

  return obj[props[i]] ?? '';
}

export function formatJoiError(errors) {
  return errors.reduce((acc, error) => {
    acc[error.context.key] = [error.message];
    return acc;
  }, {});
}

export function formatePhoneNumber(phoneNumber) {
  if (!phoneNumber) return phoneNumber;
  return [
    phoneNumber.slice(0, 4),
    phoneNumber.slice(4, 6),
    phoneNumber.slice(6, 9),
    phoneNumber.slice(9)
  ].join(' ');
}

export function _2Word(n) {
  let includesDecimalPt = n.toString().includes('.');
  let decimal = n.toFixed(2).split('.');
  if (includesDecimalPt)
    return number2words(n) + 'Birr and ' + number2words(+decimal[1]) + ' cents Only';
  return number2words(n)+' Birr Only';
}

export function number2words(n) {
  n = ~~n;
  var num = 'zero One Two Three Four Five Six Seven Eight Nine Ten Eleven Twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen'.split(
    ' '
  );
  var tens = 'twenty thirty fourty fifty sixty seventy eighty ninety'.split(
    ' '
  );

  if (n < 20) return num[n];
  var digit = n % 10;
  if (n < 100)
    return tens[~~(n / 10) - 2] + (digit ? '-' + num[digit] + '' : '');
  if (n < 1000)
    return (
      num[~~(n / 100)] +
      ' Hundred' +
      (n % 100 == 0 ? '' : '-' + number2words(n % 100))
    );

  if (n < 1000000)
    return (
      number2words(~~(n / 1000)) +
      ' thousand ' +
      (n % 1000 == 0 ? '' : '' + number2words(n % 1000) + '')
    );

  return (
    number2words(~~(n / 1000000)) +
    ' Million' +
    (n % 1000000 != 0 ? '-' + number2words(n % 1000000) : '')
  );
}
