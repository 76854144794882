<template>
  <div class="text-textMedium space-y-4">
    <div class="text-2xl uppercase py-2 text-center">EDIT SCHOOL</div>
    <edit-utility-org v-if="edit" :utilityOrg="utilityOrg" @close="close"/>
    <div v-else class="space-y-4 px-4 pb-4">
      <utility-card :utilityOrg="utilityOrg" />
      <div class="flex justify-between">
        <base-button
          label="cancel"
          class="
            py-1
            px-4
            text-textMedium
            bg-brightGray
            hover:bg-danger
            hover:text-error
            rounded
          "
          @click="close"
        />
        <base-button
          label="Edit"
          icon="pencil-alt"
          class="py-1 px-4 rounded"
          @click="editUtilityOrg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import EditUtilityOrg from '../../../forms/EditUtilityOrg.vue';
import UtilityCard from '../../UtilityCard.vue';
export default {
  components: { UtilityCard, EditUtilityOrg },
  props: ['utilityOrg'],
  data() {
    return {
      working: false,
      edit: false,
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    editUtilityOrg() {
      this.edit = true;
    },
    close() {
      this.$emit('close', {
        invokeCallBack: true
      });
    },
  },
};
</script>

<style></style>
