<template>
  <div class="py-2 text-textMedium space-y-4">
    <div
      class="
        flex
        justify-between
        pb-2
        text-xl text-center
        border-b
        uppercase
        border-brightGray
      "
    >
      <div class="w-full flex justify-center flex-grow">
        <slot class="" name="title">new item</slot>
      </div>
      <div class="flex justify-end flex-grow-0 bg-gray-100 p-1 rounded-full">
        <BaseIcon
          name="times"
          :size="17"
          class="text-textMedium cursor-pointer hover:text-error"
          @click="cancel"
        />
      </div>
    </div>
    <div class="space-y-4 px-4">
      <div>
        <slot></slot>
      </div>
      <div>
        <slot name="footer-actions">
          <div class="flex justify-between pt-2">
            <base-button
              label="cancel"
              class="
                py-1
                px-4
                text-textMedium
                bg-brightGray
                hover:bg-danger
                hover:text-error
                rounded
              "
              @click="cancel"
            />
            <base-button
              :label="saveLabel ? saveLabel : 'Save'"
              class="py-1 px-4 rounded"
              :isWorking="isWorking"
              @click="handleSave"
            />
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['isWorking', 'saveLabel'],
  methods: {
    async handleSave() {
      this.$emit('save');
    },
    cancel() {
      this.$emit('cancel');
      this.$emit('close');
    },
  },
};
</script>

<style></style>
