<template>
  <div class="flex flex-col space-y-3 bg-brightGray">
    <interpole-actions @insert-interpole="insertInterpole" />

    <base-text-area
      ref="message-input"
      placeholder="Enter your message"
      v-model="message"
    />
  </div>
</template>
<script>
import InterpoleActions from './InterpoleActions.vue';

export default {
  components: { InterpoleActions },
  data() {
    return {
      message: ''
    };
  },
  computed: {},

  methods: {
    insertInterpole(interpole) {
      const textarea = this.$refs['message-input'];

      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;

      const textBefore = textarea.value.substring(0, startPos);
      const textAfter = textarea.value.substring(endPos, textarea.value.length);

      this.message = textBefore + interpole + textAfter;
    }
  },

  mounted() {},

  created() {}
};
</script>
