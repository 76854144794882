<template>
  <div class="text-textMedium space-y-4">
    <p class="border-b border-gray-400 py-2 px-4 font-semibold text-center text-xl bg-gray-200">Pay Student Bill</p>
    <form class="space-y-4 p-4 pb-4">
      <!-- <pre>{{ payment }}</pre> -->
   <!-- Student:  {{payment.studentName}} -->
      <div class="space-y-0 bg-gray-200 border p-4 rounded">
        <div class="flex justify-between items-center space-x-1">
          <p class="text-xl font-semibold">{{ payment.studentName }}</p>
          <p>{{ payment.studentMasterId }}</p>
        </div>
        <div class="flex justify-around">
          <div v-if="payment.program" class="-space-y-1 text-center">
            <p>{{ payment.program }}</p>
            <p class="text-xs text-textLight">program</p>
          </div>
          <div v-if="payment.department" class="-space-y-1 text-center">
            <p>{{ payment.department }}</p>
            <p class="text-xs text-textLight">department</p>
          </div>
          <div v-if="payment.grade" class="-space-y-1 text-center">
            <p>{{ payment.grade }}</p>
            <p class="text-xs text-textLight">grade</p>
          </div>
          <div v-if="payment.section" class="-space-y-1 text-center">
            <p>{{ payment.section }}</p>
            <p class="text-xs text-textLight">section</p>
          </div>
          <div v-if="payment.paymentAmount" class="-space-y-1 text-center">
            <p>{{ payment.paymentAmount }}</p>
            <p class="text-xs text-textLight">Regular Payment</p>
          </div>
          <div v-if="payment.registrationFee" class="-space-y-1 text-center">
            <p>{{ payment.registrationFee }}</p>
            <p class="text-xs text-textLight">Registration</p>
          </div>
        </div>
      </div>
      <div class="grid md:grid-cols-2 gap-2 row-gap-4">
        <base-input
          label="Penalty"
          placeholder="Penalty"
          bordered
          required
          v-model="Form.penalty"
        />
        <base-input
          label="Transaction Number"
          placeholder="Transaction Number"
          bordered
          required
          v-model="Form.transactionNumber"
        />
        
      </div>
      <div class="flex justify-between mt-3">
        <base-button
          label="cancel"
          class="
            py-1
            px-4
            text-textMedium
            bg-brightGray
            hover:bg-danger
            hover:text-error
            rounded
          "
          @click="close"
        />
        <base-button
          :isWorking="working"
          label="Pay Bill"
          icon="check"
          class="py-1 px-4 rounded"
          @click="updateCustomerBill"
        />
      </div>
    </form>
  </div>
</template>

<script>
import eventBus from '@/eventBus';
import { mapActions, mapGetters } from 'vuex';
export default {
   props: ['payment', 'paymentId'],
  data() {
    return {
      working: false,
      
      Form: {
        penalty: undefined,
        transactionNumber: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
    updatedFields() {
      return Object.keys(this.Form).filter((field) => {
        const value = this.Form[field];
        if (typeof(this.Form[field]) == 'object' && value)
          return Object.keys(value).find(
            (field2) => value[field2] != this.payment[field2]
          );
        return value != this.payment[field];
      });
    },
    updatedData() {
      let data = Object.create({});
      this.updatedFields.forEach((field) => {
        data[field] = this.Form[field];
      });
      return data;
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    async updateCustomerBill() {
      this.working = true;
      let response = await this.request({
        method: 'put',
        url: 'billing/utilcustomers/pay/' + this.payment._id,
        data: {
          penalty: this.Form.penalty,
          transactionNumber: this.Form.transactionNumber,
        }
      });
      if (response) {
        this.$emit('close', {
          invokeCallBack: true,
          response: { payment: response.item },
        });
        eventBus.$emit('open-toast', {
          message: 'Student Bill paid successfully.',
        });
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror
        })
      }
      this.working = false;
    },
    close() {
      this.$emit('close');
    },
  },
  created() {
    if (this.payment) {
      this.paymentId = Object.freeze(this.paymentId);
      this.Form.cust_util_id = this.payment.studentSchoolId;
      this.Form.penalty = this.payment.penalty;
    }
  },
};
</script>

<style></style>
