<template>
  <div class="py-4 text-textMedium space-y-4">
    <div class="pb-2 text-2xl text-center border-b border-brightGray">
      Change Status
    </div>
    <div class="space-y-4 px-4">
      <credential-card :credential="credential" />
      <div>
        <base-select label="Status" :items="statuses" v-model="status" bordered>
          <template #default="{ item }">
            <div class="flex space-x-2 items-center">
              <div
                class="w-6 h-6 rounded"
                :class="status === 'Verified' ? 'bg-teal' : 'bg-danger'"
              ></div>
              <div>{{ item.value }}</div>
            </div>
          </template>
          <template #option="{ item }">
            <div class="flex space-x-2 items-center">
              <div
                class="w-8 h-8 rounded"
                :class="item.value === 'Verified' ? 'bg-teal' : 'bg-danger'"
              ></div>
              <div>{{ item.value }}</div>
            </div>
          </template>
        </base-select>
      </div>
      <div class="flex justify-between py-2">
        <base-button
          label="cancel"
          class="
            py-2
            px-6
            text-textMedium
            bg-brightGray
            hover:bg-danger
            hover:text-error
            rounded
          "
          @click="close"
        />
        <base-button
          :isWorking="isWorking"
          icon="check-circle"
          label="UPDATE"
          class="py-2 px-6 rounded"
          @click="handleChangeStatus"
        />
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus';
import { PROFILE_PATH } from '@/config/config';
import { mapActions, mapGetters } from 'vuex';
import CredentialCard from '@/components/card/CredentialCard.vue';
export default {
  components: { CredentialCard },
  props: ['credential', 'role'],
  data() {
    return {
      isWorking: false,
      PROFILE_PATH,
      photo: '',
      status: '',
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
    statuses() {
      let options = [{ value: 'Verified' }, { value: 'Suspended' }];
      return options;
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    async handleChangeStatus() {
      this.isWorking = true;
      let response = await this.request({
        method: 'put',
        url: 'billing/credential/profile/' + this.credential._id,
        data: {
          status: this.status,
        },
      });
      if (response) {
        eventBus.$emit('open-toast', {
          message: 'Credential is ' + this.status + ' successfully',
        });
        this.$emit('close', { invokeCallBack: true });
      } else {
        eventBus.$emit('open-toast', {
          message: this.berror,
          error: true,
        });
      }
      this.isWorking = false;
    },
    async getOperator() {
      let response = await this.request({
        method: 'get',
        url: 'billing/credential/' + this.credential._id,
      });
      if (response) this.photo = response.item.photo;
    },
    close() {
      this.$emit('close');
    },
  },
  created() {
    this.status = this.credential.status;
    this.getOperator();
  },
};
</script>

<style></style>
