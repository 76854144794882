import Signin from '@/views/open/Signin.vue';
import _404 from '@/views/_404.vue';

const meta = {
    public: true,
    onlyWhenLoggedOut: true
};

const OPEN_ROUTES = [
    {
        path: '/signin',
        component: Signin
    },
    {
        path: '/404',
        component: _404
    },
].map(route => ({...route, meta }));
export default OPEN_ROUTES;