<template>
  <div>
    <smart-export-pdf
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SmartExportPdf from './SmartExportPdf.vue';
export default {
  components: {
    SmartExportPdf
  },
  computed: {
    ...mapGetters('printer', [
      'showExportPdf',
    ])
  },
  created() {
  },
};
</script>

<style></style>
