<template>
  <div class="py-4 text-textMedium space-y-4">
    <div class="pb-2 text-2xl text-center border-b border-brightGray">
      Change Branch
    </div>
    <div class="space-y-4 px-4">
            <credential-card :credential="credential" />

      <div>
        <auto-complete
          label="Office"
          placeholder="Search office"
          bordered
          :items="officesTransformed"
          v-model="office_id"
        />
      </div>
      <div class="flex justify-between py-2">
        <base-button
          label="cancel"
          class="py-2 px-6 text-textMedium bg-brightGray hover:bg-danger hover:text-error rounded"
          @click="close"
        />
        <base-button
          label="Save" :isWorking='working'
          class="py-2 px-6 rounded"
          @click="handleRegister"
        />
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus';
import { mapActions, mapGetters } from 'vuex';
import AutoComplete from '@/components/base/AutoComplete.vue';
import CredentialCard from '@/components/card/CredentialCard.vue';
export default {
  components: { AutoComplete,CredentialCard },
  props: ['credential'],
  data() {
    return {
      working:false,
      photo: '',
      office_id: '',
      offices: []
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
    officesTransformed() {
      return this.offices.map(office => ({
        label: office.name,
        value: office._id
      }));
    }
  },
  methods: {
    ...mapActions('main', ['request']),
    async fetchBranches() {
      let response = await this.request({
        method: 'get',
        url: 'billing/office/verified',
        data: {}
      });
      if (!response.error) {
        this.offices = response.items;
      }
    },
    async getCredential() {
      let response = await this.request({
        method: 'get',
        url: 'billing/credential/' + this.credential._id
      });
      if (response) this.photo = response.item.studentSchoolId.photo;
    },
    async handleRegister() {
      this.working=true;
      let response = await this.request({
        method: 'put',
        url: 'billing/credential/changeoffice/' + this.credential._id,
        data: {
          office: this.office_id
        }
      });
      this.working=false;
      if (response) {
        eventBus.$emit('open-toast', {
          message: ' Officer office is changed'
        });
        this.$emit('close', { invokeCallBack: true });
      } else {
        eventBus.$emit('open-toast', {
          message: this.berror,
          error: true
        });
      }
    },
    close() {
      this.$emit('close');
    }
  },
  async created() {
    await this.fetchBranches();
    this.office_id = this.credential.office?._id;
    this.getCredential();
  }
};
</script>

<style></style>
