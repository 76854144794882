
const state = {
  isLoading: false,
  isWorking: false,
  customers: [],
  uploadedCustomers: []
};

const mutations = {
  setLoading: (state, loading) => {
    state.isLoading = loading;
  },
  setWorking: (state, working) => {
    state.isWorking = working;
  },
  setStudents: (state, items) => {
    state.customers = items;
  },
  addStudent: (state, item) => {
    state.customers.push(item);
  },
  setUploadedStudents: (state, items) => {
    state.customers = state.customers.filter(({studentSchoolId})=> !items.find((cust)=> cust.studentSchoolId == studentSchoolId));
    state.uploadedCustomers.push(...items);
  },
};
const actions = {
  async setLoading({ commit }, loading) {
      commit('setLoading', loading);
      return true;
  },
  async setWorking({ commit }, working) {
      commit('setWorking', working);
      return true;
  },
  async setStudents({ commit }, items) {
      commit('setStudents', items);
      return true;
  },
  async addStudent({ commit }, item) {
      commit('addStudent', item);
      return true;
  },
  async setUploadedStudents({ commit }, items) {
      commit('setUploadedStudents', items);
      return true;
  },
  clear({ commit }) {
    commit('setWorking', false);
    commit('setStudents', []);
    return true;
  }
};

const getters = {
  isLoading: state => state.isLoading,
  isWorking: state => state.isWorking,
  uploadedCustomers: state => state.uploadedCustomers,
  customers: state => state.customers||[],
};

const customer = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

export default customer;
