<template>
    <div class="flex flex-col justify-start space-y-2 w-full h-screen overflow-hidden">
        <div class=""></div>
        <div class="overflow-y-auto">
            <search-input />
        </div>
    </div>
</template>
  
<script>
import SearchInput from "@/components/card/search/SearchInput.vue";

export default {
    components: { SearchInput },
    data() {
        return {
        }
    },
    methods: {
    }
}

</script>
  
<style scoped></style>
  