<template>
  <div class="p-4 space-y-4 bg-white shadow-md">
    <div class="text-lg text-textMedium tracking-wide">Messages</div>
    <div class="space-y-4 p-0 ml-5 border-l border-borderGray">
      <div class="flex space-x-4 -ml-1-5" v-for="(message, i) in 3" :key="i">
        <div class="bg-info rounded-full w-5 h-3 shadow-lg self-center"></div>
        <div class="flex flex-col justify-between w-full">
          <div class="flex flex-shrink-0 text-textMedium">Haftom Haregot</div>
          <div class="text-textLight text-xs tracking-wider">Uploaded July Payment</div>
        </div>
        <div class="flex flex-shrink-0 items-start align-text-top text-textLight text-xs">14 Sep</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>