<template>
  <div class="flex items-center pl-10 space-x-4 text-textMedium ">
    <div class="flex items-center justify-end space-x-3">
          <span v-if="!isGranted('0888tt')" class="flex space-x-3">

      <div v-for="(nav, i) in grantedMenus" :key="i" @click="navigate(nav.path)" class="
          cursor-pointer
          hover:opacity-75
          grow-active
          uppercase
          tracking-widest
          text-sm 
          py-3
        " :class="{ 'text-primary font-bold border-b': nav.path === activePath}">
        {{ nav.label }}
      </div>
            </span>

      <span v-if="isGranted('0888tt')" class="flex  space-x-4">
      <div   v-for="(nav, i) in grantedMenus" :key="i" @click="navigate(nav.path)" class="
          cursor-pointer
          hover:opacity-75
          grow-active
          uppercase
          tracking-widest
          text-sm 
          text-white
          py-3
        " :class="{ 'text-primary font-bold border-b': nav.path === activePath}">
        {{ nav.label }}
      </div>
      </span>
      <tippy
        v-if="hasGrantedActivity(['0011ptrm', '0111ptrm', '0001ptrm', '0111ptype', '0011ptype', '0001ptype', '0999up'])"
        placement="bottom" trigger="click" theme="light" arrow animation="scale" size="medium" interactive
        ref="payments-menu">
        <template v-slot:trigger>
          <div class="
            flex
            items-center
            space-x-2
            bg-blueGray
            text-textMedium
            py-1
            px-3
            rounded
            hover:text-primary
            cursor-pointer
            grow-active
          ">
            <BaseIcon name="exchange-alt" />
            <div class="text-sm uppercase">Payments</div>
          </div>
        </template>
        <div class="flex flex-col space-y-2 py-3 px-1">
          <div class="
            py-2
            px-4
            text-left
            hover:bg-brightGray
            cursor-pointer
            active:text-primary
            grow-active
            uppercase
            text-13
            flex
          " v-for="(tippyNav, i) in paymentsTippyNavs" :key="i"
            @click="handleTippyNavigation(tippyNav, 'payments-menu')">
            <div class="flex-shrink-0">{{ tippyNav.label }}</div>
          </div>
        </div>
      </tippy>
      <tippy
        v-if="hasGrantedActivity([ 'r-myorgcust', 'r-mybrcust', 'r-orgcust', 'r-brcust', 'r-myorgpymnts', 'r-mybrpymnts', 'r-orgpymnts', 'r-brpymnts',])"
        placement="bottom"
        trigger="click"
        theme="light"
        arrow
        animation="scale"
        size="medium"
        interactive
        ref="reports-menu"
      >
        <template v-slot:trigger>
          <div
            class="flex items-center space-x-2 bg-blueGray text-textMedium py-1 px-3 rounded hover:text-primary cursor-pointer grow-active"
          >
            <BaseIcon name="chart-line" />
            <div class="text-sm uppercase">Reports</div>
          </div>
        </template>
        <div class="flex flex-col space-y-2 py-4 px-1 mt-12">
          <div
            class="py-2 px-4 text-left hover:bg-brightGray cursor-pointer active:text-primary grow-active uppercase text-13 flex"
            v-for="(tippyNav, i) in reportsTippyNavs"
            :key="i"
            @click="handleTippyNavigation(tippyNav, 'reports-menu')"
          >
            <div class="flex-shrink-0">{{ tippyNav.label }}</div>
          </div>
        </div>
      </tippy>

      <tippy v-if="hasGrantedActivity([
        '0001c',
        '0004c',
        '0004oc',
        '0009up',
        '0006oo',
        '0006o',
        '0002or'
      ])
        " placement="bottom" trigger="click" theme="light" arrow animation="scale" size="medium" interactive
        ref="maker-tippy-extra-menu">
        <template v-slot:trigger>
          <div class=" flex items-center space-x-2 bg-blueGray text-textMedium py-1 px-3 rounded hover:text-primary cursor-pointer grow-active">
            <BaseIcon name="plus" :size="12" />
            <div class="text-sm uppercase">New</div>
            <BaseIcon name="caret-down" />
          </div>
        </template>
        <div class="grid grid-cols-2 gap-1 py-3 px-1">
          <div class="
              py-2
              px-4
              text-left
              hover:bg-brightGray
              cursor-pointer
              active:text-primary
              grow-active
              uppercase
              text-13
            " v-for="(tippyNav, i) in newTippyNavs" :key="i"
            @click="handleTippyNavigation(tippyNav, 'maker-tippy-extra-menu')">
            {{ tippyNav.label }}
          </div>
        </div>
      </tippy>
    </div>
  </div>
</template>

<script>
import { activity } from '@/mixins/activity';
import eventBus from '../../eventBus';
export default {
  mixins: [activity],
  data() {
    return {
      moreTippyNavs: []
    };
  },
  computed: {
    activePath() {
      return this.$route.fullPath;
    },
    paymentsTippyNavs() {
      return [
        {
          label: 'Payment Terms',
          path: '/payment-terms',
          isGranted: this.hasGrantedActivity(['0111ptrm', '0011ptrm', '0001ptrm'])
        },
        {
          label: 'Payment Types',
          path: '/payment-types',
          isGranted: this.hasGrantedActivity(['0111ptype', '0011ptype', '0001ptype'])
        },
        {
          label: 'PAYMENTS',
          path: '/payments',
          isGranted: this.isGranted('0999up')
        },
      ].filter(({ isGranted }) => isGranted);
    },
    reportsTippyNavs() {
      return [
        {
          label: 'Bills',
          path: '/report/myorg-bills',
          isGranted: this.hasGrantedActivity([
            'r-myorgpymnts',
            'r-mybrpymnts',
            'r-orgpymnts',
            'r-brpymnts',
          ]),
        },
        {
          label: 'Students',
          path: '/report/students',
          isGranted: this.hasGrantedActivity([
            'r-myorgcust',
            'r-mybrcust',
            'r-orgcust',
            'r-brcust',
          ]),
        },

      ]
    },
    newTippyNavs() {
      return [
        {
          label: 'New Student',
          path: '/register-customer',
          isGranted: this.isGranted('0001c')
        },
        {
          label: 'New Credential',
          modalId: 11,
          isGranted: this.isGranted('0004c')
        },
        {
          label: 'New School Credential',
          modalId: 22,
          isGranted: this.isGranted('0004oc')
        },
        {
          label: 'New Branch',
          modalId: 2,
          isGranted: this.isGranted('0006o')
        },
        {
          label: 'New Agent',
          modalId: 222,
          isGranted: this.isGranted('0890tt')
        },
        {
          label: 'New School Branch',
          modalId: 23,
          isGranted: this.isGranted('0006oo')
        },
        {
          label: 'New Role',
          modalId: 10,
          isGranted: this.isGranted('0002r')
        },
        {
          label: 'New School Role',
          modalId: 24,
          isGranted: this.isGranted('0002or')
        },
        {
          label: 'Upload Students',
          path: '/upload-customers',
          isGranted: this.isGranted('0001uc')
        },
        {
          label: 'Upload Bills',
          path: '/upload-payments',
          isGranted: this.isGranted('0009up')
        }
        // {
        //   label: 'New TD Account',
        //   path: '/new-td-account',
        //   isGranted: this.isGranted(42)
        // },
        // {
        //   label: 'Search Customer',
        //   path: '/search-customer',
        //   isGranted: this.isGranted(44)
        // },
      ].filter(({ isGranted }) => isGranted);
    },
    allMenus() {
      return [
        {
          label: 'Dashboard',
          path: '/',
          isGranted: true
        },
        {
          label: 'Schools',
          path: '/schools',
          isGranted: this.isGranted('0888ut')
        },
        {
          label: 'Tickets',
          path: '/tickets',
          isGranted: this.isGranted('0888tt')
        },
        {
          label: 'Matches',
          path: '/matches',
          isGranted: this.isGranted('0888tt')
        },
        {
          label: 'Branches',
          path: '/all-branches',
          isGranted: this.isGranted('0888ut')
        },

        {
          label: 'BRANCHES',
          path: '/branches',
          isGranted: this.isGranted('0666o')
        },
        {
          label: 'Migration',
          path: '/migration',
          isGranted: this.isGranted('0888ut')
        },
        
        {
          label: 'STUDENTS',
          path: '/customers',
          isGranted: this.hasGrantedActivity(['0111orgc', '0111offc'])
        },
        {
          label: 'CREDENTIALS',
          path: '/credentials',
          isGranted: this.hasGrantedActivity([
            '0444allc',
            '0444orgc',
            '0444offc'
          ])
        },
        {
          label: 'CONFIG',
          path: '/configs',
          isGranted: this.hasGrantedActivity(['0222r', '0777cf'])
        },
        {
          label: 'SEARCH',
          path: '/search',
          isGranted: this.hasGrantedActivity(['0111sc', '0111st'])
        },
        // {
        //   label: 'MESSAGE',
        //   path: '/send-message',
        //   isGranted: true,
        // },
        
      ];
    },
    grantedMenus() {
      return this.allMenus.filter(({ isGranted }) => isGranted);
    }
  },
  methods: {
    navigate(path) {
      if (this.$route.fullPath !== path) this.$router.push({ path });
      else window.location.reload();
    },
    handleTippyNavigation(navTippy, ref) {
      if (navTippy.path) this.navigate(navTippy.path);
      else if (navTippy.modalId)
        eventBus.$emit('open-modal', {
          modalId: navTippy.modalId
        });
      this.hideDialog(ref);
    },
    hideDialog(ref) {
      this.$refs[ref].tip.hide();
    }
  },
  created() { }
};
</script>

<style></style>
