<template>
  <div class="text-textMedium space-y-2">
    <div class="text-sm">Total {{ users.length }} logged in users</div>
    <div class="">
      <div v-for="user in users" :key="user._id">
        <div class="grid grid-cols-7 gap-2 hover:bg-brightGray p-2">
          <div class="col-span-3 flex space-x-2 items-center">
            <avatar
              :name="user.firstname||user.username | get_2Letters"
              :_2Letters="user.firstname||user.username | get_2Letters"
              :size="28"
            />

            <div class="flex space-x-4 items-center">
              <div class="text-sm font-bold">{{ user.firstname||user.username }}</div>
              <div
                class="bg-primary text-white px-1 rounded"
                style="font-size: 0.72rem"
              >
                {{ getActiveRole(user.roles) }}
              </div>
            </div>
          </div>
          <div class="col-span-4 text-sm pl-4">
            <span class="text-success"
              >Online</span
            >
            <span class="text-textDark ml-2"
              >{{ user.updatedAt | formatDate }}
              {{ user.updatedAt | formatTime }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { _2Letters } from '@/mixins/_2Letters.js';
import Avatar from '@/components/base/BaseAvatar.vue';
import { formatDate, formatTime } from '@/utils';
export default {
  components: { Avatar },
  mixins: [_2Letters],
  data() {
    return {
      users: []
    };
  },

  filters: {
    formatDate,
    formatTime
  },
  computed:{
     ...mapGetters(['user']),
        ...mapGetters('socket', ['sioClient', 'clientConnected']),
        role() {
      return this.user.role;
    }
  },
    watch: {
    clientConnected: {
      immediate: true,
      handler() {
        if (this.clientConnected) {
          this.sioClient.on('User_Disconnected', (data) => {
           this.users=this.users.filter(user=>user._id!=data.userId)
        });
          this.sioClient.on('User_Connected', (data) => {
            if(this.role=='branch manager'||this.role=='csm'){
            this.getOnlineUsers();
            }
          else this.users=[data,...this.users]
        });
        }
      }
    }
    },
  methods: {
    ...mapActions('main', ['request']),

    async getOnlineUsers() {
      const response = await this.request({
        url: 'credential/loggedinusers',
        method: 'get'
      });
      if (response) 
      this.users = response.items.reverse();
    },
    getActiveRole(roles){
      const activeRole= roles.find(role=>role.active)
      return activeRole.role||'Role Not Found'
    }
  },
  created() {
    this.getOnlineUsers();
  }
};
</script>

<style></style>
