<template>
  <div class="flex flex-col space-y-3 items-end">
    <div class="flex items-center justify-center w-full">
      <label
        for="dropzone-file"
        class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
        v-cloak
        @drop.prevent="addFile"
        @dragover.prevent
      >
        <div class="flex flex-col items-center justify-center pt-5 pb-6">
          <svg
            class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 16"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
            />
          </svg>
          <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
            <span class="font-semibold">Click to upload</span> or drag and drop
          </p>
          <p class="text-xs text-gray-500 dark:text-gray-400">
            XLSX, XLS, CSV, ODS, FODS(MAX. 32 MB)
          </p>
          <p v-if="file" class="py-4">{{file.name}}</p>
        </div>
        <input id="dropzone-file" type="file" class="hidden" />
      </label>
    </div>
    <base-button
      :disabled="!file || isWorking"
      :isWorking="isWorking"
      icon="cloud-upload"
      label="Send File"
      @click="sendFile"
      class="rounded "
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import * as XLSX from 'xlsx/xlsx.mjs';
import eventBus from '../../../../eventBus';
export default {
  props: ['school', 'branch'],
  data() {
    return {
      file: undefined,
      isWorking: false,
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
  },
  methods: {
    ...mapActions('migration', ['smartSet', 'setSheets', 'clearSheets']),
    ...mapActions('main', ['upload']),
    addFile(e) {
      e.stopPropagation();
      e.preventDefault();
      console.info('file droped');
      this.file = e.dataTransfer.files[0];
      /* f is a File */
      // var reader = new FileReader();
      // reader.onload = (e) => {
      //   var data = e.target.result;
      //   /* reader.readAsArrayBuffer(file) -> data will be an ArrayBuffer */
      //   var workbook = XLSX.read(data);
      //   this.sheetNames = workbook.SheetNames;

      //   this.parseWorkbookToSheets(workbook);
      // };
      // reader.readAsArrayBuffer(f);
    },
    async sendFile() {
      this.isWorking = true;
      let data = new FormData();
      data.append('students', this.file);
      data.append('school', this.school);
      data.append('branch', this.branch);
      const response = await this.upload({
        url: 'migration/upload-file',
        method: 'post',
        data,
      });
      if (response) {
        const { fileId, worksheets } = response.item;
        this.smartSet({ stateKey: 'fileId', data: fileId });
        this.setSheets(worksheets);
        eventBus.$emit('open-modal', {
          modalId: 33,
          componentProps: {
            school: this.school,
            branch: this.branch
          }
        })
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror,
        });
      }
      this.isWorking = false;
    },
    parseWorkbookToSheets(workbook) {
      // Get all the sheets in the workbook
      const sheets = workbook.Sheets;
      const sheetNames = workbook.SheetNames;
      let parsedSheets = [];
      for (const sheetName in sheets) {
        // Get the current sheet
        const sheet = sheets[sheetName];
        const { data: students, headers } = this.parseSheet(sheet);
        parsedSheets.push({
          sheetName,
          headers,
          students,
          length: students.length,
        });
      }
      this.smartSet({ stateKey: 'sheetNames', data: sheetNames });
      this.setSheets(parsedSheets);
    },
    // parseSheet(sheet) {
    //   const data = XLSX.utils.sheet_to_json(sheet, {
    //     raw: true,
    //   }); // jshint ignore:line
    //   console.info({ data });
    //   const headers = data.length ? Object.keys(data[0]) : [];
    //   return { data, headers };
    //   // return data.map((x) => {
    //   //   Object.keys(x)
    //   //     .map((y) => [
    //   //       y,
    //   //       !x[y].split ? x : x[y].split(';;').filter((x) => !!x),
    //   //     ])
    //   //     .forEach((values) => {
    //   //       if (values[1].length > 1) x[values[0]] = values[1];
    //   //     });
    //   //   return Object.assign({}, x, { $doc_type: sheetName });
    //   // });
    // },
    // _parseSheet(sheet) {
    //   console.info({ sheet });
    //   let students = [];
    //   console.info({ sheet: sheet.rowCount });
    //   for (let row = 1; row <= sheet.rowCount; row++) {
    //     // Create an empty object to store the student profile information
    //     const student = {};
    //     // Iterate over each column in the row
    //     for (let column = 1; column <= sheet.columnCount; column++) {
    //       // Get the cell value
    //       const cellValue = sheet.getCell(row, column).value;

    //       // Get the column header
    //       const columnHeader = sheet.getCell(1, column).value;

    //       // Assign the cell value to the student object
    //       student[columnHeader] = cellValue;
    //     }

    //     // Add the student object to the students array
    //     students.push(student);
    //   }
    //   return students;
    // },
  },
};
</script>

<style></style>
