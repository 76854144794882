const state = {
  fileId: undefined,
  sheets: [],
  sheetNames: [],
  isLoading: false,
  isDataLoaded: false,
};

const mutations = {
  SMART_SET: (state, { stateKey, data }) => {
    state[stateKey] = data;
  },
  setSheets: (state, sheets) => {
    state.sheets = sheets;
  },
  clearSheets: (state) => {
    state.sheets = [];
  },
};

const actions = {
  smartSet({ commit }, { stateKey, data }) {
    commit('SMART_SET', {
      stateKey,
      data,
    });
  },
  setSheets({ commit }, sheets) {
    commit('setSheets', sheets);
  },
  clearSheets({ commit }) {
    commit('clearSheets');
  },
};

const getters = {
  fileId: (state) => state.fileId,
  sheets: (state) => state.sheets,
  sheetNames: (state) => state.sheetNames,
  isLoading: (state) => state.isLoading,
  isDataLoaded: (state) => state.isDataLoaded,
};

const migration = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default migration;
