<template>
  <div>
    <div class="flex items-center px-0 space-x-2 shadow-md  " :class="{ 'bg-green-800 text-white': isTic}" ref="header">
      <div v-if="isTic" class="flex items-center space-x-1 py-1 ">
        <img src="@/assets/ftlion.jpeg" class="h-24 rounded-full" alt="" />
        <div class="hidden xl:flex text-lg font-bold tracking-widest uppercase px-2 text-white">
           LIB-Tickets 
        </div>
      </div>
      <div v-else class="flex items-center space-x-1 py-1  bg-primary">
        <img src="@/assets/Lion Bank.jpg" class="h-16 rounded-full" alt="" />
        <div class="hidden xl:flex text-lg font-bold tracking-widest uppercase px-2 text-white">
           LIB-Infinity 
        </div>
      </div>

      <div class="flex-auto overflow-x-auto">
        <component :is="activeMenu" />
      </div>
      <div class="px-2 font-sans tracking-widest border border-primary">
        {{ formatedCounter }}
      </div>
      <div class="flex items-center space-x-3 "  :class="{ ' text-white': isTic,'text-textMedium':!isTic}">
        <div>
          <a href="/credentials/credentials.html" target="_blank" title="Guide">
          <BaseIcon
            name="circle-question"
            class="ml-4 cursor-pointer"
             :class="{ ' text-white': isTic,'text-textMedium':!isTic}"
            :size="20"
            @click="$router.push('/docs')"
          />
          </a>
        </div>
        <notifications />
        <div  :class="{ ' text-white': isTic,'text-textMedium':!isTic}">Hi, {{ name }}</div>

        <profile />
      </div>
    </div>
    <div class="py-2 text-center bg-danger" v-if="!clientConnected && false">
     connection with server is lost check your internet connection !!
    </div>
    <!-- <div class="py-2 text-center text-primary">
      <marquee behavior="scroll" direction="left">
       Thank you for your ongoing participation in the User Acceptance Testing. We appreciate your valuable feedback.
       </marquee>
    </div>    -->
    <div class="relative flex px-5 py-2 mt-2 text-white bg-info " v-if="passwordInfo&&passwordExpirationLeft>0">
      <div class="flex-1 text-center">
      your password will expire after <span class="px-2 py-2 text-danger">{{passwordExpirationLeft}}</span> days you are advised to change your password now.
      </div>
      <div class="absolute right-0 mr-5 cursor-pointer" @click="hidePasswordInfo">
        <BaseIcon name="times" :size="18" />
      </div>
    </div>
    <div
      class="px-4 pt-3 overflow-y-auto"
      :style="{ height: containerHeight + 'px' }"
    >
      <div v-if="changePassword">
        <div
          class="flex items-center justify-between px-8 py-2 mb-8 text-white rounded  k bg-error"
        >
          You Should Change Your Password before you proceed.
        </div>
        <settings />
      </div>
      <div v-else-if="passwordExpired">
        <div
          class="flex items-center justify-between px-8 py-2 mb-8 text-white rounded  k bg-error"
        >
          Your Password Has Expired ,You Should Change Your Password before you proceed.
        </div>
        <settings />
      </div>
      <router-view v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dynamicMenu from '@/components/menus/dynamicMenu.vue';
import Profile from '../../components/card/tippy/Profile.vue';
import Notifications from '../../components/card/tippy/Notifications.vue';
import Settings from '../protected/common/Settings.vue';

export default {
  components: { Notifications, Profile, Settings },
  data() {
    return {
      dynamicMenu,
      containerHeight: 0,
      passwordInfo:true,
     isTic: this.isGranted('0888tt')
    };
  },
  computed: {
    ...mapGetters(['user']),
  ...mapGetters('socket', ['sioClient', 'clientConnected']),
    ...mapGetters('timer', ['inactivityCounter', 'inactivityPeriod']),
    activePath() {
      return this.$route.fullPath;
    },
   
    
    formatedCounter() {
      let durationLeft = this.inactivityPeriod - this.inactivityCounter;
      durationLeft = Math.floor(durationLeft);
      let hours = Math.floor(durationLeft / 3600);
      durationLeft -= hours * 3600;
      let mins = Math.floor(durationLeft / 60);
      durationLeft -= mins * 60;
      let secs = durationLeft;
      let result = '';
      if (hours > 0)
        result =
          this.formatDigit(hours) +
          ':' +
          this.formatDigit(mins) +
          ':' +
          this.formatDigit(secs);
      else result = this.formatDigit(mins) + ':' + this.formatDigit(secs);
      return result;
    },

    role() {
      return this.user.role;
    },
    name() {
      return this.user.name;
    },
    activeMenu() {
      return this.dynamicMenu;
    },
    changePassword() {
      return this.user.changePassword;
    },
    passwordExpired() {
      return this.user.passwordExpired;
    },
    passwordExpirationLeft(){
      return this.user.passwordExpirationLeft
    }
  },
  methods: {
    hidePasswordInfo(){
      this.passwordInfo=false;
    },
    formatDigit(digit) {
      if (digit < 10) return '0' + digit;
      return digit;
    },
  },
  mounted() {
    let headerEl = this.$refs['header'];
    this.containerHeight = document.body.clientHeight - headerEl.clientHeight;
  },

  created() {
    /// sign socket
  },
};
</script>

<style></style>
