<template>
  <div>
    <sign-me-out />
  </div>
</template>

<script>
import io from 'socket.io-client';
import { IO_BASE_URL } from '@/config/config';
import { mapActions, mapGetters } from 'vuex';
import SignMeOut from './SignMeOut.vue';
export default {
  components: { SignMeOut },
  computed: {
    ...mapGetters('socket', ['sioClient']),
    ...mapGetters(['user']),
  },
  methods: {
    ...mapActions('socket', ['smartSet']),
    async ioconnect() {
      const token = await localStorage.getItem('access_token');
      let sioClient = io(IO_BASE_URL, {
        withCredentials: true,
        extraHeaders: {
          token,
        },
        query: {
          token,
        },
      });
      this.smartSet({ stateKey: 'sioClient', data: sioClient });
    },
  },
  async created() {
    await this.ioconnect();
    this.sioClient.on('connected', () => {
      this.smartSet({ stateKey: 'clientConnected', data: true });
    });
    this.sioClient.on('disconnect', () => {
      this.smartSet({ stateKey: 'clientConnected', data: false });
    });
    this.sioClient.emit('signed_in', { 
      // userId: this.user.id
       });
  },
  destroyed() {
    this.smartSet({ stateKey: 'clientConnected', data: false });
  },
};
</script>

<style></style>
