<template>
  <div
    class="flex justify-end space-x-2 px-4 py-3 shadow-md rounded-lg text-textMedium text-lg"
  >
    <div v-if="totalPages > 1" class="flex-auto flex items-center space-x-4">
      <div
        class="
          hover:text-primary
          cursor-pointer
          p-3
          hover:bg-brightGray
          rounded-full
          grow-active
        "
        @click="changePage(currentPage - 1)"
      >
        <BaseIcon name="chevron-left" :size="12" />
      </div>

      <div
        class="
          hover:text-primary
          cursor-pointer
          px-2
          rounded
          hover:bg-brightGray
          grow-active
        "
        v-for="i in pages"
        :key="i"
        :class="{ 'text-primary bg-blueGray': i === currentPage }"
        @click="changePage(i)"
      >
        {{ i }}
      </div>

      <div
        class="
          hover:text-primary
          cursor-pointer
          p-3
          hover:bg-brightGray
          rounded-full
          grow-active
        "
        @click="changePage(currentPage + 1)"
      >
        <BaseIcon name="chevron-right" :size="12" />
      </div>
      <div class="text-xs">{{ totalPages }} pages</div>
    </div>
    <div class="flex justify-end space-x-2" v-if="!necessary">
      <div v-if="totalPages > 1" class="flex space-x-2 items-center">
        <div class="text-right text-xs">Go to</div>
        <BaseInput
          style="width: 80px"
          type="number"
          v-model="pageInput"
          padding="small"
          :size="14"
          @enter="gotoPage"
        />
      </div>
      <div class="flex space-x-2 items-center">
        <div class="text-right text-xs">Rows</div>
        <BaseInput
          style="width: 80px"
          type="number"
          v-model="pageSizeInput"
          padding="small"
          :size="14"
          @enter="changePageSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: { type: Number },
    pageSize: { type: Number },
    totalPages: { type: Number },
    necessary: { type: Boolean, default: false }, /// show only necessary items : default false.
  },
  data() {
    return {
      pageInput: this.currentPage,
      pageSizeInput: this.pageSize,
    };
  },
  computed: {
    pages() {
      let p = [];
      if (this.currentPage >= 2 && this.currentPage <= this.totalPages - 1)
        p = [this.currentPage - 1, this.currentPage, this.currentPage + 1];
      if (this.currentPage === 1)
        p = [2, 3].reduce(
          (acc, i) => {
            if (i <= this.totalPages) acc.push(i);
            return acc;
          },
          [1]
        );
      if (this.currentPage === this.totalPages) {
        p = [this.currentPage - 1, this.currentPage - 2].reduce(
          (acc, i) => {
            if (i >= 1) acc.unshift(i);
            return acc;
          },
          [this.currentPage]
        );
      }
      return p;
    },
  },
  methods: {
    changePageSize() {
      if (this.pageSizeInput <= 0 || this.pageSizeInput === '') return;
      this.$emit('changepagesize', Number(this.pageSizeInput));
      this.pageInput = this.currentPage;
    },
    changePage(page) {
      this.$emit('changepage', page);
    },
    gotoPage() {
      this.changePage(Number(this.pageInput));
    },
  },
};
</script>

<style></style>
