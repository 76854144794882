<template>
  <div class="space-y-px text-textMedium bg-brightGray p-px rounded">
    <div
      class="flex items-center space-x-2 bg-white py-4 px-px"
      :class="{ 'text-sm': small }"
    >
      <div class="grid flex-auto grid-cols-2 gap-2" v-if="searchable">
        <BaseInput
          placeholder="Search"
          class="w-full"
          bordered
          dense
          v-model="search"
        >
          <template #suffix>
            <BaseButton
              :label="'(' + itemsFiltered.length + ')'"
              class="px-1 rounded-r uppercase max-w-fit"
              icon="filter"
              @click="openFiltersSelector"
            /> </template
        ></BaseInput>
      </div>
      <div
        class="flex justify-end items-center space-x-2 w-full"
        :class="{ 'flex-auto': !searchable }"
      >
        <base-button
          v-if="exportable"
          label="export"
          :primary="false"
          class="px-2 uppercase rounded bg-brightGray hover:text-primary"
          @click="exportData"
        />
        <div class="flex flex-shrink-0"><slot name="toolset"></slot></div>
        <div
          v-if="hasDynamicActions && dynamicHandlers.length > 0 && selectedId"
          class="flex justify-end"
        >
          <actions
            :actions="dynamicHandlers"
            :item="selectedItem"
            :arrow="false"
          >
            <div
              class="
                flex
                items-center
                px-2
                py-1
                space-x-2
                text-sm
                rounded
                cursor-pointer
                bg-brighterGray
                hover:text-primary
                active:bg-brighterGray
                hover:bg-brightGray
              "
            >
              <div>actions</div>
              <BaseIcon name="chevron-down" :size="12" />
            </div>
          </actions>
        </div>
      </div>
    </div>
    <div
      class="w-full overflow-x-auto text-sm text-left px-px  text-gray-600 dark:text-gray-400"
    >
      <table class="table min-w-full">
        <thead
          class="font-sans text-sm text-gray-700 tracking-wider font-semibold uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400 border-b-2 border-gray-200"
          :class="{ 'text-sm': small }"
        >
          <tr>
            <th
              v-for="(header, i) in headers"
              :key="'header' + i"
              scope="col"
              class="pr-6 pl-2 py-3  "
              :class="{ small: 'py-0' }"
            >
              {{ header.label }}
            </th>
            <th v-if="hasActions && handlers.length > 0" class="p-2">
              Actions
            </th>
          </tr>
        </thead>
        <tbody v-if="isWorking" class="flex justify-center flex-grow">
          <bar-loader />
        </tbody>
        <tbody
          v-else-if="displayItems.length > 0"
          class=""
          :class="{ 'text-sm': small }"
        >
          <template v-for="(item, i) in displayItems">
            <tr
              :key="item._id"
              class="border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary hover:bg-opacity-75 hover:text-white dark:hover:bg-gray-600"
              :class="{
                'cursor-pointer': canSelect || hasDynamicActions,
                'bg-primary bg-opacity-100 text-white':
                  (canSelect || hasDynamicActions) && selectedId === item._id
              }"
              @click="toggleSelect(item._id)"
            >
              <slot :dtItem="item" :index="i">
                <template v-for="(header, j) in headers">
                  <th
                    v-if="j == 0"
                    :key="'jheader' + j"
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <span v-if="typeof getProp(item, header.key) == 'number'">
                      {{ getProp(item, headers[0].key) | formateNumber }}
                    </span>
                    
                    <span v-else-if="headers[0].format == 'date'">
                      {{ getProp(item, headers[0].key) | toDDMMYYYY }}
                    </span>
                    <span v-else>
                      {{ getProp(item, headers[0].key) }}
                    </span>
                  </th>
                  
                  <th
                    v-else-if="header.key == 'isPaid'"
                    :key="'jheader' + j"
                    scope="row"
                    class="px-6 py-0 font-medium whitespace-nowrap dark:text-white"
                  >                  

                    <div
                      class="w-full bg-opacity-25 inline-block px-2 py-1 text-sm capitalize tracking-wider font-semibold rounded"
                      :class="{
                        'bg-green-300 text-green-700': item.isPaid,
                        'bg-red-300 text-red-700': !item.isPaid
                      }"
                    >
                      {{ item.isPaid ? 'Paid' : 'Unpaid' }}
                    </div>
                  </th>
                  <td
                    v-else
                    :key="'jheader' + j"
                    class="p-2"
                    :class="{ 'text-xs py-0': small, 'text-15': !small }"
                  >
                    <span v-if="typeof getProp(item, header.key) == 'number'">
                      {{ getProp(item, header.key) | formateNumber }}
                    </span>
                    <span v-else-if="header.format == 'date'">
                      {{ getProp(item, header.key) | toDDMMYYYY }}
                    </span>
                    <span v-else>
                      {{ getProp(item, header.key) }}
                    </span>
                  </td>
                </template>
              </slot>
              <td v-if="hasActions && handlers.length > 0" class="p-2">
                <div class="flex items-center justify-center">
                  <actions :actions="handlers" :item="item" btnO9n="vertical" />
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td :colspan="headers.length" class="p-2 text-center">
              <slot name="no-data">No data</slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination
      v-if="paginate && displayItems.length > 0"
      :currentPage="currentPage"
      :pageSize="pageSize"
      :totalPages="totalPages"
      @changepage="handlePageChange"
      @changepagesize="handlePageSizeChange"
      class=""
    />
  </div>
</template>

<script>
import { getPropByString } from '@/utils';
import Pagination from '../pagination/Pagination.vue';
import eventBus from '../../eventBus';
import Actions from '../card/Actions.vue';
import BarLoader from '../collection/BarLoader.vue';
export default {
  props: {
    headers: Array,
    exportable: {
      type: Boolean,
      default: true
    },
    items: Array,
    hasActions: Boolean,
    hasDynamicActions: Boolean,
    handlers: Array,
    dynamicHandlers: Array,
    canSelect: Boolean,
    searchable: Boolean,
    isWorking: { type: Boolean, default: false },
    paginate: Boolean,
    rowsPerPage: Number,
    name: String,
    hideCount: Boolean,
    small: Boolean,
    stripped: { type: Boolean, default: true }
  },
  components: { Pagination, Actions, BarLoader },
  data() {
    return {
      selectedId: undefined,
      currentPage: 1,
      pageSize: 10,
      search: '',
      filters: [this.headers[0].key]
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.itemsFiltered.length / this.pageSize);
    },
    selected() {
      return this.headers.map(header => header.key);
    },
    itemsFiltered() {
      if (!this.search || this.filters.length === 0) return this.items;
      return this.items.filter(item =>
        this.filters.some(
          key =>
            String(this.getProp(item, key))
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) > -1
        )
      );
      // return this.items;
    },
    displayItems() {
      if (!this.paginate) return this.itemsFiltered;
      let startIndex = (this.currentPage - 1) * this.pageSize;
      let endIndex = startIndex + this.pageSize;
      if (endIndex > this.itemsFiltered.length)
        endIndex = this.itemsFiltered.length;
      return this.itemsFiltered.slice(startIndex, endIndex);
    },
    selectedItem() {
      return this.items.find(item => item._id === this.selectedId);
    }
  },
  watch: {
    search() {
      this.currentPage = 1;
    }
  },
  methods: {
    getProp: getPropByString,

    hideDialog() {
      this.$refs['datatable-action-menu'].forEach(tippyEL =>
        tippyEL.tip.hide()
      );
    },
    smartHandler(actionHandler, item) {
      this.hideDialog();
      if (actionHandler?.fullParam) actionHandler.handler(item);
      else if (actionHandler?.idParam) actionHandler.handler(item._id);
      else actionHandler.handler();
    },
    unselect() {
      this.selectedId = undefined;
      this.$emit('unselect');
    },
    select(id) {
      this.selectedId = id;
      this.$emit('select', id);
    },
    toggleSelect(id) {
      if (this.canSelect || this.hasDynamicActions) {
        if (this.selectedId === id) return this.unselect();
        this.select(id);
      }
    },
    handlePageChange(page) {
      if (page >= 1 && page <= this.totalPages) this.currentPage = page;
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.currentPage = 1;
    },
    openFiltersSelector() {
//      let componentProps = { filters: this.filters, headers: this.headers };
//      eventBus.$emit('open-modal', {
//        modalId: 31,
//        componentProps,
//        cb: this.updateFilters
//      });
    },
    updateFilters({ response: { filters } }) {
      this.filters = filters;
    },
    exportData() {
      let componentProps = {
        title: this.name,
        headers: this.headers,
        items: this.items
      };
      eventBus.$emit('open-modal', { modalId: 8, componentProps });
    }
  },
  created() {
    this.filters = this.selected;
    if (this.rowsPerPage && typeof this.rowsPerPage === 'number')
      this.pageSize = this.rowsPerPage;
  }
};
</script>

<style></style>
