<template>
  <modal-template
    saveLabel="CONTINUE"
    @cancel="handleClose"
    @save="handleContinue"
    :isWorking="working"
  >
    <template #title>Export {{ title }}</template>
    <template>
      <div class="space-y-2">
        <div class="grid grid-cols-2 gap-1">
          <div v-for="(header, i) in headers" :key="i">
            <div
              class="flex space-x-2 items-center border p-2 cursor-pointer hover:bg-brightGray text-textDark hover:text-primary"
              :class="
                selectedKeys.includes(header.key)
                  ? 'border-primary'
                  : 'border-transparent'
              "
              @click="toggleHeader(header.key)"
            >
              <div
                style="padding: 0.375rem"
                :class="
                  selectedKeys.includes(header.key)
                    ? 'bg-primary'
                    : 'bg-textLight'
                "
              >
                <base-icon
                  name="check"
                  fill="#fff"
                  :size="selectedKeys.includes(header.key) ? 14 : 12"
                />
              </div>

              <div class="uppercase">{{ header.label }}</div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-2">
          <div>{{ items.length }} rows</div>
          <div>{{ selectedKeys.length }} coloumns selected</div>
        </div>
        <div class="flex space-x-1 items-center border">
          <input
            type="text"
            v-model="fileName"
            class="flex-auto outline-none p-2 uppercase"
          />
          <div class="bg-brightGray p-2">.CSV</div>
        </div>
        <errors
          v-if="zeroColumn"
          :errors="['Select one or more columun please']"
        />
      </div>
    </template>
  </modal-template>
</template>

<script>
import Errors from '@/components/card/Errors.vue';
import ModalTemplate from '@/components//modals/ModalTemplate.vue';
import { getPropByString } from '@/utils';
export default {
  props: ['title', 'headers', 'items'],
  components: { ModalTemplate, Errors },
  data() {
    return {
      selectedKeys: [],
      fileName: '',
      working: false
    };
  },
  computed: {
    zeroColumn() {
      return this.selectedKeys.length === 0;
    }
  },
  methods: {
    getProp: getPropByString,
    async handleContinue() {
      if (this.selectedKeys.length === 0) return;

      this.working = true;

      let headersSelected = this.headers.filter(({ key }) =>
        this.selectedKeys.includes(key)
      );
      let data = this.items.map(item =>
        headersSelected.reduce(
          (acc, { key }) => [...acc, this.getProp(item, key)],
          []
        )
      );

      let unparsedResults = this.$papa.unparse(
        { fields: headersSelected.map(({ label }) => label), data },
        {
          delimiter: ','
        }
      );
      this.$papa.download(unparsedResults, this.fileName);
      this.working = false;
    },
    handleClose() {
      this.$emit('close');
    },
    toggleHeader(key) {
      if (this.selectedKeys.includes(key))
        return (this.selectedKeys = this.selectedKeys.filter(sk => sk !== key));
      this.selectedKeys.push(key);
    },
    
  },
  created() {
    this.selectedKeys = this.headers.map(({ key }) => key);
    this.fileName = this.title;
  }
};
</script>

<style></style>
