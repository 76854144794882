<template>
  <div class="space-y-2">
    <div class="bg-brightGray flex group">
      <div
        class="flex items-center pl-2 pr-3 text-textMedium hover:text-primary hover:bg-brighterGray active:bg-brightGray cursor-pointer"
        @click="scrollLeft"
      >
        <BaseIcon name="chevron-left" :size="12" />
      </div>

      <div
        class="hide-scroll-bar w-full overflow-x-auto flex space-x-2"
        ref="tab-header"
      >
        <div
          v-for="(item, i) in items"
          :key="i"
          class="bg-brightGray flex-grow flex-shrink-0 text-center py-1 px-2 cursor-pointer"
          :class="{ 'active-tab': item.value === value }"
          @click="handleChangeItem(item.value)"
        >
          {{ item.label }}
        </div>
      </div>

      <div
        class="flex items-center pl-3 pr-2 text-textMedium hover:text-primary hover:bg-brighterGray active:bg-brightGray cursor-pointer"
        @click="scrollRight"
      >
        <BaseIcon name="chevron-right" :size="12" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['items', 'value'],

  computed: {},

  methods: {
    handleChangeItem(new_item) {
      this.$emit('input', new_item);
    },
    scrollLeft() {
      this.$refs['tab-header'].scrollLeft -= 30;
    },
    scrollRight() {
      this.$refs['tab-header'].scrollLeft += 30;
    }
  }
};
</script>

<style scoped>
.active-tab {
  @apply bg-primary;
  color: white;
}
</style>
