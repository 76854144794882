<template>
  <div class="text-textMedium space-y-4">
    <div
      class="
        text-2xl
        uppercase
        py-2
        shadow-md
        text-textDark
        font-semibold
        bg-brighterGray
        text-center
      "
    >
      SEARCH STUDENT
    </div>
    <form class="space-y-4 p-4 pb-4 items-center content-center">
      <base-input
        type="text"
        placeholder="STUDENT ID"
        label="ENTER STUDENT ID"
        bordered
        required
        v-model="search"
        @enter="searchCustomer"
      />
      <div class="flex justify-center w-full">
        <img
          v-if="customerNotFound"
          src="@/assets/page_not_found.svg"
          class="w-1/2"
        />
        <img v-else src="@/assets/file_search.svg" class="w-1/2" />
      </div>

      <div class="flex justify-between mt-3">
        <base-button
          label="cancel"
          class="
            py-1
            px-4
            text-textMedium
            bg-brightGray
            hover:bg-danger
            hover:text-error
            rounded
          "
          @click="close"
        />
        <base-button
          label="Search"
          icon="search"
          :isWorking="isWorking"
          class="py-1 px-4 rounded"
          @click="searchCustomer"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      isWorking: false,
      search: '',
      customerNotFound: false,
    };
  },
  watch: {
    search() {
      if (!this.search && this.customerNotFound) this.customerNotFound = false;
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    async searchCustomer() {
      this.isWorking = true;
      const response = await this.request({
        method: 'get',
        url: 'billing/customer/cust-id/' + this.search,
      });
      if (response) {
        this.isWorking = false;
        this.$emit('close', {
          invokeCallBack: true,
        });
        const cust_id = response.item.studentSchoolId;
        this.$router.push({name: 'customer-detail', query: {id: cust_id}})
        // this.$router.push('customer/' + cust_id);
        // this.close();
      } else {
        this.customerNotFound = true;
      }
      this.isWorking = false;
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style>
</style>