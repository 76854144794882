// import termService from "../../services/userService";
import { userService } from '../../services/userService';
import { userInfoService } from '../../services/storageService';

const state = {
  user: null,
  error: ''
};

const mutations = {
  setUser: (state, user) => {
    state.user = user;
  },
  SMART_SET: (state, { stateKey, data }) => {
    state[stateKey] = data;
  },
  setError: (state, { message }) => {
    state.error = message;
  }
};
const actions = {
  smartSet({ commit }, { stateKey, data }) {
    commit('SMART_SET', {
      stateKey,
      data
    });
  },
  async login({ commit }, login) {
    try {
      const { user } = await userService.login(login);
      commit('setUser', user);
      userInfoService.saveUser(user);
      return user;
    } catch (e) {
      commit('setError', { code: e.errorCode, message: e.message });
      return false;
    }
  },
  async logout({ dispatch }) {
    try {
      await userService.logout();
      return dispatch('clear');
    } catch (e) {
      return dispatch('clear');
    }
  },
  clear({ commit }) {
    commit('setUser', null);
    localStorage.removeItem('sessionTimestamp')
    userInfoService.removeUser();
    return true;
  }
};

const getters = {
  user: state => {
    if (!state.user) {
      return userInfoService.getUser();
    }
    return state.user;
  },
  berror: state => state.error
};

const usersStore = {
  namespaced: false,
  state,
  getters,
  mutations,
  actions
};

export default usersStore;
