<template>
  <div class="text-textMedium space-y-2">
    <div class="text-2xl uppercase text-center bg-brightGray py-2 shadow-md">
      ADD STUDENT BILL
    </div>
    <form class="disabled space-y-4 p-4 pb-4">
      <div class="grid md:grid-cols-2 gap-2 row-gap-4">
        <base-input
          placeholder="STUDENT SCHOOL ID"
          label="STUDENT SCHOOL ID"
          bordered
          required
          v-model="Form.cust_util_id"
        />
        <template v-for="(field, i) in customFields">
          <base-input
            :key="i"
            :type="field.type"
            v-model="Form.other[field.key]"
            :label="field.name"
            :placeholder="field.name"
            bordered
          />
        </template>
        <base-input
          label="TOTAL AMOUNT"
          type="number"
          placeholder="TOTAL AMOUNT"
          bordered
          required
          v-model="Form.total_amount"
        />
      </div>
      <div class="flex justify-between mt-3">
        <base-button
          label="cancel"
          class="
            py-1
            px-4
            text-textMedium
            bg-brightGray
            hover:bg-danger
            hover:text-error
            rounded
          "
          @click="close"
        />
        <base-button
          :isWorking="working"
          label="Add Bill"
          icon="check"
          class="py-1 px-4 rounded"
          @click="addCustomerBill"
        />
      </div>
    </form>
  </div>
</template>

<script>
import eventBus from '@/eventBus';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: ['payment'],
  data() {
    return {
      working: false,
      isLoading: false,
      customFields: [],
      Form: {
        cust_util_id: undefined,
        total_amount: 0,
        other: {},
      },
    };
  },
  computed: {
    ...mapGetters('main', ['berror'])
  },
  methods: {
    ...mapActions('main', ['request']),
    async addCustomerBill() {
      this.working = true;
      let response = await this.request({
        method: 'post',
        url: 'billing/utilcustomers/',
        data: {
          util_payment_id: this.payment._id,
          ...this.Form,
        },
      });
      if (response) {
        this.$emit('close', {
          invokeCallBack: true,
          response: { payment: response.item },
        });
        eventBus.$emit('open-toast', {
          message: 'Student Bill added successfully.',
        });
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror,
        });
        
      }
      this.working = false;
    },
    async getUtilOrgBillCustomFields() {
      this.isLoading = true;
      this.customFields = [];
      let response = await this.request({
        method: 'get',
        url: 'billing/customfields/bill',
      });
      if (response) {
        this.customFields = response.items[0].bill_fields;
      }
      this.isLoading = false;
    },

    close() {
      this.$emit('close');
    },
  },
  created() {
    this.getUtilOrgBillCustomFields();
  },
};
</script>

<style></style>
