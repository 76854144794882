<template>
  <div
    class="p-6 mx-auto bg-white dark:bg-neutral-800 dark:text-zinc-400 rounded-xl shadow-md  md:flex md:items-start space-x-4">
    <div class="flex space-x-4">
      <div class="flex-shrink-0 space-y-2">
        <div class="flex-shrink-0 opacity-25">
          <img src="@/assets/logo.png" class="w-10 h-10 rounded" alt="" />
        </div>

      </div>
      <div class="text-left">
        <div class="text-xl font-medium text-black dark:text-white capitalize">
          {{ bill.studentName }}
        </div>
        <div class="flex space-x-4">
          <div class="flex space-x-2 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" fill="#787a7d" viewBox="0 0 512 512">
              <path
                d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
            </svg>

            <p class="text-gray-600 dark:text-neutral-200">{{ bill.mobile }}</p>
          </div>
          <p class="text-gray-500 dark:text-neutral-300">
            {{ bill.studentAccountNumber }}
          </p>
        </div>
        <div class="flex space-x-4">
        <div class="flex space-x-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            fill="#787a7d"
            viewBox="0 0 576 512"
          >
            <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path
              d="M0 96l576 0c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96zm0 32V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128H0zM64 405.3c0-29.5 23.9-53.3 53.3-53.3H234.7c29.5 0 53.3 23.9 53.3 53.3c0 5.9-4.8 10.7-10.7 10.7H74.7c-5.9 0-10.7-4.8-10.7-10.7zM176 192a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm176 16c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16z"
            />
          </svg>

          <p class="text-gray-600 dark:text-neutral-200">
            {{ bill.studentMasterId }}
          </p>
        </div>
        <p class="text-gray-500 dark:text-neutral-300">
          {{ bill.studentSchoolId }}
        </p>
      </div>
        <div class="flex space-x-4">
          <p class="text-gray-600 dark:text-neutral-200">
            {{ bill.schoolName }}
          </p>
          <p class="text-gray-500 dark:text-neutral-300">
            {{ bill.campusName }}
          </p>
        </div>


      </div>
    </div>
    <div class="space-y-2">
      <div class="flex flex-col -space-y-1 items-end">
        <!-- <div class="flex space-x-4 rounded px-2 text-sm"
          :class="{ 'bg-green-100 text-green-600': bill.isPaid, 'bg-orange-100 text-[#fc6c0c]': !bill.isPaid }">
          <p class="">
            {{ bill.isPaid ? 'Paid' : 'Unpaid' }}
          </p>
        </div> -->
        <div class="flex justify-between space-x-4 flex-shrink-0 p-2 border-b-2 border-gray-300">
          <div class="xl:flex bg-gray-50 flex-row-reverse space-x-3 items-center rounded p-2">
            <p class="text-gray-700 dark:text-neutral-300 text-xl text-center uppercase">
              {{ bill.billCode }}
            </p>
            <p class="text-gray-300 dark:text-neutral-200 px-4">Bill Code</p>
          </div>
          <div class="xl:flex bg-gray-50 flex-row-reverse space-x-3 items-center rounded p-2">
            <p class="text-gray-700 dark:text-neutral-300 text-xl text-center uppercase">
              {{ bill.paymentTerm }}
            </p>
            <p class="text-gray-300 dark:text-neutral-200 px-4">Term</p>
          </div>
          <div class="xl:flex flex-row-reverse bg-gray-50 space-x-3 items-center rounded p-2">
            <p class="text-gray-700 dark:text-neutral-300 text-xl text-center uppercase font-semibold">
              {{ bill.amount }}
            </p>
            <p class="text-gray-300 dark:text-neutral-200 px-4 flex flex-shrink-0">
              Total
            </p>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-3 gap-4 px-6 py-2">
        <div class="flex space-x-3 items-center hover:bg-gray-100 rounded px-2 py-1" title="Regular Payment">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" fill="#787a7d" viewBox="0 0 512 512">
              <path
                d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
            </svg>
          </div>
          <p>{{ bill.paymentAmount }}</p>
        </div>
        <div class="flex space-x-3 items-center hover:bg-gray-100 rounded px-2 py-1" title="Regular Payment">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" fill="#787a7d" viewBox="0 0 640 512">
              <path
                d="M64 104v88h96V96H72c-4.4 0-8 3.6-8 8zm482 88L465.1 96H384v96H546zm-226 0V96H224v96h96zM592 384H576c0 53-43 96-96 96s-96-43-96-96H256c0 53-43 96-96 96s-96-43-96-96H48c-26.5 0-48-21.5-48-48V104C0 64.2 32.2 32 72 32H192 352 465.1c18.9 0 36.8 8.3 49 22.8L625 186.5c9.7 11.5 15 26.1 15 41.2V336c0 26.5-21.5 48-48 48zm-64 0a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM160 432a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
            </svg>
          </div>
          <p>{{ bill.transportationFee }}</p>
        </div>
        <div class="flex space-x-3 items-center hover:bg-gray-100 rounded px-2 py-1" title="Regular Payment">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" fill="#787a7d" viewBox="0 0 448 512">
              <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
            </svg>
          </div>
          <p>{{ bill.materialsFee }}</p>
        </div>
        <div class="flex space-x-3 items-center hover:bg-gray-100 rounded px-2 py-1" title="Registration Fee">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" fill="#787a7d" viewBox="0 0 512 512">
              <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM160 152V264v96c0 13.3 10.7 24 24 24s24-10.7 24-24V288h60.9l37.2 81.9c5.5 12.1 19.7 17.4 31.8 11.9s17.4-19.7 11.9-31.8L315.7 275c21.8-14.3 36.3-39 36.3-67c0-44.2-35.8-80-80-80H184c-13.3 0-24 10.7-24 24zm48 88V176h64c17.7 0 32 14.3 32 32s-14.3 32-32 32H208z" />
            </svg>
          </div>
          <p>{{ bill.registrationFee }}</p>
        </div>
        <div class="flex space-x-3 items-center hover:bg-gray-100 rounded px-2 py-1" title="Tutors Fee">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" fill="#787a7d" viewBox="0 0 512 512">
              <path
                d="M512 416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H192c20.1 0 39.1 9.5 51.2 25.6l19.2 25.6c6 8.1 15.5 12.8 25.6 12.8H448c35.3 0 64 28.7 64 64V416zM232 376c0 13.3 10.7 24 24 24s24-10.7 24-24V312h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H280V200c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H168c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z" />
            </svg>
          </div>
          <p>{{ bill.tutorFee }}</p>
        </div>
        <div class="flex space-x-3 items-center hover:bg-gray-100 rounded px-2 py-1" title="Others Fee">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" fill="#787a7d" viewBox="0 0 448 512">
              <path
                d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
            </svg>
          </div>
          <p>{{ bill.othersFee }}</p>
        </div>
      </div>
    </div>
    <div class="flex-grow flex justify-end space-x-4 rounded px-2 text-sm"
          >
          <p class="text-center px-2 w-16"
          :class="{ 'bg-green-100 text-green-600': bill.isPaid, 'bg-orange-100 text-[#fc6c0c]': !bill.isPaid }">
            {{ bill.isPaid ? 'Paid' : 'Unpaid' }}
          </p>
          <base-button v-if="!bill.isPaid && isGrantedToPayBill" label="Pay" @click="payCustomerBill" class="px-4 py-2 rounded text-lg" />
        </div>

    <!-- <pre>{{bill}}</pre> -->
  </div>
</template>

<script>
import eventBus from '../../../eventBus';

export default {
  props: ["bill"],
  computed: {
    isGrantedToPayBill() {
      return this.isGranted('pay-bill');
    },
  },
  methods: {
    payCustomerBill() {
      let componentProps = {
        payment: this.bill
      };
      eventBus.$emit('open-modal', {
        modalId: 205,
        componentProps,
        cb: () => this.$emit('refresh')
      });
    },
  }
};
</script>

<style></style>
