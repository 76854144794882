<template>
  <div class="bg-white shadow-lg flex items-center justify-around my-10">
    <div
      class="
        flex
        items-center
        space-x-3
        py-2
        w-full
        justify-center
        cursor-pointer
      "
      v-for="(step, i) in steps"
      :key="i"
      :class="{
        ' h-full border-r-2': i !== steps.length - 1,
        'border-teal': i < activeStep,
        'border-info': i === activeStep,
        ' border-lightDanger': errorSteps && errorSteps.includes(step),
        'border-brighterGray': i > activeStep,
        'bg-blueGray': i > activeStep
      }"
      @click="handleChangeStep(i)"
    >
      <!-- <div
        v-if="i !== 0"
        class="h-1 w-24 border-b-2 mr-3"
        :class="
          i <= activeStep
            ? 'border-solid border-teal'
            : 'border-dashed border-textMedium'
        "
      ></div> -->
      <div
        class="flex items-center justify-center w-7 h-7 rounded-full"
        :class="
          i > activeStep
            ? 'bg-blueGray border border-textMedium rounded-full'
            : errorSteps && errorSteps.includes(step)
            ? 'bg-lightDanger text-white'
            : activeStep === i
            ? 'bg-info text-white'
            : i < activeStep
            ? 'bg-teal text-white'
            : 'bg-white text-textMedium'
        "
      >
        {{ i + 1 }}
      </div>

      <div
        class="font-semibold"
        :class="
          errorSteps && errorSteps.includes(step)
            ? 'text-lightDanger'
            : activeStep === i
            ? 'text-info'
            : i < activeStep
            ? 'text-teal'
            : 'text-textMedium'
        "
      >
        {{ step }}
      </div>
      <div v-if="errorSteps && errorSteps.includes(step)">
        <div class="text-lightDanger" v-if="i < activeStep">
          <BaseIcon name="times" :size="20" />
        </div>
      </div>

      <div v-else>
        <div class="text-teal" v-if="i < activeStep">
          <BaseIcon name="check-circle" :size="20" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['activeStep', 'steps', 'errorSteps'],
  methods: {
    handleChangeStep(step) {
      this.$emit('changeStep', step);
    }
  }
};
</script>

<style></style>
