<template>
  <div
    class="flex space-x-2 items-center cursor-pointer hover:opacity-75"
    @click="toggle"
    :class="{
      'text-info': color,
      'text-primary': !color && selected,
      'text-textDark': !color && !selected,
    }"
  >
    <BaseIcon :name="selected ? 'check-square' : 'square'" :size="size" />

    <div class="flex-auto" :class="selected ? 'text-primary' : 'text-textDark'">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: '' },
    size: { type: Number, default: 26 },
    selected: Boolean,
    color: {
      type: String,
    },
    value: {},
  },
  methods: {
    toggle() {
      this.$emit('toggle', this.value);
    },
  },
};
</script>

<style></style>
