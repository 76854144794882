<template>
  <div class="flex space-x-4">
    <div class="w-3/12 space-y-3">
      <div class="py-2 text-xl text-center text-white bg-primary">
        Search Credentials
      </div>
      <div class="w-full mb-5 space-y-4 search-field">
        <base-input
          class="w-full"
          outlined
          placeholder="Search Credential By Username Globally"
          v-model="search"
          @enter="fetchCredentials"
        ></base-input>

        <base-select
          v-if="['director', 'admin', 'it director'].includes(user.role)"
          :bordered="true"
          :items="officesOptions"
          v-model="filterOffice"
          :searchable="true"
        />
      </div>
      <base-switch
        style="min-width: fit-content; display: none"
        class=""
        :items="searchByOptions"
        v-model="searchBy"
      />
    </div>
    <div class="w-9/12">
      <div v-if="user.role == 'branch manager' || user.role == 'csm'">
        <tabs>
          <tab title="All Credentials">
            <data-table
              :headers="headers"
              :items="credentials"
              name="credentials"
              :canSelect="true"
              @select="select"
              @unselect="unselect"
              :hasDynamicActions="true"
              :dynamicHandlers="actionHandlers"
              :searchable="true"
              :paginate="true"
              :isWorking="isLoading"
              :rowsPerPage="6"
              #default="{ dtItem }"
            >
              <td
                v-for="(header, i) in headers"
                :key="i"
                class="p-2 max-w-sm break-words"
              >
                <div
                  v-if="header.key === 'studentSchoolId.fullName'"
                  class="flex space-x-2"
                >
                  <avatar
                    :name="dtItem.studentSchoolId.fullName | get_2Letters"
                    :_2Letters="dtItem.studentSchoolId.fullName | get_2Letters"
                    :size="40"
                  />
                  <div>
                    <div>
                      {{ dtItem.studentSchoolId.fullName }}
                    </div>
                    <div class="text-xs">
                      {{ dtItem.studentSchoolId.gender }}
                    </div>
                  </div>
                </div>
                <div v-else-if="header.key === 'role'" class="flex uppercase">
                  <div>
                    {{ dtItem.role }}
                  </div>
                </div>

                <div v-else-if="header.key === 'status'" class="flex">
                  <div
                    class="inline-block px-3 text-sm rounded-full"
                    :class="
                      dtItem.status.toLowerCase() === 'verified'
                        ? 'bg-teal text-white'
                        : 'bg-danger text-error'
                    "
                  >
                    {{ dtItem.status }}
                  </div>
                </div>
                <div v-else-if="header.key === 'studentSchoolId.tel'">
                  {{ getProp(dtItem, header.key) | beautifyPhone }}
                </div>
                <div v-else>
                  {{ getProp(dtItem, header.key) }}
                </div>
              </td>
            </data-table>
          </tab>

          <tab title="LoggedIn Users">
            <online-users />
          </tab>
        </tabs>
      </div>
      <div v-else>
        <div class="pb-8">
          <filter-item
            :items="filterItems"
            class=""
            v-model="filterCondition"
          />
        </div>
        <data-table
          :headers="headers"
          :items="credentials"
          name="credentials"
          :canSelect="true"
          @select="select"
          @unselect="unselect"
          :hasDynamicActions="true"
          :dynamicHandlers="actionHandlers"
          :searchable="true"
          :paginate="false"
          :isWorking="isLoading"
          :rowsPerPage="6"
          #default="{ dtItem }"
        >
          <td
            v-for="(header, i) in headers"
            :key="i"
            class="p-2 max-w-sm break-words"
          >
            <div
              v-if="header.key === 'studentSchoolId.fullName'"
              class="flex space-x-2"
            >
              <avatar
                :name="dtItem.studentSchoolId.fullName | get_2Letters"
                :_2Letters="dtItem.studentSchoolId.fullName | get_2Letters"
                :size="40"
              />
              <div>
                <div>
                  {{ dtItem.studentSchoolId.fullName }}
                </div>
                <div class="text-xs">
                  {{ dtItem.studentSchoolId.gender }}
                </div>
              </div>
            </div>
            <div v-else-if="header.key === 'role'" class="flex uppercase">
              <div>
                {{ dtItem.role }}
              </div>
            </div>

            <div v-else-if="header.key === 'status'" class="flex">
              <div
                class="inline-block px-3 text-sm rounded-full"
                :class="
                  dtItem.status.toLowerCase() === 'verified'
                    ? 'bg-teal text-white'
                    : 'bg-danger text-error'
                "
              >
                {{ dtItem.status }}
              </div>
            </div>
            <div v-else-if="header.key === 'studentSchoolId.tel'">
              {{ getProp(dtItem, header.key) | beautifyPhone }}
            </div>
            <div v-else>
              {{ getProp(dtItem, header.key) }}
            </div>
          </td>
        </data-table>
        <div>
          <pagination
            :currentPage="currentPage"
            :pageSize="pageSize"
            :totalPages="totalPages"
            @changepage="handlePageChange"
            @changepagesize="handlePageSizeChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterItem from '@/components/filter/Filter.vue';
import { mapActions, mapGetters } from 'vuex';
import { getPropByString, formatePhoneNumber } from '@/utils';
import DataTable from '@/components/table/DataTable.vue';
import { _2Letters } from '@/mixins/_2Letters.js';
import Avatar from '@/components/base/BaseAvatar.vue';
import eventBus from '@/eventBus';
import BaseSwitch from '@/components/base/BaseSwitch.vue';
import Tab from '@/components/tab/Tab.vue';
import Tabs from '@/components/tab/Tabs.vue';
import OnlineUsers from '@/components/card/logs/OnlineUsers.vue';
import Pagination from '@/components/pagination/Pagination.vue';

export default {
  components: {
    Pagination,
    DataTable,
    Avatar,
    BaseSwitch,
    Tabs,
    Tab,
    OnlineUsers,
    FilterItem,
  },
  mixins: [_2Letters],
  data() {
    return {
      selectedId: '',
      isLoading: false,
      currentPage: 1,
      pageSize: 10,
      totalPages: 0,
      filterOffice: '',
      search: '',
      searchByOptions: [
        { label: 'Tel', value: 'isTel' },
        { label: 'Index', value: 'isIndex' },
        { label: 'Name', value: 'isName' },
        { label: 'Student ID', value: 'isCustID' },
      ],
      filterCondition: 'Verified',
      filterItems: [
        { label: 'Verified', value: 'Verified' },
        { label: 'Pending', value: 'Pending' },
        { label: 'Locked', value: 'Locked' },
        { label: 'Request Reset', value: 'Request Reset' },
        { label: 'Suspended', value: 'Suspended' },
        { label: 'All', value: '' },
      ],
      searchBy: '',
      headers: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'username',
          label: 'Username',
        },
        {
          key: 'role.role_name',
          label: 'Role',
        },
        {
          key: 'office.name',
          label: 'Branch',
        },
        // {
        //   key: 'dashboard',
        //   label: 'Dashboard',
        // },
        {
          key: 'status',
          label: 'Status',
        },
      ],
      credentials: [],
      offices: [],
      inactiveCredentials: [],
    };
  },
  watch: {
    clientConnected: {
      immediate: true,
      handler() {
        if (this.clientConnected) {
          this.sioClient.on('Password_Reset_Requested', () => {
            this.fetchCredentials();
          });
          this.sioClient.on('Password_Reset_Requested', () => {
            this.fetchCredentials();
          });
          this.sioClient.on('Credential_Created', () => {
            this.fetchCredentials();
          });
          this.sioClient.on('Credential_Updated', () => {
            this.fetchCredentials();
          });
        }
      },
    },

    filterCondition() {
      this.fetchCredentials();
      this.currentPage = 1;
    },

    currentPage() {
      this.fetchCredentials();
    },
    pageSize() {
      this.fetchCredentials();
    },
    filterOffice() {
      this.fetchCredentials();
      this.currentPage = 1;
    },
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('socket', ['sioClient', 'clientConnected']),
    selectedCredential() {
      if (!this.selectedId) return '';
      else return this.credentials.find(({ _id }) => _id == this.selectedId);
    },
    officesOptions() {
      return this.offices.map((item) => ({
        label: item.name,
        value: item._id,
      }));
    },
    role() {
      return this.user.role;
    },
    myId() {
      return this.user.id;
    },
    actionHandlers() {
      // if (!this.selectedCredential || this.selectedCredential._id == this.myId)
        // return [];
      let handlers = [
        {
          icon: 'credential',
          label: 'Change Role',
          handler: this.changeRole,
          fullParam: true,
          code: '0044crc',
        },
        {
          icon: 'logout',
          label: 'Logout',
          handler: this.logoutUser,
          fullParam: true,
          code: '0004lc',
        },
        {
          icon: 'user-lock',
          label: 'Unlock',
          handler: this.unlockUser,
          fullParam: true,
          code: '0044ulc',
        },
        {
          icon: 'pencil-alt',
          label: 'Request Reset',
          handler: this.requestPasswordReset,
          fullParam: true,
          code: '0004rrc',
        },
        {
          icon: 'exchange',
          label: 'Change Office',
          handler: this.changeOffice,
          fullParam: true,
          code: '0044coc',
        },
        {
          icon: 'shield',
          label: 'Reset Password',
          handler: this.resetPassword,
          fullParam: true,
          code: '0044rsc',
        },
        // {
        //   icon: 'chart-line',
        //   label: 'Change Dashboard',
        //   handler: this.changeDashboard,
        //   fullParam: true,
        //   code: '0044sc',
        // },
        {
          icon: 'pencil-alt',
          label: 'Change Status',
          handler: this.changeStatus,
          fullParam: true,
          code: '0044sc',
        },
        // {
        //   icon: 'circle',
        //   label: 'Credential Detail',
        //   handler: this.operatorDetails,
        //   fullParam: true,
        //   code: '0444c',
        // },
        {
          icon: 'trash-alt',
          label: 'Delete User',
          handler: this.deleteUser,
          fullParam: true,
          code: '0044dc',
        },
        {
          label: 'Authorized Tasks',
          icon: 'eye',
          handler: this.openCredentialAuthorizedTasks,
          idParam: true,
          code: '0044ac',
        },
      ];
      return handlers;
    },
  },
  filters: {
    beautifyPhone: formatePhoneNumber,
    autoComplete(gender) {
      return gender === 'M' ? 'Male' : gender === 'F' ? 'Female' : 'Other';
    },
  },
  created() {
    if(!this.user.office && this.isGranted('0444allc')) {
      this.headers.splice(3, 0, {key: 'util_id.util_name', label: 'School'})
    }
    this.fetchOffices();
    this.fetchCredentials();
    this.fetchInactiveCredentials();
  },
  methods: {
    ...mapActions('main', ['request']),
    select(id) {
      this.selectedId = id;
    },
    unselect() {
      this.selectedId = '';
    },
    stringifyQuery(query) {
      let qString = '';
      Object.keys(query).forEach((key) => {
        if (query[key]) {
          qString +=
            qString === ''
              ? '?' + `${key}=${query[key]}`
              : '&' + `${key}=${query[key]}`;
        }
        
      });
      if (this.filterCondition) {
        qString +=
          qString === ''
            ? '?' + `status=${this.filterCondition}`
            : '&' + `status=${this.filterCondition}`;
      }

      return qString;
    },

    getProp: getPropByString,
    async fetchCredentials() {
      this.isLoading = true;
      this.credentials = [];
      let url;
      if (!this.user.office && this.isGranted('0444allc')) url = 'billing/credential';
      else if (this.user.isMain) url = 'billing/credential/orgusers';
      else url = 'billing/credential/officeusers';
      // else if (this.isGranted('0444orgc')) url = 'billing/credential/orgusers';
      // else if (this.isGranted('0444offc'))
      //   url = 'billing/credential/officeusers';
      if (!url) {
        return;
      }

      let response = await this.request({
        method: 'get',
        url:
          url +
          this.stringifyQuery({
            ...this.$route.query,
            ...{ username: this.search },
          }),
      });
      if (response) {
        this.credentials = response.items;
        this.totalPages = response.total_pages;
      } else {
        this.credentials = [];
      }
      this.isLoading = false;
    },

    async fetchOffices() {
      let response = await this.request({
        method: 'get',
        url: 'billing/office/verifiedbranches',
      });
      if (response && !response.error) {
        this.offices = response.items;
      }
    },

    async fetchInactiveCredentials() {
      let url;
      if (!this.user.office && this.isGranted('0444allc')) url = 'billing/credential';
      else if (this.user.isMain) url = 'billing/credential/orgusers';
      else url = 'billing/credential/officeusers';
      // else if (this.isGranted('0444orgc')) url = 'billing/credential/orgusers';
      // else if (this.isGranted('0444offc'))
      //   url = 'billing/credential/officeusers';
      if (!url) return;
      let response = await this.request({
        method: 'get',
        url:
          url +
          this.stringifyQuery({
            ...this.$route.query,
            ...{ username: this.search },
          }),
      });
      if (response) {
        this.inactiveCredentials = response.items
          .filter((credential) => credential._id !== this.myId)
          .map((office) => {
            let updated = office;
            updated.role = office.roles.find((role) => role?.active)?.role;
            updated.branch = office.name;
            return updated;
          });
      } else {
        this.inactiveCredentials = [];
      }
    },
    operatorDetails(item) {
      this.$router.push('/credentials/' + item._id);
      window.reload();
    },
    changeOffice(credential) {
      eventBus.$emit('open-modal', {
        modalId: 12,
        componentProps: { credential },
        cb: this.fetchCredentials,
      });
    },
    changeRole(credential) {
      eventBus.$emit('open-modal', {
        modalId: 13,
        componentProps: { credential },
        cb: this.fetchCredentials,
      });
    },
    requestPasswordReset(credential) {
      eventBus.$emit('open-modal', {
        modalId: 15,
        componentProps: { credential },
        cb: this.fetchCredentials,
      });
    },
    logoutUser(credential) {
      eventBus.$emit('open-modal', {
        modalId: 17,
        componentProps: { credential },
        cb: this.fetchCredentials,
      });
    },
    deleteUser(credential) {
      eventBus.$emit('open-modal', {
        modalId: 14,
        componentProps: { credential },
        cb: this.fetchCredentials,
      });
    },
    unlockUser(credential) {
      eventBus.$emit('open-modal', {
        modalId: 18,
        componentProps: { credential },
        cb: this.fetchCredentials,
      });
    },
    resetPassword(credential) {
      eventBus.$emit('open-modal', {
        modalId: 16,
        componentProps: { credential },
        cb: this.fetchCredentials,
      });
    },
    changeDashboard(credential) {
      eventBus.$emit('open-modal', {
        modalId: 28,
        componentProps: { credential },
        cb: this.fetchCredentials,
      });
    },
    changeStatus(credential) {
      eventBus.$emit('open-modal', {
        modalId: 19,
        componentProps: { credential, role: this.role },
        cb: this.fetchCredentials,
      });
    },
    openCredentialAuthorizedTasks(id) {
      this.$router.push('/credential-activities/' + id);
    },

    handlePageChange(page) {
      this.currentPage = page;
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
    },

    getInactiveCredentials() {
      window.alert('hello there');
    },
  },
};
</script>

<style></style>
