<template>
  <div class="space-y-3 p-2">
    <smart-report :headers="headers" :title="title" :url="url"> </smart-report>
  </div>
</template>

<script>
import { currentDate } from '@/mixins/currentDate';
import billCustomFields from '@/mixins/billCustomFields.js';
import SmartReport from './SmartReport.vue';
export default {
  components: { SmartReport },
  mixins: [currentDate, billCustomFields],
  data() {
    return {
      url: 'billing/utilcustomers',
    };
  },
  computed: {
    headers() {
      return [
        { key: 'cust_util_id', label: 'ID' },
        { key: 'customer.fullName', label: 'Full Name' },
        ...this.customFields.map(({key, name})=> ({
          key: 'other.'+key,
          label: name
        })),
        { key: 'total_amount', label: 'Total Amount' },
        { key: 'status', label: 'Status' },
      ];
    },
    title() {
      return `ALL STUDENT PAYMENT BILLS AS OF ${this.currentDate}`;
    },
  },
};
</script>

<style>
</style>