var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full space-y-1 text-textMedium"},[(_vm.label)?_c('label',{staticClass:"text-textMedium",staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',[_c('textarea',_vm._b({staticClass:"w-full custom-text-input p-2 px-3 pr-6 rounded tracking-widest text-justify text-textMedium",class:{
        'bg-brightGray': _vm.success && !_vm.bordered,
        'bg-danger': !_vm.success && !_vm.bordered,
        'border-textLight': _vm.success && _vm.bordered,
        'border-error': !_vm.success && _vm.bordered,
        'border rounded': _vm.bordered
      },style:('font-size: ' + _vm.size + 'px;'),attrs:{"rows":_vm.rows},on:{"input":_vm.handleInput}},'textarea',_vm.$attrs,false))]),(!_vm.success)?_c('div',{staticClass:"text-xs",staticStyle:{"color":"red"}},_vm._l((_vm.errors),function(error,i){return _c('div',{key:i},[_vm._v(_vm._s(error))])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }