<template>
  <div class="text-textMedium space-y-4">
    <div
      class="text-2xl uppercase py-2 shadow-md text-textDark font-semibold bg-brighterGray text-center"
    >
      SCHOOL BRANCH REGISTRATION
    </div>
    <form class="disabled grid grid-cols-2 gap-3 p-4 pb-4">
      <base-select
        label="School"
        :items="organizations"
        v-model="util_id"
        bordered
        :searchable="true"
      >
        <template #default="{ item }">
          <div class="flex space-x-2 items-center">
            <base-icon name="user" />
            <div class="capitalize">{{ item.label }}</div>
          </div>
        </template>
        <template #option="{ item }">
          <div class="flex space-x-2 items-center">
            <base-icon name="user" />
            <div>{{ item.label }}</div>
          </div>
        </template>
      </base-select>
      <base-input
        type="text"
        placeholder="Branch Name"
        label="Branch Name"
        bordered
        :readOnly="action == 'view'"
        required
        :min="0"
        v-model="Form.name"
        @enter="handleSave"
        class="col-span-2 text-textDark text-lg"
      />
      <base-input
        placeholder="Branch Code"
        type="text"
        :readOnly="action == 'view'"
        label="Branch Code"
        bordered
        required
        :min="0"
        v-model="Form.code"
        @enter="handleSave"
      />
      <base-input
        placeholder="Branch Account Number"
        type="text"
        :readOnly="action == 'view'"
        label="Branch Account Number"
        bordered
        required
        :min="0"
        v-model="Form.accountNumber"
        @enter="handleSave"
      />
      <base-input
        label="Phone Number"
        type="text"
        :readOnly="action == 'view'"
        placeholder="+2519---------"
        bordered
        required
        :min="0"
        v-model="Form.tel"
        @enter="handleSave"
      />
      <base-radio
        type="text"
        label="Auto Generate Student ID"
        bordered
        required
        :min="0"
        :items="custIdOptions"
        v-model="Form.autoGenerateStudentId"
        @enter="handleSave"
      />
      <base-input
        v-if="Form.autoGenerateStudentId"
        placeholder="1001"
        type="number"
        label="Last Student ID"
        :readOnly="action == 'view'"
        bordered
        required
        :min="0"
        v-model="Form.officeLastStudentId"
        @enter="handleSave"
      />
      <base-input
        v-if="Form.autoGenerateStudentId"
        placeholder=""
        label="Prefix"
        :readOnly="action == 'view'"
        bordered
        required
        :min="0"
        v-model="Form.studentIdPrefix"
        @enter="handleSave"
      />
      <base-input
        placeholder="Address"
        type="text"
        :readOnly="action == 'view'"
        label="Address"
        bordered
        :min="0"
        v-model="Form.address"
        @enter="handleSave"
      />
       <base-radio
        type="radio"
        label="Use Ordered payment"
        bordered
        required
                :items="custIdOptions"

        v-model="Form.ordered_payment"
      />
      <base-radio
        type="radio"
        label="Pay All at once"
        bordered
        required
        :items="custIdOptions"
        v-model="Form.pay_all"
      />
      <base-radio
        type="radio"
        label="Penalty Formula From"
        bordered
        required
        :items="penaltyFormulaOptions"
        v-model="Form.isPenaltyFromHenon"
      />
    </form>
    <div class="flex justify-between mt-3 px-3">
      <base-button
        label="cancel"
        class="py-1 px-4 text-textMedium bg-brightGray hover:bg-danger hover:text-error rounded"
        @click="close"
      />
      <base-button
        :label="btnLabel"
        :isWorking="isWorking"
        class="py-1 px-4 rounded"
        @click="handleSave"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../eventBus';
export default {
  props: {
    action: {
      type: String,
      default: 'reg',
    },
    btnLabel: {
      type: String,
      default: 'Save',
    },
  },
  data() {
    return {
      isWorking: false,
      util_id: undefined,
      organizations: [],
      Form: {
        name: undefined,
        code: undefined,
        accountNumber: undefined,
        address: undefined,
        tel: undefined,
        autoGenerateStudentId: false,
        isPenaltyFromHenon: false,
        studentIdPrefix: '',
        officeLastStudentId: 0,
         pay_all:false,
        ordered_payment:false,
      },
      custIdOptions: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
      penaltyFormulaOptions: [
        {
          label: 'Henon',
          value: true,
        },
        {
          label: 'Infinity',
          value: false,
        },
      ]
    };
  },
  watch: {
    util_id() {
      this.fetchOrganizations();
    },
  },
  computed: {
    ...mapGetters('main', ['berror']),
  },
  methods: {
    ...mapActions('main', ['request']),
    async handleSave() {
      this.isWorking = true;
      if(!this.Form.autoGenerateStudentId) {
        delete this.Form.studentIdPrefix
        delete this.Form.officeLastStudentId
      }      const response = await this.request({
        method: 'post',
        url: 'billing/office/org/' + this.util_id,
        data: { ...this.Form, util_id: this.util_id },
      });
      if (response) {
        this.isWorking = false;
        this.$emit('close', {
          invokeCallBack: true,
          response: response.item,
        });
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror,
        });
      }
      this.isWorking = false;
    },
    async fetchOrganizations() {
      let response = await this.request({
        method: 'get',
        url: 'billing/utiltypes',
      });
      if (response) {
        this.organizations = response.items.map(({ util_name, _id }) => ({
          label: util_name,
          value: _id,
        }));
      }
    },

    close() {
      this.$emit('close');
    },
  },
  created() {
    this.fetchOrganizations();
    if (this.$attrs.branch) {
      Object.keys(this.Form).forEach(
        (field) => (this.Form[field] = this.$attrs.branch[field])
      );
    }
  },
};
</script>

<style></style>
