
<template>
    <div class="p-2 ">
        
<form class="mx-8" v-if="isGranted('0889tt')&&formOn">
    <div class="grid gap-6 mb-6 md:grid-cols-2">
        <div>
  <base-input
            type="text"
            label="PAYMENT TITLE"
            placeholder="Today's Match at 7:45 Pm Sunday"
            lass="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="Form.payment_type"
            bordered
            required
          />         </div>
        <div>
         <base-select
        label="Match"
        :items="matchOps"
       type="text"
            placeholder=""
            v-model="Form.payment_code"
            bordered
        required
        :searchable="true"
      /> 
           </div>
        
    </div>
    <div class="mb-6">
    </div> 
    <div class="mb-6  grid grid-cols-3   gap-12">
     <base-input
          type="date"
          label="STARTING DATE"
          placeholder=""
          v-model="Form.payment_start_date"
          bordered
          required
        />  
        <base-input
        type="date"
        label="End DATE"
        placeholder=""
        v-model="Form.payment_end_date"
        bordered
        required
      />    
        <base-input
        type="date"
        label="MATCH DATE"
        placeholder=""
        v-model="Form.match_date"
        bordered
        required
      />  
     </div> 
    <div class="mb-6  grid grid-cols-3   gap-12">
    
          <base-input
      type="date"
      label="EXPIRE DATE"
      placeholder=""
      v-model="Form.payment_exc_date"
      bordered
      required
    /> 
        <base-input
        type="number"
        label="Ticket Price"
        placeholder=""
        v-model="Form.amount"
        bordered
        required
      />    
      

           
      <base-input
      type="number"
      label="NO OF TICKETS"
      placeholder=""
      v-model="Form.no_tickets"
      bordered
      required
    />  
    <base-select
        label="TICKET TYPE"
        :items="ticOps"
          type="text"
            placeholder=""
            v-model="Form.ticket_type"
            bordered
        required
        :searchable="true"
      /> 
     </div> 
    <base-button
    :isWorking="isWorking"
    label="Create"
    v-if="isGranted('0889tt')"
    icon="plus"
    class="px-2 p-2 flex text-sm uppercase rounded"
    @click="uploadTicketPayment"
  />
  
</form>
 
  
<div class="flex justify-end">
 <button
    v-if="isGranted('0889tt')&&formOn"
    class="px-2 p-2 flex text-sm uppercase rounded bg-gray-600"
    @click="formOn=!formOn"
  >Close</button>
   <base-button
    label="New"
    icon="plus"
    v-if="isGranted('0889tt')&&!formOn"
    class="px-2 p-2 flex text-sm uppercase rounded"
    @click="formOn=!formOn"
  /></div>

      <bar-loader class="w-12 mx-auto block" v-if="working" />
      <data-table
        v-if="!working"
        name="TICKEST PAYMENTS"
        :headers="headers"
        :items="tickets"
        :canSelect="true"
        @select="select"
        @unselect="unselect"
        :hasDynamicActions="true"
        :dynamicHandlers="actionHandlers"
        :paginate="true"
        :rowsPerPage="8"
        :searchable="true"
      >
        
  
        <template #default="{ dtItem }">
          <td v-for="(header, i) in headers" :key="i" class="py-3 px-6">
            <div v-if="header.key === 'status'" class="flex">
              <div
                class="inline-block px-3 text-sm text-white lowercase rounded"
                :style="{
                  backgroundColor: getColor(dtItem.status),
                }"
              >
                {{ dtItem.status }}
              </div>
            </div>
            <div v-else-if="header.key === 'autoGenerateStudentId'" class="flex">
              <switch-button
                class="flex-shrink-0"
                :value="dtItem.autoGenrateStudentId"
              />
            </div>
            <div v-else>
              {{ getProp(dtItem, header.key) }}
            </div>
          </td>
        </template>
      </data-table>
    </div>
  </template>
  <script>
  import DataTable from '@/components/table/DataTable.vue';
  import { getPropByString } from '@/utils';
  import { mapActions, mapGetters } from 'vuex';
  import BarLoader from '@/components/collection/BarLoader.vue';
  import SwitchButton from '../../../components/base/SwitchButton.vue';
  import eventBus from '@/eventBus';
  export default {
    props: [],
    components: { DataTable, BarLoader, SwitchButton },
    data() {
      return {
        selectedId: '',
        working: false,
        isWorking: false,
        formOn: false,
        tickets: [],
        matchOps:[],
        ticOps:[{
            label: 'Full Year Ticket',
            value: 'fullYear',
          },
          {
            label: 'Half Year Ticket',
            value: 'halfYear',
          },{
            label: '3 Months ticket',
            value: 'quarter',
          },
          {
            label: 'Normal ticket',
            value: 'normal',
          },
          {
            label: 'Membership Ticket',
            value: 'member',
          },],
        Form:{
            payment_type:'',
            payment_code:'',
            payment_start_date:'',
            payment_end_date:'',
            payment_exc_date:'',
            match_date:'',
            amount:'',
            ticket_type:'normal',
            no_tickets:'',

        },
        headers: [
          {
            label: 'PAYMENT TYPE',
            key: 'payment_type',
          },
          {
            label: 'PAYMENT CODE',
            key: 'payment_code',
          },
          {
            label: 'NUMBER OF TICKETS',
            key: 'no_tickets',
          },
          {
            label: 'TICKET PRICE',
            key: 'amount',
          },
          {
            label: 'TICKET TYPE',
            key: 'ticket_type',
          },
          { key: 'payment_start_date', label: 'START DATE' },
          { key: 'payment_end_date', label: 'END DATE' },
          { key: 'payment_exc_date', label: 'EXP DATE' },
  
          {
            label: 'STATUS',
            key: 'status',
          },
        ],
      };
    },
    computed: {
      ...mapGetters('main', ['berror']),
      ...mapGetters(['user']),
      selectedItem() {
        return this.tickets.find((item) => item._id === this.selectedId);
      },
      actionHandlers() {
        if (!this.selectedItem) return [];
        return [
          
          {
            label: 'Detail',
            icon: 'more-alt',
            handler: this.detail,
            fullParam: true,
            code: '0891tt',
          },
          
        ];
      },
    },
    watch: {},
    methods: {
      ...mapActions('main', ['setData', 'request']),
      getProp: getPropByString,
      async fetchtickets() {
        this.working = true;
        this.tickets = [];
        let response = await this.request({
          method: 'get',
          url: `billing/events`,
        });
        this.working = false;
        if (response && !response.error) {
          this.tickets = response.items;
        }
      },
      select(id) {
        this.selectedId = id;
      },
      unselect() {
        this.selectedId = '';
      },
      getColor(status = '') {
        let colors = {
          verified: 'teal',
          pending: 'orange',
          suspended: 'red',
        };
        return colors[status.toLowerCase()];
      },
      openBranchRegistrationForm() {
        eventBus.$emit('open-modal', { modalId: 2, cb: this.insertNewItem });
      },
      editBranch(branch) {
        eventBus.$emit('open-modal', {
          modalId: 2,
          componentProps: {
            action: 'edit',
            branch,
          },
          cb: this.updateItem,
        });
      },
      insertNewItem({ response }) {
        this.tickets.unshift(response);
      },
      updateItem({ response }) {
        this.tickets.unshift(response);
      },
      detail(id) {
        this.$router.push('/ticketdetail/'+id._id);
      },

      async fetchMaches() {
        this.maches = [];
        let response = await this.request({
          method: 'get',
          url: `billing/events/event/matches`,
        });
        if (response && !response.error) {
          this.matches = response.items;
           this.matchOps = response.items.map(({ home_club, away_club }) => ({
          label: home_club +"  Vs  " +away_club,
          value: home_club +"  Vs  " +away_club,
        }));
        }
      },
      async uploadTicketPayment() {
      this.isWorking = true;
      const response = await this.request({
        method: 'post',
        url: 'billing/events/add',
        data: {
          ...this.Form,
        }
      });
      if (response) {
        eventBus.$emit('open-toast', {
          error: false,
          message: 'payment uploaded successfully'
        });
        this.formOn=false;
        this.fetchtickets();
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror,
          timeout: 120
        });
      }
      this.isWorking = false;
    },
    },
    mounted() {
      this.fetchtickets();
      this.fetchMaches()
    },
  };
  </script>
  <style></style>
  