<template>
  <div class="space-y-3 p-2">
    <smart-report :headers="headers" :title="title" :url="url">
      <template #header>
        <div class="flex justify-center w-full p-2 border border-primary">
          <div class="text-xl text-primary font-semibold">
            {{ title }}
          </div>
        </div>
      </template>
    </smart-report>
  </div>
</template>

<script>
import { currentDate } from '@/mixins/currentDate';
import SmartReport from '../SmartReport.vue';
export default {
  components: { SmartReport },
  mixins: [currentDate],
  data() {
    return {
      url: 'billing/reports/officer-transactions',
      headers: [
        {
          label: 'ID',
          key: 'cust_util_id',
        },
        {
          label: 'STUDENT NAME',
          key: 'fullName',
        },
        {
          label: 'AMOUNT',
          key: 'amount',
        },
        {
          label: 'Penalty',
          key: 'penalty',
        },
        {
          label: 'Remaining Amount',
          key: 'remaining_amount',
        },
        {
          label: 'DATE',
          key: 'date',
          format: 'date',
        },
        {
          label: 'Time',
          key: 'time',
        },
        {
          label: 'TR ID',
          key: 'trId',
        },
      ],
      statusOptions: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'Paid',
          value: 'paid',
        },
        {
          label: 'Partial paid',
          value: 'partial paid',
        },
        {
          label: 'Unpaid',
          value: 'unpaid',
        },

      ],
    };
  },
  computed: {
    title() {
      return `MY TRANSACTIONS REPORT AS OF ${this.currentDate}`;
    },
  },
};
</script>

<style>
</style>