const state = {
  transactionType: 'OFFICE',
  type: 'amount',
};

const mutations = {
  SMART_SET: (state, { stateKey, data }) => {
    state[stateKey] = data;
  }
};

const actions = {
  smartSet({ commit }, { stateKey, data }) {
    commit('SMART_SET', {
      stateKey,
      data
    });
  },
  setTransactionType({commit}, data) {
    commit('SMART_SET', {
      stateKey: 'transactionType',
      data
    });
  },
  setType({commit}, data) {
    commit('SMART_SET', {
      stateKey: 'type',
      data
    });

  }
};

const getters = {
  transactionType: state => state.transactionType,
  type: state => state.type,
};

const socket = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

export default socket;
