<template>
  <div class="space-y-3 p-3">
    <div>
      <slot name="header">
        <div class="flex justify-center w-full p-2 border border-primary">
          <div class="text-xl text-primary font-semibold">
            {{ title }}
          </div>
        </div>
      </slot>
      <slot name="toolset"></slot>
      <paginate-table
        :name="title"
        :headers="headers"
        :items="documents"
        :canSelect="false"
        :isWorking="isWorking"
        :hasDynamicActions="false"
        :paginate="true"
        :rowsPerPage="rowsPerPage"
        :totalPages="total_pages"
        @pageChange="handlePageChange"
        @pageSizeChange="handlePageSizeChange"
        :searchable="false"
        small
      >
        <template #toolset>
          <div class="flex justify-between py-1">
            <base-button
              label="BACK"
              icon="chevron-left"
              :primary="false"
              class="px-2 rounded bg-brightGray hover:text-primary"
              @click="goBack"
            />
            <base-button
              label="EXPORT"
              :primary="false"
              class="px-2 rounded bg-brightGray hover:text-primary"
              @click="generateReport"
            />
          </div>
        </template>
      </paginate-table>

      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import PaginateTable from '@/components/table/PaginateTable.vue';
import { mapActions } from 'vuex';
import eventBus from '@/eventBus';
export default {
  components: { PaginateTable },
  props: ['headers', 'title', 'url', 'query', 'has_query'],
  data() {
    return {
      isWorking: true,
      documents: [],
      rowsPerPage: 15,
      currentPage: 1,
      total_pages: 1
    };
  },
  watch: {
    url() {
      this.fetchdocuments();
    },
    query: {
      deep: true,
      immediate: false,
      handler: function() {
        this.currentPage = 1;
        this.fetchdocuments();
      }
    }
  },
  methods: {
    ...mapActions('main', ['request']),
    ...mapActions('report', ['setLoading', 'setReports', 'clear']),
    async fetchdocuments() {
      if (!this.url) return;
      this.isWorking = true;
      this.documents = [];
      const queryToUrl = Object.entries(this.query).reduce((acc, [key, value]) => (`${acc}&${key}=${value}`), '') + `&limit=${this.rowsPerPage}&page=${this.currentPage}`
      let response = await this.request({
        method: 'get',
        url: `${this.url}?${queryToUrl}`
      });
      this.isWorking = false;
      if (response && !response.error) {
        this.documents = response.items;
        this.total_pages = response.total_pages;
      }
    },
    async generateReport() {
      this.setLoading(true);
      this.setReports([]);
      let componentProps = {
        title: this.title,
        headers: this.headers
      };
      eventBus.$emit('open-modal', { modalId: 200, componentProps });
      const queryToUrl = Object.entries(this.query).reduce((acc, [key, value]) => (`${acc}&${key}=${value}`), '') + `&limit=${Number.MAX_SAFE_INTEGER}`
      let response = await this.request({
        method: 'get',
        url: `${this.url}?${queryToUrl}`
      });
      if (response && !response.error) {
        this.setReports(response.items);
        // this.reports = response.items;
        // this.total_pages = response.total_pages;
      }
      this.setLoading(false);
    },
    generateQuery(rowsPerPage, currentPage) {
      const paginationQuery = `limit=${rowsPerPage}&page=${currentPage}`;
      if (this.query) {
        let query = (query = Object.entries(this.query).reduce(
          (acc, [key, value]) => (acc += `${key}=${value}&`),
          ''
        ));
        return '?' + query + paginationQuery;
      } else if (this.has_query) return '&' + paginationQuery;
      else return '?' + paginationQuery;
    },
    handlePageChange(page) {
      if (page >= 1 && page <= this.total_pages) {
        this.currentPage = page;
        this.fetchdocuments();
      }
    },
    handlePageSizeChange(pageSize) {
      this.rowsPerPage = pageSize;
      this.currentPage = 1;
      this.fetchdocuments();
    },
    goBack() {
      this.$router.go(-1);
    }
  },
  created() {
    this.fetchdocuments();
  }
};
</script>

<style></style>
