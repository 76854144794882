<template>
  <div class="space-y-6 p-4 bg-white shadow-md flex flex-col">
    <!-- <pre>{{ series }}</pre> -->
    <Header @set-active-duration="setActiveDuration" />
    <chart :categories="chartCategories" :series="series" />
    <numberic-dashboard
      :categories="chartCategories"
      :series="series"
      :penalties="penalties"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import Chart from './Chart.vue';
import Header from './Header.vue';
import NumbericDashboard from './NumbericDashboard.vue';
import { isSameDate } from '../../../utils';
export default {
  components: { Header, NumbericDashboard, Chart },
  data() {
    return {
      loading: false,
      reportDuration: 'monthly',
      categories: [],
      url: '',
      data: [],
      colors: ['#4600FF', '#FF0074', '#04A9F5'],
      urls: {
        vendor: {
          today: 'all-daily-report',
          weekly: 'all-weekly-report',
          monthly: 'all-monthly-report',
          yearly: 'all-yearly-report',
          all: '',
        },
        org: {
          today: 'org-daily-report',
          weekly: 'org-weekly-report',
          monthly: 'org-monthly-report',
          yearly: 'org-yearly-report',
          all: '',
        },
        branch: {
          today: 'branch-daily-report',
          weekly: 'branch-weekly-report',
          monthly: 'branch-monthly-report',
          yearly: 'branch-yearly-report',
          all: '',
        },
        officer: {
          today: 'officer-daily-report',
          weekly: 'officer-weekly-report',
          monthly: 'officer-monthly-report',
          yearly: 'officer-yearly-report',
          all: '',
        },
      },
      permissions: {
        org: 'org',
        branch: 'branch',
        officer: 'officer',
        vendor: 'vendor',
        '': 'officer',
      },
    };
  },
  watch: {
    activeUrl: {
      deep: true,
      immediate: true,
      handler: function () {
        if (['weekly', 'monthly'].includes(this.reportDuration))
          this.getTodayDocuments();
        else this.getDocuments();
      },
    },
    reportDuration: {
      deep: true,
      immediate: true,
      handler: function (val) {
        switch (val) {
          case 'today':
            this.categories = this.getDayHours();
            break;
          case 'weekly':
            this.categories = this.getWeekDays();
            break;
          case 'monthly':
            this.categories = this.getMonthDays();
            break;
          case 'yearly':
            this.categories = this.getYearMonths();
            break;
        }
      },
    },
  },
  computed: {
    ...mapGetters('dashboard', ['transactionType', 'type']),
    ...mapGetters(['user']),
    chartCategories() {
      return this.categories.map(({ label }) => label);
    },
    series() {
      if (this.type && this.transactionType && this.data) {
        let series = [];
        if (this.transactionType == 'BANK')
          series = ['CASH', 'TRANSFER'].map((payment_type, i) => {
            return {
              name: payment_type,
              data: this.categories.map(
                ({ value }) =>
                  this.data.find(
                    ({ _id }) =>
                      _id.payment_type == payment_type &&
                      (['weekly', 'monthly'].includes(this.reportDuration)
                        ? isSameDate(value, _id.date)
                        : value == _id.date)
                  )?.[this.type] || 0
              ),
              color: this.colors[i],
            };
          });
        else
          series = [
            {
              name: 'PAID',
              data: this.categories.map(
                ({ value }) =>
                  this.data.find(({ _id }) =>
                    ['weekly', 'monthly'].includes(this.reportDuration)
                      ? isSameDate(value, _id.date)
                      : value == _id.date
                  )?.[this.type] || 0
              ),
              color: this.colors[0],
            },
          ];
        return series;
      } else return [];
    },
    penalties() {
      return this.categories.map(({ value }) =>
        this.data
          .filter(({ _id }) =>
            ['weekly', 'monthly'].includes(this.reportDuration)
              ? isSameDate(value, _id.date)
              : value == _id.date
          )
          .reduce((sum, {penalty}) => sum + penalty, 0)
      );
    },
    permission() {
      return this.permissions[this.user.dashboard];
    },
    activeUrl() {
      return (
        this.transactionType +
        '/' +
        this.urls[this.permission][this.reportDuration]
      );
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    setActiveDuration(duration) {
      this.reportDuration = duration;
    },
    async getDocuments() {
      this.loading = true;
      this.data = [];
      const response = await this.request({
        url: 'billing/reports/' + this.activeUrl,
        method: 'get',
      });
      if (response) {
        this.data = response.items;
      }
      this.loading = false;
    },
    async getTodayDocuments() {
      this.loading = true;
      this.data = [];
      const response = await this.request({
        url: 'billing/reports/' + this.activeUrl,
        method: 'get',
      });
      if (response) {
        this.data = response.items;
      }
      this.loading = false;
    },
    getYearMonths() {
      const thisMonth = Number(moment.utc(moment.now()).format('MM'));
      const first = moment
        .utc(moment().format('YYYY-MM-DD'))
        .subtract(thisMonth, 'month')
        .toDate();
      let months = [];
      for (let i = 1; i < 13; i++) {
        const date = moment.utc(first).add(i, 'month').toDate();
        months.push({
          label: moment.utc(date).format('MMM'),
          value: Number(moment.utc(date).format('MM')),
        });
      }
      return months;
    },
    getWeekDays() {
      const weekDay = moment.utc(moment.now()).isoWeekday();
      const first = moment
        .utc(moment().format('YYYY-MM-DD'))
        .subtract(weekDay - 1, 'day')
        .toDate();
      let weekdays = [];
      for (let i = 1; i < 8; i++) {
        const date = moment
          .utc(first)
          .add(i - 1, 'day')
          .toDate();
        weekdays.push({
          label: moment.utc(date).format('MMM DD'),
          value: date,
        });
      }
      return weekdays;
    },
    getDayHours() {
      let hours = [];
      for (let i = 1; i <= 24; i++) {
        hours.push({
          label: i.toString(),
          value: i,
        });
      }
      return hours;
    },
    getMonthDays() {
      const daysInMonth = moment.utc(moment.now()).daysInMonth();
      const date = Number(moment.utc(moment.now()).format('DD'));
      const first = moment
        .utc(moment().format('YYYY-MM-DD'))
        .subtract(date - 1, 'day')
        .toDate();
      let weekdays = [];
      for (let i = 1; i <= daysInMonth; i++) {
        const date = moment
          .utc(first)
          .add(i - 1, 'day')
          .toDate();
        weekdays.push({
          label: moment.utc(date).format('DD'),
          value: date,
        });
      }
      return weekdays;
    },
    getWeeklyUrl() {
      return 'org-weekly-report';
    },
  },
  created() {
    // this.getDocuments();
  },
};
</script>

<style>
</style>