// import { apiBaseUrl } from '../environment/environment';
// const apiBaseUrl = 'http://192.168.43.13';
// const apiBaseUrl = 'https://10.1.10.11';
//const apiBaseUrl = 'http://192.168.0.150';
// const apiBaseUrl = 'http://16.171.142.125';
// const apiBaseUrl = 'http://196.188.95.164';
//  const apiBaseUrl = 'http://localhost';
 const apiBaseUrl = 'http://anbesaschoolpay.et';
 const PORT = '7272'; //LIB
//  const PORT = '9090'; //LIB
const BASE_URL = `${apiBaseUrl}:${PORT}/api`;

const IO_BASE_URL = `${apiBaseUrl}:${PORT}`;
const PROFILE_PATH = `${apiBaseUrl}:${PORT}/api/photo/`;
const SIGNATURE_PATH = `${apiBaseUrl}:${PORT}/api/signature/`;
const FRONT_END_BASE_URL = apiBaseUrl;
const TOKEN_KEY = 'access_token';
const USER_KEY = '_u_k';

export {
  PROFILE_PATH,
  SIGNATURE_PATH,
  BASE_URL,
  IO_BASE_URL,
  FRONT_END_BASE_URL,
  USER_KEY,
  TOKEN_KEY
};
