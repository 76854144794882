const ROLES = {
  DIRECTOR: 'director',
  ADMIN: 'admin',
  BM: 'branch manager',
  CSM: 'csm',
  SCSO: 'scso',
  CHECKER: 'checker',
  AUDITOR: 'auditor',
  BACKMAKER: 'back maker',
  MAKER: 'maker',
  ITDIRECTOR: 'it director',
  OPADMIN: 'op admin',
  OPDIRECTOR: 'op director'
};
export default ROLES;
