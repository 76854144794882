<template>
  <div class="text-textMedium space-y-4">
    <div class="text-2xl uppercase py-2 text-center">PAY STUDENT BILL</div>
    <div class="space-y-4 px-4 pb-4">
      <customer-payment :payment="payment" @setTotalAmount="setTotalAmount" @setPenalty="setPenalty" @setDiscount="setDiscount"/>
      <base-input
        v-if="payment.util_id.allow_partial_payment"
        label="Amount"
        v-model="Form.amount"
        bordered
        type="number"
        :min="0"
        :max="payment.remaining_amount + this.Form.penalty"
      />
      <div class="flex justify-between">
        <base-button
          label="cancel"
          class="
            py-1
            px-4
            text-textMedium
            bg-brightGray
            hover:bg-danger
            hover:text-error
            rounded
          "
          @click="close"
        />
        <base-button
          :isWorking="working"
          label="Pay Bill"
          class="py-1 px-4 rounded"
          @click="payCustomerBill"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import eventBus from '@/eventBus';
import CustomerPayment from './CustomerPayment.vue';
export default {
  components: { CustomerPayment },
  props: ['payment'],
  data() {
    return {
      working: false,
      Form: {
        amount: 0,
        penalty: 0,
        discount: 0,
      },
      totalRemainingAmount: 0,
    };
  },
  
  methods: {
    ...mapActions('main', ['request']),
    async payCustomerBill() {
      if (
        Number(Number(this.Form.amount).toFixed(2)) >
        Number(Number(this.totalRemainingAmount).toFixed(2))
      ) {
        eventBus.$emit('open-toast', {
          error: 'true',
          message: 'Amount should not exceed total amount',
        });
        return;
      }
      this.working = true;
      let response = await this.request({
        method: 'post',
        url: 'billing/utilcustomers/officer-pay/' + this.payment._id,
        data: {
          ...this.Form,
          has_penalty: this.Form.penalty > 0,
          has_discount: this.Form.discount > 0,
        },
      });
      if (response) {
        eventBus.$emit('open-toast', {
          message: 'Payment billed successfully.',
        });
        this.$emit('close', {
          invokeCallBack: true,
          response: { payment: response.item },
        });
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror,
        });
      }
      this.working = false;
    },
    setTotalAmount(amount) {
      this.totalRemainingAmount = amount;
      this.Form.amount = this.totalRemainingAmount
    },
    setPenalty(amount) {
      this.Form.penalty = amount
    },
    setDiscount(amount) {
      this.Form.discount = amount
    },
    close() {
      this.$emit('close');
    },
  },
  created() {
    // if (this.payment.util_id.allow_partial_payment)
    this.Form.amount = this.payment.remaining_amount;
  },
};
</script>

<style></style>
