var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-auto max-w-7xl sm:px-6 lg:px-8"},[_c('div',{staticClass:"relative isolate overflow-hidden bg-white px-6 py-4 text-center sm:px-16 sm:shadow-sm dark:bg-transparent"},[_c('div',[_c('label',{staticClass:"tracking-widest mx-auto mt-8 relative bg-white min-w-sm max-w-2xl flex flex-col md:flex-row items-center justify-center border py-2 px-2 rounded-2xl gap-2 shadow-lg focus-within:border-gray-300",attrs:{"for":"search-bar"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keyword),expression:"keyword"}],staticClass:"px-6 py-2 w-full rounded-md flex-1 outline-none bg-white dark:bg-zinc-300 tracking-widest",attrs:{"id":"search-bar","placeholder":"Search with student name, phone number, master ID, student school ID, Bill code, transaction number ...","name":"q","required":""},domProps:{"value":(_vm.keyword)},on:{"input":function($event){if($event.target.composing)return;_vm.keyword=$event.target.value}}})])]),_c('div',{staticClass:"mt-6 mb-4 border-b border-gray-200 dark:border-gray-700"},[_c('ul',{staticClass:"flex flex-wrap -mb-px text-sm font-medium text-center",attrs:{"id":"default-tab","data-tabs-toggle":"#default-tab-content","role":"tablist"}},[_c('li',{staticClass:"mr-2",attrs:{"role":"presentation"}},[_c('button',{staticClass:"inline-block px-4 py-2 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-2 hover:border-gray-500",class:{
              'border-b-2 border-primary text-primary':
                'students' === _vm.activeTab,
              'border-transparent': 'students' !== _vm.activeTab,
            },attrs:{"id":"students-tab","data-tabs-target":"#students","type":"button","role":"tab","aria-controls":"students","aria-selected":_vm.activeTab == 'students'},on:{"click":function($event){return _vm.changeTab('students')}}},[_vm._v(" Students ")])]),_c('li',{staticClass:"mr-2",attrs:{"role":"presentation"}},[_c('button',{staticClass:"inline-block px-4 py-2 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-primary dark:hover:text-gray-300",class:{
              'border-b-2 border-primary text-primary':
                'bills' === _vm.activeTab,
              'border-transparent': 'bills' !== _vm.activeTab,
            },attrs:{"id":"bills-tab","data-tabs-target":"#bills","type":"button","role":"tab","aria-controls":"bills","aria-selected":_vm.activeTab == 'bills'},on:{"click":function($event){return _vm.changeTab('bills')}}},[_vm._v(" Bills ")])])])]),(_vm.loading)?_c('div',{},[_vm._v(" loading... ")]):(_vm.activeTab == 'students')?_c('students',{ref:"studentsComponent",attrs:{"keyword":_vm.keyword}}):(_vm.activeTab == 'bills')?_c('bills',{ref:"billsComponent",attrs:{"keyword":_vm.keyword}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }