<template>
  <div class="space-y-2">
    <div class="flex flex-col space-y-2 justify-between">
      <div class="text-lg uppercase text-primary font-medium">PAYMENTS</div>
      <div class="">
        <bar-loader v-if="isLoading" class="w-full flex justify-center" />
        <data-table
          v-else
          name="Ordered Bills"
          :headers="headers"
          :items="documents"
          :canSelect="true"
          @select="select"
          @unselect="unselect"
          :hasDynamicActions="true"
          :dynamicHandlers="actionHandlers"
          :paginate="true"
          :rowsPerPage="8"
          :searchable="true"
        >
          <template #toolset>
            <div class="flex flex-wrap">
              <base-button
                v-if="isGranted('0009up')"
                label="UPLOAD BILLS"
                icon="plus"
                class="px-2 rounded"
                @click="uploadPayments"
              />
            </div>
          </template>

          <template #default="{ dtItem }">
            <td
              v-for="(header, i) in headers"
              :key="i"
              class="py-3 text-lg px-6"
            >
              <div v-if="header.key === 'payment_type'" class="flex">
                <div
                  class="inline-block rounded capitalize font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {{ getProp(dtItem, header.key) }}
                </div>
              </div>
              <div v-else-if="header.key === 'payment_due_date'" class="flex ">
                <div class="inline-block rounded capitalize text-textDarkest">
                  {{ dtItem.payment_due_date | formatDate }}
                </div>
              </div>
              <div v-else-if="header.key === 'payment_start_date'" class="flex ">
                <div class="inline-block rounded capitalize text-textDarkest">
                  {{ dtItem.payment_start_date | formatDate }}
                </div>
              </div>

              <div
                v-else-if="header.key === 'penalty_due_date_status'"
                class="flex "
              >
                <div
                  class="w-full bg-opacity-25 inline-block px-2 py-1 text-sm capitalize tracking-wider font-semibold rounded"
                  :class="
                    'bg-' +
                      getColor(dtItem[header.key]) +
                      ' text-' +
                      getColor(dtItem[header.key])
                  "
                >
                  {{ getProp(dtItem, header.key) }}
                </div>
              </div>
              <div v-else-if="header.key === 'status'" class="flex">
                <div
                  class="inline-block text-sm px-4 text-white lowercase rounded"
                  :style="{
                    backgroundColor: getColor(dtItem.status)
                  }"
                >
                  {{ dtItem.status }}
                </div>
              </div>
              <div v-else class="">
                {{ getProp(dtItem, header.key) }}
              </div>
            </td>
          </template>
        </data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import pagination from '@/mixins/pagination';
import DataTable from '@/components/table/DataTable.vue';
import BarLoader from '../../../components/collection/BarLoader.vue';
import { getPropByString } from '@/utils';
import eventBus from '../../../eventBus';
import { formatDate } from '../../../utils';
export default {
  mixins: [pagination],
  components: { BarLoader, DataTable },
  data() {
    return {
      documents: [],
      isLoading: false,
      selectedId: '',
      headers: [
        {
          label: 'term',
          key: 'paymentTerm'
        },
        {
          label: 'payment type',
          key: 'paymentType.title'
        },
        {
          label: 'students',
          key: 'total_customers'
        },
        {
          label: 'amount',
          key: 'total_amount'
        },
        {
          label: 'starting date',
          key: 'payment_start_date',
          format: 'date'
        },
        {
          label: 'due date',
          key: 'payment_due_date',
          format: 'date'
        },
        {
          label: 'penalty status',
          key: 'penalty_due_date_status'
        }
        // {
        //   label: 'STATUS',
        //   key: 'status'
        // }
      ]
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
    ...mapGetters(['user']),
    displayItems() {
      if (!this.paginate) return this.documents;
      let startIndex = (this.currentPage - 1) * this.pageSize;
      let endIndex = startIndex + this.pageSize;
      if (endIndex > this.documents.length) endIndex = this.documents.length;
      return this.documents.slice(startIndex, endIndex);
    },
    selectedItem() {
      return this.documents.find(item => item._id === this.selectedId);
    },
    actionHandlers() {
      if (!this.selectedItem) return [];
      return [
        {
          label: 'Open Details',
          icon: 'eye',
          handler: this.openPaymentDetails,
          idParam: true,
          code: '0999up'
        },
        // {
        //   label: 'Penality Formula',
        //   icon: 'eye',
        //   handler: this.openDueDatePenalityFormula,
        //   fullParam: true,
        //   code: '0999up'
        // },
        // {
        //   label: 'Discount Formula',
        //   icon: 'eye',
        //   handler: this.openDiscountFormula,
        //   fullParam: true,
        //   code: '0999up'
        // }        
        {
          label: 'Delete Payments',
          icon: 'trash-alt',
          handler: this.confirmDelete,
          fullParam: true,
          code: '0099up'
        }
      ];
    }
  },
  filters: {
    formatDate(date) {
      return formatDate(date);
    }
  },
  watch: {
    currentPage() {
      this.getDocuments();
    }
  },
  methods: {
    ...mapActions('main', ['requestMutate', 'request']),
    getProp: getPropByString,
    selectpayment(id) {
      this.selectedId = id;
    },
    select(id) {
      this.selectedId = id;
    },
    unselect() {
      this.selectedId = '';
    },
    getColor(status = '') {
      let colors = {
        active: 'teal',
        inactive: 'yellow-600',
        suspended: 'red-600'
      };
      return colors[status.toLowerCase()] || 'orange';
    },

    async uploadPayments() {
      this.$router.push('/upload-payments');
    },
    confirmDelete(payment) {
      const componentProps = {
        title: 'Delete payment',
        message: `Are you sure you to delete ${ payment.paymentTerm } ${ payment.paymentType.title } bills`,
      };
      eventBus.$emit('open-modal', {
        modalId: 3,
        componentProps,
        cbParams: { payment },
        cb: this.delete,
      });
    },
    async delete({payment}) {
      console.info(payment)
      this.isLoading = true;
      const response = await this.request({
        url: 'billing/utilpayments/delete/'+payment._id,
        method: 'delete'
      });
      if (response) {
        eventBus.$emit('open-toast', {
          error: false,
          message: `${ payment.paymentTerm } ${ payment.paymentType.title } bills deleted successfully`
        })
      } else {
        eventBus.$emit('open-toast', { error: true, message: this.berror})
      }
      this.isLoading = false;
    },
    async getDocuments() {
      this.isLoading = true;
      const response = await this.request({
        url: 'billing/utilpayments',
        method: 'get'
      });
      if (response) {
        this.documents = response.items;
      } else {
        this.documents = [];
      }
      this.isLoading = false;
    },
    openPaymentDetails(id) {
      this.$router.push('/payment/' + id);
    },
    openDueDatePenalityFormula(payment) {
      eventBus.$emit('open-modal', {
        modalId: 25,
        componentProps: {
          payment
        }
      });
    },
    openDiscountFormula(payment) {
      eventBus.$emit('open-modal', {
        modalId: 201,
        componentProps: {
          payment
        }
      });
    }
  },
  created() {
    this.getDocuments();
  }
};
</script>

<style></style>
