<template>
  <div
    class="rounded-full flex cursor-pointer"
    :class="this.value ? 'justify-end' : 'justify-start'"
    style="width: 44px;height: 25px;padding: 1.5px"
    :style="{
      background: this.value ? '#3b49df' : '#D2D2D4'
    }"
    @click="toggleSelect"
  >
    <div class="rounded-full bg-white" style="width: 22px;height: 22px;"></div>
  </div>
</template>

<script>
export default {
  props: ['value'],
  methods: {
    toggleSelect() {
      this.$emit('input', !this.value);
    }
  }
};
</script>

<style></style>
