<template>
  <div class="space-y-3 p-2">
    <smart-report :headers="headers" :title="title" :url="url" :has_query="hasQuery">
      <template #header>
        <div class="flex justify-center w-full p-2 border border-primary">
          <div class="text-xl text-primary font-semibold">
            {{ title }}
          </div>
        </div>

      </template>
      <template #toolset>
        <div class="flex justify-between space-x-4">
          <base-select class="flex-grow" v-model="selectedId" :items="documents" label="Officer" />
          <base-radio v-model="status" :items="statusOptions" label="Status" class="flex-grow-0 flex-shrink-0" />
        </div>
      </template>
    </smart-report>
  </div>
</template>

<script>
import { currentDate } from '@/mixins/currentDate';
import SmartReport from '../SmartReport.vue';
import { mapActions } from 'vuex';
export default {
  components: { SmartReport },
  mixins: [currentDate],
  data() {
    return {
      selectedId: '',
      documents: [],
      status: '',
      statusOptions: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'Paid',
          value: 'paid',
        },
        {
          label: 'Partial paid',
          value: 'partial paid',
        },
        {
          label: 'Unpaid',
          value: 'unpaid',
        },

      ],
      headers: [
        {
          label: 'ID',
          key: 'cust_util_id',
        },
        {
          label: 'FULL NAME',
          key: 'fullName',
        },
        {
          label: 'grade/Dep\'t',
          key: 'grade',
        },
        {
          label: 'section',
          key: 'section',
        },
        {
          label: 'AMOUNT',
          key: 'amount',
        },
        {
          label: 'Penalty',
          key: 'penalty',
        },
        {
          label: 'Remaining Amount',
          key: 'remaining_amount',
        },
        {
          label: 'DATE',
          key: 'date',
          format: 'date',
        },
        {
          label: 'Time',
          key: 'time',
        },
        {
          label: 'TR ID',
          key: 'trId',
        },
      ],
    };
  },
  computed: {
    hasQuery() {
      return this.status ? true : false;
    },
    title() {
      return (
        (this.selectedItem ? this.selectedItem.label : '') +
        ` ${this.status.toUpperCase()} TRANSACTIONS REPORT AS OF ${this.currentDate}`
      );
    },
    url() {
      if (!this.selectedId) return '';
      return 'billing/reports/officer-transactions/' + this.selectedId+
        (this.status ? '?status=' + this.status : '');
    },
    selectedItem() {
      if (!this.selectedId) return '';
      return this.documents.find(({ value }) => value == this.selectedId);
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    async fetchDocuments() {
      const response = await this.request({
        url: 'billing/credential/org_users?all=true',
        method: 'get',
      });
      if (response) {
        this.documents = response.items.map(({ _id, name }) => ({
          label: name,
          value: _id,
        }));
      }
    },
  },
  created() {
    this.fetchDocuments();
  },
};
</script>

<style>
</style>