<template>
  <div
    class="
      rounded
      grow-active
      shadow-sm
      text-textMedium
      bg-brighterGray
      hover:shadow-lg
      hover:p-4
      hover:text-textDarkest
      group
      cursor-pointer
      p-2
      h-32
      space-y-2
    "
    :class="{
      ' border-2 border-primary shadow-md': selected,
      ' border border-brightGray': !selected,
    }"
  >
    <div class="flex flex-col justify-center h-full">
      <div class="text-xl text-center text-textLight font-semibold">
        {{ role.role_name }}
      </div>
      <div class="text-sm text-center text-textMedium">
        {{ role.role_description }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['role', 'selected'],
};
</script>

<style></style>
