<template>
  <div class="space-y-3 p-2">
    <smart-report
      :headers="headers"
      :title="title"
      :url="url"
      :query="query"
      :has_query="true"
    >
      <template #header>
        <div class="space-y-2">
          <div
            class="flex flex-col space-y-4 justify-start w-full p-2 border border-primary"
          >
            <div
              class="flex-grow text-center text-xl text-primary font-semibold"
            >
              {{ title }}
            </div>
            <div
              class="flex justify-end items-between items-center m-0 p-0 w-full text-textLight uppercase"
            >
              <div class="flex flex-grow space-x-5">
                <base-select
                  v-model="paymentTermId"
                  :items="paymentTermOptions"
                  label="Payment Term"
                />
              </div>
              <base-icon name="filter" class="mr-3 mt-4" />
              <div
                class="uppercase
                flex flex-col
                justify-center
                border-0 border-primary border-r
                m-0
                mt-4
                px-2
                h-full
                cursor-pointer
                hover:font-semibold
              "
                :class="{
                  'font-semibold  bg-info text-white': value == active
                }"
                v-for="({ label, value }, i) in options"
                :key="i"
                @click="toggle(value)"
              >
                {{ label }}
              </div>
            </div>
          </div>
          <sum-report-card :query="summaryQuery" />
        </div>
      </template>
    </smart-report>
  </div>
</template>

<script>
import { currentDate } from '@/mixins/currentDate';
import SmartReport from '../SmartReport.vue';
import { mapActions } from 'vuex';
import SumReportCard from '../../../../components/card/report/SumReportCard.vue';
export default {
  components: { SmartReport, SumReportCard },
  mixins: [currentDate],
  data() {
    return {
      options: [
        { label: 'all', value: 'all' },
        { label: 'Paid', value: true },
        { label: 'unpaid', value: false }
      ],
      active: 'all',
      paymentType: undefined,
      paymentTermId: undefined,
      from_date: undefined,
      to_date: undefined,
      paymentTypeOptions: [],
      paymentTermOptions: [],
      url: 'billing/reports/my-uploaded-org-bills',
      headers: [
        {
          label: 'ID',
          key: 'studentSchoolId'
        },
        {
          label: 'STUDENT NAME',
          key: 'studentName'
        },
        {
          label: 'GRADE',
          key: 'grade'
        },
        {
          label: 'SECTION',
          key: 'section'
        },
        {
          label: 'BILL CODE',
          key: 'billCode'
        },
        {
          label: 'Total Amount',
          key: 'amount'
        },
        {
          label: 'Payment Amount',
          key: 'paymentAmount'
        },
        {
          label: 'Payment Date',
          key: 'paymentDate'
        },
        {
          label: 'Registration',
          key: 'registrationFee'
        },
        {
          label: 'Tutor',
          key: 'tutorFee'
        },
        {
          label: 'Transportation',
          key: 'transportationFee'
        },
        {
          label: 'Materials',
          key: 'materialsFee'
        },
        {
          label: 'Others',
          key: 'othersFee'
        },
        {
          label: 'Penalty',
          key: 'penalty'
        },
        {
          label: 'Transaction Number',
          key: 'transactionNumber'
        },
        {
          label: 'Bank',
          key: 'bank_name'
        },
        {
          label: 'Payer',
          key: 'paid_by'
        },
        {
          label: 'Status',
          key: 'isPaid'
        }
      ]
    };
  },
  computed: {
    title() {
      return `BILLS REPORT AS OF ${this.currentDate}`;
    },
    query() {
      let query = {sortBy: "paymentDate", order: -1};
      if (this.active !== 'all') query.isPaid = this.active;
      if (this.paymentTermId) query.paymentTermId = this.paymentTermId;
      // if (this.payment_type) query.util_payment_id = this.payment_type;
      // if (this.from_date) query.from_date = this.from_date;
      // if (this.to_date) query.to_date = this.to_date;
       if (this.$route.params.id) query.id= this.$route.params.id;
      return query;
    },
    summaryQuery() {
      let query = {};
      if (this.paymentTermId) query.paymentTermId = this.paymentTermId;
      return query;
    }
  },
  methods: {
    ...mapActions('main', ['request']),
    toggle(active) {
      this.active = active;
    },
    async getPaymentTypes() {
      const response = await this.request({
        url: `billing/paymentType?limit=100000&id=${this.$route.params.id}`
      });
      if (response) {
        this.paymentTypeOptions = response.items.map(
          ({ _id: value, title: label }) => ({ label, value })
        );
        this.paymentTypeOptions.unshift({label: 'All', value: ''})
      } else {
        this.paymentTypeOptions = [];
      }
    },
    async getPaymentTerms() {
      const response = await this.request({
        url: `billing/paymentTerm?limit=100000&id=${this.$route.params.id}`
      });
      if (response) {
        this.paymentTermOptions = response.items.map(
          ({ _id: value, title: label }) => ({ label, value })
        );
        this.paymentTermOptions.unshift({label: 'All', value: ''})

      } else {
        this.paymentTermOptions = [];
      }
    }
  },
  created() {
    this.getPaymentTerms();
    this.getPaymentTypes();
  }
};
</script>

<style></style>
