<template>
  <div class="space-y-1">
    <data-table
      name="Uploaded Successfully"
      :items="uploadedCustomers"
      :headers="headers"
      :paginate="true"
      :rowsPerPage="20"
      :small="true"
    >
    </data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DataTable from '../../../../components/table/DataTable.vue';
export default {
  data() {
    return {
      headers: [
        { key: 'studentSchoolId', label: 'ID' },
        { key: 'fullName', label: 'Full Name' },
        { key: 'tel', label: 'tel' },
        { key: 'search_index', label: 'Search Index' },
        { key: 'status', label: 'Status' },
      ],
    };
  },
  components: { DataTable },
  computed: {
      ...mapGetters('customer', ['uploadedCustomers'])
  },
};
</script>

<style>
</style>