<template>
  <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
    <div
      class="relative isolate overflow-hidden bg-white px-6 py-4 text-center sm:px-16 sm:shadow-sm dark:bg-transparent">
      <div>
        <label
          class="tracking-widest mx-auto mt-8 relative bg-white min-w-sm max-w-2xl flex flex-col md:flex-row items-center justify-center border py-2 px-2 rounded-2xl gap-2 shadow-lg focus-within:border-gray-300"
          for="search-bar">
          <input v-model="keyword" id="search-bar"
            placeholder="Search with student name, phone number, master ID, student school ID, Bill code, transaction number ..."
            name="q" class="px-6 py-2 w-full rounded-md flex-1 outline-none bg-white dark:bg-zinc-300 tracking-widest" required="" />
          <!-- <button
            @click="handleSearch"
            class="w-full md:w-auto px-6 py-3 bg-black border-black text-white fill-white active:scale-95 duration-100 border will-change-transform overflow-hidden relative rounded-xl transition-all"
          >
            <div class="flex items-center transition-all opacity-1">
              <span
                class="text-sm font-semibold whitespace-nowrap truncate mx-auto"
              >
                Search
              </span>
            </div>
          </button> -->
        </label>
      </div>

      <div class="mt-6 mb-4 border-b border-gray-200 dark:border-gray-700">
        <ul class="flex flex-wrap -mb-px text-sm font-medium text-center" id="default-tab"
          data-tabs-toggle="#default-tab-content" role="tablist">
          <li class="mr-2" role="presentation">
            <button class="inline-block px-4 py-2  border-b-2 rounded-t-lg hover:text-gray-600 hover:border-2 hover:border-gray-500" id="students-tab" data-tabs-target="#students"
              type="button" role="tab" aria-controls="students" :aria-selected="activeTab == 'students'" :class="{
                'border-b-2 border-primary text-primary':
                  'students' === activeTab,
                'border-transparent': 'students' !== activeTab,
              }" @click="changeTab('students')">
              Students
            </button>
          </li>
          <li class="mr-2" role="presentation">
            <button
              class="inline-block px-4 py-2 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-primary dark:hover:text-gray-300"
              id="bills-tab" data-tabs-target="#bills" type="button" role="tab" aria-controls="bills"
              :aria-selected="activeTab == 'bills'" :class="{
                'border-b-2 border-primary text-primary':
                  'bills' === activeTab,
                'border-transparent': 'bills' !== activeTab,
              }" @click="changeTab('bills')">
              Bills
            </button>
          </li>
        </ul>
      </div>
      <div v-if="loading" class="">
        loading...
      </div>
      <students :keyword="keyword" v-else-if="activeTab == 'students'" ref="studentsComponent" />
      <bills v-else-if="activeTab == 'bills'" :keyword="keyword" ref="billsComponent" />
    </div>
  </div>
</template>

<script>
import Students from './Students.vue'
import Bills from './Bills.vue'

import { mapActions } from 'vuex'
export default {
  components: {Students, Bills },
  data() {
    return {
      keyword: '',
      loading: false,
      activeTab: 'bills',
      studentsComponent: null,
    }
  },
  methods: {
    ...mapActions('main', ['request']),
    changeTab(tab) {
      this.activeTab = tab
    }
  }
}
</script>

<style></style>
