import REPORT_ROUTES from './modules/report';
import SPECIAL_ROUTES from './modules/special';
import SendMessageView from '../message/views/SendMessageView.vue';
import _404 from './../views/_404.vue';
const PROTECTED_ROUTES = [
  ...SPECIAL_ROUTES,
  ...REPORT_ROUTES,
  {
    path: 'send-message',
    component: SendMessageView
  },
  {
    path: '*',
    component: _404
  }
];
export default PROTECTED_ROUTES;
