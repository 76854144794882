<template>
  <div class="flex justify-between w-full items-center text-textMedium">
    <div class="flex space-x-2">
      <div class="">
        <base-select
          :items="transactionTypeOptions"
          v-model="transactionType"
          bordered
          padding="small"
        />
      </div>
      <div>
        <base-select
          :items="typeOptions"
          v-model="type"
          bordered
          padding="small"
        />
      </div>
    </div>
    <div class="group flex space-x-4 text-sm font-medium tracking-wider">
      <div
        v-for="duration in reportDurationOptions"
        :key="duration.value"
        class="cursor-pointer hover:text-info font-hairline"
        :class="{ 'text-info': activeDuration == duration.value }"
        @click="toggle(duration.value)"
      >
        {{ duration.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      activeDuration: 'monthly',
      type: 'amount',
      transactionType: 'BANK',
      reportDurationOptions: [
        {
          label: 'All Time',
          value: 'all',
        },
        {
          label: 'This Year',
          value: 'yearly',
        },
        {
          label: 'This Month',
          value: 'monthly',
        },
        {
          label: 'This Week',
          value: 'weekly',
        },
        {
          label: 'Today',
          value: 'today',
        },
      ],
      typeOptions: [
        { label: 'Amount', value: 'amount' },
        { label: 'Count', value: 'count' },
      ],
      transactionTypeOptions: [
        {
          label: 'Transactions in Bank',
          value: 'BANK',
        },
        // {
        //   label: 'Transactions in Office',
        //   value: 'OFFICE',
        // },
      ],
    };
  },
  computed: {
    ...mapGetters('dashboard', ['']),
  },
  
  watch: {
    transactionType() {
      this.setTransactionType(this.transactionType)
    },
    type() {
      this.setType(this.type)
    },
  },

  methods: {
    ...mapActions('dashboard', ['setTransactionType', 'setType']),
    toggle(duration) {
      this.activeDuration = duration;
      this.$emit('set-active-duration', duration);
    },
  },
};
</script>

<style>
</style>