<template>
  <div class="space-y-2">
    <div class="space-y-1">
      <data-table
        :headers="headers"
        :items="customers"
        :isWorking="importing"
        :hideCount="true"
        :stripped="false"
        :canSelect="false"
        :searchable="true"
        :paginate="true"
      >
        <template #toolset>
          <div class="flex flex-wrap">
            <base-button
              :isWorking="isWorking"
              label="Upload Students"
              icon="plus"
              class="px-2 text-sm uppercase rounded"
              @click="preUploadStudents"
            />
          </div>
        </template>
        <template #default="{ index }">
          <td
            v-for="header in headers"
            :key="header.key"
            class="align-top p-0 m-0"
          >
            <div
              v-if="header.key === 'fullName'"
              class="flex space-x-2 items-center"
            >
              <div class="flex-auto">
                <base-input
                  type="text"
                  v-model="customers[index].fullName"
                  :errors="errors[index].fullName"
                  bordered
                  :size="11"
                  padding="small"
                />
              </div>
            </div>
            <div v-else-if="header.key === 'studentSchoolId'" class="px-1">
              <base-input
                v-model="customers[index].studentSchoolId"
                :errors="errors[index].studentSchoolId"
                bordered
                :size="11"
                padding="small"
              />
            </div>
            <div
              v-else-if="header.key === 'tel'"
              class="flex space-x-4 items-start"
            >
              <base-input
                v-model="customers[index].tel"
                :errors="errors[index].tel"
                bordered
                :size="11"
                padding="small"
                class="flex-auto"
                @enter="addRow(index)"
              />
            </div>
            <div
              v-else-if="header.key === 'branch_code'"
              class="flex space-x-4 items-start px-1"
            >
              <base-input
                v-model="customers[index].branch_code"
                :errors="errors[index].branch_code"
                bordered
                :size="11"
                padding="small"
                class="flex-auto"
                @enter="addRow(index)"
              />
            </div>
            <div
              v-else-if="header.key === 'action'"
              class="flex space-x-4 items-start"
            >
              <div
                class="
                  p-3
                  bg-brighterGray
                  text-textMedium
                  hover:bg-brightGray
                  hover:text-error
                  cursor-pointer
                  active:bg-danger
                  rounded-full
                "
                @click="removeRow(index)"
              >
                <BaseIcon name="times" :size="10" />
              </div>
            </div>
            <div v-else class="flex space-x-4 items-start px-1">
              <base-input
                v-model="customers[index][header.key]"
                :errors="errors[index][header.key]"
                bordered
                padding="small"
                class="flex-auto"
                @enter="addRow(index)"
              />
            </div>
          </td>
        </template>
        <template #no-data>
          <div :style="!allValid ? { color: '#ec3118' } : {}">No Record</div>
        </template>
      </data-table>
      <div class="flex space-x-2 justify-end">
        <input
          v-if="fileSelector"
          type="file"
          class="hidden"
          ref="csv-file-input"
          @input="handleFileSelect"
        />
        <base-button
          :label="importing ? 'importing' : 'import csv'"
          :primary="false"
          class="text-sm px-2 rounded"
          :class="{ 'bg-brightGray hover:text-primary': !importing }"
          :isWorking="importing"
          @click="openFileSelector"
        />
        <base-button
          label="remove all rows"
          :primary="false"
          class="text-sm px-2 rounded bg-brightGray hover:text-primary"
          @click="removeAllRows"
        />
        <base-button
          label="add row"
          :primary="false"
          class="text-sm px-2 rounded bg-brightGray hover:text-primary"
          @click="addRow(customers.length - 1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';
import DataTable from '@/components/table/DataTable.vue';
import eventBus from '@/eventBus';
// import billCustomFields from '@/mixins/billCustomFields.js';
// import eventBus from '@/eventBus';
export default {
  components: { DataTable },
  mixins: [validation], //billCustomFields],
  data() {
    return {
      allValid: false,
      errors: [],
      importing: false,
      unwathcCallbacks: [],
      fileSelector: false,
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
    ...mapGetters('customer', ['customers', 'isWorking']),
    headers() {
      return [
        { key: 'studentSchoolId', label: 'ID' },
        // ...this.customFields.map(({ key, name }) => ({
        //   key: key,
        //   label: name,
        // })),
        { key: 'fullName', label: 'Full Name' },
        { key: 'branch_code', label: 'Branch Code' },
        { key: 'tel', label: 'tel' },
        { key: 'accountNumber', label: 'Account Number' },
        { key: 'grade', label: 'grade/Dep\'t' },
        { key: 'section', label: 'section' },
        { key: 'action', label: undefined },
      ];
    },
    formTemplate() {
      let template = {
        studentSchoolId: '',
        fullName: '',
        branch_code: '',
        tel: '',
        accountNumber: '',
        grade: '',
        section: '',
      };
      // if (this.customFields)
      //   this.customFields.forEach(({ key }) => {
      //     template[key] = undefined;
      //   });
      // template['total_amount'] = ;
      return template;
    },
    totalStudents() {
      return this.customers.length;
    },
  },
  watch: {
    // customers() {
    //   this.smartSet({ stateKey: 'customers', data: this.customers });
    // },

    initial: {
      immediate: true,
      handler() {
        this.validationHandler();
        // this.setVal();
      },
    },
    customers: {
      immediate: true,
      deep: true,
      handler() {
        this.validationHandler();
        // this.setVal();
      },
    },
    headers() {
      this.validationHandler();
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    ...mapActions('customer', [
      'setStudents',
      'addStudent',
      'setUploadedStudents',
      'setWorking',
      'clear',
    ]),
    validationHandler() {
      this.resetErrors(0);
      if (!this.initial)
        this.customers.forEach((payee, i) =>
          Object.keys(this.formTemplate).forEach((key) => {
            if (!payee[key])
              this.errors[i][key] = [
                this.headers.find((header) => header.key === key).label +
                  ' is required',
              ];
            else this.errors[i][key] = [];
          })
        );
    },

    addRow(index) {
      this.setStudents([
        ...this.customers.slice(0, index + 1),
        { ...this.formTemplate },
        ...this.customers.slice(index + 1).map(({ ...rest }) => ({ ...rest })),
      ]);
    },
    removeRow(index) {
      this.setStudents([
        ...this.customers.slice(0, index),
        ...this.customers.slice(index + 1).map(({ ...rest }) => ({ ...rest })),
      ]);
    },
    removeAllRows() {
      this.clear();
      this.errors = [];
    },
    importCsv(file) {
      this.importing = true;
      this.$papa.parse(file, {
        skipEmptyLines: true,
        complete: ({ data }) => {
          this.importing = false;
          // let lastIndex = this.customers.length;
          data.slice(1).forEach((bill) => {
            let data = Object.keys(this.formTemplate).reduce(
              (customer, key, i) => ({ ...customer, [key]: bill[i] }),
              {}
            );
            // console.log(data, bill);
            this.addStudent(data);
          });
          this.resetFile();
        },
      });
    },
    handleFileSelect() {
      let file = this.$refs['csv-file-input'].files[0];
      this.importCsv(file);
    },
    openFileSelector() {
      this.fileSelector = true;
      this.$nextTick(() => {
        this.$refs['csv-file-input'].click();
      });
    },
    resetFile() {
      this.fileSelector = false;
    },
    resetErrors(startIndex) {
      if (startIndex < 0) startIndex = 0;
      this.customers
        .slice(startIndex)
        .forEach(
          (payee, i) =>
            (this.errors[i + startIndex] = Object.keys(
              this.formTemplate
            ).reduce((acc, key) => ({ ...acc, [key]: [] }), {}))
        );
    },
    resetForm() {
      this.customers = [];
    },
    async preUploadStudents() {
      let componentProps = {
        title:
          'UPLOAD ' + this.customers.length.toLocaleString() + ' STUDENTS',
        message:
          'Are you sure you really want to upload ' +
          this.customers.length.toLocaleString() +
          ' total customers',
      };
      eventBus.$emit('open-modal', {
        modalId: 3,
        componentProps,
        cb: this.uploadStudents,
      });
    },
    async uploadStudents() {
      this.setWorking(true);
      const response = await this.request({
        method: 'post',
        url: 'billing/customer/upload-customers',
        data: {
          customers: this.customers,
        },
      });
      if (response) {
        this.setUploadedStudents(response.items);
        eventBus.$emit('open-toast', {
          error: false,
          message: response.items.length.toLocaleString() + ' customers uploaded successfully',
        });
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror
        });
      }
      this.setWorking(false);
    },
  },
  validations() {
    return {
      customers: {
        ...this.customers.reduce(
          (acc, payee, index) => ({
            ...acc,
            [index]: {
              ...Object.keys(this.formTemplate).reduce(
                (acc, key) => ({ ...acc, [key]: { required } }),
                {}
              ),
            },
          }),
          {}
        ),
      },
    };
  },
};
</script>

<style></style>
