<template>
  <div class="p-1">
    <div class="flex p-2 mx-1 justify-around">
    <span class=" flex items-center justify-center  ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                    <img src="@/assets/lionf.jpg" class="h-48 w-48 rounded-full " alt="" />

        </span>
      <section class="bg-white dark:bg-gray-900">
        <div
          class="py-1 px-1 mx-auto max-w-screen-xl text-start justify-items-start lg:py-1"
        >
          <h1
            class="mb-1 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-xl dark:text-white"
          >
            {{ payment.payment_type }}
          </h1>
          <p
            class="mb-1 text-lg font-normal text-gray-500 lg:text-xl sm:px-1 lg:px-4 dark:text-gray-400"
          >
            Payment Code: <span class="mx-2">{{ payment.payment_code }}</span>
          </p>
          <div
            class="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0"
          >
            <div class="flex p-3 space-x-3 font-mono">
              <a
                href="#"
                class="inline-flex justify-center items-center py-1 px-1 sm:ms-4 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              >
                Start Date:<span class="mx-1 text-xs font-bold">{{
                  toYYYYMMDD(payment.payment_start_date)
                }}</span>
              </a>
              <a
                href="#"
                class="inline-flex justify-center items-center py-1  sm:ms-4 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              >
                End Date:
                <span class="flex mx-1 font-bold text-xs">{{
                  toYYYYMMDD(payment.payment_end_date)
                }}</span>
              </a>
              <a
                href="#"
                class="inline-flex justify-center items-center py-1  sm:ms-4 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              >
                Exp. Date:
                <span class="mx-1 font-bold text-xs">{{
                  toYYYYMMDD(payment.payment_exc_date)
                }}</span>
              </a>
            </div>
          </div>
          <div
            class="flex justify-between  rounded border border-blue-400"
          >
            <div class="flex justify-between space-x-5 mx-12 items-center">
              <div>
                <h5
                  class="mb-1 text-xl font-medium text-gray-900 dark:text-white"
                >
                  {{ payment.no_tickets }}
                </h5>
                <span class="text-sm text-gray-500 dark:text-gray-400"
                  >Number Of Tickets</span
                >
              </div>
              <base-button
                :isWorking="isWorking"
                v-if="!tickets.length&&isGranted('0892tt')"
                label="Generate"
                class="px-1  flex text-sm uppercase rounded"
                @click="generateTickets"
              />
            </div>
            <div class="flex">
              <div>
                <h5
                  class=" text-xl mx-5 font-medium text-gray-900 dark:text-white"
                >
                  ${{ payment.amount }}
                </h5>
                <span class="text-sm text-gray-500 dark:text-gray-400"
                  >Ticket Price</span
                >
              </div>
              <base-button
                :isWorking="issWorking"
                v-if="tickets.length&&isGranted('0892tt')"
                label="Add 1000"
                class="px-1  flex text-sm uppercase rounded"
                @click="addTickets"
              />
            </div>
          </div>
          <div class="flex justify-center">
            <a
              v-for="rp in repo"
              :key="rp"
              aria-current="page"
              class="flex px-4 py-2 text-sm font-medium text-blue-700 bg-white rounded-s-lg hover:bg-gray-100 focus:z-10 focus:ring-2 justify-items-center text-center space-x-3 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
            >
              <div
                class="flex-col items-center gap-2 border rounded p-5 font-mono"
              >
                <span class="text-lg">{{
                  rp._id ? 'Paid Tickets' : 'Unpaid  Tickets'
                }}</span>
                <div class="text text-xs text-gray-800 border-t">
                  <div class="flex items-center gap-2">
                    <p class="text text-xs text-gray-800">Amount:</p>
                    <p>{{ rp.amount }} Birr</p>
                  </div>
                  <div class="flex items-center gap-2">
                    <p class="text text-xs text-gray-800">Count:</p>
                    <p>{{ rp.count }} Tickets</p>
                  </div>
                </div>
              </div>
            </a>

            <div></div>
          </div>
        </div>
      </section>
      <div>
        <div
          class="w-full bg-white border border-gray-200 flex justify-around rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
        >
          <div class="flex-col items-center gap-2 border rounded p-1 font-mono">
            <span class="text-sm">Assigned To Agents</span>
            <div class="text text-xs text-gray-800 border-t">
              <div
                class="grid grid-cols-2 border-b space-x-8 text-sm font-semibold"
              >
                <div class="flex space-x-8">
                  <p class="text text-sm text-gray-800">range</p>
                  <p>Agent</p>
                </div>
                <div class="flex space-x-8">
                  <p class="text text-sm text-gray-800">range</p>
                  <p>Agent</p>
                </div>
              </div>
              <div
                v-if="assinned?.agente_ticket.length"
                class="grid grid-cols-2 space-x-1"
              >
              <div
              v-for="(agent, i) in assinned?.agente_ticket"
              :key="i"
              class="flex space-x-2 border-l p-1 "
            >
              <div class=" grid grid-cols-2 space-x-5">
                <span>{{agent.range}}</span>
                <span>{{agent.name}}</span>
                
              </div>
            </div>
            </div>

              <div  v-else class="grid grid-cols-2 space-x-1">
                <div
                  v-for="(agent, i) in agents"
                  :key="i"
                  class="flex space-x-0 border-l"
                  >
                  <div                  v-if="isGranted('0893tt')" 
                  class="flex" >
                    <base-input
                      type="type"
                      placeholder="1-1000"
                      v-model="Form[i].range"
                      required
                    />
                    <base-select
                      :items="agentsOps"
                      v-model="Form[i].name"
                      :searchable="true"
                    >
                      <template #default="{ item }">
                        <div class="flex items-center">
                          <div class="capitalize">
                            {{ String(item.label).substring(0, 6) }}
                          </div>
                        </div>
                      </template>
                      <template #option="{ item }">
                        <div class="flex items-center">
                          <div>{{ String(item.label) }}</div>
                        </div>
                      </template>
                    </base-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <base-button
          :isWorking="isTWorking"
          v-if="tickets.length&&!assinned?.agente_ticket.length"
          label="Assign Tickets"
          class="px-1 py-1 flex text-sm uppercase rounded"
          @click="assignTickets"
        />
        <base-button
          :isWorking="isTWorking"
          v-if="assinned?.agente_ticket.length"
                    label="Check Agent Tickets"
          class="px-1 py-1 flex text-sm uppercase rounded"
          @click="checkTickets(assinned?.agente_ticket)"
        />
      </div>
    </div>
    <bar-loader class="w-12 mx-auto block" v-if="working" />
    <div class="flex justify-between">
      <div>* * *</div>
      <div>* * *</div>
      <div>* * *</div>
      <div>* * *</div>
      <div>* * *</div>
      <div>* * *</div>
      <div class="text text-green-700 font-semibold text-xs py-2">
        Filter Items By Payment Status
      </div>
      <div class="inline-flex rounded-md shadow-sm mx-16">
        <a
          @click="toggleButton('')"
          aria-current="page"
          class="px-4 py-2 text-sm font-medium text-blue-700 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
        >
          All
        </a>
        <a
          @click="toggleButton('?isPaid=true')"
          aria-current="page"
          class="px-4 py-2 text-sm font-medium text-blue-700 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
        >
          Paid
        </a>
        <a
          @click="toggleButton('?isPaid=false')"
          class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
        >
          Unpaid
        </a>
        <a
           @click="toggleButton('?printed=true')"
          class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
        >
          Printed
        </a>
        <a
        @click="toggleButton('?confirmed=true')"
          class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
        >
          Scanned
        </a>
        
      </div>
    </div>
    <data-table
      v-if="!working"
      name="TICKEST"
      :headers="headers"
      :items="tickets"
      :canSelect="true"
      :hasDynamicActions="false"
      :paginate="true"
      :rowsPerPage="8"
      :searchable="true"
    >
      <template #default="{ dtItem }">
        <td v-for="(header, i) in headers" :key="i" class="py-3 px-6">
          <div v-if="header.key === 'status'" class="flex">
            <div
              class="inline-block px-3 text-sm text-white lowercase rounded"
              :style="{
                backgroundColor: getColor(dtItem.status),
              }"
            >
              {{ dtItem.status }}
            </div>
          </div>
          <div v-else-if="header.key === 'autoGenerateStudentId'" class="flex">
            <switch-button
              class="flex-shrink-0"
              :value="dtItem.autoGenrateStudentId"
            />
          </div>
          <div v-else>
            {{ getProp(dtItem, header.key) }}
          </div>
        </td>
      </template>
    </data-table>
  </div>
</template>
<script>
import DataTable from '@/components/table/DataTable.vue';
import { getPropByString } from '@/utils';
import { mapActions, mapGetters } from 'vuex';
import BarLoader from '@/components/collection/BarLoader.vue';
import SwitchButton from '../../../components/base/SwitchButton.vue';
import eventBus from '@/eventBus';
import { toYYYYMMDD } from '@/utils';

export default {
  props: [],
  components: { DataTable, BarLoader, SwitchButton },
  data() {
    return {
      buttonState: false,
      selectedId: '',
      working: false,
      isWorking: false,
      isTWorking: false,
      issWorking: false,
      tickets: [],
      payment: {},
      repo: [],
      agents: [],
      assinned: {},
      agentsOps: [],
      Form: [
        { name: '', range: '' },
        { name: '', range: '' },
        { name: '', range: '' },
        { name: '', range: '' },
      ],

      headers: [
        {
          label: 'Organization',
          key: 'orgName',
        },
        {
          label: 'ticket id',
          key: 'ticketId',
        },
        {
          label: 'bill code',
          key: 'billCode',
        },
        {
          label: 'TICKET PRICE',
          key: 'amount',
        },
        { key: 'isPaid', label: 'paid' },
        { key: 'confirmed', label: 'Scanned' },
        { key: 'paymentDate', label: 'TimeStamp', format: 'date' },
      ],
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
    ...mapGetters(['user']),
  },
  watch: {},
  methods: {
    ...mapActions('main', ['setData', 'request']),
    getProp: getPropByString,
    toYYYYMMDD,
    async ticketDetail() {
      let id = this.$route.params.id;
      this.dworking = true;
      this.payment = {};
      let response = await this.request({
        method: 'get',
        url: `billing/events/` + id,
      });
      if (response && !response.error) {
        this.payment = response.item;
        this.getBills();
        this.getReport();
      }
      this.dworking = false;
    },
    async ticketAgents() {
      this.dworking = true;
      this.agents = [];
      let response = await this.request({
        method: 'get',
        url: `billing/events/get/agents`,
      });
      if (response && !response.error) {
        this.agents = response.items;
        this.agentsOps = response.items.map(({ name }) => ({
          label: name,
          value: name,
        }));
      }
      this.dworking = false;
    },

    async toggleButton(query) {
      let id = this.$route.params.id;
      this.working = true;
      this.tickets = {};
      let rep_response = await this.request({
        method: 'get',
        url: `billing/events/sum-bills/` + id,
      });
      this.repo = rep_response;
      let response = await this.request({
        method: 'get',
        url: `billing/events/bill-status/` + id +query,
      });
      this.working = false;
      if (response && !response.error) {
        this.tickets = response.items;
      }
      this.getReport();
    },
    async getReport() {
      let id = this.$route.params.id;
      this.repo = {};
      let response = await this.request({
        method: 'get',
        url: `billing/events/sum-bills/` + id,
      });
      if (response && !response.error) {
        this.repo = response.record;
      }
    },
    async getBills() {
      let id = this.$route.params.id;
      this.working = true;
      this.tickets = [];
      let response = await this.request({
        method: 'get',
        url: `billing/events/bills/` + id,
      });
      this.working = false;
      if (response && !response.error) {
        this.tickets = response.items;
      }
    },

    getColor(status = '') {
      let colors = {
        verified: 'teal',
        pending: 'orange',
        suspended: 'red',
      };
      return colors[status.toLowerCase()];
    },

    async generateTickets() {
      let id = this.$route.params.id;
      this.isWorking = true;
      const response = await this.request({
        method: 'post',
        url: 'billing/events/generatebills/' + id,
      });
      if (response) {
        eventBus.$emit('open-toast', {
          error: false,
          message: 'Tickets Generated successfully',
        });
        this.isWorking = true;
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror,
          timeout: 120,
        });
        this.isWorking = false;
      }
      this.getBills();
    },
    async addTickets() {
      let id = this.$route.params.id;
      this.issWorking = true;
      const response = await this.request({
        method: 'post',
        url: 'billing/events/addbills/' + id,
        data: {
          number: 1000,
        },
      });
      if (response) {
        eventBus.$emit('open-toast', {
          error: false,
          message: 'Tickets Generated successfully',
        });
        this.issWorking = false;
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror,
          timeout: 120,
        });
        this.issWorking = false;
      }
      this.getBills();
    },
    async assignTickets() {
      let id = this.$route.params.id;
      this.isTWorking = true;
      const response = await this.request({
        method: 'post',
        url: 'billing/events/add/agentsmap/' + id,
        data: {
          agente_ticket: this.Form,
        },
      });
      if (response) {
        eventBus.$emit('open-toast', {
          error: false,
          message: 'Tickets Assigned successfully',
        });
        this.isTWorking = false;
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror,
          timeout: 120,
        });
        this.isTWorking = false;
      }
    },
    async getassignTickets() {
      let id = this.$route.params.id;
      this.isTWorking = true;
      const response = await this.request({
        method: 'get',
        url: 'billing/events/get/agentmaps/' + id,
      });
      if (response) {
        this.assinned = response.items[0];

        this.isTWorking = false;
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror,
          timeout: 120,
        });
        this.isTWorking = false;
      }
    },
    async checkTickets(ranges){
        eventBus.$emit('open-modal', {
          modalId: 333,
          componentProps: {
            ranges,
          },
          cb: this.getassignTickets,
        });
      
    }
  },
  mounted() {
    this.ticketDetail();
    this.ticketAgents();
    this.getassignTickets();
  },
};
</script>
<style></style>
