<template>
  <div class="text-textMedium space-y-4">
    <div
      class="
        text-2xl
        uppercase
        py-2
        shadow-md
        text-textDark
        font-semibold
        bg-brighterGray
        text-center
      "
    >
     {{action == 'reg' ? 'ADD NEW ROLE' : 'ROLE'}}
    </div>
    <form class="space-y-4 p-4 pb-4">
      <base-input
        type="text"
        placeholder="Role Name"
        label="NAME"
        bordered
        :readOnly="action == 'view'"
        required
        :min="0"
        v-model="Form.role_name"
        @enter="handleSave"
      />
      <base-input
        type="text"
        placeholder="Code"
        label="Code"
        bordered
        :readOnly="action == 'view'"
        required
        :min="0"
        v-model="Form.role_code"
        @enter="handleSave"
      />
      <base-text-area
        placeholder="Description"
        type="text"
        :readOnly="action == 'view'"
        label="Description"
        bordered
        required
        v-model="Form.role_description"
      />
      <div class="flex justify-between mt-3">
        <base-button
          label="cancel"
          class="
            py-1
            px-4
            text-textMedium
            bg-brightGray
            hover:bg-danger
            hover:text-error
            rounded
          "
          @click="close"
        />
        <base-button
          :label="btnLabel"
          icon="check-circle"
          :isWorking="isWorking"
          class="py-1 px-4 rounded"
          @click="handleSave"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../eventBus';
export default {
  props: {
    action: {
      type: String,
      default: 'reg',
    },
    btnLabel: {
      type: String,
      default: 'Save',
    },
    role: {}
  },
  data() {
    return {
      isWorking: false,
      Form: {
        role_name: undefined,
        role_code: undefined,
        role_description: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('main', ['berror'])
  },
  methods: {
    ...mapActions('main', ['request']),
    async handleSave() {
      let url = 'billing/roles/addrole';
      let method = 'post'
      if(this.action == 'edit' && this.role) {
        url = 'billing/roles/role/'+this.role._id;
        method= 'put'
      }
      this.isWorking = true;
      const response = await this.request({
        method,
        url,
        data: this.Form,
      });
      if (response) {
        this.isWorking = false;
        this.$emit('close', {
          invokeCallBack: true,
          response: response.item,
        });
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror
        })
      }
      this.isWorking = false;
    },
    close() {
      this.$emit('close');
    },
  },
  created() {
    if (this.role) {
      this.Form.role_name = this.role.role_name
      this.Form.role_code = this.role.role_code
      this.Form.role_description = this.role.role_description
    }
  },
};
</script>

<style></style>
