<template>
  <div
    class="
      flex
      justify-around
      space-x-8
      bg-white
      rounded
      self-center
      p-4
      shadow-md
    "
  >
    <sum-report-card
      v-for="(data, index) in totalDatas"
      :key="index"
      :data="data"
      :categories="categories"
      class=""
      :class="{ 'border-r border-borderGray': index != totalDatas.length - 1 }"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SumReportCard from './SumReportCard.vue';
export default {
  components: { SumReportCard },
  props: ['categories', 'series', 'penalties'],
  data() {
    return {
      // totalDatas: [
      //
      // ],
    };
  },
  computed: {
    ...mapGetters('dashboard', ['type']),
    totalDatas() {
      if (this.categories.length > 0 && this.series.length > 0) {
        const totalDatas = this.categories.map((name, i) =>
          this.series.reduce((sum, item) => sum + item.data[i], 0)
        );
        const penaltyDatas = this.categories.map(
          (item, i) => this.penalties[i]
        );
        return [
          ...this.series.map((item) => ({
            title: item.name,
            series: item,
            total: item.data.reduce((total, value) => total + value, 0),
            footer: this.type == 'amount' ? 'ETB' : '',
            color: item.color,
          })),
          {
            title: 'Total',
            series: {
              name: 'TOTAL',
              data: totalDatas,
            },
            total: totalDatas.reduce((total, value) => total + value, 0),
            footer: this.type == 'amount' ? 'ETB' : '',
            color: '#04A9F5',
          },
          {
            title: 'Penalty',
            series: {
              name: 'Penalty',
              data: penaltyDatas,
            },
            total: penaltyDatas.reduce((total, value) => total + value, 0),
            footer: 'ETB',
            color: '#04A9F5',
          },
        ];
      } else return [];
    },
  },
};
</script>

<style>
</style>