// import apiService from './apiService';
// import { handleError } from './handleErrorService';
import { userInfoService } from './storageService';
const activityService = {
  isGranted: function(activity_code) {
    return userInfoService.getUser().authorized_actions.includes(activity_code)
  },
  hasGrantedActivity: function(activities_code) {
    return activities_code.some(this.isGranted)
  },
};

export { activityService };
