import DASHBOARD from '@/views/protected/common/Dashboard'
import UTILITIES from '@/views/protected/Utilities/Utilities'
import TICKETS from '@/views/protected/Utilities/Tickets'
import MATCHES from '@/views/protected/Utilities/matches'
import TICKETDetail from '@/views/protected/Utilities/TicketDetail'
import BRANCHES from '@/views/protected/Utilities/Branches'
import AllBranches from '@/views/protected/Utilities/AllBranches'
import UPLOAD_PAYMENTS from '@/views/protected/Utilities/UploadPayments'
import UPLOAD_STUDENTS from '@/views/protected/Utilities/UploadCustomers'
import PAYMENTS from '@/views/protected/Utilities/Payments'
import STUDENTS from '@/views/protected/Utilities/Customers'
import PAYMENT_DETAIL from '@/views/protected/Utilities/PaymentDetail'
import ROLES from '@/views/protected/DynamicRole/Roles'
import DYNAMIC_ACTION from '@/views/protected/DynamicRole/DynamicAction'
import DYNAMIC_ORG_ACTION from '@/views/protected/DynamicRole/DynamicOrgAction'
import CONFIGS from '@/views/protected/common/Configs'
import CREDENTIALS from '@/views/protected/common/Credentials'
import STUDENT_DETAIL from '@/views/protected/common/CustomerDetail'
import STUDENT_REGISTRATION from '@/components/forms/CustomerRegistration'
import SEARCH from '@/views/protected/common/Search'
import Settings from '@/views/protected/common/Settings.vue'

import PaymentTypes from '@/views/protected/payment/PaymentTypesList.vue'
import PaymentTerms from '@/views/protected/payment/PaymentTermsList.vue'

import Migration from '@/views/protected/migration/Index.vue'

const publicMeta = {
  public: false
};

const SPECIAL_ROUTES = [
  {
    path: '',
    component: DASHBOARD,
    meta: {code: ""}
  },
  {
    path: 'schools',
    component: UTILITIES,
    meta: {code: "0888ut"}
  },
  {
    path: 'tickets',
    component: TICKETS,
    meta: {code: "0888tt"}
  },
  {
    path: 'matches',
    component: MATCHES,
    meta: {code: "0888tt"}
  },
  {
    path: 'ticketdetail/:id',
    component: TICKETDetail,
    meta: {code: "0892tt"}
  },
  {
    path: 'all-branches',
    component: AllBranches,
    meta: {code: "0888ut"}
  },
  {
    path: 'settings',
    component: Settings,
    meta: {}
  },
  {
    path: 'branches',
    component: BRANCHES,
    meta: {code: "0666o"}
  },
  {
    path: 'upload-payments',
    component: UPLOAD_PAYMENTS,
    meta: {code: "0009up"}
  },
  {
    path: 'payments',
    component: PAYMENTS,
    meta: {code: "0999up"}
  },
  {
    path: 'payment-types',
    component: PaymentTypes,
    // meta: {code: "0999up"}
  },
  {
    path: 'payment-terms',
    component: PaymentTerms,
    // meta: {code: "0999up"}
  },
  {
    path: 'migration',
    component: Migration,
    // meta: {code: ""}
  },
  {
    path: 'customers',
    component: STUDENTS,
    meta: {code: "0111c"}
  },
  {
    path: 'upload-customers',
    component: UPLOAD_STUDENTS,
    meta: {code: "0001uc"}
  },
  {
    path: 'register-customer',
    component: STUDENT_REGISTRATION,
    meta: {code: "0001c"}
  },
  {
    path: 'payment/:id',
    component: PAYMENT_DETAIL,
    meta: {code: "0999up"}
  },
  {
    path: 'action-list',
    component: ROLES,
    meta: {code: "0888ut"}
  },
  {
    path: 'role-activities/:id',
    component: DYNAMIC_ACTION,
    meta: {code: "0888ut", to: 'role'}
  },
  {
    path: 'org-activities/:id',
    component: DYNAMIC_ORG_ACTION,
    meta: {code: "0888ut", to: 'role'}
  },
  {
    path: 'credential-activities/:id',
    component: DYNAMIC_ACTION,
    meta: {code: "0888ut", to: 'credential'}
  },
  {
    path: 'configs',
    component: CONFIGS,
    meta: {code: "0888ut"}
  },
  {
    path: 'credentials',
    component: CREDENTIALS,
    meta: {code: "0444c"}
  },
  {
    path: 'student-detail',
    name: 'customer-detail',
    component: STUDENT_DETAIL,
    meta: {code: "0111sc"}
  },
  {
    path: 'search',
    component: SEARCH,
    meta: {code: "0444c"}
  },

].map(({ meta, ...route }) => ({
  ...route,
  meta: {
    ...meta,
    ...publicMeta
  }
}));
export default SPECIAL_ROUTES;
