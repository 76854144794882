<template>
  <div class="rounded p-1 bg-brightGray flex items-center">
    <div
      v-for="(item, i) in items"
      :key="i"
      :class="{
        'active-switch bg-primary text-white rounded py-1':
          item.value === value,
      }"
      class="px-2 cursor-pointer w-full text-center text-textDark"
      style="min-width: fit-content"
      @click="changeChoice(item.value)"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['items', 'value'],
  methods: {
    changeChoice(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped>
.active-switch {
  color: white;
}
</style>