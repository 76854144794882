<template>
  <section>
  <div
      id="printed"
      class="min-h-full p-2 bg-gray-200 dark:bg-primary"
    >
    
      <div class="flex flex-col justify-center px-3 mx-auto">
        <div
          class="flex flex-col items-center justify-center h-full px-5 bg-white"
        >
          <div
            class="flex items-center justify-start px-2 pt-12 mx-2"
          >
            <img class="flex items-center w-20 h-20 mx-12 rounded-full " src="@/assets/Lion Bank.jpg" />
            <div
              class="m-1 text-2xl font-semibold text-primary uppercase   dark:text-white"
            >
              LION INternational Bank S.c
            </div>
          </div>
          <div
            class="flex p-1 mt-3 font-serif text-lg font-normal text-justify text-gray-900 underline text"
          >
           Anbessa School Pay Transaction Receipt
          </div>
          <div></div>

          <div class="bg-white dark:bg-gray-900 flex    ">
          <div
                class="absolute  items-center flex-shrink pt-12 ml-32 text-6xl uppercase  w-full  opacity-25 "
              >
                <img src="@/assets/Lion Bank.jpg" class="w-40 h-40 flex justify-center" />
              </div>
            <div class="pb-12 mx-5 mt-5 font-mono justify-center items-center">
              
              <div class="grid grid-cols-3 gap-0 px-5 py-0">
                <div class="col-span-1 mr-5">
                  <h2 class="text-sm text-gray-900 dark:text-white">
                    Payment Code:
                  </h2>
                </div>
                <div class="col-span-2">
                  <div class="text-sm text-gray-900 dark:text-white">
                    {{ bill.billCode || "--" }}
                  </div>
                </div>
                <div class="col-span-1 mr-5">
                  <h2 class="text-sm text-gray-900 dark:text-white">
                   School Name :
                  </h2>
                </div>
                <div class="col-span-2">
                  <div class="text-sm font-bold text-gray-900 dark:text-white">
                    {{ bill.schoolName || "--" }} ({{bill.schoolAccountNumber}})
                  </div>
                </div>
                <div class="col-span-1 mr-5">
                  <h2 class="text-sm text-gray-900 dark:text-white">
                    Registration N0:
                  </h2>
                </div>
                <div class="col-span-2">
                  <div class="text-sm text-gray-900 dark:text-white">
                    {{ bill.studentSchoolId || "--" }}
                  </div>
                </div>
                <div class="col-span-1 mr-5">
                  <h2 class="text-sm text-gray-900 dark:text-white">
                     Name:
                  </h2>
                </div>
                <div class="col-span-2">
                  <div class="text-sm text-gray-900 dark:text-white">
                    {{ bill.studentName || "--" }}
                  </div>
                </div>
                <div class="col-span-1 mr-5">
                  <h2 class="text-sm text-gray-900 dark:text-white">Amount:</h2>
                </div>
                <div class="col-span-2">
                  <div class="text-sm font-bold text-gray-900 dark:text-white">
                    {{ Number(Number(bill.amount).toFixed(2)).toLocaleString() || "--" }}
                  </div>
                </div>
                <div class="col-span-1 mr-5">
                  <h2 class="text-sm text-gray-900 dark:text-white">
                    Amount In Words:
                  </h2>
                </div>
                <div class="col-span-2">
                  <div class="text-sm text-gray-900 dark:text-white">
                    {{ _2Word(bill.amount) || "--" }}
                  </div>
                </div>
                <div class="col-span-1 mr-5">
                  <h2 class="text-sm text-gray-900 dark:text-white">Date:</h2>
                </div>
                <div class="col-span-2">
                  <div class="text-sm text-gray-900 dark:text-white">
                    {{ bill.createdAt || "--" }}
                  </div>
                </div>

                
                <div class="col-span-1 mr-5">
                  <h2 class="text-sm text-gray-900 dark:text-white">
                    Trans Type:
                  </h2>
                </div>
                <div class="col-span-2">
                  <div class="text-sm text-gray-900 uppercase dark:text-white">
                    School Payment
                  </div>
                </div>
                <div class="col-span-1 mr-5">
                  <h2 class="text-sm text-gray-900 dark:text-white">
                    Reference Number:
                  </h2>
                </div>
                <div class="col-span-2">
                  <div class="text-sm text-gray-900 dark:text-white">
                    {{ bill._id || "--" }}
                  </div>
                </div>
                
                <div class="col-span-1 mr-5">
                  <h2 class="text-sm text-gray-900 dark:text-white">
                    Description:
                  </h2>
                </div>
                <div class="col-span-2">
                  <div class="text-sm text-gray-900 dark:text-white">
                    {{ bill.studentSchoolId+'-'+bill.studentName+'-'+ bill.paymentTerm  }}
                  </div>
                </div>
              </div>

              <div class="flex flex-row">
              <div class="mx-5 mt-5 text-xs">
                This is invalid unless a Digital stamp  is put.
              </div>
              <div
                class="  opacity-25 "
              >
                <img src="@/assets/Lion Bank.jpg" class="w-12 h-12 flex justify-center" />
              </div>
              </div>
              
              <div
                class="flex justify-between text-xs text-blue-400 border-t-2 border-gray-700"
              >
                <span>For any information, please call 8803.</span>
                <span>LIB-key to Success</span>
                <span>                   Powered by Henon EdTech 
</span>
              </div>
               
            </div>
          </div>
        </div>
        
      </div>
      </div>
      <div class="flex flex-row  space-x-32 justify-between mx-12">
      <base-button
            label="Close"
            @click="handleClose"
           class="p-2 text-white bg-red-700 rounded-lg w-40 "
          />
      <base-button
            label="Print"
            @click="printeSlip('printed')"
           class=" p-2 text-white bg-primary rounded-lg w-80 "
          />
      </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import eventBus from '../../../eventBus';

export default {
  props: ['bill'],
  data() {
    return {
        notP:false,
      Form: {
        tel: '',
        account_no: '',
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters('main', ['berror'])
  },
  methods: {
    ...mapActions('main', ['request']),
     _2Word(n) {
  let includesDecimalPt = n.toString().includes(".");
  let decimal = n.toFixed(2).split(".");
  if (includesDecimalPt)
    return (
      this.number2words(n) + "Birr and " + this.number2words(+decimal[1]) + " cents Only"
    );
  return this.number2words(n) + " Birr Only";
},

 number2words(n) {
  n = ~~n;
  var num =
    "zero One Two Three Four Five Six Seven Eight Nine Ten Eleven Twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(
      " "
    );
  var tens = "twenty thirty fourty fifty sixty seventy eighty ninety".split(
    " "
  );

  if (n < 20) return num[n];
  var digit = n % 10;
  if (n < 100)
    return tens[~~(n / 10) - 2] + (digit ? "-" + num[digit] + "" : "");
  if (n < 1000)
    return (
      num[~~(n / 100)] +
      " Hundred" +
      (n % 100 == 0 ? "" : "-" + this.number2words(n % 100))
    );

  if (n < 1000000)
    return (
      this.number2words(~~(n / 1000)) +
      " thousand " +
      (n % 1000 == 0 ? "" : "" + this.number2words(n % 1000) + "")
    );

  return (
    this.number2words(~~(n / 1000000)) +
    " Million" +
    (n % 1000000 != 0 ? "-" + this.number2words(n % 1000000) : "")
  );
},
    handleClose() {
      this.$emit('close')
    },
     printeSlip(id) {
  this.notP = false;
  let printContents = document.getElementById(id).innerHTML;
  let originalContents = document.body.innerHTML;
  document.body.innerHTML = printContents;
  window.print();
  document.body.innerHTML = originalContents;
  this.notP = true;
  window.location.reload();
}
  },

  
};
</script>

<style></style>
