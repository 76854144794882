<template>
  <div
    v-if="open"
    class="
      toast-notif
      fixed
      bg-brightGray
      p-4
      rounded
      shadow-2xl
      space-y-1
      flex
      space-x-4
      items-start
    "
    style="min-width: 25vw; max-width: 30vw"
  >
    <div class="py-2">
      <BaseIcon name="envelope" :size="20" class="text-textDarkest" />
    </div>

    <div class="flex-auto">
      <div class="text-sm font-bold font-sans tracking-wider">
        New Notification
      </div>
      <div class="text-sm text-textMedium">
        {{ message }}
      </div>
    </div>
    <div
      class="py-2 cursor-pointer text-textMedium hover:text-primary grow-active"
      @click="handleClose"
    >
      <BaseIcon name="times" />
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus.js';
export default {
  data() {
    return {
      open: false,
      message: 'No message',
      error: false,
    };
  },
  methods: {
    handleClose() {
      this.open = false;
    },
    makeSound() {
      let player = document.createElement('video');
      player.playsInline = true;
      player.src = 'test.mp3';
      player.play();
    },
    closeLater(timeout) {
      setTimeout(() => {
        this.handleClose();
      }, timeout * 1000);
    },
  },
  created() {
    eventBus.$on(
      'open-notification',
      ({ message, timeout = 5, error = false }) => {
        this.message = message;
        this.error = error;
        this.open = true;
        this.makeSound();
        this.closeLater(timeout);
      }
    );
  },
};
</script>

<style>
.toast-notif {
  right: 6rem;
  bottom: 4rem;
  z-index: 10010;
  animation: shakeToast 0.7s;
}
@keyframes shakeToast {
  0% {
    transform: translateY(-30%);
  }
  50% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
