import axios from 'axios';
import { tokenService } from './storageService';
import { BASE_URL } from '../config/config';
import eventBus from '../eventBus';

const requestInterceptor = config => {
  /** Add logging here */

  // const nontrancationroutes = ['customer', 'credential', 'office', 'account', 'transtype', 'datamigration', 'config', 'config', 'confaccounttypes'];
  // const method = config.method == 'get' ? 'get' : nontrancationroutes.includes(config.url.split('/')[1]) ? 'nontr' : 'post';
  // config.baseURL = config.baseURL.split('/').slice(0, 3).join('/') + `/${method}/` + config.baseURL.split('/').slice(3).join('/');
  // eventBus.$emit('reset-inactivity-counter');
  return config;
};

const apiService = {
  interceptor: null, // Mark: - 401 interceptor
  init(baseURL) {
    axios.defaults.baseURL = baseURL;
    axios.defaults.timeout = 500000000;
  },

  setHeader() {
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${tokenService.getToken()}`;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource) {
    return axios.get(resource);
  },

  post(resource, data) {
    return axios.post(resource, data);
  },

  put(resource, data) {
    return axios.put(resource, data);
  },

  delete(resource) {
    return axios.delete(resource);
  },

  customRequest(data) {
    return axios(data);
  },
  addInterceptor() {
    axios.interceptors.request.use(requestInterceptor);
  },

  mount401Interceptor() {
    this.interceptor = axios.interceptors.response.use(
      response => response,
      async error => {
        if (error.response.status === 401 || error.message.includes(401)) {
          eventBus.$emit('sign-out');
        }
        // If error was not 401 just reject as is
        throw error;
      }
    );
  },
  requestAgainOnServerBusy() {
    this.interceptor = axios.interceptors.response.use(
      response => {
        // console.info({ response });
        return response;
      },
      async error => {
        if (error.response.status === 503) {
          console.info({ error });
          return await axios.request(error.config);
        }
      }
    );
  },

  getBaseUrl() {
    return BASE_URL;
  }
};

export default apiService;
