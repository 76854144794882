import Vue from 'vue';
import VueRouter from 'vue-router';
import { tokenService, userInfoService } from '../services/storageService';
import OPEN_ROUTES from './open';
import PROTECTED_ROUTES from './protected';
import MainEntry from './../views/entries/MainEntry.vue';
import ConfigEntry from './../views/entries/ConfigEntry.vue';
import ROLES from '../config/roles';
import DASHBOARDS from '../config/dashboards';
import { activityService } from "@/services/activityService";

Vue.use(VueRouter);

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        ...OPEN_ROUTES,
        {
            path: '/config',
            component: ConfigEntry,
        }, {
            path: '/',
            component: MainEntry,
            children: PROTECTED_ROUTES
        },


    ]
});

router.beforeEach((to, from, next) => {
    const isPublic = to.matched.some(route => route.meta.public);
    const onlyWhenLoggedOut = to.matched.some(
        route => route.meta.onlyWhenLoggedOut
    );
    const loggedIn = !!tokenService.getToken();

    if (loggedIn) {
        const role = userInfoService.getUser().role;
        const currentDashboard =
            DASHBOARDS[Object.keys(ROLES).find(key => ROLES[key] === role)];

        if (onlyWhenLoggedOut && isPublic) return next({path: '/'});

        const grantedActivity = to.meta.code;
        if (grantedActivity && !activityService.isGranted(grantedActivity))
            return next(currentDashboard);
    } else {
        if (!isPublic)
            return next({
                path: '/signin'
            });
    }

    next();
});

export default router;