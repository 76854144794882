import apiService from './apiService';
import { handleError } from './handleErrorService';
import { tokenService, userInfoService } from './storageService';

class AuthenticationError extends Error {
    constructor(errorCode, message) {
        super(message);
        this.name = this.constructor.name;
        this.message = message;
        this.errorCode = errorCode;
    }
}

const userService = {
    login: async function(login) {
        const requestData = {
            method: 'post',
            url: '/billing/credential/login',
            data: login
        };

        try {
            const { data } = await apiService.customRequest(requestData);

            tokenService.saveToken(data.access_token);
            apiService.setHeader();
            const user = {
                username: data.username,
                name: data.name,
                role: data.role,
                photo: data.photo,
                id: data.id,
                dashboard: data.dashboard || 'vendor',
                office: data.office,
                isMain: data.isMain,
                util_id: data.util_id,
                authorized_actions: data.authorized_actions,
                // my_office: data.my_office ? data.my_office.office[0].name : undefined,
                cust_id: data.cust_id ? data.cust_id : undefined,
                // office_type: data.office_type,
                changePassword: data.changePassword,
                passwordExpired: data.passwordExpired,
                passwordExpirationLeft: data.passwordExpirationLeft,
            };

            userInfoService.saveUser(user);

            return {
                token: data.access_token,
                user
            };
        } catch (error) {
            return handleError(error);
        }
    },
    getUser: async function() {
        const requestData = {
            method: 'get',
            url: 'api/user',
            data: {}
        };

        try {
            const { data } = await apiService.customRequest(requestData);
            return data;
        } catch (error) {
            return handleError(error);
        }
    },
    logout: async function() {
        const requestData = {
            method: 'post',
            url: '/billing/credential/logout'
        };

        try {
            await apiService.customRequest(requestData);
            this.clear();
        } catch (error) {
            this.clear();
            return handleError(error);
        }
    },
    clear(data) {
        tokenService.removeToken();
        apiService.removeHeader();
        userInfoService.removeUser();
        return data;
    }
};

export { userService, AuthenticationError };