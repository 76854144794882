<template>
  <div class="space-y-4 text-center">
    <div class="py-4 text-xl text-textDark uppercase bg-brightGray">Student registered Successfully</div>
    <div class="text-textMedium text-2xl font-semibold uppercase">
      {{ customer.fullName }}
    </div>
    <div class=" mx-6 p-4 grid grid-cols-2 items-start text-left gap-y-4 text-center border-4 border-primary">
      <p class="text-2xl text-primary">
        MASTER ID
      </p>
      <p class="text-2xl text-primary text-left">
        {{ customer.masterId }}
      </p>
      <p class="text-2xl text-primary">
        SCHOOL ID
      </p>
      <p class="text-2xl text-primary text-left">
        {{ customer.studentSchoolId }}
      </p>
    </div>

    <div class="flex w-full px-4 justify-end">
      <base-button
        label="Okay"
        :primary="false"
        class="px-4 rounded bg-brightGray text-textMedium hover:text-primary"
        @click="handleClose"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ['customer'],
  computed: {},
  methods: {
    handleClose() {
      this.$emit('close');
    },
  },
  created() {},
};
</script>

<style>
</style>