<template>
  <router-link :to="{
      name: 'customer-detail',
      query: { id: student.studentSchoolId }
    }">

    <div
      class="p-6 max-w-sm mx-auto bg-white dark:bg-neutral-800 dark:text-zinc-400 rounded-xl shadow-md flex items-start space-x-4">
      <div class="flex-shrink-0 opacity-25">
        <img src="@/assets/logo.png" class="w-10 h-10 rounded" alt="" />
      </div>
      <div class="text-left space-y-2">
        <div class="text-xl font-medium text-black dark:text-white capitalize">
          {{ student.fullName }}
        </div>
        <div class="flex space-x-4">
          <div class="flex space-x-2 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" fill="#787a7d" viewBox="0 0 512 512">
              <path
                d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
            </svg>

            <p class="text-gray-600 dark:text-neutral-200">{{ student.tel }}</p>
          </div>
          <p class="text-gray-500 dark:text-neutral-300">
            {{ student.accountNumber }}
          </p>
        </div>
        <div class="flex space-x-4">
          <div class="flex space-x-2 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" fill="#787a7d" viewBox="0 0 576 512">
              <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                d="M0 96l576 0c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96zm0 32V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128H0zM64 405.3c0-29.5 23.9-53.3 53.3-53.3H234.7c29.5 0 53.3 23.9 53.3 53.3c0 5.9-4.8 10.7-10.7 10.7H74.7c-5.9 0-10.7-4.8-10.7-10.7zM176 192a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm176 16c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16z" />
            </svg>

            <p class="text-gray-600 dark:text-neutral-200">
              {{ student.masterId }}
            </p>
          </div>
          <p class="text-gray-500 dark:text-neutral-300">
            {{ student.studentSchoolId }}
          </p>
        </div>

        <div class="flex space-x-4">
          <p class="text-gray-600 dark:text-neutral-200">
            {{ student.util_id.util_name }}
          </p>
          <p class="text-gray-500 dark:text-neutral-300">
            {{ student.office.name }}
          </p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ["student"],
};
</script>

<style></style>
