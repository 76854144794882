<template>
  <tippy
    placement="bottom-end"
    trigger="click"
    theme="light"
    arrow
    size="large"
    interactive
    ref="notifications-tt"
  >
    <template v-slot:trigger>
      <div
        @click="markRead"
        class="text-textMedium cursor-pointer hover:opacity-75 relative p-1"
      >
        <BaseIcon name="bell" :size="20" />
        <div
          class="absolute top-0 right-0 text-primary"
          v-if="unreadNotification"
        >
          <BaseIcon name="circle" :size="8" />
        </div>
      </div>
    </template>
    <div
      id="notification"
      class="space-y-1 text-left text-textMedium overflow-scroll"
    >
      <div
        class="
          p-3
          border-b border-blueGray
          flex
          space-x-4
          items-center
          text-textLight
        "
      >
        <div>
          <BaseIcon name="bell" :size="20" />
        </div>
        <div class="uppercase tracking-widest">Notifications</div>
      </div>
      <div v-for="(notification, i) in notifications" :key="i">
        <div
          class="
            flex
            space-x-2
            hover:bg-brighterGray
            p-2
            cursor-pointer
            active:bg-brightGray
          "
        >
          <div>
            <div class="w-10 h-10 bg-brightGray rounded-full"></div>
          </div>
          <div>
            <div class="text-sm font-sans leading-relaxed">
              {{ notification.message }}
            </div>
            <div class="text-sm font-sans">
              {{ ago({ date: new Date(notification.createdAt) }) }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center py-2">
        <base-button
          v-if="activePage < totalPages"
          label="see more"
          @click="fetchNotifications(activePage + 1)"
          :primary="false"
          class="
            py-1
            px-4
            rounded-lg
            bg-brighterGray
            hover:text-primary
            hover:bg-brightGray
          "
        />
      </div>
    </div>
  </tippy>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import eventBus from '@/eventBus';
import ago from '@/services/s-ago';
export default {
  created() {
    this.fetchNotifications();
  },
  watch: {
    clientConnected: {
      immediate: true,
      handler() {
        if (this.clientConnected) {
          this.sioClient.on('Unread_Notifications', data => {
            if (data.count)
              eventBus.$emit('open-notification', {
                message: 'You Have ' + data.count + ' Unread Notifications'
              });
          });
          this.sioClient.on('Request_To_Cancel_Deposit', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Deposit_To_Cancel_Permitted', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Credential_Created', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Credential_Updated', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Password_Changed', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Password_Reset_Requested', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Password_Reseted', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            -this.fetchNotifications(1);
          });
          this.sioClient.on('Transfer_Overlimit', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Maker_Scso_Requested', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Maker_Scso_Confirmed', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Maker_Scso_Canceled', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Maker_Scso_Rejected', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Scso_Maker_Requested', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Scso_Maker_Confirmed', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Scso_Maker_Canceled', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Scso_Maker_Rejected', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Scso_Bm_Requested', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Scso_Bm_Confirmed', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Scso_Bm_Canceled', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Scso_Bm_Rejected', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Bm_Scso_Requested', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Bm_Scso_Confirmed', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Bm_Scso_Canceled', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Bm_Scso_Rejected', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Bm_Bm_Requested', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Bm_Bm_Canceled', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Bm_Bm_Confirmed', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Withdraw_Overlimit', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications();
          });
          this.sioClient.on('Withdraw_Rejected', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications();
          });
          this.sioClient.on('Transfer_Verified', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications();
          });
          this.sioClient.on('Transfer_Rejected', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications();
          });
          this.sioClient.on('Withdraw_Verified', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications();
          });
          this.sioClient.on('User_Loggedin', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications();
          });
          this.sioClient.on('User_Loggedout', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications();
          });
          this.sioClient.on('Request_To_Reopen_Till', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications();
          });
          this.sioClient.on('Request_To_Reopen_Head_Till', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications();
          });
          this.sioClient.on('Request_To_Open_Head_Till', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications();
          });
          this.sioClient.on('Account_Created', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications();
          });
          this.sioClient.on('Customer_Registered', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications();
          });
          this.sioClient.on('Customer_Updated', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications();
          });
          this.sioClient.on('Hq_Dop_Started', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Hq_Dop_Closed', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Hq_Dop_Reopened', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Head_Till_Closed', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Head_Till_Verified', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Till_Opened', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Till_Closed', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Vault_Opned', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
          this.sioClient.on('Vault_Closed', data => {
            eventBus.$emit('open-notification', {
              message: data.message
            });
            this.fetchNotifications(1);
          });
        }
      }
    }
  },
  data() {
    return {
      notifications: [],
      activePage: 0,
      totalPages: 0
    };
  },
  computed: {
    ...mapGetters('socket', ['sioClient', 'clientConnected']),
    unreadNotification() {
      const temp = this.notifications.find(
        notification => notification.read == false
      );
      return temp ? true : false;
    }
  },
  methods: {
    ...mapActions('main', ['request']),
    ago,
    async fetchNotifications(page = 1) {
      let queryString = 'page=' + page;
      let response = await this.request({
        method: 'get',
        url: `notification/?${queryString}`
      });

      if (response) {
        if (page == 1) this.notifications = response.items;
        else this.notifications = [...this.notifications, ...response.items];
        this.activePage = response.page;
        this.totalPages = response.total_pages;
      }
    },
    async markRead() {
      await this.request({
        method: 'post',
        url: `notification/mark_read`
      });
      await this.fetchNotifications();
    },
    hideDialog() {
      this.$refs['notifications-tt'].tip.hide();
    }
  }
};
</script>

<style>
#notification {
  max-height: 60vh;
}

#notification::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  margin: 4px;
  background-color: #e1e1e1;
}

#notification::-webkit-scrollbar-thumb {
  background-color: rgb(187, 175, 175);
  border-radius: 4px;
}

#notification::-webkit-scrollbar-thumb:hover {
  background-color: #757575;
}
</style>
