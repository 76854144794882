<template>
    <div class="text-textMedium space-y-4">
      <div
        class="text-2xl uppercase py-2 shadow-md text-textDark font-semibold bg-brighterGray text-center"
      >
        Ticket Agent REGISTRATION
      </div>
      <form class="disabled grid grid-cols-2 gap-3 p-4 pb-4">
  
        <base-input
          type="text"
          placeholder="Agent Name"
          label="agent Name"
          bordered
          :readOnly="action == 'view'"
          required
          :min="0"
          v-model="Form.name"
          @enter="handleSave"
          class="col-span-2 text-textDark text-lg"
        />
        <base-input
          placeholder="Agent Code"
          type="text"
          :readOnly="action == 'view'"
          label="Agent Code"
          bordered
          required
          :min="0"
          v-model="Form.code"
          @enter="handleSave"
        />
        <base-input
          placeholder="Agent Type"
          type="text"
          :readOnly="action == 'view'"
          label="Agent Type"
          bordered
          required
          :min="0"
          v-model="Form.type"
          @enter="handleSave"
        />
        <base-input
          placeholder="Ticket Limit"
          type="text"
          :readOnly="action == 'view'"
          label="Ticket Limit"
          bordered
          required
          :min="0"
          v-model="Form.limit"
          @enter="handleSave"
        />
        
      </form>
      <div class="flex justify-between mt-3 px-3">
        <base-button
          label="cancel"
          class="py-1 px-4 text-textMedium bg-brightGray hover:bg-danger hover:text-error rounded"
          @click="close"
        />
        <base-button
          :label="btnLabel"
          :isWorking="isWorking"
          class="py-1 px-4 rounded"
          @click="handleSave"
        />
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex';
  import eventBus from '../../eventBus';
  export default {
    props: {
      action: {
        type: String,
        default: 'reg',
      },
      btnLabel: {
        type: String,
        default: 'Save',
      },
    },
    data() {
      return {
        isWorking: false,
        util_id: undefined,
        organizations: [],
        Form: {
          name: undefined,
          code: undefined,
          type: undefined,
          limit: 0,
        },
        custIdOptions: [
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ],
      };
    },
    watch: {
      util_id() {
        this.fetchOrganizations();
      },
    },
    computed: {
      ...mapGetters('main', ['berror']),
    },
    methods: {
      ...mapActions('main', ['request']),
      async handleSave() {
        this.isWorking = true;
            const response = await this.request({
          method: 'post',
          url: 'billing/events/addagents',
          data: { ...this.Form},
        });
        if (response) {
          this.isWorking = false;
          this.$emit('close', {
            invokeCallBack: true,
            response: response.item,
          });
        } else {
          eventBus.$emit('open-toast', {
            error: true,
            message: this.berror,
          });
        }
        this.isWorking = false;
      },
     
  
      close() {
        this.$emit('close');
      },
    },
    created() {
      
    },
  };
  </script>
  
  <style></style>
  