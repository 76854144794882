<template>
  <div class="space-y-2">
    <bar-loader v-if="isLoading" class="w-full flex justify-center" />
    <div v-else class="flex justify-end space-x-3">
      <base-button
        v-if="isGranted('0002r')"
        label="New Role"
        icon="plus"
        class="px-2 rounded"
        @click="openRegForm"
      />
      <div class="flex justify-end">
        <actions
          v-if="actionHandlers.length > 0"
          :actions="actionHandlers"
          :item="selectedItem"
          :arrow="true"
        >
          <div
            class="
              flex
              items-center
              px-2
              py-1
              space-x-2
              text-sm
              rounded
              cursor-pointer
              bg-brighterGray
              hover:text-primary
              active:bg-brighterGray
              hover:bg-brightGray
            "
          >
            <div>actions</div>
            <BaseIcon name="chevron-down" :size="12" />
          </div>
        </actions>
      </div>
    </div>
    <div>
      <div
        class="
          p-2
          grid
          xl:grid-cols-3
          lg:grid-cols-3
          md:grid-cols-2
          sm:grid-cols-2
          gap-4
        "
      >
        <div
          class=""
          v-for="role in documents"
          :key="role._id"
          @click="selectRole(role._id)"
        >
          <role-card :role="role"
            :selected="selectedId == role._id"
          />
        </div>
      </div>
      <pagination
        v-if="paginate && documents && documents.length > 0"
        :currentPage="currentPage"
        :pageSize="pageSize"
        :totalPages="totalPages"
        @changepage="handlePageChange"
        @changepagesize="handlePageSizeChange"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import eventBus from '@/eventBus';
import Pagination from '@/components/pagination/Pagination.vue';
import Actions from '@/components/card/Actions.vue';
import BarLoader from '@/components/collection/BarLoader.vue';
import RoleCard from '@/components/card/dynamicRole/RoleCard.vue';
export default {
  components: { Pagination, Actions, BarLoader, RoleCard, },
  data() {
    return {
      documents: [],
      isLoading: false,
      selectedId: '',
      paginate: true,
      totalPages: 0,
      rowsPerPage: 8,
      currentPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    ...mapGetters(['user']),
    displayItems() {
      if (!this.paginate) return this.documents;
      let startIndex = (this.currentPage - 1) * this.pageSize;
      let endIndex = startIndex + this.pageSize;
      if (endIndex > this.documents.length) endIndex = this.documents.length;
      return this.documents.slice(startIndex, endIndex);
    },
    selectedItem() {
      if (this.selectedId && this.documents)
        return this.documents.find((item) => item._id === this.selectedId);
      else return '';
    },
    actionHandlers() {
      if (!this.selectedItem && this.selectedItem._id == this.user.role?._id) return [];
      return [
        {
          label: 'Edit Role',
          icon: 'pencil-alt',
          handler: this.editRole,
          fullParam: true,
          code: "0022r",
        },
        {
          label: 'Authorized Tasks',
          icon: 'eye',
          handler: this.openRoleAuthorizedTasks,
          idParam: true,
          code: "0022ar",
        },
      ];
    },
  },
  watch: {
    currentPage() {
      this.getDocuments();
    },
  },
  methods: {
    ...mapActions('main', ['requestMutate', 'request']),
    openRegForm() {
      eventBus.$emit('open-modal', { modalId: 10, cb: this.insertNewItem });
    },
    editRole(role) {
      eventBus.$emit('open-modal', {
        modalId: 10,
        componentProps: { role, action: 'edit', btnLabel: 'UPDATE' },
        cb: this.getDocuments,
      });
    },
    openRoleAuthorizedTasks(id) {
      this.$router.push('/role-activities/'+id)
    },
    selectRole(id) {
      this.selectedId = id;
    },
    insertNewItem({ response }) {
      this.documents.unshift(response);
    },
    handlePageChange(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.currentPage = 1;
    },
    async openDetails(role) {
      let response = await this.request({
        method: 'get',
        url: 'role/heads/' + role._id,
      });
      if (response) {
        role.head = response.items[0];
      }
      let componentProps = { role };
      eventBus.$emit('open-modal', { modalId: 19, componentProps });
    },
    async getDocuments() {
      this.isLoading = true;
      const response = await this.request({
        url: 'billing/roles/roles',
        method: 'get',
      });
      if (response) {
        this.documents = response.items;
        // this.documents = response.items
      } else {
        this.documents = [];
      }
      this.isLoading = false;
    },
  },
  created() {
    this.getDocuments();
  },
};
</script>

<style></style>
