import Vue from 'vue';
import BaseButton from './base/BaseButton.vue';
import BaseInput from './base/BaseInput.vue';
import BaseRadio from './base/BaseRadio.vue';
import BaseSelect from './base/BaseSelect.vue';
import BaseTextArea from './base/BaseTextArea.vue';
import BaseToggle from './base/BaseToggle.vue';
import BaseIcon from './base/BaseIcon.vue';
import BaseCheckBox from './base/BaseCheckBox.vue';
import BaseTitle from './base/BaseTitle.vue';

const baseComponents = {
    BaseButton,
    BaseInput,
    BaseRadio,
    BaseSelect,
    BaseTextArea,
    BaseToggle,
    BaseCheckBox,
    BaseTitle,
    BaseIcon
};

export default function registerGlobally() {
    Object.keys(baseComponents).forEach(key => {
        Vue.component(key, baseComponents[key]);
    });
}