<template>
  <div class="bg-brightGray h-full p-4">
    <base-title title="Payment Terms">
      <template #actions>
        <div class="flex space-x-3">
          <base-button
            label="Back"
            icon="arrow-left"
            class="px-2 bg-gray text-textDarkest"
            :primary="false"
            @click="$router.back()"
          />
          <base-button
            label="New payment term"
            icon="plus"
            class="px-2"
            @click="handleCreatePaymentTerm"
            />
            <!-- :isVisible="permission.add" -->
        </div>
      </template>
    </base-title>
    <div class="mt-2 grid grid-cols-3 gap-2">
      <div class="bg-white p-2 item-grid" v-for="(item, i) in items" :key="i">
        <div class="flex justify-between w-full">
          <div class="flex space-x-2 items-center">
            <p
              class="text-lg text-textDarkest uppercase"
            >
              {{ item.title }}
            </p>
            <div class="w-3 h-2 bg-primary"></div>
          </div>
          <div>
            <status-chip :label="item.status" />
          </div>
        </div>
        <div></div>
        <div>
          <div class="flex space-x-5 mt-3">
              <!--v-if="permission.delete || !isSchoolOfficial"-->
            <base-icon
              name="trash-alt"
              class="cursor-pointer text-textMedium"
              @click="handleDeleteItem(item._id)"
            />
              <!--v-if="permission.update || !isSchoolOfficial"-->
            <base-icon
              name="pencil-alt"
              class="cursor-pointer text-textMedium"
              @click="handleEditItem(i)"
            />
              <!--v-if="permission.view || !isSchoolOfficial"-->
            <!-- <base-icon
              name="eye"
              class="cursor-pointer text-textMedium"
              @click="handleItemDetail(item._id)"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import eventBus from '@/eventBus';
export default {
  props: [],
  data() {
    return {
      permission: {},
      isSchoolOfficial: false,
      items: [],
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    ...mapActions('main', ['request']),
    handleCreatePaymentTerm() {
      let componentProps = {
        
      };
      eventBus.$emit('open-modal', {
        modalId: 30,
        componentProps,
        cb: this.handleFetchPaymentTerms,
      });
    },

    async handleFetchPaymentTerms() {
      let response = await this.request({
        method: 'get',
        url: 'billing/paymentTerm',
      });
      if (response && !response.error) {
        this.items = response.items;
      } else {
        this.items = [];
      }
    },

    async handleEditItem(index) {
      let componentProps = {
        oldForm: this.items[index],
      };
      eventBus.$emit('open-modal', {
        modalId: 30,
        componentProps,
        cb: this.handleFetchPaymentTerms,
      });
    },

    async handleDeleteItem(id) {
      let componentProps = {
        message: 'Are you sure you want to delete the selected payment term',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 3,
        componentProps,
        cb: this.proceedDelete,
        cbParams: { id },
      });
    },

    async proceedDelete({ id }) {
      let response = await this.request({
        method: 'delete',
        url: 'billing/paymentTerm/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'payment term deleted successfully',
        });
        this.handleFetchPaymentTerms();
      } else {
        eventBus.$emit('open-toast', {
          message: 'error deleting payment term',
          error: true,
        });
      }
    },

    async handleItemDetail(id = 0) {
      this.$router.push({
        name: 'bill-generator-formula',
        params: { id },
      });
    },

  },
  created() {
    this.handleFetchPaymentTerms();
  },
};
</script>

<style scoped>
.item-grid {
  animation: slider 0.5s;
}

@keyframes slider {
  0% {
    transform: translateY(20px);
    opacity: 0%;
  }
  100% {
    transform: translateY(0);
    opacity: 100%;
  }
}
</style>
