<template>
  <div class="divide-y divide-gray-300/50 px-6 pb-4">
    <div class="space-y-3 pb-8 text-base leading-7 text-gray-600">
      <div
        class="flex py-4 justify-between text-textMedium text-xl border-b-2 border-gray-200 pb-3"
      >
        <p>Importing</p>
        <div class="hover:text-red-400 cursor-pointer" @click="handleClose">
          <base-icon name="times" class="hover:text-red-400" :size="24" />
        </div>
      </div>
      <div v-if="isImportingStudentsStarted" class="space-y-2">
        <p class="text-textMedium text-xl">Students</p>
        <div class="flex w-full">
          <div
            class="w-1/2 flex items-center space-x-4 bg-green-100 px-4 py-2 text-center text-green-400 rounded-l"
          >
            <base-icon name="check-circle" />
            <div class="space-y-2">
              <div class="text-3xl">{{ successStudentsCount }}</div>
              <div class="text-lg text-opacity-50">success</div>
            </div>
          </div>
          <div
            class="w-1/2 flex items-center space-x-4 bg-red-100 px-4 py-2 text-center text-red-400 rounded-r"
          >
            <base-icon name="exclamation-circle" />
            <div class="space-y-2">
              <div class="text-3xl">{{ failedStudentsCount }}</div>
              <div class="text-lg text-opacity-50">failed</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isImportingBillsStarted" class="space-y-2">
        <p class="text-textMedium text-xl">Bills</p>
        <div class="flex w-full">
          <div
            class="w-1/2 flex items-center space-x-4 bg-green-100 px-4 py-2 text-center text-green-400 rounded-l"
          >
            <base-icon name="check-circle" />
            <div class="space-y-2">
              <div class="text-3xl">{{ successBillsCount }}</div>
              <div class="text-lg text-opacity-50">success</div>
            </div>
          </div>
          <div
            class="w-1/2 flex items-center space-x-4 bg-red-100 px-4 py-2 text-center text-red-400 rounded-r"
          >
            <base-icon name="exclamation-circle" />
            <div class="space-y-2">
              <div class="text-3xl">{{ failedBillsCount }}</div>
              <div class="text-lg text-opacity-50">failed</div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="(isImportingStarted && !isImportingFinished) || isWorking"
        class="relative w-full h-2 bg-gray-200 rounded-full overflow-hidden"
      >
        <div
          class="absolute top-0 left-0 w-full h-2 bg-primary rounded-full animate-progress"
        ></div>
      </div>
    </div>
    <div class="flex justify-end pt-4">
      <!-- <base-button
        :disabled="!isImportingFinished"
        :isWorking="isWorking"
        label="Import"
        :iconRight="true"
        icon="chevron-right"
        @click="handleImport"
        class="rounded px-3"
      /> -->
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../../../eventBus';
export default {
  props: [ 'school', 'branch'],
  data() {
    return {
      isWorking: false,
      totalStudentsCount: 0,
      totalBillsCount: 0,

      successStudentsCount: 0,
      failedStudentsCount: 0,

      successBillsCount: 0,
      failedBillsCount: 0,

      isImportingStudentsStarted: false,
      isImportingBillsStarted: false,

      isImportingStudentsFinished: false,
      isImportingBillsFinished: false,
    };
  },
  computed: {
    ...mapGetters('migration', ['fileId']),
    ...mapGetters('socket', ['sioClient', 'clientConnected']),
    ...mapGetters('main', ['berror']),
    studentsProgress() {
      return Math.floor(
        ((this.successStudentsCount + this.failedStudentsCount) * 100) /
          this.totalStudentsCount
      );
    },
    isImportingStarted() {
      return this.isImportingStudentsStarted || this.isImportingBillsStarted;
    },
    isImportingFinished() {
      return (
        (!this.isImportingStudentsStarted || this.isImportingStudentsFinished) &&
        (!this.isImportingBillsStarted || this.isImportingBillsFinished)
      );
    },
  },
  watch: {
    clientConnected: {
      immediate: true,
      handler() {
        if (this.clientConnected) {
          this.sioClient.on('import_student_total_count', (count) => {
            this.totalStudentsCount = count;
          });
          this.sioClient.on('import_student_success', ({ success_count }) => {
            this.successStudentsCount = success_count;
          });
          this.sioClient.on('import_student_error', ({ failed_count }) => {
            console.info({failed_count})
            this.failedStudentsCount = failed_count;
          });

          this.sioClient.on('import_bill_success', ({ success_count }) => {
            this.successBillsCount = success_count;
          });

          this.sioClient.on('import_bill_error', ({ failed_count }) => {
            console.info({failed_count})
            this.failedBillsCount = failed_count;
          });

          this.sioClient.on('import_student_started', () => {
            this.isImportingStudentsStarted = true;
          });
          this.sioClient.on('import_student_finished', () => {
            this.isImportingStudentsFinished = true;
          });

          this.sioClient.on('import_bill_started', () => {
            this.isImportingBillsStarted = true;
          });
          this.sioClient.on('import_bill_finished', () => {
            this.isImportingBillsFinished = true;
          });
        }
      },
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    async handleImport() {
      this.isWorking = true;
      const response = await this.request({
        url: 'migration/import-parsed-data',
        method: 'post',
        data: {
          fileId: this.fileId,
          school: this.school,
          branch: this.branch,
        },
      });
      if (response) {
        // this.handleClose();
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror,
        });
      }
      this.isWorking = false;
    },
    
    handleClose() {
      this.$emit('close');
    },
  },
  mounted() {
    this.handleImport()
  }
};
</script>
<style>
@keyframes progressAnimation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animate-progress {
  animation: progressAnimation 2s infinite;
}
</style>
