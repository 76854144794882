const state = {
  inactivity: {
    counter: 0,
    timerId: undefined,
    period: 30 * 60 // 30 minutes
  }
};

const mutations = {
  SMART_SET: (state, { stateKey, data }) => {
    const [key1, key2] = stateKey.split('.');
    state[key1][key2] = data;
  }
};

const actions = {
  increment({ state, commit }, { stateKey }) {
    commit('SMART_SET', {
      stateKey: stateKey + '.counter',
      data: state[stateKey].counter + 1
    });
  },
  reset({ state, commit, dispatch }, { stateKey }) {
    localStorage.setItem('sessionTimestamp', Date.now());
    commit('SMART_SET', {
      stateKey: stateKey + '.counter',
      data: 0
    });
    if (state[stateKey].timerId) clearInterval(state[stateKey].timerId);
    dispatch('startCounting', {
      stateKey
    });
  },
  startCounting({ commit, dispatch }, { stateKey, counter }) {
    commit('SMART_SET', {
      stateKey: stateKey + '.counter',
      data: counter || 0
    });
    const timerId = setInterval(
      () =>
        dispatch('increment', {
          stateKey
        }),
      1000
    );
    commit('SMART_SET', {
      stateKey: stateKey + '.timerId',
      data: timerId
    });
  },
  stopCounting({ state }, { stateKey }) {
    if (state[stateKey].timerId) clearInterval(state[stateKey].timerId);
  }
};

const getters = {
  inactivityCounter: state => state.inactivity.counter,
  inactivityPeriod: state => state.inactivity.period
};

const timer = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

export default timer;
