<template>
  <div class="flex space-x-4 bg-white rounded p-2 shadow h-auto w-full">
    <div class="h-full flex flex-col justify-center">
      <base-icon
        :name="report.icon"
        :size="32"
        :style="{ color: report.color }"
      />
    </div>
    <div class="flex flex-col justify-between h-full">
      <div
        class="text-xl text-textMedium h-full"
        :style="{ color: report.color }"
      >
        {{ report.count | formateNumber }}
      </div>
      <div class="flex flex-shrink-0 text-sm text-textLight">{{ report.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['report'],
};
</script>

<style>
</style>