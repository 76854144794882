<template>
  <div class="space-y-1 w-full px-4">
    <div class="text-textLight font-hairline">{{ data.title }}</div>
    <icon-chart :series="[data.series]" :categories="categories" :color="data.color" />
    <div class="text-2xl font-semibold" :style="{ color: data.color }">
      {{ data.total | formateNumber }}
    </div>
    <div class="text-xs text-textLight pl-1 opacity-50">{{ data.footer }}</div>
  </div>
</template>

<script>
import IconChart from './IconChart.vue';
export default {
  components: { IconChart },
  props: ['data', 'categories'],
};
</script>

<style>
</style>