<template>
  <!-- list of customers -->
  <div class="grid grid-cols-12 gap-4">
    <div v-if="false" class="col-span-3 space-y-3">
      <div class="bg-primary text-white text-center p-2">Search Customers</div>
      <div class="search-field mb-5 w-full">
        <base-input
          class="w-full"
          outlined
          placeholder="Search student globally"
          v-model="search"
          @enter="fetchCustomer(search)"
        ></base-input>
      </div>
      <base-switch
        style="min-width: fit-content"
        :items="searchByOptions"
        v-model="searchBy"
      />
    </div>
    <div class="col-span-12 w-full px-8">
      <div class="w-full pb-5">
        <filter-item :items="filterItems" class="" v-model="filterCondition" />
      </div>
      <div class="w-full mx-auto pb-10">
        <data-table
          :headers="headers"
          :items="customers"
          :searchable="true"
          :canSelect="true"
          :isWorking="loadingData"
          @select="select"
          @unselect="unselect"
          :hasDynamicActions="true"
          :dynamicHandlers="actionHandlers"
        >
          <template #toolset> 
            <base-button code="0011sc" label="Verify all" icon="check-circle" @click="handleVerifyAll" />
          </template>

          <template #default="{ dtItem }">
            <td
              v-for="(header, i) in headers"
              :key="i"
              class="py-3 px-2 text-lg tracking-wider "
              @dblclick="openCustomerDetail(dtItem)"
            >
              <div
                v-if="header.key == 'fullName'"
                class="hover:active flex space-x-2 font-medium text-textMedium hover:text-white whitespace-nowrap dark:text-white"
                active-class="active"
              >
                <base-avatar
                  :name="dtItem.fullName | get_2Letters"
                  :_2Letters="dtItem.fullName | get_2Letters"
                  :size="40"
                />
                <div>
                  <div class="capitalize text-lg font-semibold ">
                    {{ dtItem.fullName }}
                  </div>
                  <div class="text-xs">
                    {{ dtItem.gender }}
                  </div>
                </div>
              </div>

              <!-- <div
                v-else-if="header.key == 'fullName'"
                class="font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {{ getProp(dtItem, header.key) }}
              </div> -->
              <div v-else-if="header.key == 'actions'" class="text-gray-700">
                <router-link :to="`student-detail?id=${dtItem.studentSchoolId}`" class="inline-flex text-sm px-3 py-1 text-teal  border border-teal rounded"
                  >Detail <span><base-icon name="chevron-right" class="ml-3 pt-1"/></span></router-link
                >
              </div>
              <div v-else-if="header.key !== 'status'" class="text-gray-700">
                {{ getProp(dtItem, header.key) }}
              </div>

              <div v-else class="flex justify-start">
                <div
                  class="rounded text-center inline-block text-sm px-4"
                  :class="
                    dtItem.status === 'Verified' || dtItem.status === 'Active'
                      ? 'bg-teal text-white'
                      : 'bg-danger text-error'
                  "
                >
                  {{ dtItem.status }}
                </div>
              </div>
            </td>
          </template>
        </data-table>
      </div>
      <div class="pb-10">
        <pagination
          :currentPage="currentPage"
          :pageSize="pageSize"
          :totalPages="totalPages"
          @changepage="handlePageChange"
          @changepagesize="handlePageSizeChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getPropByString } from '@/utils';
import { _2Letters } from '@/mixins/_2Letters.js';

import Pagination from '@/components/pagination/Pagination.vue';

import DataTable from '@/components/table/DataTable.vue';
import { PROFILE_PATH } from '@/config/config';

import BaseSwitch from '@/components/base/BaseSwitch.vue';
import FilterItem from '@/components/filter/Filter.vue';
import eventBus from '../../../eventBus';
// import eventBus from '@/eventBus';

export default {
  components: { DataTable, Pagination, BaseSwitch, FilterItem },
  mixins: [_2Letters],
  data() {
    return {
      loadingData: false,
      selectedId: '',
      filterCondition: 'All',
      filterItems: [
        { label: 'All', value: 'All' },
        { label: 'Suspended', value: 'Suspended' },
        { label: 'Modified', value: 'Modified' }
      ],
      searchByOptions: [
        { label: 'Tel', value: 'isTel' },
        { label: 'Index', value: 'isIndex' },
        { label: 'Name', value: 'isName' },
        { label: 'ID', value: 'isCustID' }
      ],
      searchBy: 'isTel',
      currentPage: 1,
      pageSize: 10,
      totalPages: 1,
      searchWithTel: false,
      search: '',
      PROFILE_PATH,
      customers: [],
      headers: [
        { key: 'fullName', label: 'Full Name' },
        { key: 'masterId', label: 'Master ID' },
        { key: 'studentSchoolId', label: 'Student SCHOOL ID' },
        { key: 'grade', label: 'grade/Dep\'t' },
        { key: 'section', label: 'section' },
        { key: 'tel', label: 'Phone Number' },
        { key: 'accountNumber', label: 'Account Number' },

        // { key: 'office.name', label: 'Branch' },
        { key: 'status', label: 'Status' },
        { key: 'actions', label: '' }
      ]
    };
  },
  computed: {
    ...mapGetters(['user']),
    selectedCustomer() {
      return this.customers.find(({ _id }) => _id == this.selectedId);
    },
    role() {
      return this.user.role;
    },
    myId() {
      return this.user.id;
    },
    actionHandlers() {
      if (!this.selectedCustomer) return [];
      return [
        {
          icon: 'pencil-alt',
          label: 'Edit',
          handler: this.openEditCustomer,
          fullParam: true,
          code: '0011sc'
        },
        {
          icon: 'pencil-alt',
          label: 'Change Status',
          handler: this.changeCustomerStatus,
          fullParam: true,
          code: '0011sc'
        },
        {
          icon: 'eye',
          label: 'Open detail',
          handler: this.openCustomerDetail,
          fullParam: true,
          code: '0011sc'
        },
        // {
        //   icon: 'link',
        //   label: 'Link Mama',
        //   handler: this.openLinkToMamaForm,
        //   fullParam: true,
        //   code: ''
        // },
        // {
        //   icon: 'link',
        //   label: 'Link CBE Birr',
        //   handler: this.openLinkToCbeBirrForm,
        //   fullParam: true,
        //   code: ''
        // },
      ];
    }
  },

  created() {
    this.fetchCustomers();
  },
  methods: {
    ...mapActions('main', ['request']),

    getProp: getPropByString,

    changeCustomerStatus(customer) {
      eventBus.$emit('open-modal', {
        modalId: 26,
        componentProps: {
          customer
        },
        cb: this.fetchCustomers
      });
    },
    openEditCustomer(customer) {
      eventBus.$emit('open-modal', {
        modalId: 32,
        componentProps: {
          customer
        },
        cb: this.fetchCustomers
      });
    },
    openCustomerDetail({ studentSchoolId }) {
      this.$router.push({
        name: 'customer-detail',
        query: { id: studentSchoolId }
      });
    },
    openLinkToMamaForm(customer) {
      eventBus.$emit('open-modal', {
        modalId: 202,
        componentProps: {
          customer
        },
        cb: this.fetchCustomers
      });
    },
    openLinkToCbeBirrForm(customer) {
      eventBus.$emit('open-modal', {
        modalId: 203,
        componentProps: {
          customer
        },
        cb: this.fetchCustomers
      });
    },

    async fetchCustomer(search) {
      this.loadingData = true;
      let response = await this.request({
        method: 'post',
        url: 'billing/customer/search',
        data: { search: search, [this.searchBy]: true }
      });

      if (response) {
        this.customers = response.items;
      } else {
        this.customers = [];
      }
      this.loadingData = false;
    },
    async handleVerifyAll() {
      const url = this.user.isMain ? 'billing/customer/verify-all' : 'billing/customer/verify-all-branch-customers';
      const response = await this.request({
        method: 'put',
        url,
      })
      if(response) {
        this.fetchCustomers()
        eventBus.$emit('open-toast', {error: false, message: "All Pending students verified successfully "});
      } else {
        eventBus.$emit('open-toast', {error: true, message: response.message});
      }
    },
    select(id) {
      this.selectedId = id;
    },
    unselect() {
      this.selectedId = '';
    },

    /// FIXME: select customer.
    selectCustomer(customer) {
      this.$router.push(`customers/${customer}`);
    },
    cancel() {
      this.$emit('close');
    },

    async fetchCustomers() {
      this.loadingData = true;
      let queryString = this.generateQuerystring();
      let url;
      if (this.user.isMain) url = 'billing/customer' + queryString;
      else 
        url = 'billing/customer/officecustomers' + queryString;
      // if (this.isGranted('0111orgc')) url = 'billing/customer' + queryString;
      // else if (this.isGranted('0111offc'))
      //   url = 'billing/customer/officecustomers' + queryString;
      let response = await this.request({ method: 'get', url: url });
      if (response) {
        this.customers = response.items;
        this.totalPages = response.total_pages;
      } else {
        this.customers = [];
        this.totalPages = 0;
      }
      this.loadingData = false;
    },

    customerSelected(studentSchoolId) {
      this.selectCustomer(studentSchoolId);
    },

    generateQuerystring(paginated = true) {
      let queryString = '';

      if (['Suspended', 'Modified'].includes(this.filterCondition)) {
        queryString = '?status=' + this.filterCondition;
      }
      if (paginated)
        queryString
          ? (queryString +=
              '&' + 'page=' + this.currentPage + '&' + 'limit=' + this.pageSize)
          : (queryString +=
              '?' +
              'page=' +
              this.currentPage +
              '&' +
              'limit=' +
              this.pageSize);
      else
        queryString
          ? (queryString +=
              '&' + 'page=1&' + 'limit=' + this.pageSize * this.totalPages)
          : (queryString +=
              '?' + 'page=1&' + 'limit=' + this.pageSize * this.totalPages);

      return queryString;
    },

    handlePageChange(page) {
      this.currentPage = page;
      this.fetchCustomers();
    },

    handlePageSizeChange(pagesize) {
      this.pageSize = pagesize;
      this.fetchCustomers();
    }
  },

  watch: {
    filterCondition() {
      this.fetchCustomers();
    }
  }
};
</script>

<style scoped>
.active {
  @apply text-white underline;
}
</style>
