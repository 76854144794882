<template>
  <div>
    <Modal
      v-if="isModalOpened"
      @close="handleClose"
      :width="openedModal.width || 480"
      :component="openedModal.component"
      :componentProps="componentProps"
      :variant="openedModal.variant"
    />
  </div>
</template>
<script>
import Modal from './Modal.vue';
import eventBus from '@/eventBus';
import { modalHandlers } from '@/mixins/modal';
import configs from './configs';

export default {
  components: {
    Modal
  },
  mixins: [modalHandlers],
  data() {
    return {
      configs
    };
  },
  computed: {
    isModalOpened() {
      if (this.openedId === undefined) return false;
      return true;
    },
    openedModal() {
      if (this.isModalOpened)
        return this.configs.find(({ id }) => id === this.openedId);
      return null;
    }
  },
  created() {
    if (this.configs.length > 0) {
      eventBus.$on('open-modal', (arg = {}) => {
        let {
          modalId,
          componentProps = null,
          cbParams = null,
          cb = null
        } = arg;
        if (modalId) {
          this.openedId = modalId;
          this.componentProps = componentProps;
          this.cbParams = cbParams;
          this.cb = cb;
        }
      });
    }
  }
};
</script>
