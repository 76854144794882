
<template>
    <div class="p-2 ">
        
<form class="mx-8 pl-5" v-if="isGranted('0889tt') &&formOn">
<div class="flex">
<span class=" flex items-center justify-center  px-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                    <img src="@/assets/lionf.jpg" class="h-32 w-32 rounded-full " alt="" />

        </span>
<ol class="relative border-s border-gray-200 dark:border-gray-700">                  
    <li class="mb-10 ms-6">            
        
        <h3 class="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">New Matche Or Event <span class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 ms-3">Latest</span></h3>
        <time class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{{Date()}}</time>
        <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">Get access to over 20+ pages including a dashboard layout, charts, kanban board, calendar, and pre-order E-commerce & Marketing pages.</p>
    </li>
</ol>
</div>
    <div class="grid gap-12 ml-12 p-2 md:grid-cols-2 ">
       



        <div class="flex space-x-12">
            <base-input
            type="text"
            label="Home Club"
            placeholder=""
            v-model="Form.home_club"
            bordered
            required
          /> 
          Vs  
            <base-input
            type="text"
            label="Away"
            placeholder=""
            v-model="Form.away_club"
            bordered
            required
          />   
       
        </div>
        
       </div>
      
       <div class=" justify-around space-x-12 py-2">
       

    <base-button
    :isWorking="isWorking"
    label="Create"
    v-if="isGranted('0889tt')"
    icon="plus"
    class="px-2 p-2 flex text-sm uppercase rounded"
    @click="uploadTicketPayment"
  />
   
        </div>

  </form>
<div class="flex justify-end">
 <base-button
    label="New"
    v-if="isGranted('0889tt')&&!formOn"
    icon="plus"
    class="px-2 p-2 flex text-sm uppercase rounded"
    @click="formOn=!formOn"
  />
    <button
    label="Close"
    v-if="isGranted('0889tt')&&formOn"
    icon="close"
    class="px-2 p-2 flex text-sm uppercase rounded text-white bg-gray-600"
    @click="formOn=!formOn"
  >CLOSE</button>
</div>
      <bar-loader class="w-12 mx-auto block" v-if="working" />
      <data-table
        v-if="!working"
        name="MATCH LIST"
        :headers="headers"
        :items="tickets"
        :canSelect="true"
        :paginate="true"
        :rowsPerPage="8"
        :searchable="true"
      >
        
  
        <template #default="{ dtItem }">
          <td v-for="(header, i) in headers" :key="i" class="py-3 px-6">
            <div v-if="header.key === 'status'" class="flex">
              <div
                class="inline-block px-3 text-sm text-white lowercase rounded"
                :style="{
                  backgroundColor: getColor(dtItem.status),
                }"
              >
                {{ dtItem.status }}
              </div>
            </div>
            <div v-else-if="header.key === 'autoGenerateStudentId'" class="flex">
              <switch-button
                class="flex-shrink-0"
                :value="dtItem.autoGenrateStudentId"
              />
            </div>
            <div v-else>
              {{ getProp(dtItem, header.key) }}
            </div>
          </td>
        </template>
      </data-table>
    </div>
  </template>
  <script>
  import DataTable from '@/components/table/DataTable.vue';
  import { getPropByString } from '@/utils';
  import { mapActions, mapGetters } from 'vuex';
  import BarLoader from '@/components/collection/BarLoader.vue';
  import SwitchButton from '../../../components/base/SwitchButton.vue';
  import eventBus from '@/eventBus';
  export default {
    props: [],
    components: { DataTable, BarLoader, SwitchButton },
    data() {
      return {
        selectedId: '',
        working: false,
        isWorking: false,
        tickets: [],
        formOn:false,
        Form:{
            home_club:'',
            away_club:''

        },
        headers: [
          {
            label: 'Home Club',
            key: 'home_club',
          },
          {
            label: 'Away club',
            key: 'away_club',
          },
          
        ],
      };
    },
    computed: {
      ...mapGetters('main', ['berror']),
      ...mapGetters(['user']),
     
    },
    watch: {},
    methods: {
      ...mapActions('main', ['setData', 'request']),
      getProp: getPropByString,
      async fetchtickets() {
        this.working = true;
        this.tickets = [];
        let response = await this.request({
          method: 'get',
          url: `billing/events/event/matches`,
        });
        this.working = false;
        if (response && !response.error) {
          this.tickets = response.items;
        }
      },
      
      
      async uploadTicketPayment() {

        if(this.Form.away_club==''||this.Form.home_club==''){
         eventBus.$emit('open-toast', {
          error: true,
          message:'Please fill the form',
          timeout: 120
        });
         return ;
        }
      this.isWorking = true;
      const response = await this.request({
        method: 'post',
        url: 'billing/events/match/add',
        data: {
          ...this.Form,
        }
      });
      if (response) {
        eventBus.$emit('open-toast', {
          error: false,
          message: 'payment uploaded successfully'
        });
        this.fetchtickets()
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror,
          timeout: 120
        });
      }
      this.isWorking = false;
    },
    },
    mounted() {
      this.fetchtickets();
    },
  };
  </script>
  <style></style>
  