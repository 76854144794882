<template>
  <div
    class="
      flex
      justify-between
      w-full
      space-x-8
      bg-brightGray
      h-full
      overflow-y-scroll
      p-4
    "
  >
    <div class="space-y-4 flex-grow h-full max-h-full">
      <counts class="" />
      <statistics class="flex flex-grow" />
    </div>
    <div v-if="false" class="flex flex-col space-y-8 flex-shrink-0">
      <messages />
      <messages />
      <messages />
    </div>
    <!-- <div class="bg-brightGray h-48 border border-yellow">
      chart figures goes here
    </div> -->
  </div>
</template>
<script>
import Counts from '../../../components/card/dashboard/Counts.vue';
import Messages from '../../../components/card/dashboard/Messages.vue';
import Statistics from '../../../components/card/dashboard/Statistics.vue';
export default {
  components: { 
     Statistics,
     Messages, Counts },
};
</script>
<style>
</style>
