import moment from "moment";

export const format = {
  filters: {
    formateNumber(number) {
      return Number(number) ? Number(number).toLocaleString() : 0;
    },
    toDDMMYYYY(date) {
      return moment.utc(date).format('DD-MM-YYYY');
    },
    toMMDDYYYY(date) {
      return moment.utc(date).format('MM-DD-YYYY');
    },
    toYYYYMMDD(date) {
      return moment.utc(date).format('YYYY-MM-DD');
    },
  }
};