<template>
  <div class="py-4 text-textMedium space-y-4">
    <div class="pb-2 text-2xl text-center border-b border-brightGray">
      Create Credential
    </div>
    <div class="space-y-4 px-4">
      <div class="space-y-4">
        <div class="grid grid-cols-2 gap-2">
          <base-input
            label="Full Name"
            placeholder="Full Name"
            v-model="Form.name"
            bordered
            required
            :errors="errors.name"
          />
          <base-input
            label="Username"
            placeholder="Username"
            v-model="Form.username"
            bordered
            required
            :errors="errors.username"
          />
        </div>
        <div class="md:grid grid-cols-2 gap-2">
          <base-select
            label="Role"
            :items="roles"
            v-model="Form.role"
            bordered
            required
            :searchable="true"
            :errors="errors.role"
          >
            <template #default="{ item }">
              <div class="flex space-x-2 items-center">
                <base-icon name="user" />
                <div class="capitalize">{{ item.label }}</div>
              </div>
            </template>
            <template #option="{ item }">
              <div class="flex space-x-2 items-center">
                <base-icon name="user" />
                <div>{{ item.label }}</div>
              </div>
            </template>
          </base-select>
          <base-select
            label="Select Branch"
            bordered
            searchable
            required
            placeholder="Search Branch..."
            :items="offices"
            v-model="Form.office"
          />
        </div>

        <div class="grid grid-cols-2 gap-2">
          <base-input
            type="password"
            label="Password"
            v-model="Form.password"
            bordered
            required
            :errors="errors.password"
          />
          <base-input
            type="password"
            label="Confirm Password"
            v-model="Form.confirm_password"
            bordered
            required
            @enter="handleRegister"
            :errors="errors.confirm_password"
          />
        </div>
        <div class="md:grid grid-cols-2 gap-2">
          <base-select
            label="Dashboard"
            :items="dashboardOptions"
            v-model="Form.dashboard"
            bordered
            :searchable="true"
            :errors="errors.role"
          >
            <template #default="{ item }">
              <div class="flex space-x-2 items-center">
                <base-icon name="user" />
                <div class="capitalize">{{ item.label }}</div>
              </div>
            </template>
            <template #option="{ item }">
              <div class="flex space-x-2 items-center">
                <base-icon name="user" />
                <div>{{ item.label }}</div>
              </div>
            </template>
          </base-select>
        </div>

      </div>
      <div class="flex justify-between py-2">
        <base-button
          label="cancel"
          class="
            py-2
            px-6
            text-textMedium
            bg-brightGray
            hover:bg-danger
            hover:text-error
            rounded
          "
          @click="close"
        />
        <base-button
          label="Save"
          class="py-2 px-6 rounded"
          :isWorking="working"
          @click="handleRegister"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import eventBus from '@/eventBus';
import { required, maxLength } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';
// import AutoComplete from '@/components/base/AutoComplete.vue';
export default {
  components: {  },
  mixins: [validation],
  props: ['customer'],
  data() {
    return {
      ROLES: [],
      office_id: undefined,
      working: false,
      meta: [
        { key: 'Form.name', label: 'Fullname' },
        { key: 'Form.username', label: 'Username' },
        { key: 'Form.password', label: 'Password' },
        { key: 'Form.confirm_password', label: 'Confirm Password' },
        { key: 'Form.role', label: 'Role' },
      ],
      Form: {
        name: undefined,
        password: undefined,
        confirm_password: undefined,
        username: undefined,
        role: undefined,
        office: undefined,
        dashboard: 'branch',
      },
      defaultForm: {
        studentSchoolId: undefined,
        password: undefined,
        confirm_password: undefined,
        username: undefined,
        role: undefined,
        office: undefined,
      },
      offices: [],
      roles: [],
      dashboardOptions: [
        {
          label: 'Organization',
          value: 'org'
        },
        {
          label: 'Branch',
          value: 'branch'
        },
        {
          label: 'Officer',
          value: 'officer'
        },
      ],
      errors: {},
      initial: true,
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    async fetchOffices() {
      let response = await this.request({
        method: 'get',
        url: 'billing/office',
      });
      if (response) {
        this.offices = response.items.map((office) => ({
          label: office.name,
          value: office._id,
        }));
      }
    },
    async fetchRoles() {
      let response = await this.request({
        method: 'get',
        url: 'billing/roles/roles',
      });
      if (response) {
        this.roles = response.items.map((role) => ({
          label: role.role_name,
          value: role._id,
        }));
      }
    },
    async handleRegister() {
      this.initial = false;
      if (!this.isValid()) {
        return;
      }

      if (this.Form.password !== this.Form.confirm_password) {
        this.errors.password = [
          'password should be the same as confirm password',
        ];
        this.errors.confirm_password = [
          'confirm password should be the same as password',
        ];

        return eventBus.$emit('open-toast', {
          error: true,
          message: 'Password and Confirm Password should be the same',
        });
      }
      this.working = true;
      let data = {
        name: this.Form.name,
        username: this.Form.username,
        password: this.Form.password,
        role: this.Form.role,
        office: this.Form.office,
      };
      let response = await this.request({
        method: 'post',
        url: 'billing/credential/signup',
        data: data,
      });
      if (response) {
        eventBus.$emit('open-toast', {
          message: 'Credential is created successfully',
        });
        this.initial = true;
        this.resetForm();
        this.$emit('close');
        this.$router.push('credentials');
      } else {
        eventBus.$emit('open-toast', {
          message: this.berror,
          error: true,
        });
      }
      this.working = false;
    },
    resetForm() {
      Object.keys(this.Form).forEach(
        (key) => (this.Form[key] = this.defaultForm[key] || undefined)
      );
    },
    close() {
      this.$emit('close');
    },
  },
  computed: {
    ...mapGetters('main', ['berror']),
  },
  mounted() {
    this.fetchRoles();
    this.fetchOffices();
    Object.keys(this.Form).forEach((key) => {
      this.$watch('Form.' + key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc) => {
          acc['password']= {required, maxLength: maxLength(15)}
          acc['confirm_password']= {required, maxLength: maxLength(15)}
          acc['username']= {required}
          acc['name']= {required}
          return acc;
        }, {}),
      }
    };
  },
};
</script>

<style></style>
