<template>
  <div class="text-textMedium space-y-4">
    <div
      class="text-2xl uppercase py-2 shadow-md text-textDark font-semibold bg-brighterGray text-center"
    >
      Organization REGISTRATION
    </div>
    <form class="space-y-4 p-4 pb-4 capitalize">
      <base-input
        type="text"
        placeholder="Organization full name"
        label="Organization name"
        bordered
        :readOnly="action == 'view'"
        required
        :min="0"
        v-model="Form.util_name"
        @enter="handleSave"
      />
      <div class="grid md:grid-cols-2 gap-2">
        <base-input
          placeholder="unique identification code"
          type="text"
          :readOnly="action == 'view'"
          label="Short code"
          bordered
          required
          :min="0"
          v-model="Form.util_id"
          @enter="handleSave"
        />
        <base-radio
          v-if="false"
          type="text"
          label="Allow Partial Payment"
          bordered
          required
          :min="0"
          :items="partialPaymentOptions"
          v-model="Form.allow_partial_payment"
          @enter="handleSave"
        />
        
        <base-input
          label="Phone Number"
          type="text"
          :readOnly="action == 'view'"
          placeholder="+251---------"
          bordered
          required
          :min="0"
          v-model="Form.tel"
          @enter="handleSave"
        />
        <base-input
          label="Account Number"
          type="text"
          :readOnly="action == 'view'"
          placeholder="-------------"
          bordered
          required
          :min="0"
          @blur="validateAccountNumber"
          v-model="Form.accountNumber"
          @enter="handleSave"
        />
        <base-select
        label="ORG. TYPE"
        :items="orgTypes"
        v-model="Form.org_type"
        bordered
        :searchable="true"
      />
      <base-radio
        type="radio"
        label="Use Ordered payment"
        bordered
        required
                :items="partialPaymentOptions"

        v-model="Form.ordered_payment"
      />
      <base-radio
        type="radio"
        label="Pay All at once"
        bordered
        required
        :items="partialPaymentOptions"
        v-model="Form.pay_all"
      />
      </div>
      <div class="flex justify-between mt-3">
        <base-button
          label="cancel"
          class="py-1 px-4 text-textMedium bg-brightGray hover:bg-danger hover:text-error rounded"
          @click="close"
        />
        <base-button
          :label="btnLabel"
          :isWorking="isWorking"
          class="py-1 px-4 rounded"
          @click="handleSave"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../eventBus';
export default {
  props: {
    action: {
      type: String,
      default: 'reg',
    },
    btnLabel: {
      type: String,
      default: 'Save',
    },
  },
  data() {
    return {
      isWorking: false,
      isValidAccount: false,
      isValidatingAccount: false,
      partialPaymentOptions: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
      orgTypes: [
        {
          label: 'School',
          value: "school",
        },
        {
          label: 'Events',
          value: "event",
        },
        {
          label: 'Sacco&MFI',
          value: "sacco",
        },
        {
          label: 'Water Service',
          value: "water",
        },
        {
          label: 'Others',
          value: "other",
        },
      ],
      Form: {
        util_name: undefined,
        util_id: undefined,
        allow_partial_payment: false,
        tel: undefined,
        accountNumber: undefined,
        org_type: undefined,
        ordered_payment: false,
        pay_all: false,
      },
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
  },
  methods: {
    ...mapActions('main', ['request']),
    async handleSave() {
      if(this.Form.accountNumber && !this.isValidAccount) {
        eventBus.$emit('open-toast', {
          error: true,
          message: "Account number is not valid",
        });
        return;
      }
      this.isWorking = true;
      const response = await this.request({
        method: 'post',
        url: 'billing/utiltypes',
        data: this.Form,
      });
      if (response) {
        this.isWorking = false;
        this.$emit('close', {
          invokeCallBack: true,
          response: response.item,
        });
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror,
        });
      }
      this.isWorking = false;
    },
    async validateAccountNumber() {
      this.isValidatingAccount = true;
      const response = await this.request({
        method: 'get',
        url: `billing/customer/account-validation/${this.Form.accountNumber}`,
      });
      if (response) {
        //
        this.isValidAccount = true;
      } else {
        this.isValidAccount = false;
        eventBus.$emit('open-toast', {
          error: true,
          message: this.berror,
        });
      }
      this.isValidatingAccount = false
    },
    close() {
      this.$emit('close');
    },
  },
  created() {
    if (this.$attrs.utilityOrg) {
      Object.assign(this.Form, this.$attrs.utilityOrg);
    }
  },
};
</script>

<style></style>
