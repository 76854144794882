<template>
  <div class="space-y-3 p-2">
    <smart-report :headers="headers" :title="title" :url="url" :query="{}">
      <template #header>
        <div class="flex justify-center w-full p-2 border border-primary">
          <div class="text-xl text-primary font-semibold">
            {{ title }}
          </div>
        </div>
      </template>
    </smart-report>
  </div>
</template>

<script>
import { currentDate } from '@/mixins/currentDate';
import SmartReport from '../SmartReport.vue';
export default {
  components: { SmartReport },
  mixins: [currentDate],
  data() {
    return {
      url: 'billing/reports/my-org-customers',
      headers: [
        {
          label: 'ID',
          key: 'studentSchoolId',
        },
        {
          label: 'FULL NAME',
          key: 'fullName',
        },
        {
          label: 'grade/Dep\'t',
          key: 'grade',
        },
        {
          label: 'section',
          key: 'section',
        },
        {
          label: 'TELEPHONE',
          key: 'tel',
        },
        {
          label: 'BRANCH',
          key: 'office.name',
        },
      ],
    };
  },
  computed: {
    title() {
      return `STUDENTS REPORT AS OF ${this.currentDate}`;
    },
  },
};
</script>

<style>
</style>