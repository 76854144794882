<template>
  <div class="space-y-2 text-textMedium">
    <div class="flex space-x-2 items-center" :class="{ 'text-sm': small }">
      <div class="flex-auto grid grid-cols-2 gap-2" v-if="searchable">
        <BaseInput
          placeholder="Search"
          bordered
          padding="small"
          v-model="search"
        >
          <template #suffix>
            <BaseButton
              :label="'(' + itemsFiltered.length + ')'"
              class="px-1 rounded-r uppercase max-w-fit"
              icon="filter"
              @click="openFiltersSelector"
            />
          </template>
        </BaseInput>
      </div>
      <div
        class="flex space-x-2 items-center"
        :class="{ 'flex-auto': !searchable }"
      >
        <div class="flex-auto"><slot name="toolset"></slot></div>

        <div
          v-if="hasDynamicActions && dynamicHandlers.length > 0"
          class="flex justify-end"
        >
          <actions
            :actions="dynamicHandlers"
            :item="selectedItem"
            :arrow="false"
          >
            <div
              class="
                flex
                space-x-2
                items-center
                bg-brighterGray
                hover:text-primary
                active:bg-brighterGray
                py-1
                px-2
                text-sm
                rounded
                cursor-pointer
                hover:bg-brightGray
              "
            >
              <div>actions</div>
              <BaseIcon name="chevron-down" :size="12" />
            </div>
          </actions>
        </div>
      </div>
    </div>
    <div
      class="w-full overflow-x-auto text-sm text-left text-gray-600 dark:text-gray-400"
    >
      <table class="table min-w-full">
        <thead
          class="font-sans text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400"
          :class="{ 'text-sm': small }"
        >
          <tr>
            <th
              v-for="(header, i) in headers"
              :key="'header' + i"
              class="px-6 py-3 text-left font-normal"
            >
              {{ header.label }}
            </th>
            <th v-if="hasActions && handlers.length > 0" class="p-2">
              Actions
            </th>
          </tr>
        </thead>
        <tbody v-if="isWorking" class="flex justify-center w-full min-w-full">
          <div class="flex w-full justify-center">
            <bar-loader />
          </div>
        </tbody>
        <tbody
          v-else-if="itemsFiltered.length > 0"
          class="space-y-2 text-15"
          :class="{ 'text-sm': small }"
        >
          <template v-for="(item, i) in itemsFiltered">
            <tr
              :key="item._id"
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600"
              :class="{
                'cursor-pointer': canSelect || hasDynamicActions,
                'bg-gray-200':
                  (canSelect || hasDynamicActions) && selectedId === item._id
              }"
              @click="toggleSelect(item._id)"
            >
              <slot :dtItem="item" :index="i">
                <template v-for="(header, j) in headers">
                  <th
                    v-if="header.key == 'status'"
                    :key="'jheader' + j"
                    scope="row"
                    class="px-6 py-0 font-medium whitespace-nowrap dark:text-white"
                  >
                    <div
                      class="w-full bg-opacity-25 inline-block px-2 py-1 text-sm capitalize tracking-wider font-semibold rounded"
                      :class="
                        'bg-' +
                          getColor(item.status) +
                          ' text-' +
                          getColor(item.status)
                      "
                    >
                      {{ getProp(item, 'status') }}
                    </div>
                  </th>

                  <th
                    v-else-if="header.key == 'isPaid'"
                    :key="'jheader' + j"
                    scope="row"
                    class="px-6 py-0 font-medium whitespace-nowrap dark:text-white"
                  >
                    <div
                      class="w-full bg-opacity-25 inline-block px-2 py-1 text-sm capitalize tracking-wider font-semibold rounded"
                      :class="{
                        'bg-green-300 text-green-700': item.isPaid,
                        'bg-red-300 text-red-700': !item.isPaid
                      }"
                    >
                      {{ item.isPaid ? 'Paid' : 'Unpaid' }}
                    </div>
                  </th>

                  <th
                    v-else-if="j == 0"
                    :key="'jheader' + j"
                    scope="row"
                    class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <span v-if="typeof getProp(item, header.key) == 'number'">
                      {{ getProp(item, headers[0].key) | formateNumber }}
                    </span>
                    <span v-else-if="headers[0].format == 'date'">
                      {{ getProp(item, headers[0].key) | toDDMMYYYY }}
                    </span>
                    <span v-else>
                      {{ getProp(item, headers[0].key) }}
                    </span>
                  </th>
                  <td
                    v-else
                    :key="'jheader' + j"
                    class="p-2"
                    :class="{
                      'text-xs px-6 py-3': small,
                      'text-15': !small,
                      'flex flex-no-wrap flex-shrink-0':
                        header.key == 'studentName'
                    }"
                  >
                    <span v-if="typeof getProp(item, header.key) == 'number'">
                      {{ getProp(item, header.key) | formateNumber }}
                    </span>

                    <div
                      v-else-if="header.key == 'studentName'"
                      class="text-textDark hover:underline cursor-pointer"
                    >
                      {{ getProp(item, header.key) }}
                    </div>
                    <span v-else-if="header.format == 'date'">
                      {{ getProp(item, header.key) | toDDMMYYYY }}
                    </span>
                    <span v-else>
                      {{ getProp(item, header.key) }}
                    </span>
                  </td>
                </template>
              </slot>
              <td v-if="hasActions && handlers.length > 0" class="p-2">
                <div class="flex justify-center items-center">
                  <actions :actions="handlers" :item="item" btnO9n="vertical" />
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td :colspan="headers.length" class="p-2 text-center">
              <slot name="no-data">No data</slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination
      v-if="paginate && itemsFiltered.length > 0"
      :currentPage="currentPage"
      :pageSize="pageSize"
      :totalPages="totalPages"
      @changepage="handlePageChange"
      @changepagesize="handlePageSizeChange"
    />
  </div>
</template>

<script>
import { getPropByString } from '@/utils';
import Pagination from '../pagination/Pagination.vue';
// import eventBus from '../../eventBus';
import Actions from '../card/Actions.vue';
import BarLoader from '../collection/BarLoader.vue';
export default {
  props: {
    headers: Array,
    items: Array,
    hasActions: Boolean,
    hasDynamicActions: Boolean,
    handlers: [],
    dynamicHandlers: Array,
    canSelect: Boolean,
    searchable: Boolean,
    paginate: Boolean,
    isWorking: Boolean,
    totalPages: Number,
    rowsPerPage: Number,
    name: String,
    hideCount: Boolean,
    small: Boolean,
    stripped: { type: Boolean, default: true }
  },
  components: { Pagination, Actions, BarLoader },
  data() {
    return {
      selectedId: undefined,
      currentPage: 1,
      pageSize: 10,
      search: '',
      filters: [this.headers[0].key]
    };
  },
  computed: {
    selected() {
      return this.headers.map(header => header.key);
    },
    itemsFiltered() {
      if (!this.search || this.filters.length === 0) return this.items;
      return this.items.filter(item =>
        this.filters.some(
          key =>
            String(this.getProp(item, key))
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) > -1
        )
      );
      // return this.items;
    },
    displayItems() {
      if (!this.paginate) return this.itemsFiltered;
      let startIndex = (this.currentPage - 1) * this.pageSize;
      let endIndex = startIndex + this.pageSize;
      if (endIndex > this.itemsFiltered.length)
        endIndex = this.itemsFiltered.length;
      return this.itemsFiltered.slice(startIndex, endIndex);
    },
    selectedItem() {
      return this.items.find(item => item._id === this.selectedId);
    }
  },
  watch: {
    search() {
      this.currentPage = 1;
    }
  },
  methods: {
    getProp: getPropByString,
    getColor(status = '') {
      let colors = {
        active: 'teal',
        paid: 'teal',
        partial_paid: 'blue-600',
        inactive: 'red-600',
        suspended: 'red-600',
        unpaid: 'red-600'
      };
      return colors[status.toLowerCase()] || 'orange';
    },
    hideDialog() {
      this.$refs['datatable-action-menu'].forEach(tippyEL =>
        tippyEL.tip.hide()
      );
    },
    smartHandler(actionHandler, item) {
      this.hideDialog();
      if (actionHandler?.fullParam) actionHandler.handler(item);
      else if (actionHandler?.idParam) actionHandler.handler(item._id);
      else actionHandler.handler();
    },
    unselect() {
      this.selectedId = undefined;
      this.$emit('unselect');
    },
    select(id) {
      this.selectedId = id;
      this.$emit('select', id);
    },
    toggleSelect(id) {
      if (this.canSelect || this.hasDynamicActions) {
        if (this.selectedId === id) return this.unselect();
        this.select(id);
      }
    },
    handlePageChange(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.$emit('pageChange', page);
      }
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.currentPage = 1;
      this.$emit('pageSizeChange', pageSize);
    },
    openFiltersSelector() {
      // let componentProps = { filters: this.filters, headers: this.headers };
      // eventBus.$emit('open-modal', {
      //   modalId: 31,
      //   componentProps,
      //   cb: this.updateFilters
      // });
    },
    updateFilters({ response: { filters } }) {
      this.filters = filters;
    }
  },
  created() {
    this.filters = this.selected;
    if (this.rowsPerPage && typeof this.rowsPerPage === 'number')
      this.pageSize = this.rowsPerPage;
  }
};
</script>

<style></style>
