
const state = {
  isLoading: false,
  reports: []
};

const mutations = {
  setLoading: (state, loading) => {
    state.isLoading = loading;
  },
  setReports: (state, items) => {
    state.reports = items;
  },
};
const actions = {
  async setLoading({ commit }, loading) {
      commit('setLoading', loading);
      return true;
  },
  async setReports({ commit }, items) {
      commit('setReports', items);
      return true;
  },
  clear({ commit }) {
    commit('setLoading', false);
    commit('setReports', []);
    return true;
  }
};

const getters = {
  isLoading: state => state.isLoading,
  reports: state => state.reports
};

const report = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

export default report;
