<template>
  <div>
    <div class="p-2 space-y-2 px-2">
      <div
        v-if="payment.customer"
        class="flex w-full shadow-md space-x-2 items-center"
      >
        <div
          class="flex-grow-0 rounded border border-borderGray w-16 h-16 m-2"
        ></div>
        <div class="flex-grow space-y-0">
          <div class="text-lg text-textMedium font-semibold">
            {{ payment.fullName }}
          </div>
          <div class="text-sm text-light">
            {{ payment.cust_util_id }}
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-2 py-2">
        <div class="uppercase text-sm text-textLight">PAYMENT TYPE</div>
        <div class="text-lg text-textMedium p0">
          {{ payment.util_payment_id.payment_type }}
        </div>
      </div>
      <div class="grid grid-cols-2 gap-2 py-2">
        <div class="uppercase text-sm text-textLight">PAYMENT DUE DATE</div>
        <div class="text-lg text-textMedium p0">
          {{ payment.util_payment_id.payment_due_date | toDDMMYYYY }}
        </div>
      </div>
      <div class="grid grid-cols-2 gap-2 py-2" v-if="penalty">
        <div class="uppercase text-sm text-textLight">PENALTY</div>
        <div class="text-lg text-textMedium p0">
          {{ penalty | formateNumber }}
        </div>
      </div>
      <div class="grid grid-cols-2 gap-2 py-2" v-if="discount">
        <div class="uppercase text-sm text-textLight">DISCOUNT</div>
        <div class="text-lg text-textMedium p0">
          {{ discount | formateNumber }}
        </div>
      </div>
      <div class="grid grid-cols-2 gap-2 py-2">
        <div class="uppercase text-sm text-textLight">AMOUNT</div>
        <div class="text-lg text-textMedium p0">
          {{ payment.total_amount | formateNumber }}
        </div>
      </div>
      <div class="grid grid-cols-2 gap-2 py-2">
        <div class="uppercase text-sm text-textLight">REMAINING</div>
        <div class="text-lg text-textMedium p0">
          {{ totalRemainingAmount | formateNumber }}
        </div>
      </div>

      <div v-if="customFields.length > 0 && payment.other">
        <template>
          <div
            v-for="{ key, name: label } in customFields"
            :key="key"
            class="grid grid-cols-2 gap-2 py-2"
            :class="{ 'bg-brightGray': i % 2 == 1 }"
          >
            <div class="uppercase text-sm text-textLight">
              {{ label.split('_').join(' ') }}
            </div>
            <div class="text-lg text-textMedium p0">
              {{ payment.other[key] }}
            </div>
          </div>
        </template>
      </div>

      <div class="grid grid-cols-2 gap-2 bg-brightGray py-2">
        <div class="uppercase text-sm text-textLight">STATUS</div>
        <div
          class="text-lg p-0 font-semibold uppercase"
          :class="{
            'text-lightDanger': ['unpaid', 'partial-paid'].includes(
              payment.status
            ),
            'text-info': ['paid'].includes(payment.status)
          }"
        >
          {{ payment.status }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import billCustomFields from '../../../../mixins/billCustomFields';
export default {
  props: ['payment'],
  mixins: [billCustomFields],
  data() {
    return {
      penalty: 0,
      discount: 0
    };
  },
  computed: {
    totalRemainingAmount() {
      const { status, total_amount, remaining_amount } = this.payment;
      if (status == 'unpaid') {
        return total_amount + this.penalty - this.discount;
      } else if (status == 'partial paid') {
        return remaining_amount;
      }
      return remaining_amount;
    }
  },
  watch: {
    totalRemainingAmount: {
      deep: true,
      immediate: true,
      handler: function(val) {
        this.$emit('setTotalAmount', val);
      }
    }
  },
  methods: {
    calculate(formula = '') {
      if (!formula) return;
      let data = formula.toString().toLowerCase();
      if (data.includes('(')) {
        const start = data.lastIndexOf('(');
        const end = data.indexOf(')', start);
        const temp = data.slice(start + 1, end);
        const res = this.calBracket(temp);
        data.splice(start, end - start + 1, res);
        return this.calculate(data);
      } else return this.calBracket(data);
    },
    calBracket(items) {
      let data = items.split(',');
      const operators = ['*', '/', '%', '+', '-'];
      let dataOp = data
        .filter(e => operators.includes(e))
        .sort((a, b) => operators.indexOf(a) - operators.indexOf(b));
      let index;
      let res;
      dataOp.forEach(op => {
        switch (op) {
          case '*':
            index = data.indexOf('*');
            res = this.getData(data, index - 1) * this.getData(data, index + 1);
            return data.splice(index - 1, 3, res);
          case '/':
            index = data.indexOf('/');
            res = this.getData(data, index - 1) / this.getData(data, index + 1);
            return data.splice(index - 1, 3, res);
          case '%':
            index = data.indexOf('%');
            res = this.getData(data, index - 1) % this.getData(data, index + 1);
            return data.splice(index - 1, 3, res);
          case '+':
            index = data.indexOf('+');
            res = this.getData(data, index - 1) + this.getData(data, index + 1);
            return data.splice(index - 1, 3, res);
          case '-':
            index = data.indexOf('-');
            res = this.getData(data, index - 1) - this.getData(data, index + 1);
            return data.splice(index - 1, 3, res);
        }
      });
      return data[0];
    },
    getData(data, index) {
      if (typeof data[index] == 'number') return Number(data[index]);
      else if (data[index] == 'late_dates')
        return moment(moment.now()).diff(
          moment(this.payment.util_payment_id.payment_due_date),
          'days'
        );
      else if (data[index] == 'early_dates') {
        return moment(this.payment.util_payment_id.payment_active_date).diff(
          moment(moment.now()),
          'days'
        );
      } else if (data[index] == 'total_amount')
        return Number(Number(this.payment['total_amount']).toFixed(2));
      else return Number(Number(data[index]).toFixed(2));
    }
  },
  created() {
    if (this.payment.status.toLowerCase() !== 'Paid') {
      if (
        this.payment.util_payment_id.due_date_formula.length > 0 &&
        moment(moment.now()).isAfter(
          moment(this.payment.util_payment_id.payment_due_date)
        )
      ) {
        const penalityformula = this.payment.util_payment_id.due_date_formula;
        this.penalty = this.calculate(penalityformula);
        this.$emit('setPenalty', this.penalty);
      }
      if (
        this.payment.util_payment_id.forward_payment_discount_formula.length >
          0 &&
        moment(moment.now()).isBefore(
          moment(this.payment.util_payment_id.payment_due_date)
        )
      ) {
        const discountformula = this.payment.util_payment_id
          .forward_payment_discount_formula;
        this.discount = this.calculate(discountformula);
        this.$emit('setDiscount', this.discount);
      }
    }
  }
};
</script>
<style></style>
