<template>
  <div class="space-y-3 p-2">
    <smart-report
      :headers="headers"
      :title="title"
      :url="url"
      :has_query="hasQuery"
    >
      <template #header>
        <div class="flex justify-center w-full p-2 border border-primary">
          <div class="text-xl text-primary font-semibold">
            {{ title }}
          </div>
        </div>
      </template>
      <template #toolset>
        <div class="flex justify-start space-x-4">
          <base-select
            v-model="selectedId"
            :items="documents"
            label="Organization"
          />
          <base-radio v-model="status" :items="statusOptions" label="Status" />
        </div>
      </template>
    </smart-report>
  </div>
</template>

<script>
import { currentDate } from '@/mixins/currentDate';
import SmartReport from '../SmartReport.vue';
import { mapActions } from 'vuex';
export default {
  components: { SmartReport },
  mixins: [currentDate],
  data() {
    return {
      selectedId: '',
      documents: [],
      status: '',
      statusOptions: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'Paid',
          value: 'paid',
        },
        {
          label: 'Partial paid',
          value: 'partial paid',
        },
        {
          label: 'Unpaid',
          value: 'unpaid',
        },

      ],
      headers: [
        {
          label: 'PAYMENT TYPE',
          key: 'payment_type',
        },
        {
          label: 'DUE DATE',
          key: 'payment_due_date',
          format: 'date',
        },
        {
          label: 'DESCRIPTION',
          key: 'description',
        },
        {
          label: 'TOTAL AMOUNT',
          key: 'total_amount',
        },
        {
          label: 'TOTAL STUDENTS',
          key: 'total_customers',
        },
      ],
    };
  },
  computed: {
    hasQuery() {
      return this.status ? true : false;
    },
    title() {
      return (
        (this.selectedItem ? this.selectedItem.label : '') +
        ` ${this.status.toUpperCase()} PAYMENTS REPORT AS OF ${this.currentDate}`
      );
    },
    url() {
      if (!this.selectedId) return '';
      return (
        'billing/reports/org-payments/' +
        this.selectedId +
        (this.status ? '?status=' + this.status : '')
      );
    },
    selectedItem() {
      if (!this.selectedId) return '';
      return this.documents.find(({ value }) => value == this.selectedId);
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    async fetchDocuments() {
      const response = await this.request({
        url: 'billing/utiltypes',
        method: 'get',
      });
      if (response) {
        this.documents = response.items.map(({ _id, util_name }) => ({
          label: util_name,
          value: _id,
        }));
      }
    },
  },
  created() {
    this.fetchDocuments();
  },
};
</script>

<style>
</style>