import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';
import main from './modules/main';
import timer from './modules/timer';
import printer from './modules/printer';
import socket from './modules/socket';
import report from './modules/report';
import customer from './modules/customer';
import dashboard from './modules/dashboard';
import migration from './modules/migration';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    main,
    timer,
    printer,
    socket,
    report,
    customer,
    dashboard,
    migration,
  }
});
