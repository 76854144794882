<template>
  <div>
    <apexchart
      ref="bankTransactionChart"
      type="area"
      height="500"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: ['categories', 'series'],
  data() {
    return {
      chartOptions: {
        chart: {
          id: 'bank-transaction-chart',
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 2,
            color: '#000',
            opacity: 0.2,
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
            export: {
              csv: {
                filename: 'Report',
                columnDelimiter: ',',
                headerCategory: 'date',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              png: {
                filename: 'Transactions',
              },
              svg: {
                filename: 'Transactions',
              },
            },
            autoSelected: 'zoom', // accepts -> zoom, pan, selection
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: true,
          borderColor: '#ededed',
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          row: {
            colors: undefined, // takes as array which will be repeated on rows
            opacity: 0.5,
          },
          column: {
            colors: undefined, // takes an array which will be repeated on columns
            opacity: 0.5,
          },
          padding: {
            top: 0,
            right: 10,
            bottom: 0,
            left: 12,
          },
        },
        legend: {
          show: false,
        },
        noData: {
          text: 'No Transactions',
          align: 'center',
          verticalAlign: 'middle',
        },
        stroke: {
          curve: 'smooth',
          width: 3,
        },
        title: {
          text: '',
        },
        yaxis: {
          labels: {
            show: false,
          },
          enabled: false,
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: false,
          followCursor: false, // when disabled, the tooltip will show on top of the series instead of mouse position
          intersect: false, // when enabled, tooltip will only show when user directly hovers over point
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: true,
          theme: 'dark',
          style: {
            fontSize: '22px',
            color: 'white',
            fontWeight: '700',
            fontFamily: 'sans',
          },
          onDatasetHover: {
            highlightDataSeries: false,
          },
          x: {
            // x value
            show: false,
            format: 'dd MMM', // dd/MM, dd MMM yy, dd MMM yyyy
            formatter: undefined, // a custom user supplied formatter function
          },
          y: {
            formatter(val) {
              return val.toLocaleString() + ' ETB ';
            },
            title: {
              formatter(seriesName) {
                return seriesName ? seriesName + ': ' : '';
              },
            },
          },
          z: {
            formatter: undefined,
            title: 'Size: ',
          },
          marker: {
            show: true,
            fillColors: undefined,
          },
          items: {
            display: 'flex',
          },
          fixed: {
            enabled: false,
            position: 'topRight', // topRight, topLeft, bottomRight, bottomLeft
            offsetX: 0,
            offsetY: 0,
          },
        },
        colors: ['#4600FF', '#FF0074', '#04A9F5'],
        xaxis: {
          labels: {
            style: {
              fontWeight: 100,
              colors: ['#8993a4'],
            },
          },
          categories: [],
        },
      },
      // series: [
      //   {
      //     name: 'Cash',
      //     data: [30, 40, 45, 50, 49, 60, 70, 81],
      //   },
      //   {
      //     name: 'Transfer',
      //     data: [20, 40, 60, 50, 80, 140, 70, 81],
      //   },
      //   {
      //     name: 'Total',
      //     data: [50, 50, 105, 100, 129.15, 120, 140, 140],
      //   },
      // ],
    };
  },
  watch: {
    categories: {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (val.length > 0) this.updateCategories();
      },
    },
    series: {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (val.length > 0) this.updateSeries();
      },
    },
    type: {
      deep: true,
      immediate: true,
      handler: function (type) {
        if(this.$refs.bankTransactionChart)
        this.$refs.bankTransactionChart.updateOptions({
          // this.$apexcharts.exec('bank-transaction-chart', 'updateOptions', {
          tooltip: {
            y: {
              formatter(val) {
                return val.toLocaleString() + (type == 'amount' ? 'ETB' : '');
              },
              title: {
                formatter(seriesName) {
                  return seriesName ? seriesName + ': ' : '';
                },
              },
            },
          },
        });
      },
    },
  },
  computed: {
    ...mapGetters('dashboard', ['type']),
  },
  methods: {
    updateCategories() {
      this.chartOptions = {
        ...this.chartOptions,
        ...{ xaxis: { categories: this.categories } },
      };
      // this.$apexcharts.exec('bank-transaction-chart', 'updateOptions', {
      //   // xaxis: { categories: this.categories },
      // });
      // this.$refs.bankTransactionChart.updateOptions({
      //   xaxis: { categories: this.categories },
      // });
    },
    updateSeries() {
      this.$apexcharts.exec(
        'bank-transaction-chart',
        'updateSeries',
        this.series || []
      );
    },
  },
  created() {
    // this.chartOptions.xaxis.categories = this.categories;
  },
};
</script>

<style>
</style>