<template>
  <div class="w-full space-y-1 text-textMedium">
    <label style="font-size: 16px" class="text-textMedium" v-if="label">{{
      label
    }}</label>
    <div>
      <textarea
        :rows="rows"
        :style="'font-size: ' + size + 'px;'"
        v-bind="$attrs"
        @input="handleInput"
        class="
          w-full
          custom-text-input
          p-2
          px-3
          pr-6
          rounded
          tracking-widest
          text-justify text-textMedium
        "
        :class="{
          'bg-brightGray': success && !bordered,
          'bg-danger': !success && !bordered,
          'border-textLight': success && bordered,
          'border-error': !success && bordered,
          'border rounded': bordered
        }"
      ></textarea>
    </div>
    <div v-if="!success" class="text-xs" style="color: red">
      <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: String,
    size: { type: Number, default: 16 },
    errors: { type: Array, default: () => [] },
    bordered: { type: Boolean, default: false },
    rows: { type: Number, default: 3 }
  },
  computed: {
    success() {
      return this.errors.length === 0;
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', event.target.value);
    }
  }
};
</script>

<style>
.custom-text-input:focus {
  outline-color: #1763ad;
}
.custom-text-input::placeholder {
  font-size: 14px;
}
</style>
