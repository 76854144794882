<template>
  <div class="space-y-3 p-2">
    <smart-report :headers="headers" :title="title" :url="url" :has_query="hasQuery">
      <template #header>
        <div class="flex justify-center w-full p-2 border border-primary">
          <div class="text-xl text-primary font-semibold">
            {{ title }}
          </div>
        </div>

      </template>
      <template #toolset>
        <div class="flex justify-between space-x-4">
          <base-select v-model="selectedId" :items="documents" label="Branch" />
          <base-radio v-model="status" :items="statusOptions" label="Status" />
        </div>
      </template>
    </smart-report>
  </div>
</template>

<script>
import { currentDate } from '@/mixins/currentDate';
import SmartReport from '../SmartReport.vue';
import { mapActions } from 'vuex';
export default {
  components: { SmartReport },
  mixins: [currentDate],
  data() {
    return {
      selectedId: '',
      documents: [],
      status: '',
      statusOptions: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'Paid',
          value: 'paid',
        },
        {
          label: 'Partial paid',
          value: 'partial paid',
        },
        {
          label: 'Unpaid',
          value: 'unpaid',
        },

      ],
      headers: [
        {
          label: 'ID',
          key: 'cust_util_id',
        },
        {
          label: 'STUDENT NAME',
          key: 'fullName',
        },
        {
          label: 'TOTAL AMOUNT',
          key: 'total_amount',
        },
        {
          label: 'REMAINING AMOUNT',
          key: 'remaining_amount',
        },
        {
          label: 'Payment Type',
          key: 'util_payment_id.payment_type',
        },
        {
          label: 'Status',
          key: 'status',
        },
      ],
    };
  },
  computed: {
    hasQuery() {
      return this.status ? true : false;
    },
    title() {
      return (
        (this.selectedItem ? this.selectedItem.label : '') +
        ` ${this.status.toUpperCase()} PAYMENTS REPORT AS OF ${this.currentDate}`
      );
    },
    url() {
      if (!this.selectedId) return '';
      return 'billing/reports/uploaded-branch-bills/' + this.selectedId+
        (this.status ? '?status=' + this.status : '');
    },
    selectedItem() {
      if (!this.selectedId) return '';
      return this.documents.find(({ value }) => value == this.selectedId);
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    async fetchDocuments() {
      const response = await this.request({
        url: 'billing/office/verified',
        method: 'get',
      });
      if (response) {
        this.documents = response.items.map(({ _id, name }) => ({
          label: name,
          value: _id,
        }));
      }
    },
  },
  created() {
    this.fetchDocuments();
  },
};
</script>

<style>
</style>