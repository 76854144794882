<template>
  <div
    v-if="open"
    class="toast bg-white p-4 fixed rounded shadow-2xl space-y-1 flex space-x-4 items-start"
    style="min-width: 25vw;max-width: 30vw;"
  >
    <div class="py-2">
      <BaseIcon
        v-if="error"
        name="exclamation-circle"
        :size="18"
        class="text-error"
      />
      <BaseIcon v-else name="check-circle-s" :size="20" class="text-success" />
    </div>

    <div class="flex-auto">
      <div
        :class="error ? 'text-error' : 'text-success'"
        class="text-sm font-bold font-sans tracking-wider"
      >
        {{ error ? 'Error' : 'Success' }}
      </div>
      <div class="text-sm text-textMedium">
        <div v-html="message"></div>
      </div>
      <div class="text-error font-semibold">{{ warn }}</div>
    </div>
    <div
      class="py-2 cursor-pointer text-textMedium hover:text-primary grow-active"
      @click="handleClose"
    >
      <BaseIcon name="times" />
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus.js';
export default {
  data() {
    return {
      open: false,
      message: 'No message',
      warn: '',
      error: false
    };
  },
  methods: {
    handleClose() {
      this.open = false;
    },
    closeLater(timeout) {
      setTimeout(() => {
        this.handleClose();
      }, timeout * 1000);
    }
  },
  created() {
    eventBus.$on(
      'open-toast',
      ({ message, warn, timeout = 30, error = false }) => {
        this.message = message;
        this.warn = warn;
        this.error = error;
        this.open = true;
        this.closeLater(timeout);
      }
    );
  }
};
</script>

<style>
.toast {
  left: 6rem;
  bottom: 4rem;
  z-index: 10010;
  animation: shakeToast 0.7s;
}
@keyframes shakeToast {
  0% {
    transform: translateY(-30%);
  }
  50% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
