<template>
  <div id="app" class="h-screen subpixel-antialiased">
    <printer v-if="printer_on" />
    <div v-show="!printer_on" class="h-screen">
      <div
        class="hidden tippy-tooltip light-theme primary-theme tippy-popper tippy-content tippy-arrow"
      ></div>
      <router-view />
      <toast />
      <notification />
      <modals />
      <timer v-if="signedIn" v-working="resetInactivityCounter" />
      <socket v-if="signedIn" />
    </div>
  </div>
</template>
<script>
import Toast from './components/collection/Toast.vue';
import Notification from './components/collection/Notification.vue';
import Modals from '@/components/modals/Modals.vue';
import { mapActions } from 'vuex';
import eventBus from './eventBus';
import Timer from './components/card/Timer.vue';
import { tokenService } from '@/services/storageService';
import Printer from './components/printer/Printer.vue';
import { mapGetters } from 'vuex';
import Socket from './components/socket/socket.vue';
import axios from 'axios';

export default {
  components: {
    Toast,
    Modals,
    Timer,
    Printer,
    Socket,
    Notification,
  },
  computed: {
    ...mapGetters('printer', ['printer_on']),
    ...mapGetters('socket', ['sioClient', 'clientConnected']),
    ...mapGetters(['user']),
    signedIn() {
      return !!tokenService.getToken();
    },
  },
  watch: {
    clientConnected: {
      immediate: true,
      handler() {
        if (this.clientConnected) {
          axios.defaults.headers.common['socket'] = this.sioClient
            ? this.sioClient.id
            : '';
        }
      },
    },
  },
  methods: {
    ...mapActions(['logout']),
    ...mapActions('timer', ['reset']),
    ...mapActions('main', ['getMyAccounts']),
    resetInactivityCounter() {
      this.reset({ stateKey: 'inactivity' });
    },
  },
  created() {
    eventBus.$on('sign-out', async () => {
      let signedOut = await this.logout();
      if (signedOut) window.location.reload();
    });

    eventBus.$on('reset-inactivity-counter', () =>
      this.resetInactivityCounter()
    );

    // eventBus.$on('sign-out', () => {
    //   this.$router.push('signin');
    // });
  },
};
</script>

<style src="./styles/styles.css"></style>
<style src="./styles/tippy.css"></style>
<style>
/* @import url('https://fonts.googleapis.com/css?family=Roboto'); */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype'); /**local */
}
body {
  font-family: 'Roboto';
}
</style>
