<template>
  <div class="flex space-x-3 items-center">
    <div class="w-20 h-20 bg-brightGray rounded-full">
      <img
        class="w-full h-full rounded-full hover:opacity-75"
        v-if="photo"
        :src="PROFILE_PATH + photo"
        v-viewer="{ inline: false, movable: true }"
        alt=""
      />
    </div>
    <div>
      <div class="text-xl">{{ customer.fullName }}</div>
      <div>{{ customer.tel }}</div>
    </div>
    <div class="flex-auto flex justify-end">
      <div
        class="rounded-full px-3 inline-block"
        :class="
          customer.status.toLowerCase() === 'verified'
            ? 'bg-teal text-white'
            : 'bg-danger text-error'
        "
      >
        {{ customer.status }}
      </div>
    </div>
  </div>
</template>

<script>
import { PROFILE_PATH } from '@/config/config';
export default {
  props: ['customer'],
  data() {
      return {
          PROFILE_PATH,
      }
  },
};
</script>

<style>
</style>